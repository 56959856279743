// Import statements at the top of the file
import {
  all,
  call,
  delay,
  fork,
  put,
  race,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import AccessData from "../../../Components/AccessData/AccessData";
import { USER_TYPE_ACCESS_LIST_SAGA } from "./actionType";
import {
  successUserTypeAccessList
} from "./action";
import { setSnackbarMessage } from "../../search/action";
import { loadingViaRedux } from "../../actions";

// Destructure the data from AccessData
const { RATeam,BDM,Admin,RecruitmentTeam,SuperAdmin,SpecialFeatures } = AccessData;

// Define the API URL
const apiUrl = process.env.REACT_APP_API_URL;
// function removeDuplicateLinks(menu) {
//   const uniqueMenu = [];
//   const links = new Set();

//   // Function to check if link is unique and add it to the set
//   function addUniqueItem(item) {
//     if (!item.linkTo || !links.has(item.linkTo)) {
//       if (item.linkTo) {
//         links.add(item.linkTo);
//       }
//       uniqueMenu.push(item);
//     }
//   }

//   for (const item of menu) {
//     // Add top-level menu item if unique
//     addUniqueItem(item);

//     // Check subMenu items if they exist
//     if (item.subMenu && item.subMenu.length > 0) {
//       const uniqueSubMenu = [];
//       for (const subItem of item.subMenu) {
//         if (!links.has(subItem.linkTo)) {
//           uniqueSubMenu.push(subItem);
//           links.add(subItem.linkTo);
//         }
//       }
//       item.subMenu = uniqueSubMenu; // Assign the unique subMenu items back
//     }
//   }

//   return uniqueMenu;
// }
// Function to combine menu items
const combineMenuItems = (items) => {
  const combinedItems = {};
  
  items.forEach(item => {
      const key = `${item.title}-${item.linkTo}`;
      
      if (!combinedItems[key]) {
          combinedItems[key] = { ...item, subMenu: [] };
      }
      
      // Combine subMenu items if they exist
      if (item.subMenu.length > 0) {
          item.subMenu.forEach(subMenuItem => {
              const existingSubMenu = combinedItems[key].subMenu.find(sub => sub.linkTo === subMenuItem.linkTo);
              if (!existingSubMenu) {
                  combinedItems[key].subMenu.push(subMenuItem);
              }
          });
      }
  });
  
  return Object.values(combinedItems);
};
// Function to filter based on user paths
function filterMenu(menuItems, userPaths) {
  return menuItems.reduce((acc, item) => {
      // If item link matches user path, add the item
      if (userPaths.includes(item.linkTo)) {
          acc.push(item);
      } 
      // If subMenu exists, check if any of the submenu links match
      else if (item.subMenu.length > 0) {
          const filteredSubMenu = item.subMenu.filter(sub => userPaths.includes(sub.linkTo));
          if (filteredSubMenu.length > 0) {
              acc.push({
                  ...item,
                  subMenu: filteredSubMenu
              });
          }
      }
      return acc;
  }, []);
}


function updateUsersTypesViaApiSaga(payload) {
  let Options = [];

  // Merge menu options based on user types
  if (payload.userDetails.userType.includes('RATeam')) {
    Options.push(...RATeam);
  }
  if(payload.userDetails.userType.includes('BDM')){
    Options.push(...BDM);
  }
  if(payload.userDetails.userType.includes('Admin')){
    Options.push(...Admin);
  }
  if(payload.userDetails.userType.includes('RecruitmentTeam')){
    Options.push(...RecruitmentTeam);
  }
  if(payload.userDetails.userType.includes('SuperAdmin') || payload.userDetails.userType.includes('Developer')){
    Options.push(...SuperAdmin);
  }
  if(payload.userDetails.specialAccessList){
    const takeSpecial= Array.isArray(payload.userDetails.specialAccessList) ? payload.userDetails.specialAccessList : []
   const filterMenuData  = filterMenu(SuperAdmin, takeSpecial);
   console.log('filterMenuData',filterMenuData)
   Options.push(...filterMenuData);
  }
console.log('Options',Options);
  // Remove duplicaes, considering both top-level and subMenu items
  const uniqueOptions = combineMenuItems(Options);

  console.log('Unique Options', uniqueOptions);
  return uniqueOptions;
}


// Saga function to handle user type access list actions
function* userTypeAccessListSagaAction(action) {
  try {
      const { response, timeout } = yield race({
          response: call(updateUsersTypesViaApiSaga, action.payload), // Call the API function
          timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
      if (response) {
          // Handle response (currently commented out)
          // if (response.error === "An internal server error occurred") {
          //   yield put(setSnackbarMessage(response.error, "error"));
          //   return null;
          // }

          // if (response.error) {
          //   yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          //   return null;
          // } else if (response.message) {
          //   yield put(setSnackbarMessage(response.message, "success"));
          // }
          yield put(successUserTypeAccessList(response))
      }
  } catch (error) {
      // Handle error (currently empty)
  }
}

// Root saga to watch for actions
function* layoutSaga() {
  yield takeEvery(USER_TYPE_ACCESS_LIST_SAGA, userTypeAccessListSagaAction);
}

export default layoutSaga;
