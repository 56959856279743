import {
    all,
    call,
    delay,
    fork,
    put,
    race,
    takeEvery,
    takeLatest,
  } from "redux-saga/effects";
  import {
    ADMIN_APPROVED_COMPANIES_VIA_API,
    ALL_RATEAM_COMPANIES_COUNT_VIA_PI,
    APPROVAL_TRANSFER_VIA_API,
    CLIENT_STATUS_VIA_API,
    COMPANY_BLOCKED_AND_DNC_VIA_API,
    COMPANY_BLOCKED_VIA_API,
    COMPANY_DNC_VIA_API,
    DATE_SEARCH_BDMS_COMPANIES_VIA_API,
    EMPLOYEE_BLOCKED_AND_DNC_VIA_API,
    EMPLOYEE_BLOCKED_VIA_API,
    EMPLOYEE_DNC_VIA_API,
    GET_ALL_EMAILS_LOGS_VIA_API,
    GET_BDM_COMPANIES_VIA_API,
    GET_SENT_EMAILS_VIA_API,
    JOB_STATUS_CHANGE_VIA_API,
    NO_FALLOWUPS_VIA_API,
    OTHER_EMAILS_SENT_VIA_API,
    SEARCH_NOT_ACTIVES_COMPANIES_VIA_API,
    SUCCESS_ALL_RATEAM_COMPANIES_COUNT,
    TRANSFER_BDM_VIA_API
  } from "./actionType";
  import { json } from "react-router-dom";
import { getCompaniesSuccess, setSnackbarMessage } from "../search/action";
import { successBdmCompanies, successDateSearchBdmsCompanies, successGetAllEmailsLogs, successGetSentEmails, successNotActivesCompanies, successOtherEmailsSent } from "./action";
import { loadingViaRedux } from "../reports/action";
  
  
const apiUrl = process.env.REACT_APP_API_URL;
 
  function allRaTeamCompaniesCountSagaApi(){
    return fetch(apiUrl + "/companyData/RATeamCount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function clientStatusViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/companyData/clientStatus", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function getBdmCompaniesViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/companyData/BdmCompanies", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function getSentEmailsViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/emailLogs/DateSearch", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function jobStatusChangeViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/companyData/jobStatus", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }

  function searchNotActiviesCompaniesViaApiSaga(payload){
    console.log('payload',payload);
    const client = payload.client; // Extract client from payload
    console.log('client', client);
   
  const myPayload = JSON.stringify({ client: client });
    return fetch(apiUrl + "/companyData/notActives", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function adminApprovedCompaniesViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/companyData/approvalStatus", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function dateSearchBdmsCompaniesViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/companyData/BdmsCompaniesList", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }

  function otherEmailsSentViaApiSaga(){
    return fetch(apiUrl + "/otherEmails/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function getAllEmailsLogsViaApiSaga(){
    return fetch(apiUrl + "/emailLogs/", {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function companyDNCViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/companyDNCs/addCompany", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  
  function employeeDNCViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/employeeDNCs/addEmail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function companyBlockedViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/companyBlockeds/addCompany", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function employeeBlockedViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/employeeBlockeds/addEmail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function noFallowupsViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/noFallowUps/addCompany", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function transferBdmViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/companyData/transferBdm", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function approvalTransferViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/companyData/approvalTransFerBdm", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function companyBlockedAndDncViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/blockedndncs/addCompany", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function employeeBlockedAndDncViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/blockedndncs/addEmail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function* allRaTeamCompaniesCountSaga() {
    try {
      const { response, timeout } = yield race({
        response: call(allRaTeamCompaniesCountSagaApi), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
          return null;
        }
        
  
        yield put({ type: SUCCESS_ALL_RATEAM_COMPANIES_COUNT, payload: response });
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* clientStatusViaSaga(action) {
    try {
      const { response, timeout } = yield race({
        response: call(clientStatusViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
  
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        } else if (response.message) {
          yield put(setSnackbarMessage(response.message, "success"));
        }
        
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* getBdmCompaniesViaSaga(action) {
    try {
      const { response, timeout } = yield race({
        response: call(getBdmCompaniesViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
  
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        yield put(loadingViaRedux(true))
          yield put(successBdmCompanies(response));
        
        
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* getSentEmailsViaSaga(action) {
    try {
      const { response, timeout } = yield race({
        response: call(getSentEmailsViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
  
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
      
          yield put(successGetSentEmails(response));
          yield put(successGetAllEmailsLogs(response)); // Dispatch error message with 'error' variant
        
        
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* jobStatusChangeViaSaga(action) {
  
    try {
      const { response, timeout } = yield race({
        response: call(jobStatusChangeViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
        ;
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
  
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        }
        if (response.message) {
          yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        }
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* searchNotActiviesCompaniesViaSaga(action) {
  
    try {
      const { response, timeout } = yield race({
        response: call(searchNotActiviesCompaniesViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
        ;
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
  
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        yield put(loadingViaRedux(true))
        yield put(successNotActivesCompanies(response))
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* adminApprovedCompaniesViaSaga(action) {
  
    try {
      const { response, timeout } = yield race({
        response: call(adminApprovedCompaniesViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
      
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
  
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        if (response.message) {
          yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        }
       
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  
  function* dateSearchBdmsCompaniesViaSaga(action) {
  
    try {
      const { response, timeout } = yield race({
        response: call(dateSearchBdmsCompaniesViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
    
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
  
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        yield put(loadingViaRedux(true))
      
          yield put(successDateSearchBdmsCompanies(response)); // Dispatch error message with 'error' variant
        
       
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }

  function* otherEmailsSentViaSaga() {
  
    try {
      const { response, timeout } = yield race({
        response: call(otherEmailsSentViaApiSaga), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
      
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
  
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        if (response.message) {
          yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        }
       
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* getAllEmailsLogsViaSaga(action) {
  
    try {
      const { response, timeout } = yield race({
        response: call(getSentEmailsViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
      
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
  
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        yield put(loadingViaRedux(true))
        yield put(successGetAllEmailsLogs(response)); // Dispatch error message with 'error' variant
       
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* companyDNCViaSaga(action) {
  
    try {
      const { response, timeout } = yield race({
        response: call(companyDNCViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
      
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
  
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        if (response.message) {
          yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        }
       
       
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* employeeDNCViaSaga(action) {
  
    try {
      const { response, timeout } = yield race({
        response: call(employeeDNCViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
      
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
  
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        if (response.message) {
          yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        }
       
       
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* companyBlockedViaSaga(action) {
  
    try {
      const { response, timeout } = yield race({
        response: call(companyBlockedViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
      
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
  
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        if (response.message) {
          yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        }
       
       
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* employeeBlockedViaSaga(action) {
  
    try {
      const { response, timeout } = yield race({
        response: call(employeeBlockedViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
      
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
  
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        if (response.message) {
          yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        }
       
       
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }

  function* noFallowupsViaSaga(action) {
  
    try {
      const { response, timeout } = yield race({
        response: call(noFallowupsViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
      
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
  
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        if (response.message) {
          yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        }
       
       
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* transferBdmViaSaga(action) {
  
    try {
      const { response, timeout } = yield race({
        response: call(transferBdmViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
      
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
  
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        if (response.message) {
          yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        }
       
       
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* approvalTransferViaSaga(action) {
  
    try {
      const { response, timeout } = yield race({
        response: call(approvalTransferViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
      
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
  
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        if (response.message) {
          yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        }
       
       
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }

  function* companyBlockedAndDncViaSaga(action){
    try {
      const { response, timeout } = yield race({
        response: call(companyBlockedAndDncViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
      
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
  
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        if (response.message) {
          yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        }
       
       
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* employeeBlockedAndDncViaSaga(action){
    try {
      const { response, timeout } = yield race({
        response: call(employeeBlockedAndDncViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
      
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
  
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        if (response.message) {
          yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        }
       
       
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }

 

  
  // Watch for SETTLE_ITEMS action and run settleItemsSaga
  function* watchSettleItems() {
    yield takeEvery(ALL_RATEAM_COMPANIES_COUNT_VIA_PI, allRaTeamCompaniesCountSaga);
    yield takeEvery(CLIENT_STATUS_VIA_API, clientStatusViaSaga);
    yield takeEvery(GET_BDM_COMPANIES_VIA_API, getBdmCompaniesViaSaga);
    yield takeEvery(GET_SENT_EMAILS_VIA_API, getSentEmailsViaSaga);
    yield takeEvery(JOB_STATUS_CHANGE_VIA_API,jobStatusChangeViaSaga);
    yield takeEvery(SEARCH_NOT_ACTIVES_COMPANIES_VIA_API,searchNotActiviesCompaniesViaSaga);
    yield takeEvery(ADMIN_APPROVED_COMPANIES_VIA_API,adminApprovedCompaniesViaSaga);
    yield takeEvery(DATE_SEARCH_BDMS_COMPANIES_VIA_API,dateSearchBdmsCompaniesViaSaga);
    yield takeEvery(OTHER_EMAILS_SENT_VIA_API,otherEmailsSentViaSaga);
    yield takeEvery(GET_ALL_EMAILS_LOGS_VIA_API,getAllEmailsLogsViaSaga);
    yield takeEvery(COMPANY_DNC_VIA_API,companyDNCViaSaga);
    yield takeEvery(EMPLOYEE_DNC_VIA_API,employeeDNCViaSaga);
    yield takeEvery(COMPANY_BLOCKED_VIA_API,companyBlockedViaSaga);
    yield takeEvery(EMPLOYEE_BLOCKED_VIA_API,employeeBlockedViaSaga);
    yield takeEvery(NO_FALLOWUPS_VIA_API,noFallowupsViaSaga);
    yield takeEvery(TRANSFER_BDM_VIA_API,transferBdmViaSaga);
    yield takeEvery(APPROVAL_TRANSFER_VIA_API,approvalTransferViaSaga);
    yield takeEvery(COMPANY_BLOCKED_AND_DNC_VIA_API,companyBlockedAndDncViaSaga);
    yield takeEvery(EMPLOYEE_BLOCKED_AND_DNC_VIA_API,employeeBlockedAndDncViaSaga);
  }
  
  export default function* CompaniesList() {
    yield all([
      // ... other sagas ...
  
      fork(watchSettleItems),
    ]);
  }
  