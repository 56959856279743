import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import theme from "../../../Theme";
import LinearScaleOutlinedIcon from "@mui/icons-material/LinearScaleOutlined";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import TrendingDownOutlinedIcon from "@mui/icons-material/TrendingDownOutlined";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import Backgrounds from "../../Backgrounds/Backgrounds";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  // [`& .${linearProgressClasses.bar}`]: {
  //   borderRadius: 5,
  //   backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  // },
}));

const Type5Layout = ({
  title,
  value,
  compairValue,
  icon,
  //color,
  variant,
  result,
  shadow,
  invert,
  messages,
}) => {
  const Setup = [
    {
      color: "success", //100+
      text: messages && messages[0] ? messages[0] : "Outstanding",
    },
    {
      color: "secondary", //90+
      text: messages && messages[1] ? messages[1] : "Excellent",
    },
    {
      color: "info", //80+
      text: messages && messages[2] ? messages[2] : "Good",
    },

    {
      color: "warning", //70+
      text: messages && messages[3] ? messages[3] : "Below Average",
    },
    {
      color: "error", //0-69
      text: messages && messages[4] ? messages[4] : "Very Poor",
    },
  ];

  const getPercentage = ((value / compairValue) * 100).toFixed(1);
  const remainingPercentage = getPercentage - 100;
  let color = "inherit";
  let compairText = "";

  if (invert) {
    if (getPercentage <= 0) {
      color = Setup[0].color;
      compairText = Setup[0].text;
    } else if (getPercentage <= 10) {
      color = Setup[1].color;
      compairText = Setup[1].text;
    } else if (getPercentage <= 20) {
      color = Setup[2].color;
      compairText = Setup[2].text;
    } else if (getPercentage <= 30) {
      color = Setup[3].color;
      compairText = Setup[3].text;
    } else {
      color = Setup[4].color; // For getPercentage <= 69
      compairText = Setup[4].text;
    }
  } else {
    if (getPercentage >= 100) {
      color = Setup[0].color;
      compairText = Setup[0].text;
    } else if (getPercentage >= 90) {
      color = Setup[1].color;
      compairText = Setup[1].text;
    } else if (getPercentage >= 80) {
      color = Setup[2].color;
      compairText = Setup[2].text;
    } else if (getPercentage >= 70) {
      color = Setup[3].color;
      compairText = Setup[3].text;
    } else {
      color = Setup[4].color; // For getPercentage <= 69
      compairText = Setup[4].text;
    }
  }

  return (
    <React.Fragment>
      <Backgrounds
        shadow={shadow}
        variant={variant}
        color={color}
        p={0.5}
        mb={0}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          style={{ gap: 10 }}
        >
          <Typography
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
              fontSize: 12,
              letterSpacing: 0.5,
              color:
                variant === "contained"
                  ? theme.palette[color].contrastText
                  : theme.palette.grey[800],
              fontWeight: "bold",
              lineHeight: 1,
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              color:
                variant === "contained"
                  ? theme.palette[color].contrastText
                  : theme.palette.text[900],
              fontSize: 20,
              fontWeight: "bold",
              lineHeight: 1,
            }}
          >
            {value}
          </Typography>
        </Box>
      </Backgrounds>
    </React.Fragment>
  );
};

export default Type5Layout;
