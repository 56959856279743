
const RATeam = [
    {
        title: "Dashboard",
        linkTo: "/raDashboard",
        icon: "DashboardIcon", // Use your specific icon
        subMenu: [], // No sub-menu for Dashboard
    },
    {
        title: "Find Info",
        linkTo: "/findInfo",
        icon: "PublishedWithChangesIcon", // Use your specific icon
        subMenu: [], // No sub-menu for Find Info
    },
    {
        title: "My Companies List",
        linkTo: "/companiesList",
        icon: "ListIcon", // Use your specific icon
        subMenu: [], // No sub-menu for My Companies List
    },
];

const BDM = [
    {
        title: "Dashboard",
        linkTo: "/bdmDashboard",
        icon: "DashboardIcon", // Use your specific icon
        subMenu: [], // No sub-menu for Dashboard
    },
    {
        title: "Client",
        linkTo: "/client",
        icon: "AutorenewIcon", // Use your specific icon
        subMenu: [], // No sub-menu for Client
    },
    {
        title: "EMails",
        icon: "ForwardToInboxIcon",
        subMenu: [
          {
            title: "Bulk Email",
            linkTo: "/bulkEmail",
            icon: "SendIcon",
          },
          {
            title: "Email Templates",
            linkTo: "/emailTemplates",
            icon: "DescriptionIcon",
          },
        ],
      },
      {
        title: "Find Info",
        linkTo: "/findInfo",
        icon: "PublishedWithChangesIcon", // Use your specific icon
        subMenu: [], // No sub-menu for Find Info
    },
      {
        title: "Reports",
        icon: "BarChartIcon",
        subMenu: [
          {
            title: "Job Reports",
            linkTo: "/jobReports",
            icon: "BarChartIcon",
          },
          {
            title: "Email Reports",
            linkTo: "/emailReports",
            icon: "BarChartIcon",
          },
        ],
      },
      {
        title: "Job Posts",
        linkTo: "/jobPosts",
        icon: "PostAddIcon",
        subMenu: [], // No sub-menu for Dashboard
      },
      {
        title: "Applicants",
        linkTo: "/applicants",
        icon: "FindInPageIcon",
        subMenu: [], // No sub-menu for Dashboard
      },
];
const RecruitmentTeam = [
    {
        title: "Dashboard",
        linkTo: "/dashboardRecruiter",
        icon: "DashboardIcon", // Use your specific icon
        subMenu: [], // No sub-menu for Dashboard
    },
    {
        title: "Job Posts",
        linkTo: "/jobPosts",
        icon: "PostAddIcon", // Use your specific icon
        subMenu: [], // No sub-menu for Dashboard
    },
    {
        title: "Applicants",
        linkTo: "/applicants",
        icon: "FindInPageIcon",
        subMenu: [], // No sub-menu for Dashboard
      },
];
const Admin = [
    {
        title: "Dashboard",
        linkTo: "/dashboardAdmin",
        icon: "DashboardIcon", // Use your specific icon
        subMenu: [], // No sub-menu for Dashboard
    },
    {
        title: "Find Info",
        linkTo: "/findInfo",
        icon: "PublishedWithChangesIcon", // Use your specific icon
        subMenu: [], // No sub-menu for Find Info
    },
    {
        title: "Reports",
        icon: "BarChartIcon",
        subMenu: [
          {
            title: "BDM Reports",
            linkTo: "/bdmReports",
            icon: "BarChartIcon",
          },
          {
            title: "Email Reports",
            linkTo: "/emailReports",
            icon: "BarChartIcon",
          },
        ],
      },
      {
        title: "Job Posts",
        linkTo: "/jobPosts",
        icon: "PostAddIcon", // Use your specific icon
        subMenu: [], // No sub-menu for Dashboard
    },
    {
        title: "Applicants",
        linkTo: "/applicants",
        icon: "FindInPageIcon",
        subMenu: [], // No sub-menu for Dashboard
      },
];
const SuperAdmin=[
    {
        title: "Dashboard",
        linkTo: "/dashboardAdmin",
        icon: "DashboardIcon", // Use your specific icon
        subMenu: [], // No sub-menu for Dashboard
    },
    {
        title: "Find Info",
        linkTo: "/findInfo",
        icon: "PublishedWithChangesIcon", // Use your specific icon
        subMenu: [], // No sub-menu for Find Info
    },
    {
      title: "My Companies List",
      linkTo: "/companiesList",
      icon: "ListIcon", // Use your specific icon
      subMenu: [], // No sub-menu for My Companies List
  },
    {
        title: "EMails",
        icon: "ForwardToInboxIcon",
        subMenu: [
          {
            title: "Bulk Email",
            linkTo: "/bulkEmail",
            icon: "SendIcon",
          },
          {
            title: "Assign",
            linkTo: "/assign",
            icon: "FlipCameraAndroidIcon",
          },
          {
            title: "Email Templates",
            linkTo: "/emailTemplates",
            icon: "DescriptionIcon",
          },
          {
            title: "FollowUp Assign",
            linkTo: "/followUpAssign",
            icon: "FlipCameraAndroidIcon",
          },
        ],
      },
      {
        title: "Users",
        icon: "PeopleAltIcon",
        subMenu:[
            {
                icon:'',
                title: "User List",
                linkTo: "/users",
                icon: "BarChartIcon",
            }
        ]
      },
      {
        title: "Test Emails",
        linkTo: "/testEmail",
        icon: "SendIcon", // Use your specific icon
        subMenu: [], // No sub-menu for Dashboard
    },
      {
        title: "Team Count",
        linkTo: "/teamCount",
        icon: "HourglassTopIcon", // Use your specific icon
        subMenu: [], // No sub-menu for Dashboard
    },
    {
        title: "Daily Production",
        linkTo: "/dailyProduction",
        icon: "GroupsIcon", // Use your specific icon
        subMenu: [], // No sub-menu for Dashboard
    },
    {
        title: "Reports",
        icon: "BarChartIcon",
        subMenu: [
          {
            title: "Download RA Sheet",
            linkTo: "/raDownload",
            icon: "DownloadIcon",
          },
          {
            title: "Download BDM Sheet",
            linkTo: "/bdmDownload",
            icon: "DownloadIcon",
          },
          {
            title: "BDM Reports",
            linkTo: "/bdmReports",
            icon: "BarChartIcon",
          },
          {
            title: "Email Reports",
            linkTo: "/emailReports",
            icon: "BarChartIcon",
          },
        ],
      },
      {
        title: "Controller",
        linkTo: "/controller",
        icon: "ComputerIcon", // Use your specific icon
        subMenu: [], // No sub-menu for Dashboard
    },
      {
        title: "Job Posts",
        linkTo: "/jobPosts",
        icon: "PostAddIcon", // Use your specific icon
        subMenu: [], // No sub-menu for Dashboard
    },
    {
        title: "Applicants",
        linkTo: "/applicants",
        icon: "FindInPageIcon",
        subMenu: [], // No sub-menu for Dashboard
      },
]
const SpecialFeatures=[
    {
        title: "Team Count",
        linkTo: "/teamCount",
        icon: "HourglassTopIcon", // Use your specific icon
        subMenu: [], // No sub-menu for Dashboard
    },
    {
        title: "Daily Production",
        linkTo: "/dailyProduction",
        icon: "GroupsIcon", // Use your specific icon
        subMenu: [], // No sub-menu for Dashboard
    }
]
const AdminShowSpecialAccess=[
  {
    title: "Dashboard",
    linkTo: "/dashboardAdmin",
    icon: "DashboardIcon", // Use your specific icon
    subMenu: [], // No sub-menu for Dashboard
},
{
    title: "Find Info",
    linkTo: "/findInfo",
    icon: "PublishedWithChangesIcon", // Use your specific icon
    subMenu: [], // No sub-menu for Find Info
},
{
    title: "Reports",
    icon: "BarChartIcon",
    subMenu: [
      {
        title: "BDM Reports",
        linkTo: "/bdmReports",
        icon: "BarChartIcon",
      },
      {
        title: "Email Reports",
        linkTo: "/emailReports",
        icon: "BarChartIcon",
      },
      {
        title: "Job Reports",
        linkTo: "/jobReports",
        icon: "BarChartIcon",
      },
    ],
  },
  
  {
    title: "Dashboard",
    linkTo: "/bdmDashboard",
    icon: "DashboardIcon", // Use your specific icon
    subMenu: [], // No sub-menu for Dashboard
},
{
    title: "Client",
    linkTo: "/client",
    icon: "AutorenewIcon", // Use your specific icon
    subMenu: [], // No sub-menu for Client
},
{
    title: "EMails",
    icon: "ForwardToInboxIcon",
    subMenu: [
      {
        title: "Bulk Email",
        linkTo: "/bulkEmail",
        icon: "SendIcon",
      },
      {
        title: "Email Templates",
        linkTo: "/emailTemplates",
        icon: "DescriptionIcon",
      },
    ],
  },
  {
    title: "Job Posts",
    linkTo: "/jobPosts",
    icon: "PostAddIcon",
    subMenu: [],
  },
  {
    title: "Applicants",
    linkTo: "/applicants",
    icon: "FindInPageIcon",
    subMenu: [],
  },
  {
    title: "Dashboard",
    linkTo: "/dashboardRecruiter",
    icon: "DashboardIcon", 
    subMenu: [], 
},
]

export default { RATeam, BDM, Admin,RecruitmentTeam,SuperAdmin,SpecialFeatures,AdminShowSpecialAccess };
