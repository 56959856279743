import {
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import theme from "../../Theme";
import CloseIcon from "@mui/icons-material/Close";

const FormRow = ({ children }) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          alignItems: "end",
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
};
const FormCell = ({ children, width = 172, flex = 1 }) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          flex: flex,
          flexBasis: width,
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
};

const JobDetailForm = () => {
  return (
    <React.Fragment>
      <Box>
        <FormRow>
          <FormCell>
            <TextField
              variant="standard"
              label="First Name"
              placeholder="Josh"
              size="small"
              fullWidth
            />
          </FormCell>
          <FormCell>
            <TextField
              variant="standard"
              label="Last Name"
              placeholder="Morgan"
              size="small"
              fullWidth
            />
          </FormCell>
          <FormCell>
            <TextField
              variant="standard"
              label="Designation"
              placeholder="HR Manager"
              size="small"
              fullWidth
            />
          </FormCell>
          <FormCell>
            <TextField
              type="email"
              variant="standard"
              label="Email Id"
              placeholder="josh.m@company.com"
              size="small"
              fullWidth
            />
          </FormCell>
          <FormCell>
            <TextField
              variant="standard"
              label="Phone No"
              placeholder="+1 (555) 555-1234"
              size="small"
              fullWidth
            />
          </FormCell>
          <FormCell width={10} flex={0}>
            <IconButton size="small" color="error">
              <CloseIcon fontSize="12" />
            </IconButton>
          </FormCell>
        </FormRow>
      </Box>
    </React.Fragment>
  );
};

export default JobDetailForm;
