// import React, { useState, useEffect } from "react";
// import { Box, Button, Typography } from "@mui/material";
// import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
// import { SnackbarProvider, useSnackbar } from "notistack"; // Import useSnackbar from notistack
// import { useSelector, useDispatch } from "react-redux";
// import { DataGrid } from "@mui/x-data-grid";
// import SnackbarComponent from "../../Components/SnackbarComponent"; // Adjust path as per your actual structure
// import {
//   getAllEmailsLogsViaApi,
// } from "../../store/companiesList/action";
// import dayjs from "dayjs";
// import { CustomDateFilter, FilterHeader, MainBackground } from "../../Components";
// import { getAllUsers } from "../../store/auth/login/actions";
// import { stopLoadingRedux } from "../../store/reports/action";
// import { ShimmerTable } from "react-shimmer-effects";
// import theme from "../../Theme";
// dayjs.extend(require("dayjs/plugin/timezone"));
// dayjs.extend(require("dayjs/plugin/utc"));
// dayjs.tz.setDefault("Asia/Kolkata");
// const EmailReports=()=>{
//     const dispatch = useDispatch();
//     const { enqueueSnackbar, closeSnackbar } = useSnackbar(); // Use useSnackbar here
//     const storeData = useSelector((state) => state.Login.storeData);
//     const client=storeData.clientDb
//     const AllEmailLogsData =
//       useSelector((state) => state.CompaniesList?.emailLogs) || [];
//       const allUsersData = useSelector((state) => state.Login.allUsers);
//   const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
//   const [loading, setLoading] = useState(true);
//   const loadingRedux = useSelector((state) => state.Reports.loadingRedux);

//   const [emailReportSenderAccount,setEmailReportSenderAccount]=useState([]);
//   const [emailReportBDMName,setEmailReportBDMName]=useState([]);
//   const [emailReportFromEmailId,setEmailReportFromEmailId]=useState([]);
//       const transformData = (data) => {
//         const map = new Map();
    
//         data.forEach((email) => {
//             const fromEmail = email.fromEmail;
//             // Convert sentDateTime to IST
//             const sentDateTimeIST = dayjs(email.sentDateTime)
//                 .tz("Asia/Kolkata")
//                 .format("YYYY-MM-DD");
    
//             if (!map.has(fromEmail)) {
//                 map.set(fromEmail, new Map());
//             }
    
//             const dateMap = map.get(fromEmail);
//             if (!dateMap.has(sentDateTimeIST)) {
//                 dateMap.set(sentDateTimeIST, []);
//             }
    
//             dateMap.get(sentDateTimeIST).push(email);
//         });
    
//         const result = [];
       
//         map.forEach((dateMap, fromEmail) => {
//           dateMap.forEach((emails, sentDateTime) => {
//               const username = fromEmail ? fromEmail.split('@')[0] : '';
//               const checkBdmEMail = allUsers.find(
//                   (user) => user.userEmailId.split('@')[0] === username
//               );
      
//               result.push({
//                   fromEmail,
//                   senderName: emails[0].userId 
//                       ? (allUsers.find((user) => user._id === emails[0].userId)?.userFirstName || '')
//                       : '',
//                   bdmName: checkBdmEMail ? checkBdmEMail.userSudoFirstName : '',
//                   sentDateTime,
//                   emails
//               });
//           });
//       });
      
    
//         return result;
//     };

    
//     let AllEMailData = Array.isArray(AllEmailLogsData)
//         ? transformData(AllEmailLogsData)
//         : [];

//   if (storeData.userType.includes('BDM')) {
//     // Ensure that AllEMailData is defined and is an array
//     AllEMailData = (AllEMailData || []).filter((email) => {
//         const bdmEmailName = storeData.userEmailId?.split('@')[0]?.trim();
//         const bdmPseudoEmailName = storeData.userSudoEmailId?.split('@')[0]?.trim();
        
//         // Check if email.bdmEmailId is defined and not null
//         const emailAccountName = email.fromEmail?.split('@')[0]?.trim();
        
//         // Return true only if either of the conditions is met
//         return emailAccountName && (bdmEmailName === emailAccountName || bdmPseudoEmailName === emailAccountName);
//     });
// }

 
//   let idCount = 1;
//   AllEMailData = Array.isArray(AllEMailData)
//     ? AllEMailData.map((email) => ({
//         ...email, 
//         id: idCount++,
//       }))
//     : [];
   

    
//     const filterSearchData = Array.isArray(AllEMailData) 
//     ? AllEMailData.filter((data) => {
//         // Convert necessary fields to lower case for case-insensitive comparison
//         const lowerSenderName = data.senderName|| "";
//         const lowerBdmName = data.bdmName || "";
//         const lowerFromEmail = data.fromEmail || "";
//         // Search term inputs
//         const SearchEMailReportSenderAccount = emailReportSenderAccount || [];
//         const SearchEmailReportBDMName = emailReportBDMName || [];
//         const SearchEmailReportFromEmailId = emailReportFromEmailId || [];
//         // Filter logic
//         return (
//           (SearchEMailReportSenderAccount.length === 0 || SearchEMailReportSenderAccount.includes(lowerSenderName)) &&
//           (SearchEmailReportBDMName.length === 0 || SearchEmailReportBDMName.includes(lowerBdmName)) &&
//           (SearchEmailReportFromEmailId.length === 0 || SearchEmailReportFromEmailId.includes(lowerFromEmail))
//         );
//       })
//     : [];

//     const transformDataByDate = (data) => {
//       const dateMap = new Map();
  
//       data.forEach((email) => {
//           const fromEmail = email.fromEmail;
//           // Convert sentDateTime to IST
//           const sentDateTimeIST = dayjs(email.sentDateTime)
//               .tz("Asia/Kolkata")
//               .format("YYYY-MM-DD");
  
//           if (!dateMap.has(sentDateTimeIST)) {
//               dateMap.set(sentDateTimeIST, new Map());
//           }
  
//           const senderMap = dateMap.get(sentDateTimeIST);
//           if (!senderMap.has(fromEmail)) {
//               senderMap.set(fromEmail, []);
//           }
  
//           senderMap.get(fromEmail).push(email);
//       });
  
//       const result = [];
  
//       dateMap.forEach((senderMap, sentDateTime) => {
//           const senderAccounts = [];
//           let totalEmails = 0;
  
//           senderMap.forEach((emails, fromEmail) => {
//               totalEmails += emails.length;
  
//               const username = fromEmail ? fromEmail.split('@')[0] : '';
//               const senderName = allUsers.find(
//                   (user) => user._id === emails[0].userId
//               )?.userFirstName || '';
  
//               senderAccounts.push({
//                   senderName,
//                   totalEmails: emails.length,
//                   // Removing bdmName if not required
//               });
//           });
  
//           result.push({
//               sentDateTime,
//               senderAccounts,
//               totalEmails
//           });
//       });
  
//       return result;
//   };
  
//   let AllEmailDateWise = Array.isArray(AllEmailLogsData)
//       ? transformDataByDate(AllEmailLogsData)
//       : [];
//       let idCountDate = 1;  // Use `let` instead of `const`

//       const aggregateData = AllEmailDateWise.map(day => {
//           // Check if senderAccounts is an array and has elements
//           if (!Array.isArray(day.senderAccounts) || day.senderAccounts.length === 0) {
//               return {
//                   id: idCountDate++,  // Increment ID for each entry
//                   sentDateTime: day.sentDateTime,
//                   senderAccounts: [],
//                   totalEmails: day.totalEmails
//               };
//           }
      
//           const aggregatedAccounts = day.senderAccounts.reduce((acc, { senderName, totalEmails }) => {
//               if (!acc[senderName]) {
//                   acc[senderName] = 0;
//               }
//               acc[senderName] += totalEmails;
//               return acc;
//           }, {});
      
//           const aggregatedSenderAccounts = Object.entries(aggregatedAccounts).map(([senderName, totalEmails]) => ({
//               senderName,
//               totalEmails
//           }));
      
//           return {
//               id: idCountDate++,  // Increment ID for each entry
//               sentDateTime: day.sentDateTime,
//               senderAccounts: aggregatedSenderAccounts,
//               totalEmails: day.totalEmails
//           };
//       });
      
  
//     const dayWise=[
//       { field: "id", headerName: "ID", width: 70 },
//       {
//         field: "sentDateTime",
//         headerName: "Email Sent Date",
//         minWidth: 150,
//         flex: 1,
//       },
//       {
//         field: "sendAccount",
//         headerName: "Sender Accounts",
//         minWidth: 150,
//         flex: 1,
//         renderCell: (params) => {
//           const senderAccounts = params.row.senderAccounts;
    
//           // Ensure senderAccounts is an array and has accounts
//           if (Array.isArray(senderAccounts) && senderAccounts.length > 0) {
//             return (
//               <Box>
//                 {senderAccounts.map((account, index) => (
//                   <Typography key={index} variant="body2">
//                     {account.senderName}: {account.totalEmails}
//                   </Typography>
//                 ))}
               
//               </Box>
//             );
//           } else {
//             return "--";  // Fallback if no sender accounts are found
//           }
//         },
//       },
//       {
//         field: "totalEmails",
//         headerName: "Count of All Emails",
//         minWidth: 150,
//         flex: 1,
//       },
//     ]

  
//     const columns = [
//         { field: "id", headerName: "ID", width: 70 },
//         {
//           field: "sentDateTime",
//           headerName: "Email Sent Date",
//           minWidth: 150,
//           flex: 1,
//         },
//         {
//           field: "senderName",
//           headerName: "Sender Account",
//           minWidth: 200,
//           flex: 1,
//           renderHeader: (params) => <FilterHeader params={params}  type="multiselect" setEmailReportSenderAccount={setEmailReportSenderAccount} selectedValue={emailReportSenderAccount} title={'senderAccount'} 
//           options={[...new Set(AllEMailData
//             .filter(user => user.senderName)
//             .map(user => user.senderName))]}
      
//             />,
          
//         },
//         {
//           field: "bdmName",
//           headerName: "BDM Name",
//           minWidth: 150,
//           flex: 1,
//           renderHeader: (params) => <FilterHeader params={params}  type="multiselect" setEmailReportBDMName={setEmailReportBDMName} selectedValue={emailReportBDMName} title={'emailReportBDMName'}
         
//             options={[...new Set(AllEMailData
//               .filter(user => user.bdmName)
//               .map(user => user.bdmName))]}/>,
         
//         },
//         {
//             field: "fromEmail",
//             headerName: "From EMailID",
//             minWidth: 150,
//             flex: 1,
//             renderHeader: (params) => <FilterHeader params={params}  type="multiselect" setEmailReportFromEmailId={setEmailReportFromEmailId} selectedValue={emailReportFromEmailId} title={'emailReportFromEmailId'}
//             //  option={AllEMailData.filter(user => user.fromEmail)
//             //   .map(user => user.fromEmail)}/>,
//             options={[...new Set(AllEMailData
//               .filter(user => user.fromEmail)
//               .map(user => user.fromEmail))]}/>,
//           },
//         {
//           field: "emails",
//           headerName: "Emails Sent Count",
//           minWidth: 150,
//           flex: 1,
//           valueGetter: (params) => {
//             if (params && params.length > 0) {
//               return params.length;
//             } else {
//               return "--";
//             }
//           },
//         },
//       ];

//     const BDMColumns = [
//         { field: "id", headerName: "ID", width: 70 },
//         {
//           field: "sentDateTime",
//           headerName: "Email Sent Date",
//           minWidth: 150,
//           flex: 1,
//         },
//         {
//             field: "fromEmail",
//             headerName: "From EMailID",
//             minWidth: 150,
//             flex: 1,
//             renderHeader: (params) => <FilterHeader params={params}  type="multiselect" setEmailReportFromEmailId={setEmailReportFromEmailId} selectedValue={emailReportFromEmailId} title={'emailReportFromEmailId'}
//             //  option={AllEMailData.filter(user => user.fromEmail)
//             //   .map(user => user.fromEmail)}/>,
//             options={[...new Set(AllEMailData
//               .filter(user => user.fromEmail)
//               .map(user => user.fromEmail))]}/>,
//           },
//         {
//           field: "emails",
//           headerName: "Emails Sent Count",
//           minWidth: 150,
//           flex: 1,
//           valueGetter: (params) => {
//             if (params && params.length > 0) {
//               return params.length;
//             } else {
//               return "--";
//             }
//           },
//         },
//       ];
      
      
  
   
  
//     const [searchFromDate, setSearchFromDate] = useState("");
//     const [startingDate, setStartingDate] = useState(
//       dayjs().tz("Asia/Kolkata").startOf("day")
//     );
  
//     const [searchToDate, setSearchToDate] = useState("");
//     const handleDateChange = (selectedDates) => {
//       setSearchFromDate("");
//       setSearchToDate("");
//       setLoading(true);
//       if (selectedDates.length === 2) {
//         const formattedDates = selectedDates.map((date, index) => {
//           return date.toISOString();
//         });
  
//         const [fromDate, toDate] = selectedDates;
//         setSearchFromDate(formattedDates[0]);
//         setSearchToDate(formattedDates[1]);
  
//         // const action = {
//         //   searchFromDate: formattedDates[0],
//         //   searchToDate: formattedDates[1],
//         // };
//         const action = {
//           searchFromDate: formattedDates[0],
//           searchToDate: formattedDates[1],
//           client:client,
//         };
  
//         dispatch(getAllEmailsLogsViaApi(action));
//       }
//     };
//     useEffect(() => {
//       // const today = new Date();
//       const today = dayjs().tz("Asia/Kolkata").startOf("day");
//       const action={
//         client:client
//       }
//       dispatch(getAllUsers(action));
//       handleDateChange([today, today.endOf("day")]);
//     }, []);
//     useEffect(() => {
//         if (loadingRedux) {
//           dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
//           setLoading(false);
    
//           // Set a timer to stop loading after 5 seconds
//           const timer = setTimeout(() => {
//             setLoading(false);
//           }, 1000);
    
//           // Cleanup timer on unmount
//           return () => clearTimeout(timer);
//         }
//       }, [loadingRedux, dispatch]);
//     return(
//         <SnackbarProvider
//         maxSnack={4}
//         anchorOrigin={{ horizontal: "right", vertical: "top" }}
//       >
//         <LayoutSidebarMenu pageTitle={"Email Reports"}>
//           <Box
//             sx={{
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "flex-start",
//               gap: 3,
//               mb: 3,
//             }}
//           >
//             <Box flex={1}></Box>
  
//             <Box sx={{ mb: 2 }}>
//               <CustomDateFilter handleDateChange={handleDateChange} />
//             </Box>
//           </Box>
//           {loading?(
//             <>
//             <ShimmerTable row={5} col={5} />
//             </>
//           ):(
//           <Box sx={{ width: "100%", overflowX: "auto", }}>
//             <MainBackground sx={{mb:10}}>

            
//             {(storeData.userType.includes("SuperAdmin") ||
//               storeData.userType.includes("Admin") ||
//               storeData.userType.includes("Developer"))&&(
//                 <>
//                  <DataGrid
//                 rows={aggregateData}
//                 columns={dayWise}
//                 disableColumnMenu
//                           disableColumnSorting
//                           disableColumnSelector
//                           disableColumnFilter
//                           disableColumnReorder
//                           disableColumnResize
//                           //disableRowSelectionOnClick
//                           disableColumnSort
//                           initialState={{
//                             pagination: {
//                               paginationModel: { page: 0, pageSize: 5 },
//                             },
//                           }}
//                           pageSizeOptions={[5, 50, 100]}
//                           getRowHeight={() => "auto"}
//                           autoHeight
//                           sx={{
//                             "& .MuiDataGrid-cell": {
//                               display: "flex",
//                               alignItems: "center !important",
//                             },
//                             [`& .MuiDataGrid-checkboxInput`]: {
//                               color: theme.palette.primary.main,
//                               p: 0,

//                               "&.Mui-checked": {
//                                 color: theme.palette.primary.dark,
//                               },
//                             },
//                             //Scroller
//                             [`& .MuiDataGrid-scrollbar`]: {
//                               scrollbarWidth: "thin",
//                               scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
//                             },
//                             [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]:
//                               {
//                                 borderRadius: "10px", // Apply border radius
//                               },

//                             [`& .MuiDataGrid-scrollbar:hover`]: {
//                               scrollbarColor: `${theme.palette.primary.dark}`,
//                             },
//                           }}
//               />
//                 </>
//               )
                
//               }
// </MainBackground>
// <Box mb={5}/>
// <MainBackground>
//               <DataGrid
//                 rows={filterSearchData}
//                 columns={ storeData.userType.includes('BDM')?BDMColumns :columns}
//                 autoHeight
//                 disableColumnMenu
//               />
              
//             </MainBackground>
//             </Box>
//             )}
//         </LayoutSidebarMenu>
//         <SnackbarComponent />
//       </SnackbarProvider>
//     );
//   };
  
// export default EmailReports;



import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import { SnackbarProvider, useSnackbar } from "notistack"; // Import useSnackbar from notistack
import { useSelector, useDispatch } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import SnackbarComponent from "../../Components/SnackbarComponent"; // Adjust path as per your actual structure
import {
  getAllEmailsLogsViaApi,
} from "../../store/companiesList/action";
import dayjs from "dayjs";
import { CustomDateFilter, FilterHeader, MainBackground } from "../../Components";
import { getAllUsers } from "../../store/auth/login/actions";
import { stopLoadingRedux } from "../../store/reports/action";
import { ShimmerTable } from "react-shimmer-effects";
import theme from "../../Theme";
dayjs.extend(require("dayjs/plugin/timezone"));
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.tz.setDefault("Asia/Kolkata");
const EmailReports=()=>{
    const dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar(); // Use useSnackbar here
    const storeData = useSelector((state) => state.Login.storeData);
    const client=storeData.clientDb
    const AllEmailLogsData =
      useSelector((state) => state.CompaniesList?.emailLogs) || [];
      const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const [loading, setLoading] = useState(true);
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);

  const [emailReportSenderAccount,setEmailReportSenderAccount]=useState([]);
  const [emailReportBDMName,setEmailReportBDMName]=useState([]);
  const [emailReportFromEmailId,setEmailReportFromEmailId]=useState([]);
    
    const groupEmails = (emails) => {
      const grouped = {};
    
      emails.forEach(email => {
        // Convert sentDateTime to IST (Asia/Kolkata) format
        const sentDateTimeIST = dayjs(email.sentDateTime)
        .tz("Asia/Kolkata")
        .format("YYYY-MM-DD");
    
        // Use converted IST sentDateTime as part of the key
        const key = `${email.userId}-${email.fromEmail}-${sentDateTimeIST}`;
     const username = email.fromEmail ? email.fromEmail.split('@')[0] : '';
                  const checkBdmEMail = allUsers.find(
                      (user) => user.userEmailId.split('@')[0] === username
                  );
        if (!grouped[key]) {
          grouped[key] = {
            senderName: email.userId?allUsers.find((user) => user._id === email.userId)?.userFirstName || '' :'',
            fromEmail: email.fromEmail,
            bdmName: checkBdmEMail ? checkBdmEMail.userSudoFirstName : '',
            sentDateTime: sentDateTimeIST, // Use IST date here
            emails: []
          };
        }
    
        grouped[key].emails.push({
          ...email
        });
      });
    
      return Object.values(grouped);
    };
    let AllEMailData = Array.isArray(AllEmailLogsData)
    ? groupEmails(AllEmailLogsData)
    : [];
  if (storeData.userType.includes('BDM')) {
    // Ensure that AllEMailData is defined and is an array
    AllEMailData = (AllEMailData || []).filter((email) => {
        const bdmEmailName = storeData.userEmailId?.split('@')[0]?.trim();
        const bdmPseudoEmailName = storeData.userSudoEmailId?.split('@')[0]?.trim();
        
        // Check if email.bdmEmailId is defined and not null
        const emailAccountName = email.fromEmail?.split('@')[0]?.trim();
        
        // Return true only if either of the conditions is met
        return emailAccountName && (bdmEmailName === emailAccountName || bdmPseudoEmailName === emailAccountName);
    });
}

 
  let idCount = 1;
  AllEMailData = Array.isArray(AllEMailData)
    ? AllEMailData.map((email) => ({
        ...email, 
        id: idCount++,
      }))
    : [];
   

    
    const filterSearchData = Array.isArray(AllEMailData) 
    ? AllEMailData.filter((data) => {
        // Convert necessary fields to lower case for case-insensitive comparison
        const lowerSenderName = data.senderName|| "";
        const lowerBdmName = data.bdmName || "";
        const lowerFromEmail = data.fromEmail || "";
        // Search term inputs
        const SearchEMailReportSenderAccount = emailReportSenderAccount || [];
        const SearchEmailReportBDMName = emailReportBDMName || [];
        const SearchEmailReportFromEmailId = emailReportFromEmailId || [];
        // Filter logic
        return (
          (SearchEMailReportSenderAccount.length === 0 || SearchEMailReportSenderAccount.includes(lowerSenderName)) &&
          (SearchEmailReportBDMName.length === 0 || SearchEmailReportBDMName.includes(lowerBdmName)) &&
          (SearchEmailReportFromEmailId.length === 0 || SearchEmailReportFromEmailId.includes(lowerFromEmail))
        );
      })
    : [];

 
  

  const transformDataByDate = (data) => {
    if (!Array.isArray(data) || !Array.isArray(allUsers)) {
      throw new Error("Invalid data or allUsers format. Both should be arrays.");
    }
  
    const dateMap = new Map();
  
    data.forEach((email) => {
      if (!email.sentDateTime || !email.fromEmail || !email.userId) {
        console.warn("Skipping invalid email entry:", email);
        return;
      }
  
      // Convert sentDateTime to IST
      const sentDateTimeIST = dayjs(email.sentDateTime)
        .tz("Asia/Kolkata")
        .format("YYYY-MM-DD");
  
      // Create a unique key using userId, fromEmail, and sentDateTime
      const uniqueKey = `${email.userId}-${email.fromEmail}-${sentDateTimeIST}`;
  
      // Ensure we have a map entry for the given date
      if (!dateMap.has(sentDateTimeIST)) {
        dateMap.set(sentDateTimeIST, new Map());
      }
  
      const senderMap = dateMap.get(sentDateTimeIST);
  
      if (!senderMap.has(uniqueKey)) {
        senderMap.set(uniqueKey, {
          userId: email.userId,
          fromEmail: email.fromEmail,
          sentDateTime: email.sentDateTime,
          emails: []
        });
      }
  
      senderMap.get(uniqueKey).emails.push({
        toEmail: email.toEmail,
        firstName: email.firstName,
        jobTitle: email.jobTitle,
        jobLocation: email.jobLocation,
        typeOfEmail: email.typeOfEmail,
        emailSource: email.emailSource,
        status: email.status,
        subject: email.subject
      });
    });
  
    const result = [];
  
    dateMap.forEach((senderMap, sentDateTime) => {
      const senderAccounts = [];
      let totalEmails = 0;
  
      senderMap.forEach((emailData) => {
        totalEmails += emailData.emails.length;
  
        
        const senderName =  emailData.userId?(allUsers.find((user) => user._id === emailData.userId)?.userFirstName || '') :'';
          
        senderAccounts.push({
          senderName:emailData.userId?(allUsers.find((user) => user._id === emailData.userId)?.userFirstName || '') :'',
          totalEmails: emailData.emails.length,
        });
      });
  
      // Push final result per date
      result.push({
        sentDateTime,
        senderAccounts,
        totalEmails
      });
    });
  
    return result;
  };
  
  
  
  


  let AllEmailDateWise = Array.isArray(AllEmailLogsData)
      ? transformDataByDate(AllEmailLogsData)
      : [];
      let idCountDate = 1;  // Use `let` instead of `const`

      const aggregateData = AllEmailDateWise.map(day => {
          // Check if senderAccounts is an array and has elements
          if (!Array.isArray(day.senderAccounts) || day.senderAccounts.length === 0) {
              return {
                  id: idCountDate++,  // Increment ID for each entry
                  sentDateTime: day.sentDateTime,
                  senderAccounts: [],
                  totalEmails: day.totalEmails
              };
          }
      
          const aggregatedAccounts = day.senderAccounts.reduce((acc, { senderName, totalEmails }) => {
              if (!acc[senderName]) {
                  acc[senderName] = 0;
              }
              acc[senderName] += totalEmails;
              return acc;
          }, {});
      
          const aggregatedSenderAccounts = Object.entries(aggregatedAccounts).map(([senderName, totalEmails]) => ({
              senderName,
              totalEmails
          }));
      
          return {
              id: idCountDate++,  // Increment ID for each entry
              sentDateTime: day.sentDateTime,
              senderAccounts: aggregatedSenderAccounts,
              totalEmails: day.totalEmails
          };
      });

      




    const dayWise=[
      { field: "id", headerName: "ID", width: 70 },
      {
        field: "sentDateTime",
        headerName: "Email Sent Date",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "sendAccount",
        headerName: "Sender Accounts",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => {
          const senderAccounts = params.row.senderAccounts;
    
          // Ensure senderAccounts is an array and has accounts
          if (Array.isArray(senderAccounts) && senderAccounts.length > 0) {
            return (
              <Box>
                {senderAccounts.map((account, index) => (
                  <Typography key={index} variant="body2">
                    {account.senderName}: {account.totalEmails}
                  </Typography>
                ))}
               
              </Box>
            );
          } else {
            return "--";  // Fallback if no sender accounts are found
          }
        },
      },
      {
        field: "totalEmails",
        headerName: "Count of All Emails",
        minWidth: 150,
        flex: 1,
      },
    ]

  
    const columns = [
        { field: "id", headerName: "ID", width: 70 },
        {
          field: "sentDateTime",
          headerName: "Email Sent Date",
          minWidth: 150,
          flex: 1,
        },
        {
          field: "senderName",
          headerName: "Sender Account",
          minWidth: 200,
          flex: 1,
          renderHeader: (params) => <FilterHeader params={params}  type="multiselect" setEmailReportSenderAccount={setEmailReportSenderAccount} selectedValue={emailReportSenderAccount} title={'senderAccount'} 
          options={[...new Set(AllEMailData
            .filter(user => user.senderName)
            .map(user => user.senderName))]}
      
            />,
          
        },
        {
          field: "bdmName",
          headerName: "BDM Name",
          minWidth: 150,
          flex: 1,
          renderHeader: (params) => <FilterHeader params={params}  type="multiselect" setEmailReportBDMName={setEmailReportBDMName} selectedValue={emailReportBDMName} title={'emailReportBDMName'}
         
            options={[...new Set(AllEMailData
              .filter(user => user.bdmName)
              .map(user => user.bdmName))]}/>,
         
        },
        {
            field: "fromEmail",
            headerName: "From EMailID",
            minWidth: 150,
            flex: 1,
            renderHeader: (params) => <FilterHeader params={params}  type="multiselect" setEmailReportFromEmailId={setEmailReportFromEmailId} selectedValue={emailReportFromEmailId} title={'emailReportFromEmailId'}
            //  option={AllEMailData.filter(user => user.fromEmail)
            //   .map(user => user.fromEmail)}/>,
            options={[...new Set(AllEMailData
              .filter(user => user.fromEmail)
              .map(user => user.fromEmail))]}/>,
          },
        {
          field: "emails",
          headerName: "Emails Sent Count",
          minWidth: 150,
          flex: 1,
          valueGetter: (params) => {
            if (params && params.length > 0) {
              return params.length;
            } else {
              return "--";
            }
          },
        },
      ];

    const BDMColumns = [
        { field: "id", headerName: "ID", width: 70 },
        {
          field: "sentDateTime",
          headerName: "Email Sent Date",
          minWidth: 150,
          flex: 1,
        },
        {
            field: "fromEmail",
            headerName: "From EMailID",
            minWidth: 150,
            flex: 1,
            renderHeader: (params) => <FilterHeader params={params}  type="multiselect" setEmailReportFromEmailId={setEmailReportFromEmailId} selectedValue={emailReportFromEmailId} title={'emailReportFromEmailId'}
            //  option={AllEMailData.filter(user => user.fromEmail)
            //   .map(user => user.fromEmail)}/>,
            options={[...new Set(AllEMailData
              .filter(user => user.fromEmail)
              .map(user => user.fromEmail))]}/>,
          },
        {
          field: "emails",
          headerName: "Emails Sent Count",
          minWidth: 150,
          flex: 1,
          valueGetter: (params) => {
            if (params && params.length > 0) {
              return params.length;
            } else {
              return "--";
            }
          },
        },
      ];
      
      
  
   
  
    const [searchFromDate, setSearchFromDate] = useState("");
    const [startingDate, setStartingDate] = useState(
      dayjs().tz("Asia/Kolkata").startOf("day")
    );
  
    const [searchToDate, setSearchToDate] = useState("");
    const handleDateChange = (selectedDates) => {
      setSearchFromDate("");
      setSearchToDate("");
      setLoading(true);
      if (selectedDates.length === 2) {
        const formattedDates = selectedDates.map((date, index) => {
          return date.toISOString();
        });
  
        const [fromDate, toDate] = selectedDates;
        setSearchFromDate(formattedDates[0]);
        setSearchToDate(formattedDates[1]);
  
        // const action = {
        //   searchFromDate: formattedDates[0],
        //   searchToDate: formattedDates[1],
        // };
        const action = {
          searchFromDate: formattedDates[0],
          searchToDate: formattedDates[1],
          client:client,
        };
  
        dispatch(getAllEmailsLogsViaApi(action));
      }
    };
    useEffect(() => {
      // const today = new Date();
      const today = dayjs().tz("Asia/Kolkata").startOf("day");
      const action={
                client:client
              }
              dispatch(getAllUsers(action));
      handleDateChange([today, today.endOf("day")]);
    }, []);
    useEffect(() => {
        if (loadingRedux) {
          dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
          setLoading(false);
    
          // Set a timer to stop loading after 5 seconds
          const timer = setTimeout(() => {
            setLoading(false);
          }, 1000);
    
          // Cleanup timer on unmount
          return () => clearTimeout(timer);
        }
      }, [loadingRedux, dispatch]);
    return(
        <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Email Reports"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              gap: 3,
              mb: 3,
            }}
          >
            <Box flex={1}></Box>
  
            <Box sx={{ mb: 2 }}>
              <CustomDateFilter handleDateChange={handleDateChange} />
            </Box>
          </Box>
          {loading?(
            <>
            <ShimmerTable row={5} col={5} />
            </>
          ):(
          <Box sx={{ width: "100%", overflowX: "auto", }}>
            <MainBackground sx={{mb:10}}>

            
            {(storeData.userType.includes("SuperAdmin") ||
              storeData.userType.includes("Admin") ||
              storeData.userType.includes("Developer"))&&(
                <>
                 <DataGrid
                rows={aggregateData}
                columns={dayWise}
                disableColumnMenu
                          disableColumnSorting
                          disableColumnSelector
                          disableColumnFilter
                          disableColumnReorder
                          disableColumnResize
                          //disableRowSelectionOnClick
                          disableColumnSort
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 5 },
                            },
                          }}
                          pageSizeOptions={[5, 50, 100]}
                          getRowHeight={() => "auto"}
                          autoHeight
                          sx={{
                            "& .MuiDataGrid-cell": {
                              display: "flex",
                              alignItems: "center !important",
                            },
                            [`& .MuiDataGrid-checkboxInput`]: {
                              color: theme.palette.primary.main,
                              p: 0,

                              "&.Mui-checked": {
                                color: theme.palette.primary.dark,
                              },
                            },
                            //Scroller
                            [`& .MuiDataGrid-scrollbar`]: {
                              scrollbarWidth: "thin",
                              scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                            },
                            [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]:
                              {
                                borderRadius: "10px", // Apply border radius
                              },

                            [`& .MuiDataGrid-scrollbar:hover`]: {
                              scrollbarColor: `${theme.palette.primary.dark}`,
                            },
                          }}
              />
                </>
              )
                
              }
</MainBackground>
<Box mb={5}/>
<MainBackground>
              <DataGrid
                rows={filterSearchData}
                columns={ storeData.userType.includes('BDM')?BDMColumns :columns}
                autoHeight
                disableColumnMenu
              />
              
            </MainBackground>
            </Box>
            )}
        </LayoutSidebarMenu>
        <SnackbarComponent />
      </SnackbarProvider>
    );
  };
  
export default EmailReports;