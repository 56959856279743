const companyBlocked = 'Company blocked';
const companyDNC = 'Company DNC';
const companyNoFollowUp = 'No FollowUp';
const employeeDNC = 'Employee DNC';
const employeeBlocked = 'Employee blocked';
const companyOnProcess = 'On Process';

export default {
  companyBlocked,
  companyDNC,
  companyNoFollowUp,
  employeeDNC,
  employeeBlocked,
  companyOnProcess,
};