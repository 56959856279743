
import{
    SUCCESS_ALL_RATEAM_COMPANIES_COUNT,
    DELETE_COMPANIESLIST,
    SUCCESS_BDM_COMPANIES,
    SUCCESS_GET_SENT_EMAILS,
    SUCCESS_SEARCH_NOT_ACTIVES_COMPANIES,
    SUCCESS_DATE_SEARCH_BDMS_COMPANIES,
    SUCCESS_GET_ALL_EMAILS_LOGS
} from "./actionType"


const INIT_STATE = {
    AllRATeamCompaniesCount:[],
    BDMCompanies:[],
    sentEmails:[],
    approvableCompanies:[],
    AllBDMCompanies:[],
    emailLogs:[],
  };
  const companiesList = (state = INIT_STATE, action) => {
    switch (action.type) {
      case SUCCESS_ALL_RATEAM_COMPANIES_COUNT: {
        const searchData = action.payload;
        // Reset the restaurant-related data to its initial state.
        return {
          ...state,
          AllRATeamCompaniesCount: searchData,
        };
      }
      case SUCCESS_BDM_COMPANIES: {
        const searchBDMData = action.payload;
        // Reset the restaurant-related data to its initial state.
        return {
          ...state,
          BDMCompanies: searchBDMData,
        };
      }
      case SUCCESS_GET_SENT_EMAILS: {
        const sentEmailsData = action.payload;
        // Reset the restaurant-related data to its initial state.
        return {
          ...state,
          sentEmails: sentEmailsData,
        };
      }
      case SUCCESS_SEARCH_NOT_ACTIVES_COMPANIES: {
        const approvableCompaniesData = action.payload;
        // Reset the restaurant-related data to its initial state.
        return {
          ...state,
          approvableCompanies: approvableCompaniesData,
        };
      }
      case SUCCESS_DATE_SEARCH_BDMS_COMPANIES: {
        const AllBDMCompaniesData = action.payload;
        // Reset the restaurant-related data to its initial state.
        return {
          ...state,
          AllBDMCompanies: AllBDMCompaniesData,
        };
      }
      case SUCCESS_GET_ALL_EMAILS_LOGS: {
        const emailLogsData = action.payload;
        // Reset the restaurant-related data to its initial state.
        return {
          ...state,
          emailLogs: emailLogsData,
        };
      }
      case DELETE_COMPANIESLIST: {
        
        return INIT_STATE;
      }
      default:
        return state;
    }
  };
  
  export default companiesList;
  