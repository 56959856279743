import React, { useState, useEffect } from "react";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Extend dayjs with the necessary plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Kolkata');

export default function DatePickerTwo({ handleDateChange, searchFromDate, setSearchFromDate, setSearchToDate }) {

  // Handler for starting date change
  const handleStartingDateChange = (newValue) => {
    if (newValue) {
      const newDate = newValue.tz('Asia/Kolkata').startOf('day');
      setSearchFromDate(newDate);
      setSearchToDate(newDate.endOf('day'));
      handleDateChange([newDate, newDate.endOf('day')]);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker
          label="Select date picker"
          value={dayjs(searchFromDate)} // Convert searchFromDate to a Dayjs object
          onChange={handleStartingDateChange} // Add onChange handler
          format="DD-MM-YYYY" // Correct the format prop
          slotProps={{ textField: { size: 'small' } }}
          maxDate={dayjs().tz("Asia/Kolkata").startOf("day")}
          sx={{ flex: 1 }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
