import React, { useState } from "react";
import {
  Box,
  Chip,
  Grid,
  Paper,
  Modal,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import { PageHeader, Widget } from "../../Components";
import { SnackbarProvider } from "notistack";

const MyCompaniesListBDM = () => {
  const [companyList, setCompanyList] = useState([
    {
      id: 1,
      companyName: "companyName 1",
      jobTitle: "job title 1",
      jobLocation: "job location 1",
      status: "Open",
    },
    {
      id: 2,
      companyName: "companyName 2",
      jobTitle: "job title 2",
      jobLocation: "job location 2",
      status: "Open",
    },
    {
      id: 3,
      companyName: "companyName 3",
      jobTitle: "job title 3",
      jobLocation: "job location 3",
      status: "Open",
    },
    {
      id: 4,
      companyName: "companyName 4",
      jobTitle: "job title 4",
      jobLocation: "job location 4",
      status: "Open",
    },
    {
      id: 5,
      companyName: "companyName 5",
      jobTitle: "job title 5",
      jobLocation: "job location 5",
      status: "Open",
    },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCompanyId, setCurrentCompanyId] = useState(null);
  const [closeReason, setCloseReason] = useState("");

  const handleStatusChange = (id, newStatus) => {
    if (newStatus === "Close") {
      setCurrentCompanyId(id);
      setIsModalOpen(true);
    } else {
      const updatedCompanyList = companyList.map((company) =>
        company.id === id ? { ...company, status: newStatus } : company
      );
      setCompanyList(updatedCompanyList);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setCloseReason("");
  };

  const handleModalConfirm = () => {
    const updatedCompanyList = companyList.map((company) =>
      company.id === currentCompanyId
        ? { ...company, status: "Close" }
        : company
    );
    setCompanyList(updatedCompanyList);
    handleModalClose();
  };

  const companyColumn = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "companyName",
      headerName: "Company Name",
      width: 150,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      width: 150,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "jobLocation",
      headerName: "Job Location",
      width: 150,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "status",
      headerName: "Status",
      width: 230,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      editable: true,
      type: "singleSelect",
      valueOptions: ["Open", "Close", "Hold", "Interview", "Priority"],
      renderCell: (params) => {
        return (
          <Box>
            <Chip
              label={params.value}
              size="small"
              sx={{ px: 0.5 }}
              color={
                params.value === "Open"
                  ? "success"
                  : params.value === "Close"
                  ? "error"
                  : params.value === "Hold"
                  ? "warning"
                  : params.value === "Interview"
                  ? "info"
                  : params.value === "Priority"
                  ? "secondary"
                  : "default"
              }
            />
          </Box>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle="MyCompaniesListBDM">
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <Widget title="Hi" value={5} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <Widget title="Hi" value={5} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <Widget title="Hi" value={5} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <Widget title="Hi" value={5} />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ mt: 3 }}>
            <Paper>
              <Box p={2}>
                <DataGrid
                  rows={companyList}
                  columns={companyColumn}
                  autoHeight
                  disableRowSelectionOnClick
                  processRowUpdate={(newRow) => {
                    handleStatusChange(newRow.id, newRow.status);
                    return newRow;
                  }}
                  sx={{
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                      outline: "none !important",
                    },
                  }}
                />
              </Box>
            </Paper>
          </Box>
        </LayoutSidebarMenu>
      </SnackbarProvider>

      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Reason for Closing
          </Typography>
          <TextField
            fullWidth
            id="modal-modal-description"
            sx={{ mt: 2 }}
            placeholder="Enter reason"
            multiline
            rows={4}
            value={closeReason}
            onChange={(e) => setCloseReason(e.target.value)}
          />
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleModalClose} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button
              onClick={handleModalConfirm}
              variant="contained"
              color="primary"
              disabled={!closeReason.trim()}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default MyCompaniesListBDM;
