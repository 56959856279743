// import { Box } from "@mui/material";
// import React, { Fragment } from "react";
// import theme from "../../Theme";

// const MainBackground = ({
//   children,
//   p = 1.5,
//   mb = 1.5,
//   height = "auto", //auto,full
// }) => {
//   return (
//     <React.Fragment>
//       <Box>
//         <Box
//           sx={{
//             borderRadius: 1,
//             background:
//               "linear-gradient(170deg, rgba(196,196,196,0.15) 0%, rgba(196,196,196,0.05                                                                                                                                                                                                             ) 100%)",
//             p: p,
//             mb: mb,
//             boxShadow: `rgba(0,0,0,.1) 0px 3px 8px 0px`,
//             //boxShadow: `rgba(${theme.palette.primary.default}, .2) 0px 1px 2px 0px`,
//             //boxShadow: `rgba(${theme.palette.primary.default}, 0.25) 0px 4px 8px -2px, rgba(${theme.palette.primary.default}, 0.08) 0px 0px 0px 1px`,
//             height:
//               height == "full"
//                 ? `calc(100% - ${p * 2 * 8 + mb * 8}px )`
//                 : height,
//             overflow: "hidden",
//           }}
//         >
//           {children}
//         </Box>
//       </Box>
//     </React.Fragment>
//   );
// };

// export default MainBackground;

import React from "react";
import theme from "../../Theme";
import { Box } from "@mui/material";

const MainBackground = ({
  children,
  shadow = true,
  color,
  variant,
  p = 2,
  mb = 0.1,
  height = "auto", //auto,full
}) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          // background:
          //   "linear-gradient(170deg, rgba(196,196,196,0.15) 0%, rgba(196,196,196,0.05) 100%)",
          background: "#fff",
          p: p,
          borderRadius: 2,
          boxShadow: shadow
            ? "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;"
            : "none",
          mb: mb,
          height:
            height == "full" ? `calc(100% - ${p * 2 * 8 + mb * 8}px )` : height,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
};

export default MainBackground;
