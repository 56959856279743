import {
  all,
  call,
  delay,
  fork,
  put,
  race,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  ADD_APPLICANT_NOTE_VIA_API,
  ADD_APPLICANT_VIA_API,
  ADD_DEGREES_VIA_API,
  ADD_RESUMESOURCES_VIA_API,
  GET_ALL_APPLICANTS_VIA_API,
  GET_ALL_DEGREES_VIA_API,
  GET_ALL_RESUMESOURCES_VIA_API,
  JOB_POST_ASSIGNED_TO_RECRUITER_VIA_API,
 JOB_POST_RECRUITER_VIA_API,
 SEARCH_DATE_JOB_POSTING_DATA_VIA_API,
 SEARCH_JOB_POST_RECRUITER_VIA_API,
 TAG_APPLICANT_TO_JOB_VIA_API,
} from "./actionType";

import { successAddApplicant, successAllResumeSources, successDegree, successjobPostRecruiter, successSearchDateJobPostingData } from "./action";
import { getCompaniesSuccess, setSnackbarMessage } from "../search/action";
import {loadingViaRedux} from "../reports/action"
const apiUrl = process.env.REACT_APP_API_URL;

function jobPostRecruiterViaApiSaga(payload){
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/jobPostings/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })

    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function searchjobPostRecruiterViaApiSaga(payload) {
  console.log('payload',payload)
const bdmCompanyId=payload.id
const client=payload.client
  return fetch(`${apiUrl}/jobPostings/${bdmCompanyId}/${client}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function searchDateJobPostingDataViaApiSaga(payload) {
  const client=payload.client
  return fetch(apiUrl +"/jobPostings/" + client, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function jobPostAssignedToRecruiterViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl +"/jobPostings/assignToRecuiter", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}



function addApplicantViaApiSaga(payload) {
const formData = new FormData();

// Add JSON fields to the FormData
formData.append("addedBy", payload.addedBy);
formData.append("addedOn", payload.addedOn);
formData.append("id", payload.id);
formData.append("client", payload.client);
formData.append("personalDetails", JSON.stringify(payload.personalDetails));
formData.append("educationDetails", JSON.stringify(payload.educationDetails));
formData.append("professionalDetails", JSON.stringify({
    ...payload.professionalDetails,
    resume: undefined, // We handle resumes separately
}));

// Append the resume files (assuming only one file is being uploaded)
if (payload.professionalDetails.resume) {
    formData.append("resume", payload.professionalDetails.resume);
}

return fetch(apiUrl + "/applicants/addApplicant", {
    method: "POST",
    body: formData, // Send the FormData directly
})
.then((response) => response.json())
.catch((error) => {
    throw error;
});
}

function getAllApplicantsViaApiSaga(payload) {
  const client=payload.client

  return fetch(apiUrl + "/applicants/" + client, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function getAllResumeSourceViaApiSaga(payload) {
  const client=payload.client
  return fetch(apiUrl + "/resumeSources/" +client, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function addResumeSourcesViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/resumeSources/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function addApplicantNoteViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/applicants/addNote", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function tagApplicantToJobViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/applicants/tagApplicant", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function getAllDegreesViaApiSaga(client) {
  return fetch(apiUrl + "/degrees/"+client, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function addDegreeViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/degrees/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}






function* jobPostRecruiterViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(jobPostRecruiterViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
      } else if (response.data) {
        yield put(loadingViaRedux(true));
        yield put(successjobPostRecruiter(response.data));
      }
      
      
      
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* searchjobPostRecruiterViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(searchjobPostRecruiterViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      yield put(loadingViaRedux(true))
        yield put(successjobPostRecruiter(response));
      
      
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* searchDateJobPostingDataViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(searchDateJobPostingDataViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      yield put(loadingViaRedux(true))
        yield put(successSearchDateJobPostingData(response));
      
      
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* jobPostAssignedToRecruiterViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(jobPostAssignedToRecruiterViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        return null;
      }
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* addApplicantViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(addApplicantViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        return null;
      }
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* getAllApplicantsViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(getAllApplicantsViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        return null;
      }
      yield put(loadingViaRedux(true))
      yield put (successAddApplicant(response))
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* getAllResumeSourceViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(getAllResumeSourceViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        return null;
      }
      yield put(successAllResumeSources(response))
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* addResumeSourcesViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(addResumeSourcesViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        return null;
      }
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* addApplicantNoteViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(addApplicantNoteViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        return null;
      }
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* tagApplicantToJobViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(tagApplicantToJobViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        return null;
      }
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* getAllDegreesViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(getAllDegreesViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        return null;
      }
      yield put(successDegree(response))
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* addDegreeViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(addDegreeViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        return null;
      }
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}


// Watch for SETTLE_ITEMS action and run settleItemsSaga
function* watchSettleItems() {
  yield takeEvery(JOB_POST_RECRUITER_VIA_API, jobPostRecruiterViaSaga);
  yield takeEvery(SEARCH_JOB_POST_RECRUITER_VIA_API, searchjobPostRecruiterViaSaga);
  yield takeEvery(SEARCH_DATE_JOB_POSTING_DATA_VIA_API, searchDateJobPostingDataViaSaga);
  yield takeEvery(JOB_POST_ASSIGNED_TO_RECRUITER_VIA_API, jobPostAssignedToRecruiterViaSaga);
  yield takeEvery(ADD_APPLICANT_VIA_API,addApplicantViaSaga );
  yield takeEvery(GET_ALL_APPLICANTS_VIA_API,getAllApplicantsViaSaga );
  yield takeEvery(GET_ALL_RESUMESOURCES_VIA_API,getAllResumeSourceViaSaga );
  yield takeEvery(ADD_RESUMESOURCES_VIA_API,addResumeSourcesViaSaga );
  yield takeEvery(ADD_APPLICANT_NOTE_VIA_API,addApplicantNoteViaSaga );
  yield takeEvery(TAG_APPLICANT_TO_JOB_VIA_API,tagApplicantToJobViaSaga );
  yield takeEvery(GET_ALL_DEGREES_VIA_API,getAllDegreesViaSaga );
  yield takeEvery(ADD_DEGREES_VIA_API,addDegreeViaSaga );

  // yield takeEvery(SEARCH_DATE_JOB_POSTING_DATA_VIA_API, se);

 
  
}

export default function* Recruiter() {
  yield all([
    // ... other sagas ...

    fork(watchSettleItems),
  ]);
}
