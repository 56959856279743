import React from "react";
import { CircularProgress, Box, Typography } from "@mui/material";
import theme from "../../../Theme";

const PercentageCircle = ({
  value,
  size = 100,
  thickness = 4,
  color,
  variant,
}) => {
  const normalizedValue = value > 100 ? 100 : value < 0 ? 0 : value;

  return (
    <Box
      position="relative"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
    >
      {/* Background CircularProgress */}
      <CircularProgress
        variant="determinate"
        value={100}
        size={size}
        thickness={thickness}
        style={{ color: "#e0e0e0" }} // gray background
      />
      {/* Foreground CircularProgress */}
      <CircularProgress
        variant="determinate"
        value={normalizedValue}
        size={size}
        thickness={thickness}
        sx={{
          color:
            variant === "contained"
              ? theme.palette[color].contrastText
              : theme.palette[color].main,
        }}
        style={{ position: "absolute", left: 0 }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="body2"
          fontWeight={"bold"}
          sx={{
            color:
              variant === "contained"
                ? theme.palette[color].contrastText
                : theme.palette.text.primary,
          }}
        >
          {/* {`${Math.round(normalizedValue)}%`} */}
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

export default PercentageCircle;
