
import {
  Box,
  Button,
  Divider,
  Icon,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import theme from "../../Theme";
import { styled, useTheme } from "@mui/material/styles";
import LinearScaleOutlinedIcon from "@mui/icons-material/LinearScaleOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import TrendingDownOutlinedIcon from "@mui/icons-material/TrendingDownOutlined";

const StyledBox = styled(Box)(({ variant, color }) => ({
  // Common styles for all variants
  padding: "15px 15px 15px 15px",
  width: "calc(100% - 30px)",
  height: "calc(100% - 50px)",
  borderRadius: 5,
  boxShadow: "rgba(0, 0, 0, 0.45) 0px 25px 10px -20px",
  marginBottom: "20px",

  // Variant-specific styles
  ...(variant === "text" && {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.text.primary,
  }),
  ...(variant === "outlined" && {
    border: `1px solid ${theme.palette[color].dark}`,
    color: theme.palette[color].dark,
  }),
  ...(variant === "contained" && {
    backgroundColor: theme.palette[color]?.dark || theme.palette.primary.dark,
    color:
      theme.palette[color]?.contrastText || theme.palette.primary.contrastText,
  }),
}));

const MainWrapper = ({ variant = "text", color = "primary", children }) => {
  return (
    <StyledBox variant={variant} color={color}>
      {children}
    </StyledBox>
  );
};

const Widget = ({
  title = "Title is Empty",
  value = 0.0,
  icon,
  color = "primary",
  variant = "text", //contained, outlined, text
  bottomDetails,
}) => {
  return (
    <React.Fragment>
      <MainWrapper variant={variant} color={color}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography>{title}</Typography>
              </Box>
              <Box>
                <IconButton size="small" sx={{}}>
                  <LinearScaleOutlinedIcon
                    fontSize="inherit"
                    sx={{ color: theme.palette[color].light }}
                  />
                </IconButton>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "space-between",
                alignItems: "end",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "45px",
                    lineHeight: "35px",
                    fontWeight: "600",
                    color: "inherit",
                  }}
                >
                  {value}
                </Typography>
              </Box>
              <Box>
                <Box
                  sx={{
                    borderRadius: "50px",
                    width: "50px",
                    height: "50px",
                    backgroundColor: theme.palette[color].dark,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: theme.palette[color].contrastText,
                    //boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)", // Define your box shadow here
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                  }}
                >
                  {icon}
                </Box>
              </Box>
            </Box>
          </Box>
          {bottomDetails && (
            <Box sx={{ mt: 1 }}>
              <Divider
                sx={{
                  bgcolor: theme.palette[color].contrastText,
                  opacity: 0.5,
                }}
              />
              <Box
                sx={{
                  mt: "5px",
                  display: "flex",
                  gap: 2,
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      letterSpacing: 0.5,
                      opacity: 0.3,
                      margin: 0,
                      p: 0,
                    }}
                  >
                    Performance
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",

                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <TrendingUpOutlinedIcon color="success" fontSize="12" />
                  <TrendingDownOutlinedIcon color="error" fontSize="12" />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </MainWrapper>
    </React.Fragment>
  );
};

export default Widget;
