
import React, { useState } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import {
  Badge,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import theme from "../../Theme";
// import { globalSearchViaApi } from "../../store/search/action";
import { useDispatch, useSelector } from "react-redux";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import WorkIcon from "@mui/icons-material/Work";
import PlaceIcon from "@mui/icons-material/Place";
import ContactsIcon from "@mui/icons-material/Contacts";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import DialpadIcon from "@mui/icons-material/Dialpad";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Javascript } from "@mui/icons-material";
import { globalSearchViaApi, searchDataViaApi } from "../../store/search/action";
import { SnackbarProvider, enqueueSnackbar, useSnackbar } from "notistack";
import {
  SnackbarComponent
} from "../../Components";
import moment from "moment-timezone";
const copyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      alert("Text copied to clipboard!");
    })
    .catch((err) => {
      console.error("Failed to copy: ", err);
    });
};

const CopyToClipboardButton = ({ text }) => {
  return (
    <Tooltip title="Copy">
      <Link
        variant="body2"
        sx={{ ml: 0.5 }}
        href="javascript:void(0)"
        onClick={(e) => {
          e.preventDefault();
          copyToClipboard(text);
        }}
        color={theme.palette.grey[500]}
      >
        <ContentCopyIcon fontSize="inherit" />
      </Link>
    </Tooltip>
  );
};
const ShowData = ({ title, value, link, varient = "body2",showDays }) => {
  return (
    <Box>
      <Box>
        <Typography variant="body3" sx={{ lineHeight: 1 }}>
          {title}
        </Typography>
      </Box>
      <Box sx={{display:"flex", gap:.5, alignItems:"end"}}>
        <Typography
          variant={varient}
          fontWeight={"bold"}
          sx={{ overflow: "clip", pr:1, pt:1 }}
        >
           {title === "Status"?(
            <Badge  variant="dot" color={value==="DNC"?"warning":value==="Blocked"?"error":value==="On Process"?"secondary":"success"}>{value}</Badge>
           ):(value)}
           
        </Typography>
        
        
           {title === "Added on"&&(
            // <Box><Chip variant="outlined" color="primary" label="1 week ago" /></Box>
            <Box><Chip variant="outlined" color="primary" label={`${showDays} days ago`} /></Box>

           )}
      </Box>
    </Box>
  );
};
const JobDetails = ({ title, location, description }) => {
  return (
    <Box>
      <Typography
        variant="h5"
        fontWeight={"bold"}
        sx={{ display: "flex", alignItems: "center", gap: 1 }}
      >
        <WorkIcon fontSize="inherit" />
        Job Details
      </Typography>
      <Box sx={{ mt: 0.5, display: "flex", flexDirection: "column", gap: 1 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              gap: 0.5,
            }}
          >
            <WorkspacePremiumIcon fontSize="inherit" />
            {title}
          </Typography>
          <CopyToClipboardButton text={title} />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              gap: 0.5,
            }}
          >
            <PlaceIcon fontSize="inherit" />
            {location}
          </Typography>
          <CopyToClipboardButton text={location} />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Link
            href={description}
            target="_blank"
            variant="subtitle2"
            underline="hover"
          >
            Open Job Description
          </Link>
          <CopyToClipboardButton text={description} />
        </Box>
      </Box>
    </Box>
  );
};

const ContactDetails = ({ data }) => {
  return (
    <Box>
      <Typography
        variant="h5"
        fontWeight={"bold"}
        sx={{ display: "flex", alignItems: "center", gap: 2 }}
      >
        <ContactsIcon fontSize="inherit" />
        Contact Details
      </Typography>
      <Box sx={{ mt: 0.5, display: "flex", gap: 1, flexWrap: "wrap" }}>
        {data.map((item, key) => {
          return (
            <Box
              sx={{
                flex: "50%",
                flexBasis: 300,
              }}
              key={key}
            >
              <Paper>
                <Box
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: 0.5,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    <AccountCircleIcon fontSize="inherit" />
                    {item.firstName} {item.lastName}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      ml: 2.5,
                      lineHeight: 1,
                      mb: 0.5,
                      color: theme.palette.grey[500],
                      whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                      
                    }}
                  >
                    {item.designation}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <EmailIcon fontSize="inherit" />
                      {item.emailId}
                    </Typography>
                    <CopyToClipboardButton text={item.emailId} />
                  </Box>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    <DialpadIcon fontSize="inherit" />
                    {item.phoneNumber?item.phoneNumber:'n/a'}
                  </Typography>
                </Box>
              </Paper>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

const GlobalSearch = () => {
  

  const dispatch = useDispatch();
  const { storeData } = useSelector((state) => ({
    storeData: state.Login.storeData,
  }));
  const searchResultsData =
  useSelector((state) => state.Search.globalSearchData) || [];
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const sortedFilteredResults =searchResultsData.length > 0
    ? searchResultsData.sort(
        (a, b) => new Date(b.addedOn) - new Date(a.addedOn)
      )
    : [];
    const latestResult = searchResultsData.sort(
      (a, b) => new Date(b.addedOn) - new Date(a.addedOn)
    )[0];
  const [searchEmail, setSearchEmail] = useState("");
  const handleSearch = () => {
    if (searchEmail.length > 0) {
      // dispatch(globalSearchViaApi(searchEmail));
      const cleanedQuery = searchEmail.replace(/\s+/g, " ").trim();
      // dispatch(searchDataViaApi(cleanedQuery));
      dispatch(globalSearchViaApi(cleanedQuery));


    }
  };
  const handleKeyPress = (e) => {
    // e.preventDefault()
    if (e.key === "Enter") {
      if (searchEmail.length === 0) {
        // If reduxPlaceOrder is not empty, return or show a message
        return;
      }
      handleSearch();
    }
  };
  const formatDate = (dateString) => {
    const date = moment.tz(dateString, "UTC"); // Parse date in UTC
    return date.tz("Asia/Kolkata").format("MMMM DD, YYYY"); // Convert to Kolkata time and format
  };
 
    const calculateDays = (providedDate) => {
    const timeZone = "Asia/Kolkata";

    // Convert provided date to IST
    const providedDateObj = moment.tz(providedDate, timeZone).startOf("day");

    // Get today's date in IST
    const todayDateObj = moment.tz(timeZone).startOf("day");

    // Calculate the difference in milliseconds between the provided date and today's date
    const differenceInMilliseconds =
      todayDateObj.valueOf() - providedDateObj.valueOf();

    // Convert milliseconds to days
    const daysDifference = Math.round(
      differenceInMilliseconds / (1000 * 60 * 60 * 24)
    );

    return daysDifference;
  };

  const sanitizeString = (str) => {
    if (str) {
        return str.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
    }
    return ""; // Return an empty string if str is undefined or null
};
  const handleCheckEmailID = (latestResult, query) => {
    if (latestResult && latestResult.contactDetails) {
        // Loop through each contact detail
        for (let contact of latestResult.contactDetails) {
            // Check if the sanitized email ID matches the sanitized query
            if (sanitizeString(contact.emailId) === sanitizeString(query)) {
                // If match found, return true
                return true;
            }
        }
    }
    // If no match found, return false
    return false;
  }
  
  
  const extractDomain = (email) => {
    const domainMatch = email.match(/@(.+)$/);
    return domainMatch ? domainMatch[1].toLowerCase() : email.toLowerCase().replace(/^[^@]*@?/, '');
  };
  
  // Function to check if any email ID domain matches the query domain
  const handleCheckEmailDomain = (latestResult, query) => {
    if (latestResult && latestResult.contactDetails && query) {
      for (let contact of latestResult.contactDetails) {
        const emailDomain = extractDomain(contact.emailId);
        // Check if the domain of the email matches the query domain
        if (emailDomain.toLowerCase() === query.trim().toLowerCase()) {
          return true;
        }
      }
    }
  
    // If no match found, return false
    return false;
  };
  const showSearchData = sortedFilteredResults.filter((data) =>
    data.contactDetails &&
    Array.isArray(data.contactDetails) &&
    data.contactDetails.some((contact) =>
      sanitizeString(latestResult.companyName) === sanitizeString(searchEmail) ||
    sanitizeString(latestResult.domain)===sanitizeString(searchEmail) ||
      handleCheckEmailID(latestResult, searchEmail) ||
      handleCheckEmailDomain(latestResult, searchEmail)||
      sanitizeString(latestResult.domain)===sanitizeString(extractDomain(searchEmail))
    )
  );
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Global Search"}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: "1",
              alignItems: "center",
              gap: "15px",
              height: "100%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: "600px",
                display: "flex",
                flexDirection: "",
                justifyContent: "center",
              }}
            >
              <TextField
                label="Search by Company Name / Domain / Email-ID"
                fullWidth
                onChange={(e) => setSearchEmail(e.target.value)}
                onKeyDown={handleKeyPress}
              />
              <Button variant="contained" size="large" onClick={handleSearch}>
                Submit
              </Button>
            </Box>
            {showSearchData.length>0&&searchEmail&&
            (
              <>            {sortedFilteredResults &&
              sortedFilteredResults.map((data, index) => (
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: "1400px",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                  key={index}
                >
                  <Paper
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Box sx={{ p: 2 }}>
                      <Box sx={{ mb: 1 }}>
                        <Typography
                          variant="h6"
                          fontWeight={"bold"}
                          color={theme.palette.primary.dark}
                        >
                          {data.companyName}
                        </Typography>
                      </Box>
                      <Divider />
                      <Box
                        sx={{
                          my: 1,
                          display: "flex",
                          gap: 3,
                          flexWrap: "wrap",
                        }}
                      >
                        <ShowData
                          title={"Added By"}
                          // value={"Omkar Sahu"}
                          value={allUsers
                            .find((user) => user._id === data.addedBy)
                            ?.userFirstName}
                          varient="h6"
                        />
                        <Divider orientation="vertical" flexItem />
                        <ShowData
                          title={"Added on"}
                          value={formatDate(data.addedOn)}
                          varient="h6"
                          showDays={calculateDays(data.addedOn)}
                        />
                        <Divider orientation="vertical" flexItem />
                        <ShowData
                          title={"Status"}
                          value={data.status}
                          varient="h6"
                        />
                      </Box>
                      <Divider />

                      <Box sx={{ mt: 2 }}>
                        <Grid2 container spacing={2}>
                          {data.jobDetails &&
                            data.jobDetails.map((job, index) => (
                              <Grid2 xs={12} sm={3} key={index}>
                                <JobDetails
                                  title={job.jobTitle}
                                  location={job.jobLocation}
                                  description={job.jobDescription}
                                />
                              </Grid2>
                            ))}
                         {storeData.userType.includes('BDM')&&(
                          <Grid2 xs={12} sm={9}>
                            <ContactDetails data={data && data.contactDetails ? data.contactDetails : []} />
                          </Grid2>
                           )}
                        </Grid2>
                      </Box>
                    </Box>
                  </Paper>
                </Box>
              ))}
              </>
            )
            }

          </Box>
          <SnackbarComponent/>
        </LayoutSidebarMenu>
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default GlobalSearch;
