import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import { SnackbarProvider, useSnackbar } from "notistack"; // Import useSnackbar from notistack
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import SnackbarComponent from "../../Components/SnackbarComponent"; // Adjust path as per your actual structure
import {
  getAllEmailsLogsViaApi,
  getSentEmailsViaApi,
  otherEmailsSentViaApi,
} from "../../store/companiesList/action";
import dayjs from "dayjs";
import { CustomDateFilter } from "../../Components";

dayjs.extend(require("dayjs/plugin/timezone"));
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.tz.setDefault("Asia/Kolkata");
const OtherEmailsSend = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar(); // Use useSnackbar here
  const AllEmailLogsData =
    useSelector((state) => state.CompaniesList?.emailLogs) || [];

  // useEffect(() => {
  // dispatch(getAllEmailsLogsViaApi());
  // }, [dispatch]);

  // Counting emails sent on each date
  const dateCountMap = new Map();
  AllEmailLogsData.forEach((email) => {
    const sentDate = email.sentDateTime.split("T")[0];
    if (dateCountMap.has(sentDate)) {
      dateCountMap.set(sentDate, dateCountMap.get(sentDate) + 1);
    } else {
      dateCountMap.set(sentDate, 1);
    }
  });

  // Creating rows for DataGrid with unique IDs
  let id = 1;
  const result = Array.from(dateCountMap, ([sentDate, count]) => ({
    id: id++,
    sentDate,
    count,
  }));

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "sentDate",
      headerName: "Email Sent Date",
      minWidth: 150,
      flex: 1,
    },
    { field: "count", headerName: "Emails Sent Count", minWidth: 150, flex: 1 },
  ];

  const handleSendEmails = () => {
    dispatch(otherEmailsSentViaApi());
    // dispatch(getAllEmailsLogsViaApi());
  };

  const [searchFromDate, setSearchFromDate] = useState("");
  const [startingDate, setStartingDate] = useState(
    dayjs().tz("Asia/Kolkata").startOf("day")
  );

  const [searchToDate, setSearchToDate] = useState("");
  const handleDateChange = (selectedDates) => {
    setSearchFromDate("");
    setSearchToDate("");
    if (selectedDates.length === 2) {
      const formattedDates = selectedDates.map((date, index) => {
        return date.toISOString();
      });

      const [fromDate, toDate] = selectedDates;
      setSearchFromDate(formattedDates[0]);
      setSearchToDate(formattedDates[1]);

      // const action = {
      //   searchFromDate: formattedDates[0],
      //   searchToDate: formattedDates[1],
      // };
      const action = {
        searchFromDate: formattedDates[0],
        searchToDate: formattedDates[1],
      };

      dispatch(getAllEmailsLogsViaApi(action));
    }
  };
  useEffect(() => {
    // const today = new Date();
    const today = dayjs().tz("Asia/Kolkata").startOf("day");

    handleDateChange([today, today.endOf("day")]);
  }, []);
  return (
    <SnackbarProvider
      maxSnack={4}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
    >
      <LayoutSidebarMenu>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            gap: 3,
            mb: 3,
          }}
        >
          <Box flex={1}></Box>

          <Box sx={{ mb: 2 }}>
            <CustomDateFilter handleDateChange={handleDateChange} />
          </Box>
        </Box>
        <Box sx={{ width: "100%", overflowX: "auto" }}>
            <DataGrid
              rows={result}
              columns={columns}
              autoHeight
              disableColumnMenu
            />
          </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: "1",
            alignItems: "center",
            justifyContent: "center",
            gap: "15px",
            height: "100%",
          }}
        >
          {/* <Box> Please click send Option</Box>
          <Box
            sx={{
              width: "100%",
              maxWidth: "600px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{ m: 1, minWidth: 100 }}
              endIcon={<SendIcon />}
              onClick={handleSendEmails}
            >
              Send
            </Button>
          </Box> */}
          
        </Box>
      </LayoutSidebarMenu>
      <SnackbarComponent />
    </SnackbarProvider>
  );
};

export default OtherEmailsSend;
