import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  API_ERROR,
  RESET_LOGIN_FLAG,
  SUCCESS_GET_PROFILE_DATA,
  GET_ALL_USERS_SUCCESS,
  LOGOUT_USER,
} from "./actionTypes";

const initialState = {
  errorMsg: "",
  loading: false,
  error: false,
  storeData: {},
  allUsers: [],
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
        error: false,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        storeData: action.payload,
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        errorMsg: action.payload.data,
        loading: false,
        error: true,
        isUserLogout: false,
      };
      break;
    case SUCCESS_GET_PROFILE_DATA:
      state = {
        ...state,
        storeData: action.payload.userDetails,
        errorMsg: false,
        loading: false,
        error: false,
      };
    case GET_ALL_USERS_SUCCESS:
      state = {
        ...state,
        allUsers: action.payload,
        loading: false,
        error: false,
      };
      break;
      break;
    case RESET_LOGIN_FLAG:
      state = {
        ...state,
        loading: false,
        error: false,
      };
      break;
    case LOGOUT_USER:
      state = {
        ...state,
        loading: false,
        error: false,
        storeData: {},
        allUsers: [],
      };
      break;
    default:
      return state;
  }
  return state;
};

export default login;
