import {
  SnackbarProvider,
  closeSnackbar,
  enqueueSnackbar,
  useSnackbar,
} from "notistack";
import React, { useEffect, useState } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import theme from "../../Theme";
import { CustomBox, DateTime, PageHeader, Widget } from "../../Components";
import BusinessIcon from "@mui/icons-material/Business";
import GetAppIcon from "@mui/icons-material/GetApp";
import { useSelector, useDispatch } from "react-redux";
import {
  dateSearchUsersCompaniesViaApi,
  getAllUsersCompaniesList,
} from "../../store/search/action";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import CloseIcon from "@mui/icons-material/Close";
import { DateTimePicker } from "@mui/x-date-pickers";
import DateTimePickerValue from "../../Components/DateandTime/DateTimePickerValue";
import DatePicker from "../../Components/DateandTime/DatePicker";
import dayjs from "dayjs";
import "dayjs/plugin/timezone";
import "dayjs/plugin/utc";
import { getAllUsers } from "../../store/auth/login/actions";
import * as XLSX from "xlsx";
dayjs.extend(require("dayjs/plugin/timezone"));
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.tz.setDefault("Asia/Kolkata");

const Reports = () => {
  const dispatch = useDispatch();
  const { storeData } = useSelector((state) => ({
    storeData: state.Login.storeData,
  }));
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];

  const AllCompaniesList =
    useSelector((state) => state.Search.AllDateSearchComapniesList) || [];
  let AlluserComapnies = Array.isArray(AllCompaniesList)
    ? AllCompaniesList.reduce((acc, companies) => {
        const useraddedBy = companies.addedBy;
        const existingGroupIndex = acc.findIndex(
          (group) => group.useraddedBy === useraddedBy
        );
        if (existingGroupIndex !== -1) {
          acc[existingGroupIndex].companies.push(companies);
        } else {
          acc.push({ useraddedBy, companies: [companies] });
        }
        return acc;
      }, []).map((group) => ({
        raName: group.useraddedBy,
        companies: group.companies,
      }))
    : [];
  const mergedData = AlluserComapnies.map((group) => {
    const userData = allUsers.find((user) => user._id === group.raName);
    if (userData) {
      return {
        raName: userData.userFirstName,
        userSudoFirstName: userData.userSudoFirstName,
        emailid: userData.userEmailId,
        position: userData.userLevel,
        companies: group.companies.map((company) => {
          return {
            ...company,
            addedBy: userData.userFirstName, // Update addedBy to user's name
          };
        }),
      };
    } else {
      return group;
    }
  });
  const companiesListWithIds = Array.isArray(mergedData)
    ? mergedData.map((company, index) => ({
        ...company,
        id: index + 1,
      }))
    : [];

  useEffect(() => {
    dispatch(getAllUsersCompaniesList());
    dispatch(getAllUsers());
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "raName",
      headerName: "RA Name",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "userSudoFirstName",
      headerName: "Pseudo Name",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "emailid",
      headerName: "Email Id",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "position",
      headerName: "Position",
      width: 180,
    },
    {
      field: "companies",
      headerName: "Total Companies",
      width: 180,
      valueGetter: (value, row) => {
        if (row.companies && row.companies.length > 0) {
          return row.companies.length;
        } else {
          return "--";
        }
      },
    },
    {
      field: "jobDetails",
      headerName: "Total Job Details",
      width: 180,
      valueGetter: (value, row) => {
        if (row.companies && row.companies.length > 0) {
          const companies = row.companies;
          return companies.reduce((count, company) => {
            return company.jobDetails && company.jobDetails.length > 0
              ? count + 1
              : count;
          }, 0);
        } else {
          return "--";
        }
      },
    },
    {
      field: "contactDetails",
      headerName: "Total Contact Details",
      width: 180,
      valueGetter: (value, row) => {
        if (row.companies && row.companies.length > 0) {
          const companies = row.companies;

          return companies.reduce((count, company) => {
            return (
              count +
              (company.contactDetails ? company.contactDetails.length : 0)
            );
          }, 0);
        } else {
          return "--";
        }
      },
    },
  ];
  const totalCompanies = AllCompaniesList.length;
  const totalUsers = AlluserComapnies.length;
  const AVG = (totalUsers !== 0 ? totalCompanies / totalUsers : 0).toFixed(2);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchFromDate, setSearchFromDate] = useState("");
  const [startingDate, setStartingDate] = useState(
    dayjs().tz("Asia/Kolkata").startOf("day")
  );

  const [searchToDate, setSearchToDate] = useState("");
  const handleDateChange = (selectedDates) => {
    setSearchFromDate("");
    setSearchToDate("");
    if (selectedDates.length === 2) {
      const formattedDates = selectedDates.map((date, index) => {
        return date.toISOString();
      });

      const [fromDate, toDate] = selectedDates;
      setSearchFromDate(formattedDates[0]);
      setSearchToDate(formattedDates[1]);

      const action = {
        searchFromDate: formattedDates[0],
        searchToDate: formattedDates[1],
      };

      dispatch(dateSearchUsersCompaniesViaApi(action));
    }
  };
  useEffect(() => {
    // const today = new Date();
    const today = dayjs().tz("Asia/Kolkata").startOf("day");
    // handleDateChange([
    //   dayjs(today.setHours(0, 0, 0, 0)),
    //   dayjs(today.setHours(23, 59, 0, 0)),
    // ]);
    handleDateChange([today, today.endOf("day")]);
  }, []);
  const handleRowDownload = (rowData) => {
    const csv = convertToCSV(rowData);
    const currentDate = new Date().toISOString().slice(0, 10); // Get today's date in YYYY-MM-DD format
    const fileName = `${rowData.raName}_${currentDate}.csv`;
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function convertToCSV(data) {
    const headers = [
      "companyName",
      "comment",
      "jobTitle",
      "jobLocation",
      "jobDescription",
      "jobWebsite",
      "contactFirstName",
      "contactLastName",
      "contactDesignation",
      "contactEmail",
      "contactPhoneNumber",
    ];

    // Add headers to CSV
    let csv = headers.join(",") + "\n";

    // Add data rows to CSV
    data.companies.forEach((company) => {
      // Add job details if available
      if (company.jobDetails && company.jobDetails.length > 0) {
        const job = company.jobDetails[0];
        // Add contact details if available
        if (company.contactDetails && company.contactDetails.length > 0) {
          company.contactDetails.forEach((contact) => {
            csv += `"${company.companyName || ""}","${
              company.comment || ""
            }","${job.jobTitle || ""}","${job.jobLocation || ""}","${
              job.jobDescription || ""
            }","${job.jobWebsite || ""}","${contact.firstName || ""}","${
              contact.lastName || ""
            }","${contact.designation || ""}","${contact.emailId || ""}","${
              contact.phoneNumber || ""
            }"\n`;
          });
        } else {
          // Add a row with empty contact details if no contact details are available
          csv += `"${company.companyName || ""}","${company.comment || ""}","${
            job.jobTitle || ""
          }","${job.jobLocation || ""}","${job.jobDescription || ""}","${
            job.jobWebsite || ""
          }","","","","",""\n`;
        }
      } else {
        // Add a row with empty job details if no job details are available
        csv += `"${company.companyName || ""}","${
          company.comment || ""
        }","","","","","","","","",""\n`;
      }
    });

    return csv;
  }
  const handleDownloadAll = () => {
    if (selectedRows.length > 0) {
      selectedRows.forEach((company) => {
        handleRowDownload(company);
      });
    } else {
      if (companiesListWithIds && companiesListWithIds.length > 0) {
        companiesListWithIds.forEach((company) => {
          handleRowDownload(company);
        });
      } else {
        enqueueSnackbar(
          `Today's data is unavailable and cannot be downloaded.`,
          {
            variant: "error",
            action: (key) => (
              <IconButton onClick={() => closeSnackbar()} color="inherit">
                <CloseIcon />
              </IconButton>
            ),
          }
        );
      }
    }
  };

  const handleAllRowDownload = (rowData) => {
    const csv = ALLconvertToCSV(rowData);
    const currentDate = new Date().toISOString().slice(0, 10); // Get today's date in YYYY-MM-DD format
    const fileName = `AllRATeamSheet_${currentDate}.csv`;
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function ALLconvertToCSV(company) {
    const headers = [
      "addedByRaName",
      "companyName",
      "comment",
      "jobTitle",
      "jobLocation",
      "jobDescription",
      "jobWebsite",
      "contactFirstName",
      "contactLastName",
      "contactDesignation",
      "contactEmail",
      "contactPhoneNumber",
    ];

    // Add headers to CSV
    let csv = headers.join(",") + "\n";
    company.forEach((data) => {
      // Add data rows to CSV
      data.companies.forEach((company) => {
        // Add job details if available
        if (company.jobDetails && company.jobDetails.length > 0) {
          const job = company.jobDetails[0];
          // Add contact details if available
          if (company.contactDetails && company.contactDetails.length > 0) {
            company.contactDetails.forEach((contact) => {
              csv += `"${data.raName || ""}","${company.companyName || ""}","${
                company.comment || ""
              }","${job.jobTitle || ""}","${job.jobLocation || ""}","${
                job.jobDescription || ""
              }","${job.jobWebsite || ""}","${contact.firstName || ""}","${
                contact.lastName || ""
              }","${contact.designation || ""}","${contact.emailId || ""}","${
                contact.phoneNumber || ""
              }"\n`;
            });
          } else {
            // Add a row with empty contact details if no contact details are available
            csv += `"${data.raName || ""}","${company.companyName || ""}","${
              company.comment || ""
            }","${job.jobTitle || ""}","${job.jobLocation || ""}","${
              job.jobDescription || ""
            }","${job.jobWebsite || ""}","","","","",""\n`;
          }
        } else {
          // Add a row with empty job details if no job details are available
          csv += `"${data.raName || ""}","${company.companyName || ""}","${
            company.comment || ""
          }","","","","","","","","",""\n`;
        }
      });
    });
    return csv;
  }

  const handleDownloadAllOneFile = () => {
    if (companiesListWithIds && companiesListWithIds.length > 0) {
      handleAllRowDownload(companiesListWithIds);
      // companiesListWithIds.forEach((company) => {
      //   handleAllRowDownload(company);
      // });
    } else {
      enqueueSnackbar(`Today's data is unavailable and cannot be downloaded.`, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
  };
  const handleRowDownloadExcel = (rowData) => {
    // Convert the data to the same format as the CSV function
    const formattedData = convertToJSON(rowData);

    // Create a worksheet from the formatted data
    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "AllData");

    // Generate the file name with the current date
    const currentDate = new Date().toISOString().slice(0, 10);
    const fileName = `${rowData.raName}_${currentDate}.xlsx`;

    // Write the workbook to a file
    XLSX.writeFile(workbook, fileName);
  };
  const convertToJSON = (data) => {
    const headers = [
      "companyName",
      "comment",
      "jobTitle",
      "jobLocation",
      "jobDescription",
      "jobWebsite",
      "contactFirstName",
      "contactLastName",
      "contactDesignation",
      "contactEmail",
      "contactPhoneNumber",
    ];

    const json = [];
    data.companies.forEach((company) => {
      if (company.jobDetails && company.jobDetails.length > 0) {
        const job = company.jobDetails[0];
        if (company.contactDetails && company.contactDetails.length > 0) {
          company.contactDetails.forEach((contact) => {
            json.push({
              companyName: company.companyName || "",
              comment: company.comment || "",
              jobTitle: job.jobTitle || "",
              jobLocation: job.jobLocation || "",
              jobDescription: job.jobDescription || "",
              jobWebsite: job.jobWebsite || "",
              contactFirstName: contact.firstName || "",
              contactLastName: contact.lastName || "",
              contactDesignation: contact.designation || "",
              contactEmail: contact.emailId || "",
              contactPhoneNumber: contact.phoneNumber || "",
            });
          });
        } else {
          json.push({
            companyName: company.companyName || "",
            comment: company.comment || "",
            jobTitle: job.jobTitle || "",
            jobLocation: job.jobLocation || "",
            jobDescription: job.jobDescription || "",
            jobWebsite: job.jobWebsite || "",
            contactFirstName: "",
            contactLastName: "",
            contactDesignation: "",
            contactEmail: "",
            contactPhoneNumber: "",
          });
        }
      } else {
        json.push({
          companyName: company.companyName || "",
          comment: company.comment || "",
          jobTitle: "",
          jobLocation: "",
          jobDescription: "",
          jobWebsite: "",
          contactFirstName: "",
          contactLastName: "",
          contactDesignation: "",
          contactEmail: "",
          contactPhoneNumber: "",
        });
      }
    });
    return json;
  };
  const handleDownloadAllExcel = () => {
    if (selectedRows.length > 0) {
      selectedRows.forEach((company) => {
        handleRowDownloadExcel(company);
      });
    } else {
      if (companiesListWithIds && companiesListWithIds.length > 0) {
        companiesListWithIds.forEach((company) => {
          handleRowDownloadExcel(company);
        });
      } else {
        enqueueSnackbar(
          `Today's data is unavailable and cannot be downloaded.`,
          {
            variant: "error",
            action: (key) => (
              <IconButton onClick={() => closeSnackbar()} color="inherit">
                <CloseIcon />
              </IconButton>
            ),
          }
        );
      }
    }
  };

  const handleAllRowDownloadExcel = (rowData) => {
    // Convert the data to the same format as the CSV function
    const formattedData = ALLconvertToJSON(rowData);

    // Create a worksheet from the formatted data
    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "AllData");

    // Generate the file name with the current date
    const currentDate = new Date().toISOString().slice(0, 10);
    const fileName = `AllRATeamSheet_${currentDate}.xlsx`;

    // Write the workbook to a file
    XLSX.writeFile(workbook, fileName);
  };

  // Function to convert the data to the same format as the CSV function
  const ALLconvertToJSON = (company) => {
    const headers = [
      "addedByRaName",
      "companyName",
      "comment",
      "jobTitle",
      "jobLocation",
      "jobDescription",
      "jobWebsite",
      "contactFirstName",
      "contactLastName",
      "contactDesignation",
      "contactEmail",
      "contactPhoneNumber",
    ];

    const json = [];
    company.forEach((data) => {
      data.companies.forEach((company) => {
        if (company.jobDetails && company.jobDetails.length > 0) {
          const job = company.jobDetails[0];
          if (company.contactDetails && company.contactDetails.length > 0) {
            company.contactDetails.forEach((contact) => {
              json.push({
                addedByRaName: data.raName || "",
                companyName: company.companyName || "",
                comment: company.comment || "",
                jobTitle: job.jobTitle || "",
                jobLocation: job.jobLocation || "",
                jobDescription: job.jobDescription || "",
                jobWebsite: job.jobWebsite || "",
                contactFirstName: contact.firstName || "",
                contactLastName: contact.lastName || "",
                contactDesignation: contact.designation || "",
                contactEmail: contact.emailId || "",
                contactPhoneNumber: contact.phoneNumber || "",
              });
            });
          } else {
            json.push({
              addedByRaName: data.raName || "",
              companyName: company.companyName || "",
              comment: company.comment || "",
              jobTitle: job.jobTitle || "",
              jobLocation: job.jobLocation || "",
              jobDescription: job.jobDescription || "",
              jobWebsite: job.jobWebsite || "",
              contactFirstName: "",
              contactLastName: "",
              contactDesignation: "",
              contactEmail: "",
              contactPhoneNumber: "",
            });
          }
        } else {
          json.push({
            addedByRaName: data.raName || "",
            companyName: company.companyName || "",
            comment: company.comment || "",
            jobTitle: "",
            jobLocation: "",
            jobDescription: "",
            jobWebsite: "",
            contactFirstName: "",
            contactLastName: "",
            contactDesignation: "",
            contactEmail: "",
            contactPhoneNumber: "",
          });
        }
      });
    });
    return json;
  };

  const handleDownloadAllOneFileXlSX = () => {
    if (companiesListWithIds && companiesListWithIds.length > 0) {
      handleAllRowDownloadExcel(companiesListWithIds);
    } else {
      enqueueSnackbar(`Today's data is unavailable and cannot be downloaded.`, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
  };

  const [selectDay, setSelectDay] = useState("Today");
  const handleChangeDay = (event) => {
    setSelectDay(event.target.value);

    const currentDay = dayjs().tz("Asia/Kolkata").startOf("day");

    if (event.target.value === "Today") {
      setStartingDate(currentDay);
      handleDateChange([currentDay, currentDay.endOf("day")]);
    } else if (event.target.value === "Yesterday") {
      const yesterday = currentDay.subtract(1, "day");
      setStartingDate(yesterday);

      handleDateChange([yesterday.startOf("day"), yesterday.endOf("day")]);
    } else if (event.target.value === "Last week") {
      const lastWeekSameDay = currentDay.subtract(1, "week");
      setStartingDate(lastWeekSameDay);
      handleDateChange([
        lastWeekSameDay.startOf("day"),
        lastWeekSameDay.endOf("day"),
      ]);
    }
  };
  const [downloadModalOpen, setdownloadModalOpen] = useState(false);
  const [selectDownloadType, setSelectDownloadType] = useState("");
  const [clickDownloadType, setClickDownloadType] = useState("");
  const handleDownloadModalOpen = (value) => {
    setdownloadModalOpen(!downloadModalOpen);
    setClickDownloadType(value);
  };
  const handleSelectDownloadType = (value) => {
    setSelectDownloadType(value);
  };
  const handleDownload = () => {
    if (selectDownloadType === ".csv") {
      if (clickDownloadType === "downloadAll") {
        handleDownloadAll();
      } else if (clickDownloadType === "downloadAllOneSheet") {
        handleDownloadAllOneFile();
      }
    } else if (selectDownloadType === ".xlsx") {
      if (clickDownloadType === "downloadAll") {
        handleDownloadAllExcel();
      } else if (clickDownloadType === "downloadAllOneSheet") {
        handleDownloadAllOneFileXlSX();
      }
    }
  };
  const handleCancelDownload = () => {
    setdownloadModalOpen(!downloadModalOpen);
    setClickDownloadType("");
    setSelectDownloadType("");
  };

  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Reports"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              gap: 3,
              mb: 3,
            }}
          >
            <Box flex={1}></Box>

            <Box sx={{ display: "flex" }}>
              {/* <DateTimePickerValue handleDateChange={handleDateChange} /> */}
              <FormControl sx={{ m: 1, minWidth: 300 }}>
                <Select
                  value={selectDay}
                  onChange={handleChangeDay}
                  inputProps={{ "aria-label": "Without label" }}
                  //   size="large"
                >
                  <MenuItem>Please select one option</MenuItem>

                  <MenuItem value={"Today"}>Today</MenuItem>
                  <MenuItem value={"Yesterday"}>Yesterday</MenuItem>
                  <MenuItem value={"Last week"}>Last week</MenuItem>
                </Select>
              </FormControl>
              <DatePicker
                handleDateChange={handleDateChange}
                searchFromDate={startingDate}
              />
            </Box>
          </Box>

          <Grid container columnSpacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <Widget
                title="Total Companies"
                value={AllCompaniesList.length}
                icon={<BusinessIcon />}
                color="primary"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Widget
                title="Avg Companies/RA"
                value={AVG}
                icon={<BusinessIcon />}
                color="success"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Widget
                title="Total Job Details"
                value={
                  AllCompaniesList
                    ? AllCompaniesList.reduce((count, company) => {
                        return company.jobDetails &&
                          company.jobDetails.length > 0
                          ? count + 1
                          : count;
                      }, 0)
                    : 0
                }
                icon={<BusinessIcon />}
                color="primary"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Widget
                title="Total Contact Details"
                value={
                  AllCompaniesList
                    ? AllCompaniesList.reduce((count, company) => {
                        return (
                          count +
                          (company.contactDetails
                            ? company.contactDetails.length
                            : 0)
                        );
                      }, 0)
                    : 0
                }
                icon={<BusinessIcon />}
                color="success"
              />
            </Grid>
          </Grid>

          <Box sx={{ width: "100%", overflowX: "auto" }}>
            <DataGrid
              rows={companiesListWithIds}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 100 },
                },
              }}
              // checkboxSelection
              disableColumnMenu // Add this line to hide the column menu
              //onCellClick={handleCellClick} // Handle cell click event
              autoHeight
              columnBuffer={companiesListWithIds.length} // Set columnBuffer to the number of columns
              disableRowSelectionOnClick
              onRowSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = companiesListWithIds.filter((row) =>
                  selectedIDs.has(row.id)
                );
                setSelectedRows(selectedRows);
              }}
            />
          </Box>
          <Box
            sx={{
              mt: 5,
              display: "flex",
              justifyContent: "center",
              gap: 3,
            }}
          >
            {/* <Button variant="contained" onClick={handleDownloadAll}> */}
            <Button
              variant="contained"
              onClick={() => handleDownloadModalOpen("downloadAll")}
            >
              Download All
            </Button>
            <Button
              variant="contained"
              onClick={() => handleDownloadModalOpen("downloadAllOneSheet")}
            >
              Download All In One Sheet
            </Button>
          </Box>
        </LayoutSidebarMenu>
      </SnackbarProvider>
      <Modal
        open={downloadModalOpen}
        //onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: {
              xs: 1,
              md: 2,
            },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: 1,
              rowGap: 0.5,
              flexWrap: "wrap",

              mb: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={"400"}>
              Select your preferred download format
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              variant="contained"
              color={selectDownloadType === ".xlsx" ? "error" : "inherit"}
              sx={{ textTransform: "inherit" }}
              onClick={() => handleSelectDownloadType(".xlsx")}
            >
              .xlsx
            </Button>
            <Button
              variant="contained"
              color={selectDownloadType === ".csv" ? "error" : "inherit"}
              sx={{ textTransform: "inherit" }}
              onClick={() => handleSelectDownloadType(".csv")}
            >
              .csv
            </Button>
          </Box>
          <Divider />
          <Box sx={{ display: "flex", gap: 2, justifyContent: "end", mt: 2 }}>
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleCancelDownload()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleDownload()}
            >
              Download
            </Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default Reports;
