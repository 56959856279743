import { Box, Typography } from "@mui/material";
import React from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import theme from "../../Theme";

const Footer = () => {
  return (
    <React.Fragment>
      <Box sx={{ p: 1, display: "flex", justifyContent: "end" }}>
        <Typography
          variant="caption"
          sx={{ fontWeight: 500, color: theme.palette.grey[600] }}
        >
          2024 Tektree. Crafted with{" "}
          <FavoriteIcon color="error" fontSize="12" /> by Tektree
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default Footer;
