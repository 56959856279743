import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  // const user = useSelector((state) => state.user);
  const { storeData } = useSelector((state) => ({
    storeData: state.Login.storeData,
  }));
  let location = useLocation();
  const user = false;
  if (storeData === undefined || Object.keys(storeData).length === 0) {
    return <Navigate to="/login" replace />;
  }

  const restrictedTypesRA = [
    'Download', 'Users', 'GlobalSearch', 'BulkEmail', 'ManualEmail', 'Assign',
    'UpdateCompanyStatus', 'BdmDownload', 'AddUser', 'UpdateUser',
    'DashboardAdmin','Client','BDMDashboard','ApprovalStatus','TestEmail','BdmReports','testEmail',
  ];
  const isRestrictedUserRA = storeData.userType === 'RATeam';
  const isRestrictedChildRA = restrictedTypesRA.includes(children.type.name);
  const isTeamCountRestrictedRA = children.type.name === 'g6' && 
    (storeData.userEmailId !== 'tarun@tektreeinc.com' && storeData.userEmailId !== 'waheed.md@tektreeinc.com');
  
  if (isRestrictedUserRA && (isRestrictedChildRA || isTeamCountRestrictedRA)) {
    return <Navigate to="/raDashboard" replace />;
  }
  const restrictedTypesBDM=[
    'Download', 'Users', 'GlobalSearch', 'Assign','BdmDownload', 'AddUser', 'UpdateUser',
    'DashboardAdmin','RADashboard','TeamCount','ApprovalStatus','TestEmail','BdmReports','testEmail',
  ];
  const isRestrictedUserBDM = storeData.userType === 'BDM';
  const isRestrictedChildBDM = restrictedTypesBDM.includes(children.type.name);
  if (isRestrictedUserBDM && isRestrictedChildBDM) {
    return <Navigate to="/bdmDashboard" replace />;
  }
  return children;
};

export default ProtectedRoute;
