import { Alert, Box, Snackbar } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearSnackbarMessage } from "../store/search/action";
import { SevenKPlusRounded } from "@mui/icons-material";
import { SnackbarProvider, enqueueSnackbar, useSnackbar } from "notistack";
const SnackbarComponent = () => {
  const dispatch = useDispatch();
  const { message, severity } = useSelector((state) => state.Search.snackbarMessage);
  useEffect(() => {
    if (message !== '') {
    enqueueSnackbar(`${message}`, { variant: severity});
    }
  }, [message, dispatch]);
  useEffect(() => {
    if (message !== '') {
      setTimeout(() => {
        dispatch(clearSnackbarMessage());
      }, 2000); // Auto-hide snackbar after 4 seconds
    }
  }, [message, dispatch]);

  const handleClose = () => {
    dispatch(clearSnackbarMessage());
  };
  

  return (
    
   <Box>

   </Box>
  );
};

export default SnackbarComponent;
