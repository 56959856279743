import {
  Box,
  TextField,
  Typography,
  Button,
  Modal,
  Chip,
  Avatar,
  IconButton,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import theme from "../../Theme";
import { useSelector, useDispatch } from "react-redux";

import SendIcon from "@mui/icons-material/Send";
import LayoutSidebarMenu from "../InnerPages/Layout/LayoutSidebarMenu";

import { SnackbarProvider, closeSnackbar, enqueueSnackbar, useSnackbar } from "notistack";
import { SnackbarComponent } from "../../Components";
import { sendEmailViaApi } from "../../store/search/action";
import CloseIcon from '@mui/icons-material/Close';
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EmailSend = () => {
  const dispatch = useDispatch();
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const storeData = useSelector((state) => state.Login.storeData);

  const [duration, setDuration] = React.useState("");

  const handleChange = (event) => {
    setDuration(event.target.value);
  };
  const handleSendEmails = () => {
    if (duration.length > 0) {
        const action = {
            duration: duration // or "Today", "This week", etc.
          }; 
      dispatch(sendEmailViaApi(action));
    } else {
      enqueueSnackbar(`Please select one option`, { variant: "error",action: (key) => (
        <IconButton  onClick={()=>closeSnackbar()} color="inherit">
          <CloseIcon />
        </IconButton>
      ) });
    }
  };

  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: "1",
              alignItems: "center",
              justifyContent: "center",
              gap: "15px",
              height: "100%",
            }}
          >
            <Box> Please Select Option and click send Option</Box>
            <Box
              sx={{
                width: "100%",
                maxWidth: "600px",
                display: "flex",
                flexDirection: "",
                justifyContent: "center",
              }}
            >
              <FormControl sx={{ m: 1, minWidth: 300 }}>
                <Select
                  value={duration}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "Without label" }}
                  //   size="large"
                >
                  <MenuItem>Please select one option</MenuItem>
                  <MenuItem value={"Test"}>Test</MenuItem>
                  <MenuItem value={"Today"}>Today</MenuItem>
                  <MenuItem value={"Yesterday"}>Yesterday</MenuItem>
                  <MenuItem value={"This week"}>This week</MenuItem>
                  <MenuItem value={"This month"}>This month</MenuItem>
                  <MenuItem value={"Previous month"}>Previous month</MenuItem>
                </Select>
              </FormControl>
              <Button
                variant="contained"
                // size="large"
                sx={{ m: 1, minWidth: 100 }}
                endIcon={<SendIcon />}
                onClick={handleSendEmails}
              >
                Send
              </Button>
            </Box>
            <Box
              sx={{
                width: "100%",
                maxWidth: "1200px",
              }}
            ></Box>
          </Box>
        </LayoutSidebarMenu>
      </SnackbarProvider>
      <SnackbarComponent />
    </React.Fragment>
  );
};

export default EmailSend;
