import {
    Box,
    Button,
    Chip,
    Divider,
    IconButton,
    Link,
    List,
    ListItem,
    Pagination,
    TablePagination,
    Tooltip,
    Typography,
  } from "@mui/material";
  import React, { useState } from "react";
  
  import theme from "../../Theme";
  import DeleteIcon from "@mui/icons-material/Delete";
  import EditIcon from "@mui/icons-material/Edit";
  import CloseIcon from "@mui/icons-material/Close";
  const TableRow = ({ children }) => {
    return (
      <React.Fragment>
        <Box
          sx={{
            display: "flex",
            flexWrap: "nowrap",
            gap: "2px",
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
            width: "fit-content",
            ":hover": {
              backgroundColor: theme.palette.grey[100],
            },
          }}
        >
          {children}
        </Box>
      </React.Fragment>
    );
  };
  const TableCell = ({
    title = "Title",
    width = 250,
    p = 1,
    bgcolor = "transparent",
    header = false,
    flex,
    textAlign = "left",
  }) => {
    return (
      <React.Fragment>
        <Box
          sx={{
           
            flexBasis: width,
            width: `${width}px`,
            p: p,
            flex: flex,
            backgroundColor: theme.palette[bgcolor].main,
          }}
        >
          {typeof title === "string" && (
            <Typography
              sx={{
                color: theme.palette[bgcolor].contrastText,
                fontWeight: header ? 600 : 400,
                textAlign: textAlign,
              }}
            >
              {title}
            </Typography>
          )}
          {typeof title === "function" && title()}
        </Box>
      </React.Fragment>
    );
  };
  
  const ContactDetail = ({ name, designation, email, phone }) => {
    return (
      <React.Fragment>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <Box sx={{ flexBasis: 200, maxWidth: 200, overflow: "hidden" }}>
            <Typography
              variant="body2"
              sx={{
                fontSize: "13px",
                letterSpacing: 0.1,
                fontWeight: 600,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {name}
            </Typography>
          </Box>
          <Box sx={{ flexBasis: 120, maxWidth: 120, overflow: "hidden" }}>
            <Typography
              variant="body2"
              sx={{
                fontSize: "12px",
                color: theme.palette.grey[600],
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {designation}
            </Typography>
          </Box>
          <Box sx={{ overflow: "hidden", flexBasis: 200, maxWidth: 200 }}>
            <Typography
              variant="body2"
              sx={{
                fontSize: "12px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {email}
            </Typography>
          </Box>
          <Box sx={{ flexBasis: 120, overflow: "hidden" }}>
            <Typography
              variant="body2"
              sx={{
                fontSize: "12px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {phone && phone ? <>{phone}</> : ""}
            </Typography>
          </Box>
        </Box>
      </React.Fragment>
    );
  };
  const JobDetail = ({ title, location, description }) => {
    return (
      <React.Fragment>
        <Box>
          <Box>
            <Typography
              variant="body2"
              sx={{
                letterSpacing: 0.5,
                fontWeight: 600,
                lineHeight: 1,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.grey[600],
                lineHeight: 1,
                fontSize: "12px",
                mt: 0.5,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {location}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.grey[600],
                lineHeight: 1,
                fontSize: "12px",
                mt: 1,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {description}
            </Typography>
          </Box>
        </Box>
      </React.Fragment>
    );
  };
  const CompanyName = ({ title }) => {
    return (
      <React.Fragment>
        <Box>
          <Typography
            variant="body2"
            sx={{
              letterSpacing: 0.5,
              fontWeight: 600,
              lineHeight: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2, // Use WebkitLineClamp for -webkit-box
              WebkitBoxOrient: "vertical",
            }}
          >
            {title}
          </Typography>
        </Box>
      </React.Fragment>
    );
  };
  
  const MyCompaniesListView = ({
    preViewCancel,
    previewData,
    toggleDeleteOrderModal,
    handleOpen,
    viewIndex,
  }) => {
    return (
      <React.Fragment>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "1400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: {
              xs: 2,
              md: 4,
            },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: -1.5,
              mb: 2.5,
            }}
          >
            <Typography>Any Content Required</Typography>
            <Button
              startIcon={<CloseIcon />}
              size="small"
              color="error"
              variant="outlined"
              onClick={preViewCancel}
            >
              Close
            </Button>
          </Box>
  
          <Box>
            <Box sx={{ overflow: "auto", maxHeight: "80vh" }}>
              {/* Table */}
              <TableRow>
                <TableCell title={"Company"} bgcolor="primary" header={true} />
                <TableCell
                  title={"Job Details"}
                  bgcolor="primary"
                  header={true}
                />
                <TableCell
                  title={"Contact Details"}
                  width={750}
                  bgcolor="primary"
                  header={true}
                  flex={1}
                />
                <TableCell
                  title={"Action"}
                  width={80}
                  bgcolor="primary"
                  header={true}
                  textAlign="center"
                />
              </TableRow>
              {previewData&&previewData.map((item, index) =>
                parseInt(item.id) === parseInt(viewIndex)  &&(
                  <TableRow key={index}>
                    {/* <TableCell title={item.companyName} /> */}
                    <TableCell
                      title={() => <CompanyName title={item.companyName} />}
                    />
                    <TableCell
                      title={() =>
                        item.jobDetails?.length > 0 ? (
                          <JobDetail
                            title={item.jobDetails[0].jobTitle}
                            location={item.jobDetails[0].jobLocation}
                            description={item.jobDetails[0].jobDescription}
                          />
                        ) : (
                          "--"
                        )
                      }
                    />
                    <TableCell
                      width={750}
                      flex={1}
                      title={() =>
                        item.contactDetails?.length > 0 ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 0.5,
                            }}
                          >
                            {item.contactDetails?.map((contact, idx) => (
                              <Box key={idx}>
                                <ContactDetail
                                  name={`${
                                    contact.firstName && contact.firstName
                                  } ${contact.lastName && contact.lastName}`}
                                  designation={
                                    contact.designation && contact.designation
                                  }
                                  email={contact.emailId && contact.emailId}
                                  phone={
                                    contact.phoneNumber && contact.phoneNumber
                                  }
                                />
                              </Box>
                            ))}
                          </Box>
                        ) : (
                          "--"
                        )
                      }
                    />
                    <TableCell
                      width={80}
                      title={() => (
                        <Box
                          sx={{
                            display: "flex",
                            gap: 0.5,
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <Tooltip title="Add/Edit">
                            <IconButton
                              size="small"
                              onClick={() => handleOpen(item.id)}
                            >
                              <EditIcon fontSize="12" />
                            </IconButton>
                          </Tooltip>
  
                          <Tooltip title="Delete">
                            <IconButton
                              size="small"
                              color="error"
                              onClick={() => toggleDeleteOrderModal(item.id)}
                            >
                              <DeleteIcon fontSize="12" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                    />
                  </TableRow>
                
            )
        )}
            
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
                mt: 2,
              }}
            >
             
            </Box>
          </Box>
        </Box>
      </React.Fragment>
    );
  };
  
  export default MyCompaniesListView;
  