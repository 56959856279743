import { Box, Typography } from "@mui/material";
import React, { Children, useState } from "react";
import Logo from "../../Assets/Images/logo-color.png";
import Tech5Logo from "../../Assets/Images/tech5-logo-color (3).png"
import theme from "../../Theme";
import BarChartIcon from "@mui/icons-material/BarChart";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import ListIcon from "@mui/icons-material/List";
import DownloadIcon from "@mui/icons-material/Download";
import SendIcon from "@mui/icons-material/Send";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";

import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import DescriptionIcon from "@mui/icons-material/Description";
import GroupsIcon from "@mui/icons-material/Groups";
import ComputerIcon from '@mui/icons-material/Computer';
import PostAddIcon from '@mui/icons-material/PostAdd';
import FindInPageIcon from '@mui/icons-material/FindInPage';

import {
  Sidebar as SidebarMenu,
  Menu,
  MenuItem,
  SubMenu,
  menuClasses,
  sidebarClasses,
} from "react-pro-sidebar";
const iconMapping = {
  DashboardIcon: <DashboardIcon />,
  ListIcon: <ListIcon />,
  PublishedWithChangesIcon: <PublishedWithChangesIcon />,
  AutorenewIcon: <AutorenewIcon />,
  BarChartIcon:<BarChartIcon/>,
  ManageSearchIcon:<ManageSearchIcon/>,
  DownloadIcon:<DownloadIcon/>,
  SendIcon:<SendIcon/>,
  TravelExploreIcon:<TravelExploreIcon/>,
  PeopleAltIcon:<PeopleAltIcon/>,
  HourglassTopIcon:<HourglassTopIcon/>,
  FlipCameraAndroidIcon:<FlipCameraAndroidIcon/>,
  ForwardToInboxIcon:<ForwardToInboxIcon/>,
  CheckCircleOutlineIcon:<CheckCircleOutlineIcon/>,
  DescriptionIcon:<DescriptionIcon/>,
  GroupsIcon:<GroupsIcon/>,
  ComputerIcon:<ComputerIcon/>,
  PostAddIcon:<PostAddIcon/>,
  FindInPageIcon:<FindInPageIcon/>,
  // Add other mappings here
};
const resolveIcon = (iconName) => {
  return iconMapping[iconName] || ''; // Return null or a default icon if not found
};
const CustomMenuItem = ({ title, linkTo, icon, active, customStyles }) => {

  return (
    <MenuItem
      component={<Link to={linkTo} />}
      icon={icon}
      rootStyles={{
        ["." + menuClasses.button]: {
          //backgroundColor: theme.palette.primary.dark,
          color: "#fff",
          display: "flex",
          flexDirection: "column",
          paddingLeft: "5px !important",
          paddingRight: "5px !important",
          height: "60px !important",
          padding: "10px !important",
          borderBottom: `1px solid ${theme.palette.primary[200]}`,
          "&:hover": {
            backgroundColor: `${theme.palette.primary.dark} !important`,
            color: theme.palette.primary.contrastText,
          },
          ...customStyles, // Apply additional custom styles
        },
        ".ps-active": {
          backgroundColor: `${theme.palette.primary.dark}!important`,
          color: theme.palette.primary.contrastText,
          "&:hover": {
            backgroundColor: `${theme.palette.primary.dark} !important`,
            color: theme.palette.primary.contrastText,
          },
        },
        ".ps-menu-icon": {
          marginRight: 0,
          width: 25,
          minWidth: 25,
          height: 25,
          lineHeight: 25,
          fontSize: 15,
        },
        ".ps-menu-label": {
          fontSize: 10,
          color: theme.palette.primary[500],
          letterSpacing: 0.5,
        },
      }}
      active={active}
    >
      {title}
    </MenuItem>
  );
};

const CustomSubMenu = ({
  title,
  icon,
  children,
  subMenuItemStyles,
  customStyles,
}) => {
  const isActive = Children.toArray(children).some(
    (child) => child && child.props && child.props.active
  );

  return (
    <SubMenu
      icon={icon}
      label={title}
      open={isActive ? true : undefined}
      active={isActive}
      rootStyles={{
        ["& > ." + menuClasses.button]: {
          //backgroundColor: theme.palette.primary.dark,
          //color: "#9f0099",
          display: "flex",
          flexDirection: "column",
          paddingLeft: "5px !important",
          paddingRight: "5px !important",
          height: "60px !important",
          padding: "10px !important",
          borderBottom: `1px solid ${theme.palette.primary[200]}`,
          "&:hover": {
            backgroundColor: `${theme.palette.primary.dark}!important`,
            color: theme.palette.primary.contrastText,
          },
          ...customStyles, // Apply additional custom styles
        },
        ["." + menuClasses.subMenuContent]: {
          backgroundColor: "#fbedff !important",
        },
        ".ps-active": {
          backgroundColor: `${theme.palette.primary.dark}!important`,
          color: theme.palette.primary.contrastText,
          "&:hover": {
            backgroundColor: `${theme.palette.primary.dark}!important`,
            color: theme.palette.primary.contrastText,
          },
        },
        ".ps-menu-icon": {
          marginRight: 0,
          width: 25,
          minWidth: 25,
          height: 25,
          lineHeight: 25,
        },
        ".ps-menu-label": {
          fontSize: 10,
          color: theme.palette.primary[500],
          letterSpacing: 0.5,
        },
        "& .ps-submenu-expand-icon": {
          right: "15px !important",
        },
      }}
    >
      {Children.map(children, (child) =>
        child && React.isValidElement(child)
          ? React.cloneElement(child, { customStyles: subMenuItemStyles })
          : child
      )}
    </SubMenu>
  );
};

const SideBar = ({ sidebarStatus }) => {
  const location = useLocation();
  const storeData = useSelector((state) => state.Login.storeData);
  const menuOptions=useSelector((state)=>state.Layouts?.menuOptions)
  const menuItemStyles = {
    //backgroundColor: "lightblue", // Example custom style for CustomMenuItem
  };

  const subMenuStyles = {
    //backgroundColor: "lightcoral", // Example custom style for CustomSubMenu
  };

  const subMenuItemStyles = {
    backgroundColor: theme.palette.grey[200],
    height: "40px !important",
    borderBottom: `1px solid ${theme.palette.grey[300]}`,

    flexDirection: "row",
    fontSize: 10,

    "&:hover": {
      backgroundColor: `${theme.palette.grey[300]} !important`,
      color: theme.palette.grey[700],
    },
    "&.ps-active": {
      backgroundColor: `${theme.palette.grey[300]} !important`,
      color: theme.palette.grey[700],
      "&:hover": {
        backgroundColor: `${theme.palette.grey[300]} !important`,
        color: theme.palette.grey[700],
      },
      "& .ps-menu-icon": {
        color: theme.palette.primary.dark,
        backgroundColor: `${theme.palette.grey[300]} !important`,
      },
      "& .ps-menu-label": {
        color: theme.palette.primary.dark,
        backgroundColor: `${theme.palette.grey[300]} !important`,
        fontSize: "13px !important",
      },
    },
    "& .ps-menu-icon": {
      color: theme.palette.grey[700],
      fontSize: "13px !important",
      marginRight: "1px",
      height: "25px",
    },
    "& .ps-menu-label": {
      color: theme.palette.grey[700],
      fontSize: "13px !important",
    },
    "& .ps-submenu-expand-icon": {
      right: "15px !important",
    },
  };
const checkLogoType=storeData.clientDb==='tech5'?Tech5Logo:Logo
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        height: "calc(100%)",
      }}
    >
      <Box
        sx={{
          p: 1,
          mx: sidebarStatus ? 3 : 0,
          display: "flex",
          flexDirection: "column",
          height: "65px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={checkLogoType}
          alt="checkLogoType"
          style={{
            width: sidebarStatus ? "75px" : "90px",
            transition: "width 1000ms",
          }}
        />
        <Box
          sx={{
            display: "flex",
            gap: 0.5,
            overflow: "hidden",
            flexDirection: "row",
            flexWrap: "nowrap",
            //alignItems: "center",
          }}
        >
          <Typography
            fontWeight={700}
            component="span"
            sx={{
              fontSize: "14px",
            }}
          >
            ATS
          </Typography>

          <Typography
            component="span"
            noWrap
            sx={{
              fontSize: sidebarStatus ? "11px" : "0px",
              width: sidebarStatus ? "100%" : "0px",
              transition: "fontSize 1000ms, width 1000ms",
            }}
          >
            - Applicant Tracking System
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          position: "relative",
          zIndex: 150,
          my: 0,
          overflowY: "auto",
          overflowX: "hidden",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: theme.palette.primary[400],
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.light,
            borderRadius: 5,
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: theme.palette.primary.dark,
          },
        }}
      >
        <Box>
          <SidebarMenu
            collapsed={!sidebarStatus}
            transitionDuration={1000}
            collapsedWidth="110px"
            rootStyles={{
              [`.${sidebarClasses.container}`]: {
                backgroundColor: "transparent",
              },
            }}
          >
            <Menu>
            {menuOptions.map((option, index) => (
                <div key={index}>
                 
                  {  option.subMenu.length==0&&(
                    <CustomMenuItem
                        title={option.title}
                        linkTo={option.linkTo}
                        icon={resolveIcon(option.icon)}
                        // active={option.active}
                        active={location.pathname === option.linkTo}
                        customStyles={menuItemStyles}
                    />
                  )}
                    {option.subMenu.length > 0 && (
                        <CustomSubMenu
                        icon={resolveIcon(option.icon)}
                    title={option.title}
                    customStyles={subMenuStyles} // Pass custom styles for SubMenu
                    subMenuItemStyles={subMenuItemStyles} // Pass custom styles for SubMenu children

                        >
                            {option.subMenu.map((sub, subIndex) => (
                                <CustomMenuItem
                                    key={subIndex}
                                    title={sub.title}
                                    linkTo={sub.linkTo}
                                    active={location.pathname === sub.linkTo}
                                    customStyles={menuItemStyles}
                                />
                            ))}
                        </CustomSubMenu>
                    )}
                </div>
            ))}




            </Menu>
          </SidebarMenu>
        </Box>
      </Box>
      <Box sx={{ my: 1 }}>
        {sidebarStatus ? (
          <Typography
            variant="body2"
            sx={{ textAlign: "center", opacity: 0.2 }}
          >
            Version : 1.2.6
          </Typography>
        ) : (
          <Typography
            variant="body2"
            sx={{ textAlign: "center", opacity: 0.2 }}
          >
            V : 1.2.6
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default SideBar;
