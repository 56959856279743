
import {
    ADD_APPLICANT_NOTE_VIA_API,
    ADD_APPLICANT_VIA_API,
    ADD_DEGREE_VIA_API,
    ADD_DEGREES_VIA_API,
    ADD_RESUMESOURCES_VIA_API,
    DELETE_RECRUITER,
    GET_ALL_APPLICANTS_VIA_API,
    GET_ALL_DEGREES_VIA_API,
    GET_ALL_RESUMESOURCES_VIA_API,
    JOB_POST_ASSIGNED_TO_RECRUITER_VIA_API,
    JOB_POST_RECRUITER_VIA_API,
    SEARCH_DATE_JOB_POSTING_DATA_VIA_API,
    SEARCH_JOB_POST_RECRUITER_VIA_API,
    SUCCESS_ADD_APPLICANT,
    SUCCESS_ALL_RESUMESOURCES,
    SUCCESS_DEGREE,
    SUCCESS_DEGREES,
    SUCCESS_JOB_POST_RECRUITER,
    SUCCESS_SEARCH_DATE_JOB_POSTING_DATA,
    TAG_APPLICANT_TO_JOB_VIA_API,
} from "./actionType"


export const jobPostRecruiterViaApi=(action)=>{
    console.log('action',action);
    return{
        type:JOB_POST_RECRUITER_VIA_API,
        payload:action
    }
}
export const successjobPostRecruiter=(response)=>{
    console.log('response',response);
    return{
        type:SUCCESS_JOB_POST_RECRUITER,
        payload:response
    }
}
export const searchjobPostRecruiterViaApi=(actions)=>{
    return{
        type:SEARCH_JOB_POST_RECRUITER_VIA_API,
        payload:actions
    }
}
export const deleteRecruiter=()=>{
    return{
        type:DELETE_RECRUITER,
        payload:{}
    }
}
export const searchDateJobPostingDataViaApi=(action)=>{
    return{
        type:SEARCH_DATE_JOB_POSTING_DATA_VIA_API,
        payload:action
    }
}
export const successSearchDateJobPostingData=(reponse)=>{
    return{
        type:SUCCESS_SEARCH_DATE_JOB_POSTING_DATA,
        payload:reponse
    }
}
export const jobPostAssignedToRecruiterViaApi=(action)=>{
    console.log('action',action)
    return{
        type:JOB_POST_ASSIGNED_TO_RECRUITER_VIA_API,
        payload:action
    }
}
export const addApplicantViaApi=(action)=>{
   
    return{
        type:ADD_APPLICANT_VIA_API,
        payload:action
    }
}

export const getAllApplicantsViaApi=(action)=>{
    return{
        type:GET_ALL_APPLICANTS_VIA_API,
        payload:action
    }
}
export const successAddApplicant=(reponse)=>{
    return{
        type:SUCCESS_ADD_APPLICANT,
        payload:reponse
    }
}

export const addResumeSourcesViaApi=(action)=>{
    return{
        type:ADD_RESUMESOURCES_VIA_API,
        payload:action
    }
}
export const getAllResumeSourceViaApi=(action)=>{
    return{
        type:GET_ALL_RESUMESOURCES_VIA_API,
        payload:action
    }
}
export const successAllResumeSources=(reponse)=>{
    return{
        type:SUCCESS_ALL_RESUMESOURCES,
        payload:reponse
    }
}
export const addApplicantNoteViaApi=(action)=>{
    return {
        type:ADD_APPLICANT_NOTE_VIA_API,
        payload:action
    }
}
export const tagApplicantToJobViaApi=(action)=>{
    return {
        type:TAG_APPLICANT_TO_JOB_VIA_API,
        payload:action
    }
}
export const getAllDegreesViaApi=(client)=>{
    console.log('client',client)
    
    return {
        type:GET_ALL_DEGREES_VIA_API,
        payload:client
    }
}
export const addDegreeViaApi=(action)=>{
    return {
        type:ADD_DEGREES_VIA_API,
        payload:action
    }
}
export const successDegree=(reponse)=>{
    return {
        type:SUCCESS_DEGREES,
        payload:reponse
    }
}
