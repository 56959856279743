import { type } from "@testing-library/user-event/dist/type";

import {
    ADMIN_APPROVED_COMPANIES_VIA_API,
    ALL_RATEAM_COMPANIES_COUNT_VIA_PI,
    APPROVAL_TRANSFER_VIA_API,
    CLIENT_STATUS_VIA_API,
    COMPANY_BLOCKED_AND_DNC_VIA_API,
    COMPANY_BLOCKED_VIA_API,
    COMPANY_DNC_VIA_API,
    DATE_SEARCH_BDMS_COMPANIES_VIA_API,
    DELETE_COMPANIESLIST,
    EMPLOYEE_BLOCKED_AND_DNC_VIA_API,
    EMPLOYEE_BLOCKED_VIA_API,
    EMPLOYEE_DNC_VIA_API,
    GET_ALL_EMAILS_LOGS_VIA_API,
    GET_BDM_COMPANIES_VIA_API,
    GET_SENT_EMAILS_VIA_API,
    JOB_STATUS_CHANGE_VIA_API,
    NO_FALLOWUPS_VIA_API,
    OTHER_EMAILS_SENT_VIA_API,
    SEARCH_NOT_ACTIVES_COMPANIES_VIA_API,
    SUCCESS_ALL_RATEAM_COMPANIES_COUNT,
    SUCCESS_BDM_COMPANIES,
    SUCCESS_DATE_SEARCH_BDMS_COMPANIES,
    SUCCESS_GET_ALL_EMAILS_LOGS,
    SUCCESS_GET_SENT_EMAILS,
    SUCCESS_OTHER_EMAILS_SENT,
    SUCCESS_SEARCH_NOT_ACTIVES_COMPANIES,
    TRANSFER_BDM_VIA_API
} from "./actionType"

export const allRaTeamCompaniesCountViaApi=()=>{
    return{
        type:ALL_RATEAM_COMPANIES_COUNT_VIA_PI,
        payload:{}
    }
}
export const successAllTeamCompaniesCount=(reponse)=>{
    return{
        type:SUCCESS_ALL_RATEAM_COMPANIES_COUNT,
        payload:reponse
    }
}
export const deleteComapniesList=()=>{
    return{
        type:DELETE_COMPANIESLIST,
        payload:{}
    }
}

export const clientStatusViaApi=(action)=>{
    return{
        type:CLIENT_STATUS_VIA_API,
        payload:action
    }
}

export const getBdmComapniesViaApi=(action)=>{
 
    return{
        type:GET_BDM_COMPANIES_VIA_API,
        payload:action
    }
}
export const successBdmCompanies=(response)=>{
    return{
        type:SUCCESS_BDM_COMPANIES,
        payload:response,
    }
}
export const getSentEmailsViaApi=(action)=>{
    return{
        type:GET_SENT_EMAILS_VIA_API,
        payload:action,
    }
}
export const successGetSentEmails=(response)=>{
    return{
        type:SUCCESS_GET_SENT_EMAILS,
        payload:response,
    }
}
export const JobStatusChangeViaApi=(action)=>{
    return{
        type:JOB_STATUS_CHANGE_VIA_API,
        payload:action,
    }
}
export const searchNotActiveCompanies=(action)=>{
    return{
        type:SEARCH_NOT_ACTIVES_COMPANIES_VIA_API,
        payload:action,
    }
}
export const successNotActivesCompanies=(reponse)=>{
    return{
        type:SUCCESS_SEARCH_NOT_ACTIVES_COMPANIES,
        payload:reponse,
    }
}
export const adminApprovedCompaniesViaApi=(action)=>{
    return{
        type:ADMIN_APPROVED_COMPANIES_VIA_API,
        payload:action,
    }
}
export const dateSearchBdmsCompaniesViaApi=(action)=>{
  
    return{
        type:DATE_SEARCH_BDMS_COMPANIES_VIA_API,
        payload:action,
    }
}
export const successDateSearchBdmsCompanies=(action)=>{
    return{
        type:SUCCESS_DATE_SEARCH_BDMS_COMPANIES,
        payload:action,
    }
}
export const otherEmailsSentViaApi=()=>{
    return{
        type:OTHER_EMAILS_SENT_VIA_API,
        payload:{},
    }
}
export const getAllEmailsLogsViaApi=(action)=>{
    return{
        type:GET_ALL_EMAILS_LOGS_VIA_API,
        payload:action,
    }
}
export const successGetAllEmailsLogs=(reponse)=>{
    return{
        type:SUCCESS_GET_ALL_EMAILS_LOGS,
        payload:reponse,
    }
}
{/**11-07-24 */}
export const companyDNCViaApi=(action)=>{

    return{
        type:COMPANY_DNC_VIA_API,
        payload:action,
    }
}
export const employeeDNCViaApi=(action)=>{
    return{
        type:EMPLOYEE_DNC_VIA_API,
        payload:action,
    }
}

export const companyBlockedViaApi=(action)=>{

    return{
        type:COMPANY_BLOCKED_VIA_API,
        payload:action,
    }
}
export const employeeBlockedViaApi=(action)=>{

    return{
        type:EMPLOYEE_BLOCKED_VIA_API,
        payload:action,
    }
}

export const noFallowUpsViaApi=(action)=>{

    return{
        type:NO_FALLOWUPS_VIA_API,
        payload:action,
    }
}
export const approvalTransferViaApi =(action)=>{
    return{
        type:APPROVAL_TRANSFER_VIA_API,
        payload:action,
    }
}
export const transferBdmViaApi =(action)=>{
    return{
        type:TRANSFER_BDM_VIA_API,
        payload:action,
    }
}
export const companyBlockedAndDncViaApi =(action)=>{
    console.log('action',action);
    return{
        type:COMPANY_BLOCKED_AND_DNC_VIA_API,
        payload:action,
    }
}
export const employeeBlockedAndDncViaApi =(action)=>{
    console.log('action',action);
    return{
        type:EMPLOYEE_BLOCKED_AND_DNC_VIA_API,
        payload:action,
    }
}
