import React, { useState, useEffect, useCallback } from "react";
import { SnackbarProvider } from "notistack";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";

import theme from "../../Theme";
import { ProductionDateFilter } from "../../Components";
import {
  productionDateSearchViaApi,
  stopLoadingRedux,
} from "../../store/reports/action";
import Checkbox from "@mui/material/Checkbox";
import dayjs from "dayjs"; // Import dayjs for date formatting
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../store/auth/login/actions";
import { debounce } from "lodash";
import { ShimmerTable } from "react-shimmer-effects";
import companyStatus from "../../Components/companyStatusElements/companyStatus";
dayjs.extend(require("dayjs/plugin/timezone"));
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.tz.setDefault("Asia/Kolkata");
const DailyProduction = () => {
  const { companyBlocked, companyDNC, companyNoFollowUp, employeeDNC, employeeBlocked,companyOnProcess } = companyStatus;

  const dispatch = useDispatch();
  const { storeData } = useSelector((state) => ({
    storeData: state.Login.storeData,
  }));
  const client=storeData.clientDb
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  useEffect(() => {
    const action={
      client:client
    }
    dispatch(getAllUsers(action));
  }, []);
  const [searchFromDate, setSearchFromDate] = useState("");
  const [searchToDate, setSearchToDate] = useState(
    dayjs().endOf("week").subtract(1, "day").endOf("day")
  );
  let retrictAllUsers =
    allUsers &&
    allUsers.filter(
      (user) =>
        user.userEmailId === "ratest@tektreeinc.com" ||
        user.userEmailId === "lilyra@tektreeinc.com" ||
        user.userEmailId === "oliviara@tektreeinc.com" ||
        user.userEmailId === "emmara@tektreeinc.com" ||
        user.userEmailId === "charlottera@tektreeinc.com" ||
        user.userEmailId === "danielra@tektreeinc.com" ||
        user.userEmailId === "miara@tektreeinc.com" ||
        user.userEmailId === "williamra@tektreellc.com" ||
        user.userType.includes('SuperAdmin') || user.userType.includes('Admin') || user.userType.includes('Developer')
    );
  const isAuthorizedUser =
  (storeData.userType.includes('SuperAdmin') ||  storeData.userType.includes('Developer'))
  if (isAuthorizedUser) {
    retrictAllUsers =
      allUsers && allUsers.filter((user) => user.userType.includes('SuperAdmin') ||  user.userType.includes('Developer'));
  }
  const restrictUserIds = retrictAllUsers
    ? retrictAllUsers.map((user) => user._id)
    : [];
  let productionData =
    useSelector((state) => state.Reports.productionData) || [];
  productionData = productionData
    ? productionData.sort((a, b) => new Date(a.addedOn) - new Date(b.addedOn))
    : [];
  productionData = productionData.filter(
    (company) => !restrictUserIds.includes(company.addedBy)
  );
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);

  const transformData = (data) => {
    const map = new Map();

    data.forEach((company) => {
      if (company.status !== "Active") {
        return "";
      }
      const addedBy = company.addedBy;
      // Convert addedOn date to IST
      const addedOnDate = dayjs(company.addedOn)
        .tz("Asia/Kolkata")
        .format("YYYY-MM-DD");

      if (!map.has(addedBy)) {
        map.set(addedBy, new Map());
      }

      const dateMap = map.get(addedBy);
      if (!dateMap.has(addedOnDate)) {
        dateMap.set(addedOnDate, []);
      }

      dateMap.get(addedOnDate).push(company);
    });

    const result = [];
    map.forEach((dateMap, addedBy) => {
      const dateWise = [];
      dateMap.forEach((companies, addedOn) => {
        dateWise.push({
          addedOn,
          companiesData: companies,
        });
      });
      result.push({
        addedBy,
        dateWise,
      });
    });

    return result;
  };

  let sequencesData = Array.isArray(productionData)
    ? transformData(productionData)
    : [];
  sequencesData = sequencesData.map((company) => {
    const user = allUsers.find((user) => company.addedBy === user._id);
    if (user) {
      return {
        ...company,
        userReportingTo: user.userReportingTo,
        userRole: user.userRole,
        userType:user.userType,
        raName: user.userFirstName,
        emailID:user.userEmailId,
      };
    }
    return company;
  });
  let filterDataAllTeamLead = allUsers.filter(
    user => user.userType.includes('RATeam') &&
            (user.userRole === 'Team Lead' || user.userRole === 'Supporting TL'||(user.userType==='Manager'&&user.userType.includes('RATeam'))) &&
            !restrictUserIds.includes(user._id)
  );
  filterDataAllTeamLead.forEach(user => {
    const existsInSequences = sequencesData.some(company => company.addedBy === user._id);
    if (!existsInSequences) {
      sequencesData.push({
        addedBy:user._id,
        userReportingTo: user.userReportingTo,
        userRole: user.userRole,
        raName: user.userFirstName,
        emailID: user.userEmailId,
        dateWise: [],
      });
    }
  });
  let takeOnProcessData = Array.isArray(productionData)
    ? Object.values(
        productionData
          .filter((company) => company.status === companyOnProcess)
          .reduce((acc, item) => {
            const { leadRAId } = item;

            if (!acc[leadRAId]) {
              acc[leadRAId] = { leadRAId, companies: [] };
            }

            acc[leadRAId].companies.push(item);

            return acc;
          }, {})
      )
    : [];

  //   // Helper function to format the date
  const formatDate = (date) => dayjs(date).format("DD/MMM/YYYY");
  // Helper function to get the day of the week
  const getDayOfWeek = (date) => dayjs(date).format("dddd");

  const extractDates = (data) => {
    return Array.from(
      new Set(
        data.flatMap((entry) =>
          entry.dateWise.flatMap(
            (dateEntry) => dateEntry.addedOn // Extract the date directly
          )
        )
      )
    );
  };

  // Function to add one day to a given date string in YYYY-MM-DD format
  const addOneDay = (dateStr) => {
    const date = new Date(dateStr);
    if (isNaN(date.getTime())) return null; // Return null for invalid dates
    date.setDate(date.getDate() + 1);
    return date.toISOString().split("T")[0];
  };

  const ensureDateListIncludes = (dates, targetDate) => {
    const target = new Date(targetDate);
    if (isNaN(target.getTime())) return dates;
    const targetDateStr = target.toISOString().split("T")[0];
    let lastDateStr = dates[dates.length - 1];
    let lastDate = new Date(lastDateStr);
    if (isNaN(lastDate.getTime())) return dates;
    while (lastDate.toISOString().split("T")[0] < targetDateStr) {
      lastDateStr = addOneDay(lastDateStr);
      if (!lastDateStr) break;
      dates.push(lastDateStr);
      lastDate = new Date(lastDateStr);
    }
    return Array.from(new Set(dates)).sort((a, b) => new Date(a) - new Date(b));
  };

  let allDates = extractDates(sequencesData).sort(
    (a, b) => new Date(a) - new Date(b)
  );
  allDates = ensureDateListIncludes(allDates, searchToDate);

  // Function to get styles based on userRole
  const getBDMCellStyle = (userRole) => {
    switch (userRole) {
      case "Manager":
        return{
          bgcolor: theme.palette.primary.main,
          color: theme.palette.success.contrastText,
        }
      case "Team Lead":
        return {
          bgcolor: theme.palette.success.dark,
          color: theme.palette.success.contrastText,
        };
      case "Supporting TL":
        return {
          bgcolor: theme.palette.success.main,
          color: theme.palette.success.contrastText,
        };
      case "Team Member":
        return {
          bgcolor: theme.palette.success.light,
          color: theme.palette.grey[800],
        };
      default:
        return {};
    }
  };

  // Function to calculate the sum of productionData for each BDM
  const calculateBDMProductionSum = (bdmId) => {
    return allDates.reduce((sum, date) => {
      // Find the entry for the given bdmId
      const bdmEntry = sequencesData.find(
        (entry) => entry.addedBy === bdmId.addedBy
      );
      // If the entry is found and the date exists in the entry's dateWise data
      if (
        bdmEntry &&
        bdmEntry.dateWise.some((entry) => entry.addedOn === date)
      ) {
        const dateEntry = bdmEntry.dateWise.find(
          (entry) => entry.addedOn === date
        );

        // Sum up the companies data for that date
        return sum + (dateEntry?.companiesData.length || 0);
      }

      return sum;
    }, 0);
  };

  // Function to calculate the total number of leads for each BDM
  const calculateBDMLeads = (bdmId) => {
    // Initialize the sum variable
    let sum = 0;
    // Find the BDM entry by ID
    const bdmEntry = sequencesData.find(
      (entry) => entry.addedBy === bdmId.addedBy
    );
    // If the BDM entry is found, proceed to calculate the sum
    if (bdmEntry) {
      bdmEntry.dateWise.forEach((dateEntry) => {
        dateEntry.companiesData.forEach((company) => {
          // Add the number of contact details if they exist
          sum += company.contactDetails ? company.contactDetails.length : 0;
        });
      });
    }
    // Return the sum or 0 if the BDM entry is not found
    return sum;
  };
  // Function to calculate the total number of leads for each BDM
  const calculateJobOpenings = (bdmId) => {
    return (
      takeOnProcessData.find((entry) => entry.leadRAId === bdmId.addedBy)
        ?.companies.length || 0
    );
  };
  const [loading, setLoading] = useState(true);

  const handleDateChange = debounce((selectedDates) => {
    setSearchFromDate("");
    setSearchToDate("");
    setLoading(true);

    if (selectedDates.length === 2) {
      const formattedDates = selectedDates.map((date, index) => {
        return date.toISOString();
      });

      const [fromDate, toDate] = selectedDates;
      setSearchFromDate(formattedDates[0]);
      setSearchToDate(formattedDates[1]);

      const action = {
        searchFromDate: formattedDates[0],
        searchToDate: formattedDates[1],
        client:client,
      };
      // setLoading(true);

      dispatch(productionDateSearchViaApi(action));
    }
  }, 3000);


  let filterTeamLeadAddedBy = sequencesData
    .filter((company) => company.userRole === "Team Lead"||(company.userRole==="Manager"))
    .map((company) => company.addedBy);

  const [checked, setChecked] = useState([]);

  useEffect(() => {
    if (
      storeData.userRole === "Team Lead"
    ) {
      setChecked([storeData._id]);
    }
  }, [storeData]);

  const handleDebouncedChange = debounce(() => {
    setLoading(false);
  }, 1000);

  const handleChange = (lead) => {
    setLoading(true); // Set loading to true on click
    setChecked((prevChecked) => {
      if (prevChecked.includes(lead)) {
        return prevChecked.filter((item) => item !== lead);
      } else {
        return [...prevChecked, lead];
      }
    });
    handleDebouncedChange(lead);
  };

  let hierachiALL = [];
  let selectData = new Set();

  // Step 1: Populate selectData based on checked users and reporting structure
checked.forEach((checkedUser) => {
  allUsers.forEach((user) => {
    if (user._id === checkedUser) {
      selectData.add(user._id);

      if (user.userRole === "Manager") {
        // Find all users reporting to the Manager
        allUsers.forEach((SUser) => {
          if (SUser.userReportingTo === user._id) {
            selectData.add(SUser._id);
            if (SUser.userRole === "Team Lead") {
              // Find all users reporting to the Team Lead
              allUsers.forEach((Tuser) => {
                if (Tuser.userReportingTo === SUser._id && Tuser.userRole !== 'Supporting TL') {
                  selectData.add(Tuser._id);
                }
              });
              allUsers.forEach((STuser) => {
                if (STuser.userReportingTo === SUser._id && STuser.userRole === 'Supporting TL') {
                  selectData.add(STuser._id);

                  // Find users reporting to the Supporting TL
                  allUsers.forEach((Tuser) => {
                    if (Tuser.userReportingTo === STuser._id) {
                      selectData.add(Tuser._id);
                    }
                  });
                }
              });
            }
          }
        });
      }else if(user.userRole === "Team Lead"){
        selectData.add(user._id);
              // Find all users reporting to the Team Lead
              allUsers.forEach((Tuser) => {
                if (Tuser.userReportingTo === user._id && Tuser.userRole !== 'Supporting TL') {
                  selectData.add(Tuser._id);
                }
              });
              allUsers.forEach((STuser) => {
                if (STuser.userReportingTo === user._id && STuser.userRole === 'Supporting TL') {
                  selectData.add(STuser._id);

                  // Find users reporting to the Supporting TL
                  allUsers.forEach((Tuser) => {
                    if (Tuser.userReportingTo === STuser._id) {
                      selectData.add(Tuser._id);
                    }
                  });
                }
              });
            
      }
    }
  });
});

  if (selectData.size > 0) {
    sequencesData = sequencesData.filter((company) =>
      selectData.has(company.addedBy)
    );
  }
const filtercheckManager=sequencesData.filter((company)=>company.userRole==="Manager")
  sequencesData.forEach((company) => {
    if(company.userRole==="Manager"){
      hierachiALL.push(company);
        sequencesData.forEach((check) => {
          if (
            company.addedBy === check.userReportingTo &&
            check.userRole === "Team Lead"
          ) {
            hierachiALL.push(check);
            sequencesData.forEach((checked) => {
              if (
                check.addedBy === checked.userReportingTo &&
                checked.userRole !== "Supporting TL"&&checked.userRole !== "Team Lead"
              ) {
                hierachiALL.push(checked);
              }
            });
            sequencesData.forEach((checks) => {
              if (
                check.addedBy === checks.userReportingTo &&
                checks.userRole === "Supporting TL"
              ) {
                hierachiALL.push(checks);
                sequencesData.forEach((checkTeam) => {
                  if (checks.addedBy === checkTeam.userReportingTo) {
                    hierachiALL.push(checkTeam);
                  }
                });
              }
            }); 

          }
        });
    }else if(filtercheckManager.length===0&&company.userRole === "Team Lead"){
        hierachiALL.push(company);
        sequencesData.forEach((check) => {
          if (
            company.addedBy === check.userReportingTo &&
            check.userRole !== "Supporting TL"
          ) {
            hierachiALL.push(check);
          }
        });
        sequencesData.forEach((check) => {
          if (
            company.addedBy === check.userReportingTo &&
            check.userRole === "Supporting TL"
          ) {
            hierachiALL.push(check);
         
            sequencesData.forEach((checkTeam) => {
            
              if (check.addedBy === checkTeam.userReportingTo) {
                
                hierachiALL.push(checkTeam);
              }
            });
          }
        }); 
    }
  });
  
  useEffect(() => {
    const thisMonday = dayjs().startOf("week").add(1, "day"); // start of Monday
    const thisFriday = dayjs().endOf("week").subtract(1, "day"); // end of Friday
    handleDateChange([thisMonday.startOf("day"), thisFriday.endOf("day")]);
  }, []);
  // Effect to manage loading state based on Redux state
  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      setLoading(false);

      // Set a timer to stop loading after 5 seconds
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      // Cleanup timer on unmount
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);
  
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Daily Production"}>
          <Box sx={{ mb: 2 }}>
            <ProductionDateFilter handleDateChange={handleDateChange} />
          </Box>
          {storeData.userRole !== "Team Lead" &&
          storeData.userRole !== "Manager" ? (
            <>
              <Box
                sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
              >
                {filterTeamLeadAddedBy &&
                  filterTeamLeadAddedBy.map((lead, index) => (
                    <Box
                      key={index}
                      sx={{ display: "flex", alignItems: "center", mr: 2 }}
                    >
                      <Checkbox
                        checked={checked.includes(lead)}
                        onChange={() => handleChange(lead)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <Box component="span" sx={{ ml: 1 }}>
                        {
                          allUsers.find((user) => user._id === lead)
                            ?.userFirstName
                        }
                      </Box>
                    </Box>
                  ))}
              </Box>
            </>
          ) : (
            <>
             
            </>
          )}

          {loading ? (
           
            <ShimmerTable row={5} col={5} />
          ) : (
            <TableContainer
              component={Paper}
              sx={{
                overflowX: "auto",
                "&::-webkit-scrollbar": {
                  //width: "8px", // width of the scrollbar
                  height: 8,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: theme.palette.grey[300],
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: theme.palette.primary.light, // color of the thumb
                  borderRadius: "10px", // roundness of the thumb
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: theme.palette.primary.dark, // color of the thumb on hover
                },
              }}
            >
              <Table
                aria-label="spanning table"
                sx={{ minWidth: 1000 }}
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        position: "sticky",
                        left: 0,
                        zIndex: 1,
                        minWidth: 100,
                        width: 100,
                        bgcolor: theme.palette.primary.dark,
                        color: theme.palette.primary.contrastText,
                        fontWeight: 400,
                        letterSpacing: 1,
                        py: 2,
                      }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      sx={{
                        position: "sticky",
                        left: 132,
                        zIndex: 1,
                        minWidth: 100,
                        width: 100,
                        bgcolor: theme.palette.secondary.dark,
                        color: theme.palette.secondary.contrastText,
                        fontWeight: 400,
                        letterSpacing: 1,
                      }}
                    >
                      Day
                    </TableCell>
                    {hierachiALL.map((entry) => (
                      <TableCell
                        key={entry.id}
                        sx={{
                          minWidth: 80,
                          textAlign: "center",
                          borderLeft: `1px solid ${theme.palette.divider}`,
                          ...getBDMCellStyle(entry.userRole),
                        }}
                      >
                        {entry.raName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allDates.map((date) => (
                    <TableRow key={date}>
                      <TableCell
                        sx={{
                          position: "sticky",
                          left: 0,
                          zIndex: 1,
                          bgcolor: theme.palette.grey[100],
                          color: theme.palette.grey[700],
                          fontWeight: 400,
                          letterSpacing: 1,
                          py: 2,
                        }}
                      >
                        {formatDate(date)}
                      </TableCell>
                      <TableCell
                        sx={{
                          position: "sticky",
                          left: 132,
                          zIndex: 1,
                          bgcolor: theme.palette.grey[100],
                          color: theme.palette.grey[700],
                          fontWeight: 400,
                          letterSpacing: 1,
                        }}
                      >
                        {getDayOfWeek(date)}
                      </TableCell>
                      {hierachiALL.map((entry) => (
                        <TableCell
                          key={entry.id}
                          sx={{
                            textAlign: "center",
                            bgcolor: theme.palette.grey[50],
                            color: theme.palette.grey[900],
                            fontWeight: 400,
                            letterSpacing: 1,
                            borderLeft: `1px solid ${theme.palette.divider}`,
                          }}
                        >
                          {entry.dateWise.find((item) => item.addedOn === date)
                            ?.companiesData.length || 0}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      sx={{
                        position: "sticky",
                        left: 0,
                        zIndex: 1,
                        minWidth: 100,
                        width: 100,
                        bgcolor: "#BFCFEC",
                        color: theme.palette.grey[900],
                        fontWeight: 600,
                        letterSpacing: 1,
                      }}
                    >
                      No.of Companies
                    </TableCell>
                    {hierachiALL.map((index, entry) => (
                      <TableCell
                        key={index}
                        sx={{
                          textAlign: "center",
                          bgcolor: "#E9F3FD",
                          color: theme.palette.grey[900],
                          fontWeight: 600,
                          letterSpacing: 1,
                          borderLeft: `1px solid ${theme.palette.divider}`,
                        }}
                      >
                        {calculateBDMProductionSum(index)}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      sx={{
                        position: "sticky",
                        left: 0,
                        zIndex: 1,
                        minWidth: 100,
                        width: 100,
                        bgcolor: "#BFCFEC",
                        color: theme.palette.grey[900],
                        fontWeight: 600,
                        letterSpacing: 1,
                      }}
                    >
                      No.of leads
                    </TableCell>
                    {hierachiALL.map((index, entry) => (
                      <TableCell
                        key={index}
                        sx={{
                          textAlign: "center",
                          bgcolor: "#E9F3FD",
                          color: theme.palette.grey[900],
                          fontWeight: 600,
                          letterSpacing: 1,
                          borderLeft: `1px solid ${theme.palette.divider}`,
                        }}
                      >
                        {calculateBDMLeads(index)}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      sx={{
                        position: "sticky",
                        left: 0,
                        zIndex: 1,
                        minWidth: 100,
                        width: 100,
                        bgcolor: "#EAD1DC",
                        color: theme.palette.grey[900],
                        fontWeight: 600,
                        letterSpacing: 1,
                      }}
                    >
                      Job Positives
                    </TableCell>
                    {hierachiALL.map((index, entry) => (
                      <TableCell
                        key={index}
                        sx={{
                          textAlign: "center",
                          bgcolor: "#F2E3EA",
                          color: theme.palette.grey[900],
                          fontWeight: 600,
                          letterSpacing: 1,
                          borderLeft: `1px solid ${theme.palette.divider}`,
                        }}
                      >
                        {calculateJobOpenings(index)}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </LayoutSidebarMenu>
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default DailyProduction;
