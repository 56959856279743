import React, { useState, useEffect } from "react";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Extend dayjs with the necessary plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Kolkata');

export default function BasicDatePicker({ handleDateChange, searchFromDate,setSearchFromDate,setSearchToDate,typeOfEmail }) {
  // Set the initial state for starting and ending dates
  // const [startingDate, setStartingDate] = useState(searchFromDate);
  

  // Handler for starting date change
  const handleStartingDateChange = (newValue) => {
    const newDate = newValue.tz('Asia/Kolkata').startOf('day');
    
    // setStartingDate(newDate);
    setSearchFromDate(newDate);
    setSearchToDate( newDate.endOf('day'))
    handleDateChange([newDate, newDate.endOf('day')]);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker
          label="Select date picker"
          value={searchFromDate}
          onChange={handleStartingDateChange} // Add onChange handler
          format="DD-MM-YYYY" // Correct the format prop
          // maxDate={dayjs().tz("Asia/Kolkata").startOf("day")}
          maxDate={typeOfEmail==='Follow-up data'?dayjs().tz("Asia/Kolkata").subtract(1, 'day').startOf('day') :dayjs().tz("Asia/Kolkata").startOf("day")}
          disabled={typeOfEmail === 'Latest data'?true:false}
          slotProps={{ textField: { size: 'small' } }}
          sx={{flex:1}}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
