import { Box } from "@mui/material";
import React from "react";
import { CustomModal, CustomSnackbar } from "../../Components";

const Main = () => {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100dvh",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          gap: 5,
        }}
      >
        <Box>
          <CustomModal />
        </Box>
        <Box>
          <CustomSnackbar />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Main;
