import { Box, Button } from "@mui/material";
import React from "react";
import theme from "../../Theme";

const CustomButton = ({
  title = "link",
  value,
  active,
  color = "primary", //primary, secondary, error,info,success,warning
}) => {
  return (
    <React.Fragment>
      <Box>
        <Button
          size="small"
          sx={{ textTransform: "inherit", fontSize: 12, minWidth: "auto" }}
          variant={active ? "contained" : "outlined"}
          color={color}
        >
          {title}{" "}
          {value && (
            <Box
              sx={{
                backgroundColor: active
                  ? theme.palette[color].contrastText
                  : `rgba(${theme.palette[color].default},.2)`,
                color: `rgba(${theme.palette[color].default},1)`,

                px: 0.5,
                borderRadius: 10,
                fontSize: 10,
                ml: 0.5,
                minWidth: 10,
              }}
            >
              {value}
            </Box>
          )}
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default CustomButton;
