import { TextField, Button, Grid, Box, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import LayoutSidebarMenu from "../InnerPages/Layout/LayoutSidebarMenu";

import { SnackbarProvider, enqueueSnackbar, useSnackbar } from "notistack";
import { SnackbarComponent } from "../../Components";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
const Test = () => {
  const  storeData  = useSelector((state) => state.Login.storeData)||[];
  const [addedDateTime, setAddedDateTime] = useState(new Date());
  const FormCell = ({ children, width = 172, flex = 1 }) => {
    return (
      <React.Fragment>
        <Box
          sx={{
            flex: flex,
            flexBasis: width,
          }}
        >
          {children}
        </Box>
      </React.Fragment>
    );
  };
  const [excelData, setExcelData] = useState([]);
  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text");
    const rows = pastedText.split("\n");
    const data = rows
      .map((row) => {
        const [firstName = "", lastName = "", designation = "", emailId = "", phoneNumber = ""] = row.split("\t");
        // Remove carriage return (\r) characters from phone number
        const cleanedPhoneNumber = phoneNumber.replace(/\r/g, "");
        return { firstName, lastName, designation, emailId, phoneNumber: cleanedPhoneNumber };
      })
      .filter(
        (contact) =>
          contact.firstName.trim() ||
          contact.lastName.trim() ||
          contact.designation.trim() ||
          contact.emailId.trim() ||
          contact.phoneNumber.trim()
      );
   
    setExcelData(data);
  };
  

  const handleChange = (index, field, value) => {
    const updatedContacts = [...excelData];
    updatedContacts[index][field] = value;
    setExcelData(updatedContacts);
  };
  const handleDeleteContact = (index) => {
    if (excelData.length > 1) {
      const updatedData = [...excelData];

      const contact = updatedData[index];
      if (Object.values(contact).some((value) => value === "")) {
        updatedData.splice(index, 1);
        setExcelData(updatedData);
      } else {
        enqueueSnackbar(`Are you sure? Kindly verify the records before deletion.`, {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar(
        `At least one contact type must be present in the list. Deletion failed.`,
        { variant: "error" }
      );
    }
  };
  const [comment, setComment] = useState("");
  const [jobTitleError, setJobTitleError] = useState("");
  const [jobLocationError, setJobLocationError] = useState("");
  const [jobDescriptionError, setJobDescriptionError] = useState("");
  const [jobWebsiteError, setJobWebsiteError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [designationError, setDesignationError] = useState("");
  const [emailIdError, setEmailIdError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [showContact, setShowContact] = useState(false);
  const [firstNameIndex, setFirstNameIndex] = useState("");
  const [currentCompany, setCurrentComapny] = useState("");

  const handleClose = () => {

    setFirstNameError("");
    setLastNameError("");
    setDesignationError("");
    setEmailIdError("");
    setComment("");
    // setOpenCompanyId(null);
    setShowContact(false);
   
  };
  const handleSubmit = () => {
    // Resetting error states
    setJobTitleError("");
    setJobLocationError("");
    setJobDescriptionError("");
    setFirstNameError("");
    setLastNameError("");
    setDesignationError("");
    setEmailIdError("");
    setPhoneError("")
   
   
    // const phoneRegex = /^\+?\d{1,2}\s?\(\d{3}\)\s?\d{3}[.\s‑-]?\d{4}$/;
    // const phoneRegex = /^\+?\d{1,2}\s?\(\d{3}\)\s?\d{3}[-‑‑]?\d{4}$/;
    const phoneRegex = /^[+\d()-.,\s]+$/;



const consecutiveSpacesRegex = /\s{2,}/;

    // Check for invalid contact details
    const invalidContactDetails = excelData.some((contact, index) => {
      if (!contact.firstName.trim()) {
        setFirstNameIndex(index);
        setFirstNameError(`First name ${index + 1} is required.`);
        return true;
      }

      if (!contact.designation.trim()) {
        setFirstNameIndex(index);
        setDesignationError(`Designation ${index + 1} is required.`);
        return true;
      }
      
      // Email validation using regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!contact.emailId.trim()) {
        // enqueueSnackbar(`Email ID ${index + 1} is required.`, {
        //   variant: "error",
        // });
        setFirstNameIndex(index);
        setEmailIdError(`Email ID ${index + 1} is required.`);

        return true;
      } else if (!emailRegex.test(contact.emailId.trim())) {
        // enqueueSnackbar(`Email ID ${index + 1} is not in a valid format.`, {
        //   variant: "error",
        // });
        setEmailIdError(`Email ID ${index + 1} is not in a valid format.`);
        setFirstNameIndex(index);
        return true;
      } else if (contact.emailId.endsWith("@gmail.com")) {
        // enqueueSnackbar(`Email ID ${index + 1} `, {
        //   variant: "error",
        // });
        setFirstNameIndex(index);
        setEmailIdError(
          `Email ID ${
            index + 1
          } must not be a Gmail address write company email.`
        );
        return true;
      }
     
      if (contact.phoneNumber.trim()) {
        if (!phoneRegex.test(contact.phoneNumber.trim())) {
          setFirstNameIndex(index);
          setPhoneError(`Phone number ${index + 1} is not in a valid phone number format.`);
          return true;
        } else if (consecutiveSpacesRegex.test(contact.phoneNumber.trim())) {
          setFirstNameIndex(index);
          setPhoneError(`Phone number ${index + 1} contains consecutive spaces.`);
          return true;
        }
      }

      return false;
    });

    // Check if any required fields are empty or if email format is incorrect
    if (  invalidContactDetails) {
      return;
    }

    // All validations passed, proceed with submission
    const action = {
      id: currentCompany._id,
      companyName: currentCompany.companyName,
      addedBy: storeData._id,
      status: "Active",
      addedOn: addedDateTime,
      // jobDetails,
      // contactDetails,
      comment,
    };
    // dispatch(addJobDataViaApi(action));
    // dispatch(getCompanies(storeData._id));
    // handleClose();
  };
  
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu>
          <Box>
            <h2>Paste Excel Content Here:</h2>
            <textarea
              rows="10"
              cols="50"
              onPaste={handlePaste}
              placeholder="Paste Excel content here..."
            />

            <h2>Parsed Content:</h2>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
              {excelData.map((row, rowIndex) => (
                <Box
                  key={rowIndex}
                  sx={{
                    display: "flex",
                    gap: 3,
                  }}
                >
                  <TextField
                    variant="standard"
                    label="First Name"
                    placeholder="Josh"
                    size="small"
                    fullWidth
                   
                    value={row.firstName}
                    onChange={(e) =>
                      handleChange(rowIndex, "firstName", e.target.value)
                    }
                    error={
                      Boolean(firstNameError) &&
                      row.firstName.trim() === "" &&
                      rowIndex === firstNameIndex
                    }
                    helperText={
                      rowIndex === firstNameIndex &&
                      row.firstName.trim() === ""
                        ? firstNameError
                        : ""
                    }
                  />
                  <TextField
                    variant="standard"
                    label="Last Name"
                    placeholder="Morgan"
                    size="small"
                    fullWidth
                    value={row.lastName}
                          onChange={(e) =>
                            handleChange(rowIndex, "lastName", e.target.value)
                          }
                          error={
                            Boolean(lastNameError) &&
                            row.lastName.trim() === "" &&
                            rowIndex === firstNameIndex
                          }
                          helperText={
                            rowIndex === firstNameIndex &&
                            row.lastName.trim() === ""
                              ? lastNameError
                              : ""
                          }
                  />
                  <TextField
                    variant="standard"
                    label="Designation"
                    placeholder="HR Manager"
                    size="small"
                    fullWidth
                    value={row.designation}
                    onChange={(e) =>
                      handleChange(rowIndex, "designation", e.target.value)
                    }
                    error={
                      Boolean(designationError) &&
                      row.designation.trim() === "" &&
                      rowIndex === firstNameIndex
                    }
                    helperText={
                      rowIndex === firstNameIndex &&
                      row.designation.trim() === ""
                        ? designationError
                        : ""
                    }
                  />
                  <TextField
                    variant="standard"
                    label="Email Id"
                    placeholder="josh.m@company.com"
                    size="small"
                    fullWidth
                    value={row.emailId}
                    onChange={(e) =>
                      handleChange(rowIndex, "emailId", e.target.value)
                    }
                   
                    error={
                      Boolean(emailIdError) && rowIndex === firstNameIndex
                    }
                    helperText={
                      rowIndex === firstNameIndex ? emailIdError : ""
                    }
                  />
                  <TextField
                    variant="standard"
                    label="Phone"
                    placeholder="+1 (555) 555 555"
                    size="small"
                    fullWidth
                    value={row.phoneNumber}
                    onChange={(e) =>
                      handleChange(rowIndex, "phoneNumber", e.target.value)
                    }
                    error={Boolean(phoneError)&&rowIndex=== firstNameIndex}
                    helperText={rowIndex===firstNameIndex?phoneError:""}
                  />
                  <FormCell width={10} flex={0}>
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => handleDeleteContact(rowIndex)}
                    >
                      <CloseIcon fontSize="12" />
                    </IconButton>
                  </FormCell>
                </Box>
              ))}
              <Button variant="contained" onClick={handleSubmit}>
                    Submit
                  </Button>
            </Box>
          </Box>
        </LayoutSidebarMenu>
      </SnackbarProvider>

      <SnackbarComponent />
    </React.Fragment>
  );
};

export default Test;
