import { SnackbarProvider } from "notistack";
import React, { useEffect, useState } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AddApplicant,
  AddApplicantForm,
  CustomButton,
  CustomDetailViewSection,
  CustomSubmissionTilmeLine,
  JobPostingForm,
  KeyData,
  MainBackground,
  SectionTitle,
} from "../../Components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CircleIcon from "@mui/icons-material/Circle";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import { addApplicantViaApi, addDegreeViaApi, addResumeSourcesViaApi, getAllApplicantsViaApi, getAllDegreesViaApi, getAllResumeSourceViaApi } from "../../store/recruiter/action";
import { debounce } from "lodash";

const TooltipIconButton = ({
  title,
  arrow = false,
  pointer = "right",
  icon = <ArrowBackIcon />,
}) => {
  const styledIcon = React.cloneElement(icon, {
    sx: { fontSize: 14 },
  });
  return (
    <Box>
      <Tooltip title={title} arrow={arrow} placement={pointer}>
        <IconButton>{styledIcon}</IconButton>
      </Tooltip>
    </Box>
  );
};

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const ApplicantDetails = () => {
  const [tabShow, setTabShow] = React.useState(0);
  const location = useLocation();

   const applicant= location.state?.applicant || {}
   const allUsersData = useSelector((state) => state.Login.allUsers);
   const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const [personalDetails, setPersonalDetails] = useState(
    applicant?applicant.personalDetails:{}
  );
  const [professionalDetails, setProfessionalDetails] = useState(
    applicant?applicant.professionalDetails:{}
  );
  const [educationDetails, setEducationDetails] = useState(
    applicant?applicant.educationDetails
    :{}
  );
  const resumeSources = useSelector((state) => state.Recruiters?.resumeSources);

  const resumeSourcesNames =Array.isArray(resumeSources)
  ?resumeSources.map((item) => item.resumeSource):[];
  const degrees = useSelector((state) => state.Recruiters?.degrees);
  const degreesNames = Array.isArray(degrees)
  ? degrees.map((item) => item.degreeName)
  : [];
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.Login.storeData);
  const client=storeData.clientDb
  const [applicantDocId, setApplicantDocId] = useState("");
  const [errors, setErrors] = useState({});
  const handleTabChange = (event, newValue) => {
    setTabShow(newValue);
  };

  const [isAddApplicantModalOpen, setIsAddApplicantModalOpen] = useState(false);

  const handleAddApplicantCloseModal = () => {
    setIsAddApplicantModalOpen(!isAddApplicantModalOpen);
  };
  const validateForm = () => {
    const newErrors = {};

    // Check if firstName is present and not empty inside personalDetails
    if (!personalDetails?.firstName) {
      newErrors.firstName = "First Name is required in Personal Details";
    }

    // Check if lastName is empty
    if (!personalDetails?.lastName) {
      newErrors.lastName = "Last Name is required in Personal Details";
    }

    // Validate personalDetails fields
    if (!personalDetails?.gender) {
      newErrors.gender = "Gender is required in Personal Details";
    }
    if (!personalDetails?.emailAddress) {
      newErrors.emailAddress = "EmailAddress is required in Personal Details";
    }
    if (!personalDetails?.mobileNumber) {
      newErrors.mobileNumber = "MobileNumber is required in Personal Details";
    }
    if (!personalDetails?.state) {
      newErrors.state = "state is required in Personal Details";
    }
    if (!personalDetails?.city) {
      newErrors.city = "city is required in Personal Details";
    }
    if (!personalDetails?.zipCode) {
      newErrors.zipCode = "zipCode is required in Personal Details";
    }
    if (!personalDetails?.taxTerm) {
      newErrors.taxTerm = "taxTerm is required in Personal Details";
    }
    // Validate professionalDetails fields
    if (
      !professionalDetails?.resume
    ) {
      newErrors.resumes = "resumes is required";
    }
    if (!professionalDetails?.payRateSalary?.minAmount) {
      newErrors.payRateMinAmount =
        "payRateMaxAmount is required in Professional Details";
    }
    if (!professionalDetails?.payRateSalary?.maxAmount) {
      newErrors.payRateMaxAmount =
        "payRateMaxAmount is required in Professional Details";
    }
    if (!professionalDetails?.payRateSalary?.paySchedule) {
      newErrors.payRatePaySchedule =
        "paySchedule is required in Professional Details";
    }
    if (!professionalDetails?.payRateSalary?.jobType) {
      newErrors.payRateJobType = "jobType is required in Professional Details";
    }
    if (!professionalDetails?.experience?.years) {
      newErrors.years = "years is required in Professional Details";
    }
    if (!professionalDetails?.experience?.months) {
      newErrors.months = "months is required in Professional Details";
    }
    if (!professionalDetails?.title) {
      newErrors.title = "title is required in Professional Details";
    }
    if (!professionalDetails?.sourceOfResume) {
      newErrors.sourceOfResume =
        "sourceOfResume is required in Professional Details";
    }
    if (!professionalDetails?.availability) {
      newErrors.availability =
        "availability is required in Professional Details";
    }
    if (!professionalDetails?.skills || professionalDetails.skills.length === 0) {
      newErrors.skills = "At least one  skill is required";
    }
     if (!professionalDetails?.workAuthorization) {
      newErrors.workAuthorization = "At least one  workAuthorization is required";
    }
     if (!professionalDetails?.relocate) {
      newErrors.relocate = "relocation is required";
    }
     if (!educationDetails?.schoolName) {
      newErrors.schoolName = "schoolName is required";
    }
     if (!educationDetails?.degree) {
      newErrors.degree = "degree is required";
    }
     if (!educationDetails?.yearCompleted) {
      newErrors.yearCompleted = "yearCompleted is required";
    }
     if (!educationDetails?.country) {
      newErrors.country = "country is required";
    }
     if (!educationDetails?.state) {
      newErrors.state = "country is required";
    }
     if (!educationDetails?.city) {
      newErrors.city = "country is required";
    }
    setErrors(newErrors);

    // Returns true if no errors exist
    return Object.keys(newErrors).length === 0;
  };
  
  const trimObjectValuesExceptResume = (obj) => {
    return Object.keys(obj).reduce((acc, key) => {
      const value = obj[key];
      if (key === "resume") {
        acc[key] = value; // Skip trimming for the 'resume' field
      }else if (Array.isArray(value)) {
        acc[key] = value; 
      }
       else if (typeof value === "string") {
        acc[key] = value.trim(); // Trim strings
      } else if (typeof value === "object" && value !== null) {
        acc[key] = trimObjectValuesExceptResume(value); // Recursively trim nested objects
      } else {
        acc[key] = value; // Handle other types as-is
      }
      return acc;
    }, {});
  };
  const handleSaveSubmitApplicant = debounce(async () => {
    if (validateForm()) {

      // Trim the values of personalDetails and professionalDetails, except for the 'resume' field
    const trimmedPersonalDetails = trimObjectValuesExceptResume(personalDetails);
    const trimmedProfessionalDetails = trimObjectValuesExceptResume(professionalDetails);
    const trimEducationDeatils=trimObjectValuesExceptResume(educationDetails);
    // Define action object for the second API call
    const action = {
      addedBy: storeData._id,
      addedOn: new Date().toISOString(),
      personalDetails: trimmedPersonalDetails,
      professionalDetails: trimmedProfessionalDetails,
      educationDetails:trimEducationDeatils,
      id: applicantDocId?._id || "",
      resumeSource: trimmedProfessionalDetails.sourceOfResume?.trim() || "",
      degree:trimEducationDeatils.degree?.trim()||"",
      client:client
    };
    //  console.log('formData',formData);
      // // Dispatch the actions
      await dispatch(addApplicantViaApi(action));
      await dispatch(addResumeSourcesViaApi(action));
      await dispatch(addDegreeViaApi(action));
      setTimeout(() => {
        const action={client:client}
        dispatch(getAllApplicantsViaApi(action));
        dispatch(getAllResumeSourceViaApi(action));
        dispatch(getAllDegreesViaApi(action));
        handleAddApplicantCloseModal();
      }, 2000);
    }
  }, 2000);
  // const jobRows = [
  //   {
  //     id: 1,
  //     jodCode: "CAL-SOLA-1724045786376",
  //     companyName: "Cal Solar Inc.",
  //     website: "#",
  //     jobTitle: "Project Manager",
  //     jobLocation: "Hayward, CA",
  //     jobStatus: "Active",
  //     clientBill: "USD/120000/Yearly/Fulltime",
  //     payBill: "USD/80000/Yearly/Fulltime",
  //     recruitmentManager: "Recruitment Manager - 01",
  //     primaryRecruiter: "Primary Recruiter - 01",
  //     assignedTo: "Assigned To - 01",
  //     jobCreatedBy: "Job Created By - 01",
  //     jobCreatedOn: "Time",
  //     minSalary: "USD/68000/Yearly/Fulltime",
  //     maxSalary: "USD/78000/Yearly/Fulltime",
  //     status: "active",
  //     note: true,
  //   },
  // ];
  // const personalDetails = [
  //   {
  //     "Pricing Model": "N/A",
  //     TVOP: "0.000",
  //     "Job Start Date": "08/22/24",
  //     "Job End Date": "N/A",
  //     "Required Documents": "Resume",
  //     "Remote Job": "No",
  //     "Required Hours/Week": "40",
  //     "Turnaround Time": "N/A",
  //     "End Client": "N/A",
  //     Duration: "Full Time",
  //     "Additional Details": "N/A",
  //     "Work Authorization": "N/A",
  //     "Application Form": "General Application",
  //     "Respond By": "Open Until Filled",
  //     "Expenses Paid": "No",
  //     Client: "Southeastern Engineering Inc.",
  //     "Client Manager": "N/A",
  //     Priority: "N/A",
  //     "Client Job ID": "N/A",
  //     "Client Category": "N/A",
  //     "Interview Mode": "N/A",
  //     Address: "N/A",
  //     Clearance: "No",
  //     "Post Job on Career Portal": "No",
  //     "Job Type": "N/A",
  //     "Display Contact Details on Career Portal": "Yes",
  //     "PW Published Status": "N/A",
  //   },
  // ];

  // const jobSkills = [
  //   {
  //     Industry: "N/A",
  //     Experience: "3-4 Years",
  //     Languages: "N/A",
  //     Degree: "N/A",
  //     "Evaluation Template": "N/A",
  //     "Primary Skills": "Environmental Services Division Manager",
  //     "Secondary Skills": "N/A",
  //     "Employment Test Template": "N/A",
  //   },
  // ];

  // const jobOrganizationalInformation = [
  //   {
  //     "Number of Positions": "1",
  //     "Sales Manager": "N/A",
  //     Department: "N/A",
  //     Comments: "N/A",
  //     "Additional Notifications": "N/A",
  //     "Maximum Allowed Submissions": "N/A",
  //     "Tax Terms": "Full Time",
  //     "Assigned To": "N/A",
  //     "Primary Recruiter": "N/A",
  //     "Account Manager": "N/A",
  //   },
  // ];

  const ApplicantsList = [
    {
      id: 1,
      applicantID: "458912",
      applicantName: {
        firstName: "John",
        middleName: "A.",
        lastName: "Doe",
      },
      gender: "Male",
      emailAddress: "john.doe@example.com",
      mobileNumber: "(555) 123-4567",
      source: "Career Builder",
      location: {
        city: "Vallejo",
        state: "California",
        zipCode: "94590",
      },
      applicantStatus: "New lead",
      jobTitle: "Project Manager",
      ownership: "Patrick Hawkins",
      workAuthorization: "H1-B",
      createdBy: "Admin",
      createdOn: "08/26/24 21:19:34",
      availability: "Availability",
      payRate: "USD/200,000.00/Yearly/Full Time",
      resume: "https://msnlabs.com/img/resume-sample.pdf",
      resumeTitle: "Use Document Title",
      resumeInfo:
        "Created By Lily Scarlette on 08/27/24 15:50:14 | Modified By Lily Scarlette",
    },
  ];

  function flattenObject(obj, parentKey = "", result = {}) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = parentKey ? `${parentKey}_${key}` : key;

        if (
          typeof obj[key] === "object" &&
          obj[key] !== null &&
          !Array.isArray(obj[key])
        ) {
          flattenObject(obj[key], newKey, result);
        } else {
          result[newKey] = obj[key];
        }
      }
    }
    return result;
  }

  // const flattenedApplicantsList = ApplicantsList.map((applicant) =>
  //   flattenObject(applicant)
  // );
  const flattenedPersonalDetails = [personalDetails].map((applicant) =>
    flattenObject(applicant)
  );
  const flattenedProfessionalDetails = [professionalDetails].map((applicant) =>
    flattenObject(applicant)
  );
  
  // const flattenedApplicantsList = ApplicantsList.map((applicant) =>
  //   flattenObject(applicant)
  // );
  const formatDateForDisplay = (date) => {
    const dateInNY = dayjs(date).tz("America/New_York");
    const isDST = moment.tz(date, "America/New_York").isDST();
    const timeZoneAbbr = isDST ? "EDT" : "EST";
    return dateInNY.format(`MMMM DD, YYYY h:mm A [${timeZoneAbbr}]`);
  };
  const noteFilter = applicant?.note?.map((note) => ({
    addedBy: allUsers.find((user) => user._id === note.addedBy)?.userSudoFirstName,
    addedOn: formatDateForDisplay(note.addedOn),
    note: note.note
  }));
  

  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Applicant Details"}>
          <Grid container spacing={2}>
            <Grid item xs={8.5}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <MainBackground>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 2,
                        mb: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Link
                          component="button" // Use `button` for a clickable element
                          to={`/applicants`}
                        >
                          <TooltipIconButton title="back" />
                        </Link>
                        <Tooltip title={""}>
                          <CircleIcon sx={{ fontSize: 12 }} />
                        </Tooltip>
                        <Chip
                          label="Status"
                          color="error"
                          size="small"
                          variant="outlined"
                          sx={{ px: 0.5, borderRadius: 1 }}
                        />
                      </Box>
                      <Box flex={1} bgcolor={"red"}></Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <TooltipIconButton
                          title="left"
                          icon={<ChevronLeftIcon />}
                        />
                        <TooltipIconButton
                          title="right"
                          icon={<ChevronRightIcon />}
                        />
                      </Box>
                    </Box>
                    <Divider />
                    <Box sx={{ mt: 2 }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ fontWeight: 600 }}>
                          {applicant?.applicateId}-
                          {personalDetails?.firstName}{" "}
                          {personalDetails?.middleName}{" "}
                          {personalDetails?.lastName}
                        </Typography>
                        <Box sx={{ display: "flex", gap: 2 }}>
                          <ButtonGroup
                            variant="outlined"
                            size="small"
                            color="error"
                          >
                            <Button startIcon={<EditIcon fontSize="inherit" />} onClick={handleAddApplicantCloseModal}>
                              Edit
                            </Button>
                          </ButtonGroup>
                        </Box>
                      </Box>
                      <Divider sx={{ my: 2 }} />
                      <Box>
                        <Box sx={{ display: "flex", gap: 2 }}>
                          <KeyData
                            title={"job Title"}
                            value={professionalDetails?.title}
                          />
                          <Divider orientation="vertical" flexItem />
                          <KeyData
                            title={"Location"}
                            value={`${personalDetails?.city},
                            ${personalDetails?.state},
                            ${personalDetails?.zipCode}`}
                          />

                          <Divider orientation="vertical" flexItem />
                          <KeyData
                            title={"mobile Number"}
                            value={personalDetails?.mobileNumber}
                          />

                          <Divider orientation="vertical" flexItem />
                          <KeyData
                            title={"Email Address"}
                            value={personalDetails?.emailAddress}
                          />
                          <Divider orientation="vertical" flexItem />
                          <KeyData
                            title={"Source"}
                            value={professionalDetails?.sourceOfResume}
                          />

                          <Divider orientation="vertical" flexItem />
                          <KeyData
                            title={"Work Authorization"}
                            value={professionalDetails?.workAuthorization}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </MainBackground>

                <Tabs
                  value={tabShow}
                  onChange={handleTabChange}
                  sx={{ mt: 4, mb: 2 }}
                >
                  <Tab label="Snapshot" />
                  <Tab label="Personal Details" />
                  <Tab label="Profession Details" />
                  <Tab label="Employer Details" />
                  <Tab label="Employment Test Results" />
                  <Tab label=" Activities" disabled />
                  {/* 
                  
                  
                  <Tab label=" Email Merges" disabled /> */}
                </Tabs>
                <TabPanel value={tabShow} index={0}>
                  <CustomDetailViewSection
                    headerData={() => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 3,
                          }}
                        >
                          <SectionTitle title="Notes" />
                          <ButtonGroup>
                            <CustomButton title="Applicants" value={2} />
                            <CustomButton title="Submissions" value={0} />
                            <CustomButton title="Pipeline" value={2} />
                          </ButtonGroup>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 0.5,
                          }}
                        >
                          <CustomButton title="Add" />
                        </Box>
                      </Box>
                    )}
                    data={noteFilter}

                  />
                  <CustomDetailViewSection
                    headerData={() => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <SectionTitle title="Documents" />
                        <Box
                          sx={{
                            display: "flex",
                            gap: 0.5,
                          }}
                        >
                          <CustomButton title="Add" active />
                        </Box>
                      </Box>
                    )}
                    bodyData={() => (
                      <Box>
                        <Typography>
                          {ApplicantsList[0].resumeTitle}{" "}
                        </Typography>
                        <Typography variant="caption">
                          {ApplicantsList[0].resumeInfo}
                        </Typography>
                      </Box>
                    )}
                  />
                </TabPanel>
                <TabPanel value={tabShow} index={1}>
                  <CustomDetailViewSection
                    headerData={() => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <SectionTitle title="Personal Details" />
                      </Box>
                    )}
                    title={"Job Details"}
                    data={flattenedPersonalDetails}
                  />
                </TabPanel>
                <TabPanel value={tabShow} index={2}>
                <CustomDetailViewSection
                    headerData={() => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <SectionTitle title="Personal Details" />
                      </Box>
                    )}
                    title={"Job Details"}
                    data={flattenedProfessionalDetails}
                  />
                </TabPanel>
                <TabPanel value={tabShow} index={3}>
                  <MainBackground>No data available</MainBackground>
                </TabPanel>
                <TabPanel value={tabShow} index={4}>
                  <MainBackground>
                    No data available v Employment Test Results
                  </MainBackground>
                </TabPanel>
                <TabPanel value={tabShow} index={5}>
                  <MainBackground>
                    No data available v Employment Test Results
                  </MainBackground>
                </TabPanel>
                {/*
                 */}
              </Box>
            </Grid>
            <Grid item xs={3.5}>
              <MainBackground>adf</MainBackground>
            </Grid>
          </Grid>
        </LayoutSidebarMenu>

        <Modal open={isAddApplicantModalOpen} onClose={handleAddApplicantCloseModal}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              background:
                "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)",
              height: "100vh",
            }}
          >
            <Box>
              <Paper
                elevation={1}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 1,
                  px: 4,
                }}
              >
                <Typography variant="h5" fontWeight={600}>
                  Add Applicant
                </Typography>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Button color="error" onClick={handleAddApplicantCloseModal}>
                    Close
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveSubmitApplicant}
                  >
                    Submit Applicant
                  </Button>
                </Box>
              </Paper>
            </Box>

            <Box flex={1} overflow={"auto"} p={1}>
              <AddApplicantForm
                personalDetails={personalDetails}
                professionalDetails={professionalDetails}
                setPersonalDetails={setPersonalDetails}
                setProfessionalDetails={setProfessionalDetails}
                errors={errors}
                resumeSourcesNames={resumeSourcesNames}
                educationDetails={educationDetails}
                setEducationDetails={setEducationDetails}
                degreesNames={degreesNames}
              />
            </Box>
          </Box>
        </Modal>
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default ApplicantDetails;