import {
    all,
    call,
    delay,
    fork,
    put,
    race,
    takeEvery,
    takeLatest,
  } from "redux-saga/effects";
  import {
    BDM_REPORT_DATE_SEARCH_VIA_API,
    PRODUCTION_DATE_SEARCH_VIA_API,
   SEARCH_BLOCKED_DNC_EMAILS_VIA_API,
   SEARCH_BLOCKED_DNC_NOFALLOWUP_COMPANIES_VIA_API,
   SEARCH_DATE_BDM_COMPANY_STATUS_VIA_API,
   GET_DESKTOP_CLIENTS_VIA_API,
   SEARCH_RA_REPORTS_VIA_API,
   EDIT_DESKTOP_CLIENTS_VIA_API,
   BDM_WEEK_REPORT_VIA_API
  } from "./actionType";
  import { json } from "react-router-dom";
import { getCompaniesSuccess, setSnackbarMessage } from "../search/action";
import { loadingViaRedux, successBdmReportDateSearch, successBdmWeekReport, successDeskTopClient, successGetDeskTopClient, successProductionDateSearch, successRAReports, successRaWeekReport, successSearchBlockedDncCompanies, successSearchBlockedDNCEmails, successSearchDateBdmCompanyStatus } from "./action";
  
  
const apiUrl = process.env.REACT_APP_API_URL;

  function searchRAReportsViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/companyData/raReports", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })

      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function productionDateSearchViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/companyData/productionDateSearch", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })

      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function bdmReportDateSearchViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/companyData/bdmReportSearch", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })

      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  
  function searchDateBdmCompanyStatusViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/companyData/bdmCompanyStatusSearch", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })

      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function searchBlockedDncNoFallowupCompaniesViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/companyData/AdminChangeCompanyStatus", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })

      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function searchBlockedDncEmailsViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/companyData/AdminChangeEMailsStatus", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })

      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function getDesktopClientsViaApiSaga(){
    return fetch(apiUrl + "/desktopClients/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })

      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function editDesktopClientsViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/desktopClients/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })

      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }

  function* searchRAReportsViaSaga(action) {
    try {
      const { response, timeout } = yield race({
        response: call(searchRAReportsViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
  
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        yield put(loadingViaRedux(true))
          yield put(successRAReports(response));
        
        
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* productionDateSearchViaSaga(action) {
    try {
      const { response, timeout } = yield race({
        response: call(productionDateSearchViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
           yield put(loadingViaRedux(true))
          yield put(successProductionDateSearch(response));
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* bdmReportDateSearchViaSaga(action) {
    try {
      const { response, timeout } = yield race({
        response: call(bdmReportDateSearchViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        yield put(loadingViaRedux(true))
          yield put(successBdmReportDateSearch(response));
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
 
  function* searchDateBdmCompanyStatusViaSaga(action) {
    try {
      const { response, timeout } = yield race({
        response: call(searchDateBdmCompanyStatusViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
  
        
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        yield put(loadingViaRedux(true))
          yield put(successSearchDateBdmCompanyStatus(response));
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* searchBlockedDncNoFallowupCompaniesViaSaga(action) {
    try {
      const { response, timeout } = yield race({
        response: call(searchBlockedDncNoFallowupCompaniesViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
  
        
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        yield put(loadingViaRedux(true))
          yield put(successSearchBlockedDncCompanies(response));
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* searchBlockedDncEmailsViaSaga(action) {
    try {
      const { response, timeout } = yield race({
        response: call(searchBlockedDncEmailsViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
  
        
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        yield put(loadingViaRedux(true))
          yield put(successSearchBlockedDNCEmails(response));
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* getDesktopClientsViaSaga() {
    try {
      const { response, timeout } = yield race({
        response: call(getDesktopClientsViaApiSaga), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
  
        
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        yield put(loadingViaRedux(true))
          yield put(successGetDeskTopClient(response));
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* editDesktopClientsViaSaga(action) {
    try {
      const { response, timeout } = yield race({
        response: call(editDesktopClientsViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
  
        
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        if (response.message) {
          yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
          return null;
        }
        // yield put(loadingViaRedux(true))
        // console.log('response',response);
        //   yield put(successGetDeskTopClient(response));
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* bdmWeekReportsViaSaga(action) {
    try {
      const { response, timeout } = yield race({
        response: call(bdmReportDateSearchViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        
        yield put(loadingViaRedux(true))
          yield put(successBdmWeekReport(response));
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  
  // Watch for SETTLE_ITEMS action and run settleItemsSaga
  function* watchSettleItems() {
    yield takeEvery(SEARCH_RA_REPORTS_VIA_API, searchRAReportsViaSaga);
    yield takeEvery(PRODUCTION_DATE_SEARCH_VIA_API, productionDateSearchViaSaga);
    yield takeEvery(BDM_REPORT_DATE_SEARCH_VIA_API, bdmReportDateSearchViaSaga);
    yield takeEvery(SEARCH_DATE_BDM_COMPANY_STATUS_VIA_API, searchDateBdmCompanyStatusViaSaga);
    yield takeEvery(SEARCH_BLOCKED_DNC_NOFALLOWUP_COMPANIES_VIA_API, searchBlockedDncNoFallowupCompaniesViaSaga);
    yield takeEvery(SEARCH_BLOCKED_DNC_EMAILS_VIA_API, searchBlockedDncEmailsViaSaga);
    yield takeEvery(GET_DESKTOP_CLIENTS_VIA_API, getDesktopClientsViaSaga);
    yield takeEvery(EDIT_DESKTOP_CLIENTS_VIA_API, editDesktopClientsViaSaga);
    yield takeEvery(BDM_WEEK_REPORT_VIA_API,bdmWeekReportsViaSaga)
  }
  
  export default function* Reports() {
    yield all([
      // ... other sagas ...
  
      fork(watchSettleItems),
    ]);
  }
  