// import React from "react";
// import theme from "../../Theme";
// import { Box } from "@mui/material";

// const Backgrounds = ({
//   children,
//   shadow,
//   color,
//   variant,
//   p = 1.5,
//   mb = 1.5,
// }) => {
//   return (
//     <React.Fragment>
//       <Box
//         sx={{
//           bgcolor:
//             variant === "contained" ? theme.palette[color].main : "white",
//           p: p,
//           borderRadius: 2,
//           boxShadow: shadow
//             ? "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;"
//             : "none",
//           mb: mb,
//         }}
//       >
//         {children}
//       </Box>
//     </React.Fragment>
//   );
// };

// export default Backgrounds;

import React from "react";
import theme from "../../Theme";
import { Box } from "@mui/material";

const Backgrounds = ({
  children,
  shadow,
  color,
  variant,
  p = 1.5,
  mb = 1.5,
  height = "auto", //auto,full
}) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          bgcolor:
            variant === "contained" ? theme.palette[color].main : "white",
          p: p,
          borderRadius: 2,
          boxShadow: shadow
            ? "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;"
            : "none",
          mb: mb,
          height:
            height == "full" ? `calc(100% - ${p * 2 * 8 + mb * 8}px )` : height,
          overflow: "hidden",
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
};

export default Backgrounds;
