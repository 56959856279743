import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import theme from "../../../Theme";
import LinearScaleOutlinedIcon from "@mui/icons-material/LinearScaleOutlined";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import TrendingDownOutlinedIcon from "@mui/icons-material/TrendingDownOutlined";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import useSetupLogic from "./useSetupLogic";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 3,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  // [`& .${linearProgressClasses.bar}`]: {
  //   borderRadius: 5,
  //   backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  // },
}));

const Type4Layout = ({
  title,
  value,
  compairValue,
  icon,
  //color,
  variant,
  result,
  shadow,
  invert,
  messages,
}) => {
  const { getPercentage, remainingPercentage, color, compairText } =
    useSetupLogic(value, compairValue, messages, invert);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "end",
          mb: 0.5,
        }}
        style={{ gap: 1 }}
      >
        <Typography
          variant="body1"
          sx={{
            color:
              variant === "contained"
                ? theme.palette[color].contrastText
                : theme.palette[color].main,
            fontWeight: 400,
            lineHeight: 1,
          }}
        >
          {value}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            lineHeight: 1,
            color:
              variant === "contained"
                ? theme.palette[color].contrastText
                : theme.palette.grey[500],
          }}
        >
          /
        </Typography>
        <Typography
          variant="body1"
          sx={{
            lineHeight: 1,
            color:
              variant === "contained"
                ? theme.palette[color].contrastText
                : theme.palette.grey[500],
          }}
        >
          {compairValue}
        </Typography>
      </Box>
      {/* <Box>
        <BorderLinearProgress
          variant="determinate"
          value={getPercentage > 100 ? 100 : getPercentage}
          color={color}
        />
      </Box> */}
    </Box>
  );
};

export default Type4Layout;
