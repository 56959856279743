// import { SnackbarProvider } from "notistack";
// import React, { useState } from "react";
// import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
// import { Box, Button, Divider, Grid, TextField } from "@mui/material";
// import { Backgrounds, KeyData, SectionTitle } from "../../Components";

// const SearchCompanyDetailed = () => {
//   const ContactList = [
//     {
//       id: 1,
//       personFirstName: "person-1 Fitst Name",
//       personLastName: "person-1 Last Name",
//       personDesignation: "person-1 Designation",
//       personEmail: "person-1@www.larsentoubro.com",
//       personContactNo: "+1 285 361 5890",
//     },
//     {
//       id: 2,
//       personFirstName: "person-2 Fitst Name",
//       personLastName: "person-2 Last Name",
//       personDesignation: "person-2 Designation",
//       personEmail: "person-2@www.larsentoubro.com",
//       personContactNo: undefined,
//     },
//     {
//       id: 3,
//       personFirstName: "person-3 Fitst Name",
//       personLastName: "person-3 Last Name",
//       personDesignation: "person-3 Designation",
//       personEmail: "person-3@www.larsentoubro.com",
//       personContactNo: undefined,
//     },
//     {
//       id: 4,
//       personFirstName: "person-4 Fitst Name",
//       personLastName: "person-4 Last Name",
//       personDesignation: "person-4 Designation",
//       personEmail: "person-4@www.larsentoubro.com",
//       personContactNo: undefined,
//     },
//     {
//       id: 5,
//       personFirstName: "person-5 Fitst Name",
//       personLastName: "person-5 Last Name",
//       personDesignation: "person-5 Designation",
//       personEmail: "person-5@www.larsentoubro.com",
//       personContactNo: undefined,
//     },
//   ];
//   return (
//     <React.Fragment>
//       <SnackbarProvider
//         maxSnack={4}
//         anchorOrigin={{ horizontal: "right", vertical: "top" }}
//       >
//         <LayoutSidebarMenu pageTitle={"Search Company Detailed"}>
//           <Box>
//             <Backgrounds>
//               <Box sx={{ display: "flex", maxWidth: 600, margin: "0px auto" }}>
//                 <TextField
//                   label="Search by Company Name / Domain / Email-ID"
//                   fullWidth
//                 />
//                 <Button variant="contained" size="large">
//                   Submit
//                 </Button>
//               </Box>
//             </Backgrounds>
//           </Box>
//           <Box>
//             <Grid container spacing={2}>
//               <Grid item xs={12} sm={12} md={4}>
//                 <Backgrounds height="full">
//                   <KeyData
//                     title={"Company Name"}
//                     value={"Larsen & Toubro Infotech Ltd (LTI)"}
//                     size={"large"}
//                   />
//                   <Box sx={{ my: 1 }}>
//                     <Grid container rowSpacing={0.5} columnSpacing={0.2}>
//                       <Grid item xs={12} sm={3} md={3}>
//                         <KeyData title={"Industry"} value={"Software"} />
//                         <Divider />
//                       </Grid>
//                       <Grid item xs={12} sm={9} md={9}>
//                         <KeyData
//                           title={"Website"}
//                           value={"https://www.larsentoubro.com"}
//                         />
//                         <Divider />
//                       </Grid>

//                       <Grid item xs={6} sm={3} md={3}>
//                         <KeyData title={"Company  Status"} value={"Active"} />
//                       </Grid>

//                       <Grid item xs={6} sm={3} md={3}>
//                         <KeyData title={"Last Added By"} value={"Omkar Sahu"} />
//                       </Grid>
//                       <Grid item xs={6} sm={3} md={3}>
//                         <KeyData
//                           title={"Last Added On"}
//                           value={"July 2, 2024"}
//                         />
//                       </Grid>
//                       <Grid item xs={6} sm={3} md={3}>
//                         <KeyData
//                           title={"Lock-In Period"}
//                           value={"17 days left"}
//                         />
//                       </Grid>
//                     </Grid>
//                   </Box>
//                 </Backgrounds>
//               </Grid>
//               <Grid item xs={12} sm={12} md={8}>
//                 <Backgrounds height="full">
//                   <SectionTitle title={"Contact Lists"} />
//                   <Box sx={{ my: 1 }}>
//                     {ContactList.map((item, key) => {
//                       return (
//                         <Box key={key} sx={{ p: 1 }}>
//                           <Grid container rowSpacing={0.5} columnSpacing={0.2}>
//                             <Grid item md={4}>
//                               {item.personFirstName} {item.personLastName}
//                             </Grid>
//                             <Grid item md={3}>
//                               {item.personDesignation}
//                             </Grid>
//                             <Grid item md={3}>
//                               {item.personEmail}
//                             </Grid>
//                             <Grid item md={2}>
//                               {item.personContactNo}
//                             </Grid>
//                           </Grid>
//                           <Divider />
//                         </Box>
//                       );
//                     })}
//                   </Box>
//                 </Backgrounds>
//               </Grid>
//               <Grid item xs={12} sm={6} md={4}>
//                 <Backgrounds height="full">
//                   <KeyData
//                     title={"Company Name"}
//                     value={"Larsen & Toubro Infotech Ltd (LTI)"}
//                   />
//                 </Backgrounds>
//               </Grid>
//             </Grid>
//           </Box>
//         </LayoutSidebarMenu>
//       </SnackbarProvider>
//     </React.Fragment>
//   );
// };

// export default SearchCompanyDetailed;




//**10-07-24 search page updates */
import { SnackbarProvider } from "notistack";
import React, { useState } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import {
  Box,
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  Backgrounds,
  KeyData,
  ScrollView,
  SectionTitle,
} from "../../Components";
import theme from "../../Theme";

const ContactView = ({
  data,
  key,
  color = "primary", //primary, secondary, info, success, warning, error
  isSelected,
  onClick,
}) => {
  return (
    <React.Fragment>
      <Box
        key={key}
        sx={{
          borderLeft:
            (color === "primary" || color === "success") &&
            data.status === "blocked"
              ? `3px solid ${theme.palette.error.dark}`
              : `3px solid ${theme.palette[color].dark}`,
          p: 1,
          display: "flex",
          flexDirection: "column",
          gap: 0.4,
          overflow: "hidden",
          cursor: data.status === "blocked" ? "not-allowed" : "pointer",
          backgroundColor: isSelected
            ? theme.palette.action.selected
            : "inherit",
          opacity: data.status === "blocked" ? 0.8 : 1,
        }}
        onClick={() => data.status !== "blocked" && onClick(data.id)}
      >
        <Box>
          <Typography
            noWrap
            sx={{ fontSize: 14, fontWeight: 600, lineHeight: 1.2 }}
          >
            {data.personFirstName} {data.personLastName}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: theme.palette.grey[500],
              flex: 1,
            }}
          >
            {data.personDesignation}
          </Typography>
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: theme.palette.grey[500],
            }}
          >
            Used: 35
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: theme.palette.grey[500],
              flex: 1,
            }}
          >
            {data.personEmail}
          </Typography>
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: theme.palette.grey[500],
            }}
          >
            {data.personContactNo}
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};

const SearchCompanyDetailed = () => {
  const ContactList = [
    {
      id: 1,
      personFirstName: "Jonathan",
      personLastName: "Alexander-Wellington",
      personDesignation: "Chief Executive Officer",
      personEmail: "jonathan.alexander-wellington@larsentoubro.com",
      personContactNo: "+1 285 361 5890",
    },
    {
      id: 2,
      personFirstName: "Catherine",
      personLastName: "Elizabeth-Montgomery",
      personDesignation: "Chief Operating Officer",
      personEmail: "catherine.elizabeth-montgomery@larsentoubro.com",
    },
    {
      id: 3,
      personFirstName: "Alexander",
      personLastName: "Maximillian-Thompson",
      personDesignation: "Chief Financial Officer",
      personEmail: "alexander.maximillian-thompson@larsentoubro.com",
    },
    {
      id: 4,
      personFirstName: "Isabella",
      personLastName: "Sophia-Harrington",
      personDesignation: "Chief Technology Officer",
      personEmail: "isabella.sophia-harrington@larsentoubro.com",
      status: "blocked",
    },
    {
      id: 5,
      personFirstName: "Christopher",
      personLastName: "Sebastian-Worthington",
      personDesignation: "Chief Marketing Officer",
      personEmail: "christopher.sebastian-worthington@larsentoubro.com",
    },
    {
      id: 6,
      personFirstName: "Christopher",
      personLastName: "Sebastian-Worthington",
      personDesignation: "Chief Marketing Officer",
      personEmail: "christopher.sebastian-worthington@larsentoubro.com",
    },
    {
      id: 7,
      personFirstName: "Christopher",
      personLastName: "Sebastian-Worthington",
      personDesignation: "Chief Marketing Officer",
      personEmail: "christopher.sebastian-worthington@larsentoubro.com",
    },
    {
      id: 8,
      personFirstName: "Christopher",
      personLastName: "Sebastian-Worthington",
      personDesignation: "Chief Marketing Officer",
      personEmail: "christopher.sebastian-worthington@larsentoubro.com",
    },
    {
      id: 9,
      personFirstName: "Christopher",
      personLastName: "Sebastian-Worthington",
      personDesignation: "Chief Marketing Officer",
      personEmail: "christopher.sebastian-worthington@larsentoubro.com",
    },
  ];

  const beforeData = [
    { id: 1, raName: "Ramesh", addedOn: "May 15, 2024" },
    { id: 2, raName: "Suresh", addedOn: "Aprl 08, 2024" },
    { id: 3, raName: "Suresh", addedOn: "Feb 17, 2024" },
    { id: 4, raName: "Suresh", addedOn: "Jan 06, 2024" },
  ];

  const [selectedContactIds, setSelectedContactIds] = useState([]);

  const handleContactClick = (id) => {
    setSelectedContactIds((prevSelectedContactIds) =>
      prevSelectedContactIds.includes(id)
        ? prevSelectedContactIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedContactIds, id]
    );
  };


  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Search Company Detailed"}>
          <Box>
            <Backgrounds>
              <Box sx={{ display: "flex", maxWidth: 600, margin: "0px auto" }}>
                <TextField
                  label="Search by Company Name / Domain / Email-ID"
                  fullWidth
                />
                <Button variant="contained" size="large">
                  Submit
                </Button>
              </Box>
            </Backgrounds>
          </Box>
          
          <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={5} xl={4}>
                  <Backgrounds height="full">
                    <KeyData
                      title={"Company Name"}
                      value={"Larsen & Toubro Infotech Ltd (LTI)"}
                      size={"large"}
                      color={"error"}
                    />
                    <Box sx={{ my: 1 }}>
                      <Grid container rowSpacing={0.5} columnSpacing={0.2}>
                        <Grid item xs={6} sm={3} md={3}>
                          <KeyData title={"Industry"} value={"Software"} color={"error"} />
                          <Divider />
                        </Grid>
                        <Grid item xs={6} sm={9} md={9}>
                          <KeyData
                            title={"Website"}
                            value={"https://www.larsentoubro.com"}
                            color={"error"}
                          />
                          <Divider />
                        </Grid>

                        <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                          <KeyData title={"Company  Status"} value={"Oepn"} color={"error"} />
                        </Grid>
                        <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                          <KeyData
                            title={"Last Added By"}
                            value={"Omkar Sahu"}
                            color={"error"}
                          />
                        </Grid>
                        <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                          <KeyData
                            title={"Last Added On"}
                            value={"July 2, 2024"}
                            color={"error"}
                          />
                        </Grid>
                        <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                          <KeyData title={"Lock-In Period"} value={"--"} color={"error"} />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <SectionTitle title={"Other Added Details"} small />
                      <TableContainer
                        sx={{
                          maxHeight: 100,
                          "&::-webkit-scrollbar": {
                            width: "8px", // width of the scrollbar
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "#f1f1f1", // color of the track
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#888", // color of the thumb
                            borderRadius: "4px", // roundness of the thumb
                          },
                          "&::-webkit-scrollbar-thumb:hover": {
                            backgroundColor: "#555", // color of the thumb on hover
                          },
                        }}
                      >
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          size="small"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  noWrap
                                  sx={{
                                    fontSize: 14,
                                    fontWeight: 600,
                                    lineHeight: 1.2,
                                  }}
                                >
                                  RA Team
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  noWrap
                                  sx={{
                                    fontSize: 14,
                                    fontWeight: 600,
                                    lineHeight: 1.2,
                                  }}
                                >
                                  Added On
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {beforeData.map((item, key) => (
                              <TableRow key={key}>
                                <TableCell>
                                  <Typography
                                    noWrap
                                    sx={{
                                      fontSize: 12,
                                      fontWeight: 600,
                                    }}
                                  >
                                    {item.raName}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    noWrap
                                    sx={{
                                      fontSize: 12,
                                      fontWeight: 600,
                                    }}
                                  >
                                    {item.addedOn}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Backgrounds>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={8}>
                  <Backgrounds height="full">
                    <SectionTitle title={"Contact Lists"} />
                    <Box pb={1}>
                      <ScrollView height={252}>
                        <Grid container spacing={0.5}>
                          {ContactList.map((contact, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={6} xl={3}>
                              <ContactView
                                key={index}
                                data={contact}
                                color={"error"}
                                isSelected={selectedContactIds.includes(
                                  contact.id
                                )}
                                onClick={handleContactClick}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </ScrollView>
                    </Box>
                  </Backgrounds>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Backgrounds>
              <KeyData
                      title={"Company Name"}
                      value={"Larsen & Toubro Infotech Ltd (LTI)"}
                      size={"large"}
                      
                    />
              </Backgrounds>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button variant="outlined">Add Company</Button>
            </Box>
        </LayoutSidebarMenu>
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default SearchCompanyDetailed;