import { type } from "@testing-library/user-event/dist/type"
import {
    BDM_REPORT_DATE_SEARCH_VIA_API,
    BDM_WEEK_REPORT_VIA_API,
    DELETE_REPORTS,
    EDIT_DESKTOP_CLIENTS_VIA_API,
    GET_DESKTOP_CLIENTS_VIA_API,
    LOADING_VIA_REDUX,
    PRODUCTION_DATE_SEARCH_VIA_API,
    RA_WEEK_REPORTS_VIA_API,
    SEARCH_BLOCKED_DNC_EMAILS_VIA_API,
    SEARCH_BLOCKED_DNC_NOFALLOWUP_COMPANIES_VIA_API,
    SEARCH_DATE_BDM_COMPANY_STATUS_VIA_API,
    SEARCH_DESKTOP_CLIENTS_VIA_API,
    SEARCH_RA_REPORTS_VIA_API,
    STOP_LOADING_REDUX,
    SUCCESS_BDM_REPORT_DATE_SEARCH,
    SUCCESS_BDM_WEEK_REPORT,
    SUCCESS_GET_DESKTOP_CLIENTS,
    SUCCESS_PRODUCTION_DATE_SEARCH,
    SUCCESS_RA_REPORTS,
    SUCCESS_RA_WEEK_REPORTS,
    SUCCESS_SEARCH_BLOCKED_DNC_EMAILS,
    SUCCESS_SEARCH_BLOCKED_DNC_NOFALLOWUP_COMPANIES,
    SUCCESS_SEARCH_DATE_BDM_COMPANY_STATUS,
    SUCCESS_SEARCH_DESKTOP_CLIENTS,
    SUCCESS_YESTERDAY_RA_REPORTS
} from "./actionType"


export const searchRAReportsViaApi=(action)=>{
    return{
        type:SEARCH_RA_REPORTS_VIA_API,
        payload:action
    }
}
export const successRAReports=(response)=>{
    return{
        type:SUCCESS_RA_REPORTS,
        payload:response
    }
}

export const deleteReports=()=>{
    return{
        type:DELETE_REPORTS,
        payload:{}
    }
}

export const productionDateSearchViaApi=(action)=>{
    return{
        type:PRODUCTION_DATE_SEARCH_VIA_API,
        payload:action
    }
}
export const successProductionDateSearch=(response)=>{
    return{
        type:SUCCESS_PRODUCTION_DATE_SEARCH,
        payload:response
    }
}
export const loadingViaRedux=(response)=>{
    return{
        type:LOADING_VIA_REDUX,
        payload:response
    }
}
export const stopLoadingRedux=(action)=>{
    return{
        type:STOP_LOADING_REDUX,
        payload:action
    }
}
export const bdmReportDateSearchViaApi=(action)=>{
    return{
        type:BDM_REPORT_DATE_SEARCH_VIA_API,
        payload:action
    }
}
export const successBdmReportDateSearch=(reponse)=>{
    return{
        type:SUCCESS_BDM_REPORT_DATE_SEARCH,
        payload:reponse
    }
}

export const searchDateBdmCompanyStatusViaApi=(action)=>{
    return{
        type:SEARCH_DATE_BDM_COMPANY_STATUS_VIA_API,
        payload:action
    }
}
export const successSearchDateBdmCompanyStatus=(reponse)=>{
    return{
        type:SUCCESS_SEARCH_DATE_BDM_COMPANY_STATUS,
        payload:reponse
    }
}
export const searchBlockedDncCompaniesViaApi=(action)=>{
    return{
        type:SEARCH_BLOCKED_DNC_NOFALLOWUP_COMPANIES_VIA_API,
        payload:action
    }
}
export const successSearchBlockedDncCompanies=(reponse)=>{
    return{
        type:SUCCESS_SEARCH_BLOCKED_DNC_NOFALLOWUP_COMPANIES,
        payload:reponse
    }
}
export const searchBlockedDncEmailsViaApi=(action)=>{
    return{
        type:SEARCH_BLOCKED_DNC_EMAILS_VIA_API,
        payload:action
    }
}
export const successSearchBlockedDNCEmails=(reponse)=>{
    return{
        type:SUCCESS_SEARCH_BLOCKED_DNC_EMAILS,
        payload:reponse
    }
}
export const getDeskTopClientsViaApi=()=>{
    return{
        type:GET_DESKTOP_CLIENTS_VIA_API,
        payload:{}
    }
}
export const successGetDeskTopClient=(reponse)=>{
    return{
        type:SUCCESS_GET_DESKTOP_CLIENTS,
        payload:reponse
    }
}
export const editDeskTopClients=(action)=>{
    return{
        type:EDIT_DESKTOP_CLIENTS_VIA_API,
        payload:action
    }
}
export const bdmWeekReportViaApi=(action)=>{
    return{
        type:BDM_WEEK_REPORT_VIA_API,
        payload:action
    }
}
export const successBdmWeekReport=(reponse)=>{

    return{
        type:SUCCESS_BDM_WEEK_REPORT,
        payload:reponse
    }
}

export const successRaWeekReport=(reponse)=>{
    return{
        type:SUCCESS_RA_WEEK_REPORTS,
        payload:reponse
    }
}
export const successYesterDayRAReports=(action)=>{
    return{
        type:SUCCESS_YESTERDAY_RA_REPORTS,
        payload:action
    }
  }