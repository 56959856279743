export const JOB_POST_RECRUITER_VIA_API='JOB_POST_RECRUITER_VIA_API'
export const SEARCH_JOB_POST_RECRUITER_VIA_API='SEARCH_JOB_POST_RECRUITER_VIA_API'
export const  SUCCESS_JOB_POST_RECRUITER='SUCCESS_JOB_POST_RECRUITER';
export const DELETE_RECRUITER='DELETE_RECRUITER';

export const SEARCH_DATE_JOB_POSTING_DATA_VIA_API='SEARCH_DATE_JOB_POSTING_DATA_VIA_API'
export const SUCCESS_SEARCH_DATE_JOB_POSTING_DATA='SUCCESS_SEARCH_DATE_JOB_POSTING_DATA';
export const JOB_POST_ASSIGNED_TO_RECRUITER_VIA_API='JOB_POST_ASSIGNED_TO_RECRUITER_VIA_API';

export const ADD_APPLICANT_VIA_API='ADD_APPLICANT_VIA_API';
export const SUCCESS_ADD_APPLICANT='SUCCESS_ADD_APPLICANT';
export const GET_ALL_APPLICANTS_VIA_API='GET_ALL_APPLICANTS_VIA_API';

export const GET_ALL_RESUMESOURCES_VIA_API='GET_ALL_RESUMESOURCES_VIA_API';
export const SUCCESS_ALL_RESUMESOURCES='SUCCESS_ALL_RESUMESOURCES';
 

export const ADD_RESUMESOURCES_VIA_API='ADD_RESUMESOURCES_VIA_API';

export const ADD_APPLICANT_NOTE_VIA_API='ADD_APPLICANT_NOTE_VIA_API';

export const TAG_APPLICANT_TO_JOB_VIA_API='TAG_APPLICANT_TO_JOB_VIA_API';

export const GET_ALL_DEGREES_VIA_API='GET_ALL_DEGREES_VIA_API';
export const ADD_DEGREES_VIA_API='ADD_DEGREES_VIA_API';
export const SUCCESS_DEGREES='SUCCESS_DEGREES';



