import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import theme from "../../Theme";
import LogoutButton from "../../Pages/Authentication/LogoutButton";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MailIcon from "@mui/icons-material/Mail";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../../store/auth/login/actions";
import { useNavigate } from "react-router-dom";
import DateTime from "../Helper/DateTime";
import SectionTitle from "../Helper/SectionTitle";
import { searchNotActiveCompanies } from "../../store/companiesList/action";
import companyStatus from "../companyStatusElements/companyStatus";
const NotificationSection = ({
  title = "This is the notification title",
  text = "I think it is not possible as material-ui creates a complete page overlay using React Portal and then put modal into that. So you can't interact with the background without closing the modal",
  link = "#",
}) => {
  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        gap: 1.5,
        "&:hover": {
          backgroundColor: "lightgrey", // Example hover effect
        },
      }}
    >
      <Box sx={{ mt: 1 }}>
        <Avatar sx={{ width: 30, height: 30 }} />
      </Box>
      <Box>
        <Typography
          sx={{
            fontWeight: 600,
            lineHeight: 1.3,
            mb: 0.5,
            fontSize: 14,
            letterSpacing: 0.5,
            wordSpacing: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "1",
            WebkitBoxOrient: "vertical",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: 12,
            color: theme.palette.grey[600],
            lineHeight: 1.4,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            fontWeight: 300,
          }}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

const Header = ({ sidebarToggle, pageTitle }) => {
const { companyBlocked, companyDNC, companyNoFollowUp, employeeDNC, employeeBlocked,companyOnProcess } = companyStatus;

  let ApprovableCompanies =
  useSelector((state) => state.CompaniesList?.approvableCompanies) || [];
   // Filter and map approvable companies based on specified conditions
   ApprovableCompanies = ApprovableCompanies.filter(
    (company) =>
      company.status === companyOnProcess &&
      company.jobDetails &&
      company.jobDetails.some(
        (jobDetails) =>
          jobDetails &&
          (jobDetails.status === "Close" ||
            jobDetails.status === "Hold" ||
            jobDetails.status === "Backout" ||
            company.transFerBdm) &&
          jobDetails.approvalStatus === false
      )
  );
  
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const handleDelte = () => {
    navigate("/logout", { replace: true });
  };
  const { storeData } = useSelector((state) => ({
    storeData: state.Login.storeData,
  }));
  const client=storeData.clientDb
  //Notifications
  const [anchorElNotifications, setAnchorElNotifications] = useState(null);
  const [notifications] = useState(
    new Array(5).fill(null).map((_, index) => `Notification ${index + 1}`)
  );
  const handleClickNotifications = (event) => {
    setAnchorElNotifications(event.currentTarget);
  };

  const handleCloseNotifications = () => {
    setAnchorElNotifications(null);
  };
  const action={
    client:client
  }
  useEffect(() => {
    dispatch(searchNotActiveCompanies(action));
  }, []);
  const handleNavigateClick=()=>{
    navigate("/approvalStatus");
  }
  const handleNavigateSetting=()=>{
    navigate("/settings");
  }
  return (
    <React.Fragment>
      <Box
        sx={{
          height: 80,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          //bgcolor: "#fff",
          mx: 2,
          borderBottom: `1px solid ${theme.palette.primary[200]}`,
          overflow: "hidden",
        }}
      >
        {/* <Box>
          <IconButton onClick={sidebarToggle}>
            <DragHandleIcon />
          </IconButton>
        </Box> */}
        <SectionTitle title={pageTitle} size="xl" />
        {/* <Typography variant="h5" fontWeight={900}>
          {pageTitle}
        </Typography> */}

        <Box sx={{ flex: 1 }}></Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box display={"flex"} gap={2}>
            <DateTime timeZone="America/New_York" />
            <Divider orientation="vertical" flexItem />
            <DateTime timeZone="Asia/Kolkata" />
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box
            sx={{
              display: "flex",
              gap: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
           {(storeData.userType.includes('Admin') || 
  storeData.userType.includes('SuperAdmin') || 
  storeData.userType.includes('Developer'))&&(
            <IconButton aria-label="delete" size="medium" title="Approval Page" onClick={handleNavigateClick}>
              <Badge badgeContent={ApprovableCompanies.length} color="error">
                <CheckCircleOutlineIcon fontSize="inherit" fill={theme.palette.grey[200]} />
              </Badge>
            </IconButton>
           )}
            

            <IconButton aria-label="delete" size="medium">
              <Badge badgeContent={0} color="error">
                <MailIcon fontSize="inherit" fill={theme.palette.grey[200]} />
              </Badge>
            </IconButton>

            <IconButton
              aria-label="delete"
              size="medium"
              onClick={handleClickNotifications}
            >
              <Badge badgeContent={0} color="error">
                <NotificationsIcon fontSize="inherit" />
              </Badge>
            </IconButton>
            {(
  storeData.userType.includes('SuperAdmin') || 
  storeData.userType.includes('Developer')||storeData.userType.includes('Admin') )&&(
<IconButton
            size="medium" 
            onClick={handleNavigateSetting}
            title="Setting Page"
            >
              <SettingsIcon/>
            </IconButton>
  )}
            
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box
            sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
          >
            <Tooltip title="Account settings">
              <Box
                onClick={handleClick}
                sx={{ cursor: "pointer", display: "flex", gap: 1 }}
              >
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    bgcolor: theme.palette.primary.main,
                  }}
                >
                  {storeData && storeData.userFirstName.charAt(0)}
                </Avatar>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      textAlign: "left",
                      color: theme.palette.grey[700],
                      lineHeight: 1.2,
                    }}
                  >
                    {storeData &&
                      storeData.userFirstName + " " + storeData.userLastName}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 12,
                      textAlign: "left",
                      lineHeight: 1,
                      color: theme.palette.grey[400],
                    }}
                  >
                    {storeData && storeData.userType}
                  </Typography>
                </Box>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      {/* Menu for Email */}
      {/* Menu for Email End */}
      {/* Menu for Notification */}
      <Menu
        anchorEl={anchorElNotifications}
        open={Boolean(anchorElNotifications)}
        onClose={handleCloseNotifications}
        PaperProps={{
          style: {
            maxHeight: 400,
            width: "33ch",
            overflow: "auto",
          },
        }}
        MenuListProps={{
          style: {
            padding: 0,
          },
        }}
        BackdropProps={{
          style: {
            backgroundColor: "transparent",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Paper>
          <Box sx={{ pt: 2, px: 2 }}>
            <SectionTitle title="Notification" />
          </Box>
          <Box>
            {notifications.map((notification, index) => (
              <>
                <NotificationSection />
                {index < notifications.length - 1 && <Divider />}
              </>
            ))}
          </Box>

          {/* <List>
            {notifications.map((notification, index) => (
              <ListItem key={index} button onClick={handleClose}>
                <ListItemText primary={notification} />
              </ListItem>
            ))}
          </List> */}
        </Paper>
      </Menu>
      {/* Menu for Notification End */}
      {/* Menu for Profile */}
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        hideBackdrop={true}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.02))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        {/* <MenuItem onClick={handleClose}>
              <Avatar /> Profile
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Avatar /> My account
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <PersonAddIcon fontSize="small" />
              </ListItemIcon>
              Add another account
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem> */}
        <MenuItem onClick={() => handleDelte()}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      {/* Menu for Profile End */}
    </React.Fragment>
  );
};

export default Header;
