import {
    Box,
    Button,
    FormControl,
    FormLabel,
    IconButton,
    Paper,
    TextField,
    Typography,
  } from "@mui/material";
  import { CustomSelect, SnackbarComponent } from "../../Components";
  import React, { useState } from "react";
  import Logo from "../../Assets/Images/logo-color.png";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
    SnackbarProvider,
    enqueueSnackbar,
    closeSnackbar,
    useSnackbar,
  } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { unSubscribeViaApi } from "../../store/search/action";

  const Unsubscribe = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const params = new URLSearchParams(location.search);

    const email = params.get('email');
    const client=params.get('client')
  ;
    const unsubscribeReasons = [
      "I no longer find the content relevant.",
      "I receive too many emails.",
      "The emails are too frequent.",
      "The content quality has decreased.",
      "I am trying to reduce the number of emails I receive.",
      "The emails are not relevant to my interests.",
      "I'm getting duplicate emails.",
      "I have privacy concerns.",
      "The content is too promotional.",
      "Other (please specify).",
    ];
  
    const [selectedReason, setSelectedReason] = useState("");
    const [otherReason, setOtherReason] = useState("");
    const [charCount, setCharCount] = useState(0);
    const maxChars = 150;
  
    const handleReasonChange = (title, value) => {
      setSelectedReason(value);
      if (value !== "Other (please specify).") {
        setOtherReason("");
        setCharCount(0);
      }
    };
  
    const handleOtherReasonChange = (event) => {
      const value = event.target.value;
      if (value.length <= maxChars) {
        setOtherReason(value);
        setCharCount(value.length);
      }
    };
    
  const handleUnSubscribeSave = () => {
    const action = {
       client:client,
        email: email,
        reason: selectedReason === 'Other (please specify).' ? otherReason : selectedReason
    };
    dispatch(unSubscribeViaApi(action));
};

    return (
      <React.Fragment>

        <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          bgcolor: "gray",
          flex: 1,
          height: "100vh",
        }}
      >
       
        <Box sx={{ py: 1, px: 2 }}>
          <img src={Logo} width={100} />
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <Paper
              sx={{
                maxWidth: 400,
                p: 4,
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
              elevation={1}
            >
              <Typography variant="h5" fontWeight={"bold"} textAlign={"center"}>
                Do you want to unsubscribe?
              </Typography>
  
              <Box
                sx={{ mb: 2, display: "flex", flexDirection: "column", gap: 1 }}
              >
                <FormControl fullWidth>
                  <FormLabel id="unsubscribe-reasons-label">
                    If you have a moment, please let us know why you are
                    unsubscribing.
                  </FormLabel>
                  <CustomSelect
                    title="Please Select"
                    options={unsubscribeReasons}
                    value={selectedReason}
                    onChange={handleReasonChange}
                    size="large"
                  />
                </FormControl>
                {selectedReason === "Other (please specify)." && (
                  <>
                    <TextField
                      fullWidth
                      label="Please specify"
                      variant="standard"
                      value={otherReason}
                      onChange={handleOtherReasonChange}
                      multiline
                      rows={4}
                      placeholder="Please specify"
                      inputProps={{ maxLength: maxChars }}
                    />
                    <Typography
                      variant="caption"
                      display="block"
                      textAlign="right"
                    >
                      {charCount}/{maxChars}
                    </Typography>
                  </>
                )}
              </Box>
  
              <Button variant="contained" color="error" fullWidth size="large" onClick={()=>handleUnSubscribeSave()}>
                Unsubscribe
              </Button>
            </Paper>
          </Box>
        </Box>
        <Box sx={{ py: 1, px: 2 }}>
          <Typography variant="body2" color={"InfoText"}>
            Copyright © 2024 Tektree Inc. All rights reserved.
          </Typography>
        </Box>
     
     
      </Box>
      </SnackbarProvider>
       <SnackbarComponent />
      </React.Fragment>
    );
  };
  
  export default Unsubscribe;