import {
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import theme from "../../Theme";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import SectionTitle from "../Helper/SectionTitle";
import moment from "moment-timezone";

const EditableKeyData = ({
  title,
  value,
  editable = false,
  isEditing = false,
  onEditClick,
  onSave,
  onCancel,
  idetifyName = "",
  section='',
  type = "line", // select, multiselect
  options = [],
}) => {
  const [textValue, setTextValue] = useState(
    type === "multiselect" ? (Array.isArray(value) ? value : []) : value
  );
  const [originalValue, setOriginalValue] = useState(value);
  const [open, setOpen] = useState(false);

  const handleEditClick = () => {
    if (editable) {
      onEditClick();
    }
  };

  const handleSaveClick = () => {
    if (onSave) {
      onSave(section,idetifyName, textValue);
    }
    handleEditClick();
  };

  const handleCancelClick = () => {
    setTextValue(textValue ? textValue : originalValue);
    if (onCancel) {
      onCancel();
    }
    handleEditClick();
  };

  const handleSelectChange = (event) => {
    setTextValue(event.target.value);
  };

  const handleOpen = () => {
    setOpen(true); // Open the Select dropdown
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 0.5, gap: 0.5 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          noWrap
          sx={{
            fontSize: 12,
            fontWeight: 400,
            letterSpacing: 0.2,
            color: theme.palette.primary.main,
            lineHeight: 1.2,
            whiteSpace: "nowrap",
            textTransform: "capitalize",
          }}
        >
          {title} :
        </Typography>
        {editable && (
          <Box>
            {isEditing ? (
              <>
                <IconButton onClick={handleSaveClick} size="small">
                  <SaveIcon fontSize="small" sx={{ fontSize: 10 }} />
                </IconButton>
                <IconButton onClick={handleCancelClick} size="small">
                  <CloseIcon fontSize="small" sx={{ fontSize: 10 }} />
                </IconButton>
              </>
            ) : (
              <IconButton onClick={handleEditClick} size="small">
                <EditIcon fontSize="small" sx={{ fontSize: 10 }} />
              </IconButton>
            )}
          </Box>
        )}
      </Box>
      <Box>
        {isEditing ? (
          type === "multiselect" ? (
            <Select
              labelId={`${title}-label`}
              id={title}
              value={textValue}
              label={title}
              multiple
              onChange={handleSelectChange}
              MenuProps={
                {
                  //hideBackdrop: true,
                }
              }
              variant="standard"
              fullWidth
              size="small"
              sx={{ fontSize: "12px", fontWeight: 600 }}
            >
              {options &&
                options.map((item, key) => (
                  <MenuItem
                    value={item}
                    key={key}
                    sx={{ fontSize: "12px", fontWeight: 600 }}
                  >
                    {item}
                  </MenuItem>
                ))}
            </Select>
          ) : type === "select" ? (
            <Select
              labelId={`${title}-label`}
              id={title}
              value={textValue}
              label={title}
              onChange={handleSelectChange}
              MenuProps={
                {
                  //hideBackdrop: true,
                }
              }
              variant="standard"
              fullWidth
              size="small"
              sx={{ fontSize: "12px", fontWeight: 600 }}
            >
              {options &&
                options.map((item, key) => (
                  <MenuItem
                    value={item}
                    key={key}
                    sx={{ fontSize: "12px", fontWeight: 600 }}
                  >
                    {item}
                  </MenuItem>
                ))}
            </Select>
          ) : (
            <TextField
              value={textValue}
              onChange={(e) => setTextValue(e.target.value)}
              fullWidth
              size="small"
              variant="standard"
              InputProps={{
                style: { fontSize: "12px", fontWeight: 600 }, // Set font size for the input element
              }}
            />
          )
        ) : (
          <>
            {type === "multiselect" ? (
              <Box
                sx={{
                  display: "flex",
                  gap: 0.5,
                  flexDirection: "row",
                  flexWrap: "wrap",
                  height: "100%",
                  maxHeight: "200px",
                  overflow: "auto",
                  //bgcolor: theme.palette.grey[100],
                  "&::-webkit-scrollbar": {
                    width: "5px", // width of the scrollbar
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: theme.palette.primary[400],
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: theme.palette.primary.light,
                    borderRadius: 5,
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: theme.palette.primary.dark,
                  },
                }}
              >
                {textValue.map((value, key) => {
                  return (
                    <Chip
                      key={key}
                      label={value}
                      size="small"
                      variant="outlined"
                      sx={{ fontSize: "12px", fontWeight: 600 }}
                    />
                  );
                })}
              </Box>
            ) : (
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: 12,
                  letterSpacing: 0.2,
                  color:
                    textValue === "Running"
                      ? theme.palette.success.dark
                      : textValue === "Idle"
                      ? theme.palette.error.dark
                      : theme.palette.grey[800],
                  lineHeight: 1.2,
                  wordBreak: "break-word",
                }}
              >
                {textValue}
              </Typography>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
const formatDate = (dateString) => {
  const date = moment.tz(dateString, "UTC"); // Parse date in UTC
  return date.tz("Asia/Kolkata").format("MMMM DD, YYYY"); // Convert to Kolkata time and format
};
const ControllerShowData = ({ data, setSelectedRow }) => {
  const [editingKey, setEditingKey] = useState(null);
  const handleEditClick = (key) => {
    setEditingKey(editingKey === key ? null : key);
  };
 

 
  const handleConfigSave = (section, key, newValue) => {
    if (newValue === "Idle"||newValue==="Inactive") {
      newValue = false;
    } else if (newValue === "Running"||newValue==="Active") {
      newValue = true;
    }
    setSelectedRow((prevData) => ({
      ...prevData,
      config: {
        ...prevData.config,
        [section]: {
          ...prevData.config[section],
          [key]: newValue,
        },
      },
    }));
  };
 
  const handleScrapSave = (section, key, newValue) => {
    if (newValue === "Idle") {
      newValue = false;
    } else if (newValue === "Running") {
      newValue = true;
    }
    setSelectedRow((prevData) => ({
      ...prevData,
      scrapeStatus: {
        ...prevData.scrapeStatus,
        [section]: {
          ...prevData.scrapeStatus[section],
          [key]: newValue,
        },
      },
    }));
  };
 
  const handleCancel = () => {
    setEditingKey(null);
  };

  //
  /////
  const configOrder = ["user_data_dir", "tag", "industry", "locations"];
  const industry = ["Healthcare", "IT", "Health"];

  const tag = [
    "ratest@tektreeinc.com",
    "ratest2@tektreeinc.com",
    "ratest3@tektreeinc.com",
    "ratest4@tektreeinc.com",
    "ratest5@tektreeinc.com",
    "ratest6@tektreeinc.com",
  ];
  const locations = [
    "Alabama",
    "Colorado",
    "Kentucky",
    "Minnesota",
    "New Mexico",
    "Oklahoma",
    "Virginia",
    "Arizona",
    "Connecticut",
    "Louisiana",
    "Mississippi",
    "New Jersey",
    "Pennsylvania",
    "Washington",
    "Arkansas",
    "Georgia",
    "Maryland",
    "Missouri",
    "New York",
    "South Carolina",
    "West Virginia",
    "California",
    "Illinois",
    "Massachusetts",
    "Nevada",
    "North Carolina",
    "Tennessee",
    "Wisconsin",
    "Chicago",
    "Indiana",
    "Michigan",
    "New Hampshire",
    "Ohio",
    "Texas",
  ];
  const trueFalse = ["Running", "Idle"];
  const productKeyTrueFalse=["Active","Inactive"]
  // Function to render key-value pairs dynamically
  const renderKeyValues = (data, customOrder = []) => {
    // Function to format the value for display
    const formatValue = (value) => {
      if (Array.isArray(value)) {
        return value.join(", "); // Display array elements as a comma-separated string
      }
      return JSON.stringify(value);
    };

    // Render key-value pairs based on customOrder or default ordering
    const itemsToRender =
      customOrder.length > 0
        ? customOrder.map((key) => ({ key, value: data[key] }))
        : Object.entries(data).map(([key, value]) => ({ key, value }));

    return itemsToRender.map(({ key, value }) => (
      <Grid item xs={12} key={key}>
        <Box sx={{ py: 0.5 }}>
          <EditableKeyData
            title={key.replace(/_/g, " ")}
            value={formatValue(value)}
          />
        </Box>
        <Divider />
      </Grid>
    ));
  };
  function parseTags(tagString) {
    // Check if the input string is not empty
    if (!tagString) return [];

    // Split the string by commas and trim any whitespace
    return tagString.split(",").map((tag) => tag.trim());
  }
  return (
    <Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <EditableKeyData title={"Mac Address"} value={data.macAddress} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <EditableKeyData
              title={"Date Time"}
              value={formatDate(data.created_at)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <EditableKeyData
              title={"Updated Date Time"}
              value={formatDate(data.updated_at)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <EditableKeyData title={"oid"} value={data._id} />
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <SectionTitle title="System Info" size="sm" />
        <Box mt={1} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle2">OS Info</Typography>
            <Grid container>{renderKeyValues(data.systemInfo.os_info)}</Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle2">CPU Info</Typography>
            <Grid container>{renderKeyValues(data.systemInfo.cpu_info)}</Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle2">Memory Info</Typography>
            <Grid container>
              {renderKeyValues(data.systemInfo.memory_info)}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle2">Other Info</Typography>
            <Grid container>
              <Grid item xs={12}>
                <Box sx={{ py: 0.5 }}>
                  <EditableKeyData
                    title={"Mac Address"}
                    value={data.systemInfo.mac_address}
                  />
                </Box>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ py: 0.5 }}>
                  <EditableKeyData
                    title={"Hostname"}
                    value={data.systemInfo.hostname}
                  />
                </Box>
                <Divider />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <SectionTitle title="Config" size="sm" />
        <Box mt={1} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle2">Glassdoor</Typography>
            <Grid container>
              <Grid item xs={12}>
                <EditableKeyData
                  editable
                  title={"user data dir"}
                  value={data.config.glassdoor.user_data_dir}
                  idetifyName={"user_data_dir"}
                  section={'glassdoor'}
                  isEditing={editingKey === "user data dir"}
                  onEditClick={() => handleEditClick("user data dir")}
                  onSave={handleConfigSave}
                />
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <EditableKeyData
                  editable
                  type="select"
                  options={industry}
                  title={"industry"}
                  idetifyName={"industry"}
                  section={'glassdoor'}
                  value={data.config.glassdoor.industry}
                  isEditing={editingKey === "industry"}
                  onEditClick={() => handleEditClick("industry")}
                  onSave={handleConfigSave}
                />
              </Grid>
              <Grid item xs={12}>
                <EditableKeyData
                  editable
                  type="multiselect"
                  options={tag}
                  title={"tag"}
                  idetifyName={"tag"}
                  section={'glassdoor'}
                  value={parseTags(data.config.glassdoor.tag)}
                  isEditing={editingKey === "tag"}
                  onEditClick={() => handleEditClick("tag")}
                  onSave={handleConfigSave}
                />
              </Grid>
              <Grid item xs={12}>
                <EditableKeyData
                  editable
                  type="multiselect"
                  options={locations}
                  title={"locations"}
                  idetifyName={"locations"}
                  section={'glassdoor'}
                  value={data.config.glassdoor.locations}
                  isEditing={editingKey === "locations"}
                  onEditClick={() => handleEditClick("locations")}
                  onSave={handleConfigSave}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle2">Linkedin</Typography>
            <Grid container>
              <Grid item xs={12}>
                <EditableKeyData
                  editable
                  title={"user data dir"}
                  section={'linkedin'}
                  value={data.config.linkedin.user_data_dir}
                  isEditing={editingKey === "linkedin user data dir"}
                  onEditClick={() => handleEditClick("linkedin user data dir")}
                  idetifyName={"user_data_dir"}
                  onSave={handleConfigSave}
                />
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <EditableKeyData
                  editable
                  type="select"
                  options={industry}
                  title={"industry"}
                  value={data.config.linkedin.industry}
                  isEditing={editingKey === "linkedin industry"}
                  onEditClick={() => handleEditClick("linkedin industry")}
                  idetifyName={"industry"}
                  section={'linkedin'}
                  onSave={handleConfigSave}
                />
              </Grid>
              <Grid item xs={12}>
                <EditableKeyData
                  editable
                  type="multiselect"
                  options={tag}
                  title={"tag"}
                  value={parseTags(data.config.linkedin.tag)}
                  isEditing={editingKey === "linkedin tag"}
                  onEditClick={() => handleEditClick("linkedin tag")}
                  idetifyName={"tag"}
                  section={'linkedin'}
                  onSave={handleConfigSave}
                />
              </Grid>
              <Grid item xs={12}>
                <EditableKeyData
                  editable
                  type="multiselect"
                  options={locations}
                  title={"locations"}
                  value={data.config.linkedin.locations}
                  isEditing={editingKey === "linkedin locations"}
                  onEditClick={() => handleEditClick("linkedin locations")}
                  idetifyName={"locations"}
                  section={'linkedin'}
                  onSave={handleConfigSave}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle2">Indeed</Typography>
            <Grid container>
              <Grid item xs={12}>
                <EditableKeyData
                  editable
                  title={"user data dir"}
                  value={data.config.indeed.user_data_dir}
                  isEditing={editingKey === "indeed user data dir"}
                  onEditClick={() => handleEditClick("indeed user data dir")}
                  idetifyName={"user_data_dir"}
                  section={'indeed'}
                  onSave={handleConfigSave}
                />
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <EditableKeyData
                  editable
                  type="select"
                  options={industry}
                  title={"industry"}
                  value={data.config.indeed.industry}
                  isEditing={editingKey === "indeed industry"}
                  onEditClick={() => handleEditClick("indeed industry")}
                  idetifyName={"industry"}
                  section={'indeed'}
                  onSave={handleConfigSave}
                />
              </Grid>
              <Grid item xs={12}>
                <EditableKeyData
                  editable
                  type="multiselect"
                  options={tag}
                  title={"tag"}
                  value={parseTags(data.config.indeed.tag)}
                  isEditing={editingKey === "indeed tag"}
                  onEditClick={() => handleEditClick("indeed tag")}
                  idetifyName={"tag"}
                  section={'indeed'}
                  onSave={handleConfigSave}
                />
              </Grid>
              <Grid item xs={12}>
                <EditableKeyData
                  editable
                  type="multiselect"
                  options={locations}
                  title={"locations"}
                  value={data.config.indeed.locations}
                  isEditing={editingKey === "indeed locations"}
                  onEditClick={() => handleEditClick("indeed locations")}
                  idetifyName={"locations"}
                  section={'indeed'}
                  onSave={handleConfigSave}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle2">Product Key</Typography>
            <Grid container>
              <Grid item xs={12}>
                <EditableKeyData
                  title={"email"}
                  value={data.config.product_key.email}
                />
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <EditableKeyData
                  title={"key"}
                  value={data.config.product_key.key}
                />
                <Divider />
              </Grid>
              
              <Grid item xs={12}>
                <EditableKeyData
                  editable
                  title={"status"}
                  type="select"
                  options={productKeyTrueFalse}
                  value={
                    (data.config.product_key.status === true ? "Active" : "Inactive")
                  }
                  isEditing={editingKey === "product_key status"}
                  onEditClick={() => handleEditClick("product_key status")}
                  idetifyName={"status"}
                  section={'product_key'}
                  onSave={handleConfigSave}
                />
                <Divider />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <SectionTitle title="Scrape Status" size="sm" />
        <Box mt={1} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle2">Glassdoor</Typography>
            <Grid container>
              <Grid item xs={12}>
                <EditableKeyData
                  title={"Last Company"}
                  value={data.scrapeStatus.glassdoor.lastCompany}
                />
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <EditableKeyData
                  // title={"Status"}
                  // value={data.scrapeStatus.glassdoor.status}
                  editable
                  title={"status"}
                  type="select"
                  options={trueFalse}
                  value={
                    data.scrapeStatus.glassdoor.status ? "Running" : "Idle"
                  }
                  isEditing={editingKey === "scrapeStatus glassdoor"}
                  onEditClick={() => handleEditClick("scrapeStatus glassdoor")}
                  idetifyName={"status"}
                  section={'glassdoor'}
                  onSave={handleScrapSave}
                />
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <EditableKeyData
                  title={"Checked Company"}
                  value={data.scrapeStatus.glassdoor.checkedCompany}
                />
                <Divider />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle2">linkedin</Typography>
            <Grid container>
              <Grid item xs={12}>
                <EditableKeyData
                  title={"Last Company"}
                  value={data.scrapeStatus.linkedin.lastCompany}
                />
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <EditableKeyData
                  // title={"Status"}
                  // value={data.scrapeStatus.linkedin.status}
                  editable
                  title={"status"}
                  type="select"
                  options={trueFalse}
                  value={data.scrapeStatus.linkedin.status ? "Running" : "Idle"}
                  isEditing={editingKey === "scrapeStatus linkedin"}
                  onEditClick={() => handleEditClick("scrapeStatus linkedin")}
                  idetifyName={"status"}
                  section={'linkedin'}
                  onSave={handleScrapSave}
                />
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <EditableKeyData
                  title={"Checked Company"}
                  value={data.scrapeStatus.linkedin.checkedCompany}
                />
                <Divider />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle2">indeed</Typography>
            <Grid container>
              <Grid item xs={12}>
                <EditableKeyData
                  title={"Last Company"}
                  value={data.scrapeStatus.indeed.lastCompany}
                />
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <EditableKeyData
                  // title={"Status"}
                  // value={data.scrapeStatus.indeed.status}
                  editable
                  title={"status"}
                  type="select"
                  options={trueFalse}
                  value={data.scrapeStatus.indeed.status ? "Running" : "Idle"}
                  isEditing={editingKey === "scrapeStatus indeed"}
                  onEditClick={() => handleEditClick("scrapeStatus indeed")}
                  idetifyName={"status"}
                  section={'indeed'}
                  onSave={handleScrapSave}
                />
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <EditableKeyData
                  title={"Checked Company"}
                  value={data.scrapeStatus.indeed.checkedCompany}
                />
                <Divider />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ControllerShowData;
