import React, { useState, useEffect } from "react";
import { SnackbarProvider, useSnackbar } from "notistack";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../store/auth/login/actions";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { PageHeader, SnackbarComponent, Widget } from "../../Components";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import theme from "../../Theme";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Modal,
  Typography,
  Divider,
  Grid,
} from "@mui/material";
import {
  dateSearchUsersCompaniesViaApi,
  shuffledDataViaApi,
} from "../../store/search/action";
import BusinessIcon from "@mui/icons-material/Business";
import dayjs from "dayjs";
dayjs.extend(require("dayjs/plugin/timezone"));
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.tz.setDefault("Asia/Kolkata");
const ItemType = {
  CHIP: "chip",
};

const DraggableChip = ({
  member,
  level,
  bdm,
  moveMember,
  color,
  AllRATeamCompaniesCount,
}) => {
  const [, ref] = useDrag({
    type: ItemType.CHIP,
    item: { member, level, bdm },
  });
  const filteredResult = AllRATeamCompaniesCount?.filter(
    (item) => item.raId === member._id
  );
  const count = filteredResult?.length > 0 ? filteredResult[0].count : 0;
  return (
    <Chip
      ref={ref}
      label={`${member.userFirstName} ${member.userLastName} - ${count}`}
      size="small"
      avatar={
        <Avatar
          sx={{
            bgcolor:
              level === "senior"
                ? theme.palette.secondary.dark
                : level === "junior"
                ? theme.palette.error.dark
                : theme.palette.success.dark,
            color:
              level === "senior"
                ? `${theme.palette.secondary.contrastText} !important`
                : level === "junior"
                ? `${theme.palette.error.contrastText} !important`
                : `${theme.palette.success.contrastText} !important`,
          }}
        >
          {member.userFirstName[0]}
        </Avatar>
      }
      sx={{ cursor: "move" }}
      color={color}
      variant="outlined"
    />
  );
};

const DroppableContainer = ({
  bdm,
  members,
  level,
  moveMember,
  color,
  AllRATeamCompaniesCount,
}) => {
  const [, ref] = useDrop({
    accept: ItemType.CHIP,
    drop: (draggedItem) => {
      // Only allow dropping if the level matches
      if (draggedItem.level === level && draggedItem.bdm._id !== bdm._id) {
        moveMember(draggedItem.member, draggedItem.level, draggedItem.bdm, bdm);
      }
    },
  });

  return (
    <Box
      ref={ref}
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 1,
        padding: 1,
        border: members.length ? "" : "1px dashed #ccc",
        borderRadius: "4px",
        backgroundColor: members.length ? "inherit" : "#f0f0f0",
      }}
    >
      {members.length ? (
        members.map((member, index) => (
          <DraggableChip
            key={index}
            member={member}
            level={level}
            bdm={bdm}
            moveMember={moveMember}
            color={color}
            AllRATeamCompaniesCount={AllRATeamCompaniesCount}
          />
        ))
      ) : (
        <Typography variant="body2" color="textSecondary">
          Drop items here
        </Typography>
      )}
    </Box>
  );
};

const Assign = () => {
  const [assignments, setAssignments] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const storeData = useSelector((state) => state.Login.storeData) || [];
  const client=storeData.clientDb
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
 
  
    const AllCompaniesListRedux= useSelector((state) => state.Search.AllDateSearchComapniesList) || [];
    let AllCompaniesList =Array.isArray(AllCompaniesListRedux)?AllCompaniesListRedux:[] 
    const RATeamAccounts = allUsers.filter(user =>  user.userType.includes('RATeam') && user.status !== "Inactive"
      &&user.userEmailId !== "ratest@tektreeinc.com"&&
      user.userEmailId !== "lilyra@tektreeinc.com" &&
      user.userEmailId !== "oliviara@tektreeinc.com" &&
      user.userEmailId !== "emmara@tektreeinc.com" &&
      user.userEmailId !== "charlottera@tektreeinc.com" &&
      user.userEmailId !== "danielra@tektreeinc.com" &&
      user.userEmailId !== "miara@tektreeinc.com" &&
      user.userEmailId !== "williamra@tektreellc.com" 

    );
    const AllRATeamCompaniesCount = RATeamAccounts.map(user => {
      const count = AllCompaniesList.reduce((acc, company) => {
        if (user._id.toString() === company.addedBy.toString()) {
          return acc + (company.contactDetails?.filter(contact => contact.emailId).length || 0);
        }
        return acc;
      }, 0);
      return { raId: user._id, count };
    });
  let TotalEmailCount = 0;

  if (AllRATeamCompaniesCount) {
    TotalEmailCount = AllRATeamCompaniesCount.reduce(
      (total, count) => total + count.count,
      0
    );
  }

  const dispatch = useDispatch();

  useEffect(() => {
    const action={
      client:client
    }
    dispatch(getAllUsers(action));
  }, [dispatch]);

  const activeBDMs = allUsers.filter(
    (user) =>
      user.userType.includes('BDM')  &&
      user.userStatus === "Active" &&
      user.userEmailId !== "emily.johnson@tektreecorp.com"&&
      user.userEmailId !== "olivia.clark@tektreellc.com"&&
      user.userEmailId !== "emma.scott@tektreellc.com"&&
      user.userEmailId !== "william.turner@tektreellc.com"&&
      user.userEmailId !== "charlotte.mitchell@tektreellc.com"&&
      user.userEmailId !== "daniel.clark@tektreellc.com"
  );
  const RATeamMembers = allUsers.filter(
    (user) =>
      user.userType.includes('RATeam') &&
      user.userRole !== "Manager" &&
      user.userStatus === "Active" &&
      user.userEmailId !== "ratest@tektreeinc.com"&&
      user.userEmailId !== "lilyra@tektreeinc.com" &&
      user.userEmailId !== "oliviara@tektreeinc.com" &&
      user.userEmailId !== "emmara@tektreeinc.com" &&
      user.userEmailId !== "charlottera@tektreeinc.com" &&
      user.userEmailId !== "danielra@tektreeinc.com" &&
      user.userEmailId !== "miara@tektreeinc.com" &&
      user.userEmailId !== "williamra@tektreellc.com" 
  );

  const SeniourCount = RATeamMembers.filter(
    (user) => user.userLevel === "Senior"
  );
  const JuniorCount = RATeamMembers.filter(
    (user) => user.userLevel === "Junior"
  );
  const FresherCount = RATeamMembers.filter(
    (user) => user.userLevel === "Fresher"
  );

  const assignRATeamToBDMs = (RATeam) => {
    const senior = RATeam.filter((user) => user.userLevel === "Senior");
    const junior = RATeam.filter((user) => user.userLevel === "Junior");
    const fresher = RATeam.filter((user) => user.userLevel === "Fresher");

    const newAssignments = activeBDMs.map((bdm) => ({
      bdm,
      assignedRATeam: { senior: [], junior: [], fresher: [] },
    }));

    // Shuffle BDMs for randomness
    const shuffledBDMs = [...newAssignments];
    if (shuffledBDMs.length === 0) {
      console.warn(`No BDMs available to assign members for level `);
      return;
    }
    for (let i = shuffledBDMs.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledBDMs[i], shuffledBDMs[j]] = [shuffledBDMs[j], shuffledBDMs[i]];
    }

    // Assign members to BDMs
    const assignMembers = (members, level) => {
      let index = 0;
      members.forEach((member) => {
        const currentBDM = shuffledBDMs[index];

        currentBDM.assignedRATeam[level].push(member);
        index = (index + 1) % shuffledBDMs.length;
      });
    };

    assignMembers(senior, "senior");
    assignMembers(junior, "junior");
    assignMembers(fresher, "fresher");

    setAssignments(newAssignments);
    enqueueSnackbar("Assignments updated!", { variant: "success" });
  };

  const shuffleRATeamMembers = () => {
    const shuffledRATeam = [...RATeamMembers].sort(() => Math.random() - 0.5);
    assignRATeamToBDMs(shuffledRATeam);
    enqueueSnackbar("RATeam members shuffled!", { variant: "info" });
  };

  useEffect(() => {
    assignRATeamToBDMs(RATeamMembers);
  }, []);

  const moveMember = (member, fromLevel, fromBDM, toBDM) => {
    setAssignments((prevAssignments) => {
      const newAssignments = prevAssignments.map((assignment) => {
        if (assignment.bdm._id === fromBDM._id) {
          return {
            ...assignment,
            assignedRATeam: {
              ...assignment.assignedRATeam,
              [fromLevel]: assignment.assignedRATeam[fromLevel].filter(
                (m) => m.userEmailId !== member.userEmailId
              ),
            },
          };
        }
        if (assignment.bdm._id === toBDM._id) {
          return {
            ...assignment,
            assignedRATeam: {
              ...assignment.assignedRATeam,
              [fromLevel]: [...assignment.assignedRATeam[fromLevel], member],
            },
          };
        }
        return assignment;
      });
      return newAssignments;
    });
  };
  const filterResultCount = (team) => {
    return team.reduce((total, member) => {
      const filteredResult = AllRATeamCompaniesCount?.find(
        (item) => item.raId === member._id
      );
      return total + (filteredResult ? filteredResult.count : 0);
    }, 0);
  };

  const calculateTotalValue = (row) => {
    const totalSenior = filterResultCount(row.seniorTeam || []);
    const totalJunior = filterResultCount(row.juniorTeam || []);
    const totalFresher = filterResultCount(row.fresherTeam || []);
    return totalSenior + totalJunior + totalFresher;
  };
  const columns = [
    {
      field: "bdm",
      headerName: "BDM",
      minWidth: 300,
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {params.value.userFirstName} {params.value.userLastName}
          </Typography>
          <Typography variant="body2">
            {params.value.userSudoEmailId}
          </Typography>
        </Box>
      ),
    },
    {
      field: "seniorTeam",
      headerName: "Senior Team",
      minWidth: 300,
      flex: 1,
      renderCell: (params) => (
        <DroppableContainer
          bdm={params.row.bdm}
          members={params.value}
          level="senior"
          moveMember={moveMember}
          color="secondary"
          AllRATeamCompaniesCount={AllRATeamCompaniesCount}
        />
      ),
    },
    {
      field: "juniorTeam",
      headerName: "Junior Team",
      minWidth: 300,
      flex: 1,
      renderCell: (params) => (
        <DroppableContainer
          bdm={params.row.bdm}
          members={params.value}
          level="junior"
          moveMember={moveMember}
          color="error"
          AllRATeamCompaniesCount={AllRATeamCompaniesCount}
        />
      ),
    },
    {
      field: "fresherTeam",
      headerName: "Fresher Team",
      minWidth: 300,
      flex: 1,
      renderCell: (params) => (
        <DroppableContainer
          bdm={params.row.bdm}
          members={params.value}
          level="fresher"
          moveMember={moveMember}
          color="success"
          AllRATeamCompaniesCount={AllRATeamCompaniesCount}
        />
      ),
    },
    {
      field: "count",
      headerName: "Total Emails Count",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Typography>{calculateTotalValue(params.row)}</Typography>
      ),
    },
  ];

  const rows = assignments.map((assignment, index) => ({
    id: index,
    bdm: assignment.bdm,
    seniorTeam: assignment.assignedRATeam.senior || [],
    juniorTeam: assignment.assignedRATeam.junior || [],
    fresherTeam: assignment.assignedRATeam.fresher || [],
  }));
  const [saveModal, setSaveModal] = useState(false);
  const handleSaveModalOpne = () => {
    setSaveModal(!saveModal);
  };

  const shuffledData = [];

  // Iterate over each entry in the data
  assignments.forEach((entry) => {
    // Extract BDM email
    const bdmEmail = entry.bdm.userSudoEmailId;
    const bdmpseudoEmail = entry.bdm.userEmailId;
    const bdmId = entry.bdm._id;

    // Initialize assignUsers array for this BDM
    const assignUsers = [];

    // Extract senior, junior, and fresher RA Team members
    const seniors = entry.assignedRATeam.senior.map((member) => member._id);
    const juniors = entry.assignedRATeam.junior.map((member) => member._id);
    const freshers = entry.assignedRATeam.fresher.map((member) => member._id);

    // Push senior, junior, and fresher IDs to assignUsers array
    assignUsers.push(...seniors, ...juniors, ...freshers);

    // Push BDM email and assignUsers array to shuffledData
    shuffledData.push({
      BdmId: bdmId,
      BdmEmail: bdmEmail,
      BdmpseudoEmail: bdmpseudoEmail,
      assignUsers,
    });
  });
  const handleSaveSuffleData = () => {
    if (shuffledData.length > 0) {
      const action = {
        addedBy: storeData._id,
        addedOn: new Date(),
        shuffledData,
        client:client,
      };

      dispatch(shuffledDataViaApi(action));
      handleSaveModalOpne();
    } else {
      alert("please assign the suffeled data");
    }
  };


  const [searchFromDate, setSearchFromDate] = useState("");
  const [startingDate, setStartingDate] = useState(
    dayjs().tz("Asia/Kolkata").startOf("day")
  );

  const [searchToDate, setSearchToDate] = useState("");
  const handleDateChange = (selectedDates) => {
    setSearchFromDate("");
    setSearchToDate("");
    if (selectedDates.length === 2) {
      const formattedDates = selectedDates.map((date, index) => {
        return date.toISOString();
      });

      const [fromDate, toDate] = selectedDates;
      setSearchFromDate(formattedDates[0]);
      setSearchToDate(formattedDates[1]);

      // const action = {
      //   searchFromDate: formattedDates[0],
      //   searchToDate: formattedDates[1],
      // };
      const action = {
        searchFromDate: formattedDates[0],
        searchToDate: formattedDates[1],
        client:client,
      };
   
      dispatch(dateSearchUsersCompaniesViaApi(action));
    }
  };
  useEffect(() => {
    // const today = new Date();
    const today = dayjs().tz("Asia/Kolkata").startOf("day");
   
    handleDateChange([today, today.endOf("day")]);
  }, []);
  return (
    <SnackbarProvider
      maxSnack={4}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
    >
      <DndProvider backend={HTML5Backend}>
        <LayoutSidebarMenu pageTitle="Assign RA Team to BDMs">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Box flex={1}></Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 2,
                alignItems: "center",
              }}
            >
              <Button variant="contained" onClick={handleSaveModalOpne}>
                Save
              </Button>
              <Button onClick={shuffleRATeamMembers} variant="contained">
                Shuffle
              </Button>
            </Box>
          </Box>
          <Grid
            container
            columnSpacing={2}
            columns={{ xs: 12, sm: 12, md: 15, lg: 15 }}
          >
            <Grid item xs={12} sm={12} md={5} lg={3}>
              <Widget
                title="Total BDM's"
                value={activeBDMs.length}
                icon={<BusinessIcon />}
                color="primary"
                // variant="contained"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={3}>
              <Widget
                title="Seniour RA"
                value={SeniourCount.length}
                icon={<BusinessIcon />}
                color="secondary"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={3}>
              <Widget
                title="Juniour RA"
                value={JuniorCount.length}
                icon={<BusinessIcon />}
                color="error"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={3}>
              <Widget
                title="Fresher RA"
                value={FresherCount.length}
                icon={<BusinessIcon />}
                color="success"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={3}>
              <Widget
                title="Emails Count"
                value={TotalEmailCount}
                icon={<BusinessIcon />}
                color="primary"
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <DataGrid
              rows={rows}
              columns={columns}
              disableRowSelectionOnClick
              autoHeight
              getRowHeight={() => "auto"}
              sx={{
                [`& .${gridClasses.cell}`]: {
                  py: 0.5,
                },
              }}
            />
          </Box>
        </LayoutSidebarMenu>
      </DndProvider>
      <Modal
        open={saveModal}
        //onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: {
              xs: 1,
              md: 2,
            },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: 1,
              rowGap: 0.5,
              flexWrap: "wrap",

              mb: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={"400"}>
              Are you sure you want to save shuffeled data!
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleSaveModalOpne()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleSaveSuffleData()}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      <SnackbarComponent />
    </SnackbarProvider>
  );
};

export default Assign;
