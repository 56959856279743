import{
    DELETE_LAYOUT,
    SUCCESS_USER_TYPE_ACCESS_LIST
} from "./actionType"


const INIT_STATE = {
  menuOptions:[],
  };
  const layouts = (state = INIT_STATE, action) => {
    switch (action.type) {
      case SUCCESS_USER_TYPE_ACCESS_LIST: {
        const OptionsData = action.payload;
        // Reset the restaurant-related data to its initial state.
        return {
          ...state,
          menuOptions: OptionsData,
        };
      }
        
      case DELETE_LAYOUT: {
        
        return INIT_STATE;
      }
      default:
        return state;
    }
  };
  
  export default layouts;
  