import { combineReducers } from "redux";
// Authentication
import Login from "./auth/login/reducer";
import Search from "./search/reducer";
import CompaniesList from "./companiesList/reducer"
import Reports from "./reports/reducer";
import Recruiters from "./recruiter/reducer";
import Layouts from "./auth/layout/reducer";
const rootReducer = combineReducers({
    // public
    Login,
    Search,
    CompaniesList,
    Reports,
    Recruiters,
    Layouts,
});

export default rootReducer;