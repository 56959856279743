import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import MainBackground from "../Backgrounds/MainBackground";
import SectionTitle from "../Helper/SectionTitle";
import { DataGrid } from "@mui/x-data-grid";
import theme from "../../Theme";
import ScrollView from "../Helper/ScrollView";
import companyStatus from "../companyStatusElements/companyStatus";
const { companyBlocked, companyDNC, companyNoFollowUp, employeeDNC, employeeBlocked } = companyStatus;
const ContactView = ({
    data,
    key,
    color = "primary", //primary, secondary, info, success, warning, error
    isSelected,
    onClick,
  }) => {
    return (
      <React.Fragment>
        <Box
          key={key}
          sx={{
            borderLeft:
              ((color === "primary" || color === "success") &&
                data.status === employeeBlocked) ||
              data.status === employeeDNC
                ? `3px solid ${theme.palette.error.main}`
                : `3px solid ${theme.palette[color].main}`,
            p: 1,
            display: "flex",
            flexDirection: "column",
            gap: 0.4,
            overflow: "hidden",
            cursor:
              data.status === employeeBlocked || data.status === employeeDNC
                ? "not-allowed"
                : "pointer",
            backgroundColor: isSelected
              ? theme.palette.action.selected
              : "inherit",
            opacity: data.status === employeeBlocked || data.status === employeeDNC ? 0.8 : 1,
          }}
          onClick={() =>
            data.status !== employeeBlocked && data.status !== employeeDNC && onClick(data)
          }
        >
          <Box>
            <Typography
              noWrap
              sx={{ fontSize: 14, fontWeight: 600, lineHeight: 1.2 }}
            >
              {data.firstName} {data.lastName}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 0.5,
            }}
          >
            <Typography
              noWrap
              sx={{
                fontSize: 12,
                color: theme.palette.grey[500],
                flex: 1,
              }}
            >
              {data.designation}
            </Typography>
            <Typography
              noWrap
              sx={{
                fontSize: 12,
                color: theme.palette.grey[500],
              }}
            >
              {/* Used: 35 */}
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 0.5,
            }}
          >
            <Typography
              noWrap
              sx={{
                fontSize: 12,
                color: theme.palette.grey[500],
                flex: 1,
              }}
            >
              {data.emailId}
            </Typography>
            <Typography
              noWrap
              sx={{
                fontSize: 12,
                color: theme.palette.grey[500],
              }}
            >
              {data.phoneNumber}
            </Typography>
          </Box>
        </Box>
      </React.Fragment>
    );
  };

const ContactDetails=(
    {
        mergeContactDetails,
        sanitizeString,
        handleCheckEmailID,
        extractDomain,
        handleCheckEmailDomain,
        storeData,
        dataShow,
        query,
        latestResult,
        beforeData,
        columnsData,
        colorStatus,
        selectedContactIds,
        handleContactClick,
    }
)=>{
    return(
        <Box sx={{mt:3}}>
            {!storeData.userType.includes("BDM") &&
              dataShow &&
              sanitizeString(dataShow) === sanitizeString(query) && (
                <>
                  {mergeContactDetails &&
                    mergeContactDetails.map((result, index) =>
                      sanitizeString(result.companyName) ===
                        sanitizeString(query) ||
                      sanitizeString(result.domain) === sanitizeString(query) ||
                      sanitizeString(result.domain) ===
                        sanitizeString(extractDomain(query)) ||
                      handleCheckEmailID(latestResult, query) ||
                      handleCheckEmailDomain(latestResult, query) ? (
                        <Grid container spacing={1}>
                          <Grid item xs={4}>
                            <MainBackground height="full">
                              <SectionTitle title="Other Added Details" />
                              <DataGrid
                                rows={beforeData}
                                columns={columnsData}
                                disableColumnMenu
                                disableColumnSorting
                                disableColumnSelector
                                disableColumnFilter
                                disableColumnReorder
                                disableColumnResize
                                //disableRowSelectionOnClick
                                disableColumnSort
                                hideFooterSelectedRowCount
                                //hideFooter
                                initialState={{
                                  pagination: {
                                    paginationModel: { page: 0, pageSize: 3 },
                                  },
                                }}
                                pageSizeOptions={[3]}
                                getRowHeight={() => "auto"}
                                autoHeight
                                sx={{
                                  "& .MuiDataGrid-cell": {
                                    display: "flex",
                                    alignItems: "center !important",
                                  },
                                  [`& .MuiDataGrid-checkboxInput`]: {
                                    color: theme.palette.primary.main,
                                    p: 0,

                                    "&.Mui-checked": {
                                      color: theme.palette.primary.dark,
                                    },
                                  },
                                  //Scroller
                                  [`& .MuiDataGrid-scrollbar`]: {
                                    scrollbarWidth: "thin",
                                    scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                                  },
                                  [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]:
                                    {
                                      borderRadius: "10px", // Apply border radius
                                    },

                                  [`& .MuiDataGrid-scrollbar:hover`]: {
                                    scrollbarColor: `${theme.palette.primary.dark}`,
                                  },
                                }}
                              />
                            </MainBackground>
                          </Grid>
                          <Grid item xs={8}>
                            <MainBackground height="full">
                              <SectionTitle title="Contact Lists" />
                              <Box pb={1}>
                                <ScrollView height={252}>
                                  <Grid container spacing={0.5}>
                                    {result.contactDetails.map(
                                      (contact, index) => (
                                        <Grid
                                          item
                                          xs={12}
                                          sm={6}
                                          md={4}
                                          lg={6}
                                          xl={3}
                                        >
                                          <ContactView
                                            key={index}
                                            data={contact}
                                            color={colorStatus}
                                            // isSelected={selectedContactIds.includes(
                                            //   contact
                                            // )}
                                            isSelected={selectedContactIds.includes(
                                              contact
                                            )}
                                            onClick={handleContactClick}
                                          />
                                        </Grid>
                                      )
                                    )}
                                  </Grid>
                                </ScrollView>
                              </Box>
                            </MainBackground>
                          </Grid>
                        </Grid>
                      ) : (
                        ""
                      )
                    )}
                </>
              )}
          </Box>
    )
}
export default ContactDetails;