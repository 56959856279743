
import React, { useState } from "react";
import dayjs from 'dayjs';
import 'dayjs/plugin/timezone';
import 'dayjs/plugin/utc';

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Box } from "@mui/material";

dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.tz.setDefault('Asia/Kolkata');

const DateTimePickerValue = ({ handleDateChange }) => {
  // Get today's date and time in Indian Kolkata timezone
  const today = dayjs().tz('Asia/Kolkata').startOf('day');

  // Set the initial state for starting and ending dates
  const [startingDate, setStartingDate] = useState(today);
  const [endingDate, setEndingDate] = useState(today.endOf('day'));

  // Handler for starting date change
  const handleStartingDateChange = (newValue) => {
    setStartingDate(newValue);
    handleDateChange([newValue, endingDate]);
  };

  // Handler for ending date change
  const handleEndingDateChange = (newValue) => {
    setEndingDate(newValue);
    handleDateChange([startingDate, newValue]);
  };
  const handleAccept = (value) => {
    console.log("OK button clicked. Selected date:", value.format('DD-MM-YYYY hh:mm a'));
  };
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DateTimePicker", "DateTimePicker"]}>
          <Box sx={{ display: "flex", gap: 2 }}>
            <DateTimePicker
              label="Start Date Time"
              value={startingDate}
              onChange={handleStartingDateChange}
              maxDate={endingDate}
              sx={{ flex: 1 }}
              format="DD-MM-YYYY hh:mm a"
            />
            <DateTimePicker
              label="End Date Time"
              value={endingDate}
              onChange={handleEndingDateChange}
              minDate={startingDate}
              minTime={startingDate}
              sx={{ flex: 1 }}
              format="DD-MM-YYYY hh:mm a"
              onAccept={handleAccept}
            />
          </Box>
        </DemoContainer>
      </LocalizationProvider>
    </div>
  );
};

export default DateTimePickerValue;
