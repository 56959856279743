import { SnackbarProvider, closeSnackbar, enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Typography,
  Divider,
  Chip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { BDMCustomeDateFilter, Widget } from "../../Components";
import BusinessIcon from "@mui/icons-material/Business";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import * as XLSX from "xlsx";
import moment from "moment-timezone";

import JSZip from "jszip";
import { getAllUsers } from "../../store/auth/login/actions";
import { dateSearchBdmsCompaniesViaApi } from "../../store/companiesList/action";
import dayjs from 'dayjs';
//mport dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { stopLoadingRedux } from "../../store/reports/action";
import { ShimmerTable } from "react-shimmer-effects";
import companyStatus from "../../Components/companyStatusElements/companyStatus";
// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/New_York'); // Set default timezone

const BDMJobReports = () => {
  const { companyBlocked, companyDNC, companyNoFollowUp, employeeDNC, employeeBlocked,companyOnProcess } = companyStatus;

  const dispatch = useDispatch();
  const { storeData } = useSelector((state) => ({
    storeData: state.Login.storeData,
  }));
  const client=storeData.clientDb
  useEffect(() => {
    const action={
      client:client
    }
    dispatch(getAllUsers(action));
  }, []);
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const [loading, setLoading] = useState(true);
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
  
  let retrictEmailID =
  allUsers &&
  allUsers.filter(
    (user) =>
      user.userEmailId === "ratest@tektreeinc.com" ||
      user.userEmailId === "lilyra@tektreeinc.com" ||
      user.userEmailId === "oliviara@tektreeinc.com" ||
      user.userEmailId === "emmara@tektreeinc.com" ||
      user.userEmailId === "charlottera@tektreeinc.com" ||
      user.userEmailId === "danielra@tektreeinc.com" ||
      user.userEmailId === "miara@tektreeinc.com" ||
      user.userEmailId === "williamra@tektreellc.com"
  );

let retrictEmailIDBDM =
  allUsers &&
  allUsers.filter(
    (user) =>
      user.userEmailId === "emily.johnson@tektreecorp.com" ||
    user.userEmailId === "olivia.clark@tektreellc.com"||
    user.userEmailId === "emma.scott@tektreellc.com"||
    user.userEmailId === "william.turner@tektreellc.com"||
    user.userEmailId === "charlotte.mitchell@tektreellc.com"||
    user.userEmailId === "daniel.clark@tektreellc.com"
  );
  
  let AllCompaniesList =
    useSelector((state) => state.CompaniesList?.AllBDMCompanies) || [];
    AllCompaniesList =
    AllCompaniesList &&
    AllCompaniesList.filter((company) =>company.status!==companyDNC&&company.status!==companyBlocked);
  AllCompaniesList = AllCompaniesList.filter((company) => {
    if (company.status === companyOnProcess) {
      // Check if there are job details
      if (company.jobDetails && company.jobDetails.length > 0) {
        // Check if any job detail meets the criteria
        return company.jobDetails.some((jobDetails) => {
          // Check job detail status and approval status
          if (
            (jobDetails.status === "Close" ||
              jobDetails.status === "Hold" ||
              jobDetails.status === "Backout") &&
            jobDetails.approvalStatus === false
          ) {
            return false; 
          } else {
            return true; 
          }
        });
      } else {
        return true; 
      }
    } else {
      return true; 
    }
  });
  const isAuthorizedUser =(storeData.userType.includes('SuperAdmin') ||  storeData.userType.includes('Developer'))
  
  if (!isAuthorizedUser) {
  
    AllCompaniesList = AllCompaniesList.filter((company) => {
      const addedBy = company.addedBy;
      const isRestricted =
        retrictEmailID.some((user) => user._id === addedBy) ||retrictEmailID.some((user)=>user._id===company.leadRAId)||
        retrictEmailIDBDM.some((user) => user._id === addedBy);
    
      return !isRestricted;
    });
  }
  let AlluserComapnies = Array.isArray(AllCompaniesList)
    ? AllCompaniesList
    : [];

  const formatDate = (dateString) => {
    const date = moment.tz(dateString, "UTC"); // Parse date in UTC
    return date.tz("Asia/Kolkata").format("DD-MM-YYYY"); // Convert to Kolkata time and format
  };
  // Function to format date for display in EST (America/New_York)
  // const formatDateForDisplay = (date) => {
  //   return dayjs(date).tz('America/New_York').format('MMMM DD, YYYY');
  // };
  const formatDateForDisplay = (date) => {
    const dateInNY = dayjs(date).tz('America/New_York');
    const isDST = moment.tz(date, 'America/New_York').isDST();
    const timeZoneAbbr = isDST ? 'EDT' : 'EST';
    return dateInNY.format(`MMMM DD, YYYY h:mm A [${timeZoneAbbr}]`);
  };
  
  
  const mergedData = AlluserComapnies.map((group) => {
    const userData = allUsers.find((user) => user._id === group.addedBy);
    const raData = allUsers.find((user) => user._id === group.leadRAId);
    const bdmName = userData?.userSudoFirstName ?? "";
    const leadRaName = raData?.userFirstName ?? "";
    if (userData) {
      return {
        bdmName: bdmName,
        leadRaName: leadRaName,
        ...group,
        leadRADate: group?.leadRADate ? formatDate(group.leadRADate) : "",
        addedOn: group?.addedOn ? formatDateForDisplay(group.addedOn) : "",
        jobDetails: group.jobDetails.map((company) => {
          const jobAddedUser = allUsers.find(
            (user) => user._id === company.addedBy
          );
          return {
            ...company,
            addedBy: jobAddedUser.userFirstName,
          };
        }),

        contactDetails: group.contactDetails.map((company) => {
          const contactUser = allUsers.find(
            (user) => user._id === company.addedBy
          );
          return {
            ...company,
            addedBy: contactUser.userFirstName, // Update addedBy to user's name
          };
        }),
      };
    } else {
      return group;
    }
  });
  const companiesListWithIds = Array.isArray(mergedData)
    ? mergedData.map((company, index) => ({
        ...company,
        id: index + 1,
      }))
    : [];

  const [downloadModalOpen, setdownloadModalOpen] = useState(false);
  const [selectDownloadType, setSelectDownloadType] = useState("");
  const [clickDownloadType, setClickDownloadType] = useState("");
  const [clickDownloadAction, setClickDownloadAction] = useState("");
  const handleDownloadModalOpen = (value, data) => {
    setdownloadModalOpen(!downloadModalOpen);
    setClickDownloadType(value);
    if (value === "actionDownload") {
      setClickDownloadAction(data);
    }
  };

  const handleRowDownload = (rowData) => {
    const csv = convertToCSV(rowData);

    const currentDate = new Date().toISOString().slice(0, 10); // Get today's date in YYYY-MM-DD format
    const fileName = `${rowData.bdmName}_${currentDate}.csv`;
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function convertToCSV(data) {
    const headers = [
      "companyName",
      "comment",
      "jobTitle",
      "jobLocation",
      "jobDescription",
      "jobWebsite",
      "jobStatus",
      "contactFirstName",
      "contactLastName",
      "contactDesignation",
      "contactEmail",
      "contactPhoneNumber",
    ];

    // Add headers to CSV
    let csv = headers.join(",") + "\n";

    // Add data rows to CSV
    data.companies.forEach((company) => {
      // Add job details if available
      if (company.jobDetails && company.jobDetails.length > 0) {
        const job = company.jobDetails[0];
        // Add contact details if available
        if (company.contactDetails && company.contactDetails.length > 0) {
          company.contactDetails.forEach((contact) => {
            csv += `"${company.companyName || ""}","${
              company.comment || ""
            }","${job.jobTitle || ""}","${job.jobLocation || ""}","${
              job.jobDescription || ""
            }","${job.jobWebsite || ""}","${job.status || ""}","${
              contact.firstName || ""
            }","${contact.lastName || ""}","${contact.designation || ""}","${
              contact.emailId || ""
            }","${contact.phoneNumber || ""}"\n`;
          });
        } else {
          // Add a row with empty contact details if no contact details are available
          csv += `"${company.companyName || ""}","${company.comment || ""}","${
            job.jobTitle || ""
          }","${job.jobLocation || ""}","${job.jobDescription || ""}","${
            job.jobWebsite || ""
          }","${job.status || ""}","","","","",""\n`;
        }
      } else {
        // Add a row with empty job details if no job details are available
        csv += `"${company.companyName || ""}","${
          company.comment || ""
        }","","","","","","","","",""\n`;
      }
    });

    return csv;
  }

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "addedOn",
      headerName: "Date",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "companyName",
      headerName: "Company Name",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "jobTitle",
      headerName: "Job Position",
      minWidth: 150,
      flex: 1,
      valueGetter: (value, row) => {
        if (row.jobDetails && row.jobDetails.length > 0) {
          const companies = row.jobDetails;
          return companies[0].jobTitle;
        }
      },
    },
    {
      field: "jobLocation",
      headerName: "Job Location",
      minWidth: 150,
      flex: 1,
      valueGetter: (value, row) => {
        if (row.jobDetails && row.jobDetails.length > 0) {
          const companies = row.jobDetails;
          return companies[0].jobLocation;
        }
      },
    },

    {
      field: "bdmName",
      headerName: "BDM Name",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        if (row.jobDetails && row.jobDetails.length > 0) {
          const company = row.jobDetails[0];
          const status = company.status === 'Active' ? 'Open' : company.status;
    
          return (
            <Box>
              <Chip
                label={status}
                size="small"
                sx={{ px: 0.5 }}
                color={
                  status === "Open"
                    ? "success"
                    : status === "Close"
                    ? "error"
                    : status === "Hold"
                    ? "warning"
                    : ["Interview L1", "Interview L2", "Interview L3"].includes(status)
                    ? "info"
                    : status === "Priority"
                    ? "secondary"
                    : "default"
                }
              />
            </Box>
          );
        }
        return '';
      }
    },
  ];
  const totalCompanies = AllCompaniesList.length;
  const totalUsers =
    allUsers && allUsers.filter((user) => user.userType.includes("BDM"));
  const AVG = (
    totalUsers.length > 0 ? totalCompanies / totalUsers.length : 0
  ).toFixed(2);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleDownloadAll = () => {
    const currentDate = new Date().toISOString().slice(0, 10);
    const zip = new JSZip();
    if (selectedRows.length > 0) {
      selectedRows.forEach((company, index) => {
        const csv = convertToCSV(company);
        const fileName = `${company.bdmName}_${currentDate}.csv`;
        zip.file(fileName, csv);
      });
      zip.generateAsync({ type: "blob" }).then((content) => {
        // Create a download link for the ZIP file
        const zipFileName = `selectBDMTeamData_${currentDate}.zip`;
        const url = window.URL.createObjectURL(content);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", zipFileName);
        document.body.appendChild(link);
        // Trigger the download
        link.click();
        document.body.removeChild(link);
      });
    } else {
      if (companiesListWithIds && companiesListWithIds.length > 0) {
        companiesListWithIds.forEach((company, index) => {
          const csv = convertToCSV(company);
          const fileName = `${company.bdmName}_${currentDate}.csv`;
          zip.file(fileName, csv);
        });

        zip.generateAsync({ type: "blob" }).then((content) => {
          // Create a download link for the ZIP file
          const zipFileName = `AllBDMTeamData_${currentDate}.zip`;
          const url = window.URL.createObjectURL(content);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", zipFileName);
          document.body.appendChild(link);
          // Trigger the download
          link.click();
          document.body.removeChild(link);
        });
      } else {
        enqueueSnackbar(
          `Today's data is unavailable and cannot be downloaded.`,
          {
            variant: "error",
            action: (key) => (
              <IconButton onClick={() => closeSnackbar()} color="inherit">
                <CloseIcon />
              </IconButton>
            ),
          }
        );
      }
    }
  };

  const handleAllRowDownload = (rowData) => {
    const csv = ALLconvertToCSV(rowData);
    const currentDate = new Date().toISOString().slice(0, 10); // Get today's date in YYYY-MM-DD format
    const fileName = `AllBDMTeamSheet_${currentDate}.csv`;
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function ALLconvertToCSV(company) {
    const headers = [
      "addedByBdmName",
      "companyName",
      "comment",
      "jobTitle",
      "jobLocation",
      "jobDescription",
      "jobWebsite",
      "jobStatus",
      "contactFirstName",
      "contactLastName",
      "contactDesignation",
      "contactEmail",
      "contactPhoneNumber",
    ];

    // Add headers to CSV
    let csv = headers.join(",") + "\n";
    company.forEach((data) => {
      // Add data rows to CSV
      data.companies.forEach((company) => {
        // Add job details if available
        if (company.jobDetails && company.jobDetails.length > 0) {
          const job = company.jobDetails[0];
          // Add contact details if available
          if (company.contactDetails && company.contactDetails.length > 0) {
            company.contactDetails.forEach((contact) => {
              csv += `"${data.bdmName || ""}","${company.companyName || ""}","${
                company.comment || ""
              }","${job.jobTitle || ""}","${job.jobLocation || ""}","${
                job.jobDescription || ""
              }","${job.jobWebsite || ""}","${job.status || ""}","${
                contact.firstName || ""
              }","${contact.lastName || ""}","${contact.designation || ""}","${
                contact.emailId || ""
              }","${contact.phoneNumber || ""}"\n`;
            });
          } else {
            // Add a row with empty contact details if no contact details are available
            csv += `"${data.bdmName || ""}","${company.companyName || ""}","${
              company.comment || ""
            }","${job.jobTitle || ""}","${job.jobLocation || ""}","${
              job.jobDescription || ""
            }","${job.jobWebsite || ""}","${
              job.status || ""
            }","","","","",""\n`;
          }
        } else {
          // Add a row with empty job details if no job details are available
          csv += `"${data.bdmName || ""}","${company.companyName || ""}","${
            company.comment || ""
          }","","","","","","","","",""\n`;
        }
      });
    });
    return csv;
  }
  const handleDownloadAllOneFile = () => {
    if (companiesListWithIds && companiesListWithIds.length > 0) {
      handleAllRowDownload(companiesListWithIds);
      // companiesListWithIds.forEach((company) => {
      //   handleAllRowDownload(company);
      // });
    } else {
      enqueueSnackbar(`Today's data is unavailable and cannot be downloaded.`, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
  };

  const handleRowDownloadExcel = (rowData) => {
    // Convert the data to the same format as the CSV function
    const formattedData = convertToJSON(rowData);

    // Create a worksheet from the formatted data
    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "AllData");

    // Generate the file name with the current date
    const currentDate = new Date().toISOString().slice(0, 10);
    const fileName = `${rowData.bdmName}_${currentDate}.xlsx`;

    // Write the workbook to a file
    XLSX.writeFile(workbook, fileName);
  };
  const convertToJSON = (data) => {
    const headers = [
      "companyName",
      "comment",
      "jobTitle",
      "jobLocation",
      "jobDescription",
      "jobWebsite",
      "jobStatus",
      "contactFirstName",
      "contactLastName",
      "contactDesignation",
      "contactEmail",
      "contactPhoneNumber",
    ];

    const json = [];
    data.companies.forEach((company) => {
      if (company.jobDetails && company.jobDetails.length > 0) {
        const job = company.jobDetails[0];
        if (company.contactDetails && company.contactDetails.length > 0) {
          company.contactDetails.forEach((contact) => {
            json.push({
              companyName: company.companyName || "",
              comment: company.comment || "",
              jobTitle: job.jobTitle || "",
              jobLocation: job.jobLocation || "",
              jobDescription: job.jobDescription || "",
              jobWebsite: job.jobWebsite || "",
              jobStatus: job.status || "",
              contactFirstName: contact.firstName || "",
              contactLastName: contact.lastName || "",
              contactDesignation: contact.designation || "",
              contactEmail: contact.emailId || "",
              contactPhoneNumber: contact.phoneNumber || "",
            });
          });
        } else {
          json.push({
            companyName: company.companyName || "",
            comment: company.comment || "",
            jobTitle: job.jobTitle || "",
            jobLocation: job.jobLocation || "",
            jobDescription: job.jobDescription || "",
            jobWebsite: job.jobWebsite || "",
            jobStatus: job.status || "",
            contactFirstName: "",
            contactLastName: "",
            contactDesignation: "",
            contactEmail: "",
            contactPhoneNumber: "",
          });
        }
      } else {
        json.push({
          companyName: company.companyName || "",
          comment: company.comment || "",
          jobTitle: "",
          jobLocation: "",
          jobDescription: "",
          jobWebsite: "",
          jobStatus: "",
          contactFirstName: "",
          contactLastName: "",
          contactDesignation: "",
          contactEmail: "",
          contactPhoneNumber: "",
        });
      }
    });
    return json;
  };

  const createExcelZipFile = (excelData) => {
    // Create a new instance of JSZip
    const zip = new JSZip();

    // Add each Excel file to the ZIP archive
    excelData.forEach((data, index) => {
      const currentDate = new Date().toISOString().slice(0, 10);
      const fileName = `${data.bdmName}_${currentDate}.xlsx`;

      // Create a worksheet from the formatted data
      const worksheet = XLSX.utils.json_to_sheet(data.json);

      // Create a new workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "AllData");

      // Generate the file as a binary string
      const workbookBinary = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "binary",
      });

      // Convert the binary string to an array buffer
      const buffer = new ArrayBuffer(workbookBinary.length);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < workbookBinary.length; i++) {
        view[i] = workbookBinary.charCodeAt(i) & 0xff;
      }

      // Add the file to the ZIP archive
      zip.file(fileName, buffer);
    });

    // Generate the ZIP file asynchronously
    zip.generateAsync({ type: "blob" }).then((content) => {
      // Create a download link for the ZIP file
      const currentDate = new Date().toISOString().slice(0, 10);
      const zipFileName = `RATeamData_${currentDate}.zip`;
      const url = window.URL.createObjectURL(content);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", zipFileName);
      document.body.appendChild(link);
      // Trigger the download
      link.click();
      document.body.removeChild(link);
    });
  };
  const handleDownloadAllExcel = () => {
    if (selectedRows.length > 0) {
      const excelData = [];
      selectedRows.forEach((company) => {
        const formattedData = convertToJSON(company);
        excelData.push({ json: formattedData, bdmName: company.bdmName });
      });
      createExcelZipFile(excelData);
    } else {
      if (companiesListWithIds && companiesListWithIds.length > 0) {
        const excelData = [];
        companiesListWithIds.forEach((company) => {
          const formattedData = convertToJSON(company);
          excelData.push({ json: formattedData, bdmName: company.bdmName });
        });
        createExcelZipFile(excelData);
      } else {
        enqueueSnackbar(
          `Today's data is unavailable and cannot be downloaded.`,
          {
            variant: "error",
            action: (key) => (
              <IconButton onClick={() => closeSnackbar()} color="inherit">
                <CloseIcon />
              </IconButton>
            ),
          }
        );
      }
    }
  };

  const handleAllRowDownloadExcel = (rowData) => {
    // Convert the data to the same format as the CSV function
    const formattedData = ALLconvertToJSON(rowData);

    // Create a worksheet from the formatted data
    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "AllData");

    // Generate the file name with the current date
    const currentDate = new Date().toISOString().slice(0, 10);
    const fileName = `AllBDMTeamSheet_${currentDate}.xlsx`;

    // Write the workbook to a file
    XLSX.writeFile(workbook, fileName);
  };

  // Function to convert the data to the same format as the CSV function
  const ALLconvertToJSON = (company) => {
    const headers = [
      "addedByBdmName",
      "companyName",
      "comment",
      "jobTitle",
      "jobLocation",
      "jobDescription",
      "jobWebsite",
      "jobStatus",
      "contactFirstName",
      "contactLastName",
      "contactDesignation",
      "contactEmail",
      "contactPhoneNumber",
    ];

    const json = [];
    company.forEach((data) => {
      data.companies.forEach((company) => {
        if (company.jobDetails && company.jobDetails.length > 0) {
          const job = company.jobDetails[0];
          if (company.contactDetails && company.contactDetails.length > 0) {
            company.contactDetails.forEach((contact) => {
              json.push({
                addedByBdmName: data.bdmName || "",
                companyName: company.companyName || "",
                comment: company.comment || "",
                jobTitle: job.jobTitle || "",
                jobLocation: job.jobLocation || "",
                jobDescription: job.jobDescription || "",
                jobWebsite: job.jobWebsite || "",
                jobStatus: job.status || "",
                contactFirstName: contact.firstName || "",
                contactLastName: contact.lastName || "",
                contactDesignation: contact.designation || "",
                contactEmail: contact.emailId || "",
                contactPhoneNumber: contact.phoneNumber || "",
              });
            });
          } else {
            json.push({
              addedByBdmName: data.bdmName || "",
              companyName: company.companyName || "",
              comment: company.comment || "",
              jobTitle: job.jobTitle || "",
              jobLocation: job.jobLocation || "",
              jobDescription: job.jobDescription || "",
              jobWebsite: job.jobWebsite || "",
              jobStatus: job.status || "",
              contactFirstName: "",
              contactLastName: "",
              contactDesignation: "",
              contactEmail: "",
              contactPhoneNumber: "",
            });
          }
        } else {
          json.push({
            addedByBdmName: data.bdmName || "",
            companyName: company.companyName || "",
            comment: company.comment || "",
            jobTitle: "",
            jobLocation: "",
            jobDescription: "",
            jobWebsite: "",
            jobStatus: "",
            contactFirstName: "",
            contactLastName: "",
            contactDesignation: "",
            contactEmail: "",
            contactPhoneNumber: "",
          });
        }
      });
    });
    return json;
  };

  const handleDownloadAllOneFileXlSX = () => {
    if (companiesListWithIds && companiesListWithIds.length > 0) {
      handleAllRowDownloadExcel(companiesListWithIds);
    } else {
      enqueueSnackbar(`Today's data is unavailable and cannot be downloaded.`, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
  };

  const handleSelectDownloadType = (value) => {
    setSelectDownloadType(value);
  };
  const handleDownload = () => {
    if (selectDownloadType === ".csv") {
      if (clickDownloadType === "downloadAll") {
        handleDownloadAll();
      } else if (clickDownloadType === "downloadAllOneSheet") {
        handleDownloadAllOneFile();
      } else if (clickDownloadType === "actionDownload") {
        handleRowDownload(clickDownloadAction);
      }
    } else if (selectDownloadType === ".xlsx") {
      if (clickDownloadType === "downloadAll") {
        handleDownloadAllExcel();
      } else if (clickDownloadType === "downloadAllOneSheet") {
        handleDownloadAllOneFileXlSX();
      } else if (clickDownloadType === "actionDownload") {
        handleRowDownloadExcel(clickDownloadAction);
      }
    } else if (selectDownloadType === "") {
      enqueueSnackbar(`Please select one atleast one option`, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
  };
  const handleCancelDownload = () => {
    setdownloadModalOpen(!downloadModalOpen);
    setClickDownloadType("");
    setSelectDownloadType("");
    setClickDownloadAction("");
  };

  const [searchFromDate, setSearchFromDate] = useState("");
  const [startingDate, setStartingDate] = useState(
""
  );

  const [searchToDate, setSearchToDate] = useState("");
  const handleDateChange = (selectedDates) => {
    setSearchFromDate("");
    setSearchToDate("");
    setLoading(true);
    if (selectedDates.length === 2) {
      const formattedDates = selectedDates.map((date, index) => {
        return date.toISOString();
      });

      const [fromDate, toDate] = selectedDates;
      setSearchFromDate(formattedDates[0]);
      setSearchToDate(formattedDates[1]);
    
      
      const startDate = new Date(formattedDates[0]);
      const endDate = new Date(formattedDates[1]);

    

      const action = {
        searchFromDate: formattedDates[0],
        searchToDate: formattedDates[1],
        bdmUsers:allUsers && allUsers.filter((user) => user.userType.includes("BDM")),
        client:client
      };
      dispatch(dateSearchBdmsCompaniesViaApi(action));
    }
  };
  
  useEffect(() => {
    
    const today = dayjs().startOf("day");
  
    handleDateChange([ dayjs().tz('America/New_York').startOf('day'),
      dayjs().tz('America/New_York').endOf('day')]);
  }, []);
  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      setLoading(false);

      // Set a timer to stop loading after 5 seconds
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      // Cleanup timer on unmount
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle="BDM Job Reports">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              gap: 3,
              mb: 3,
            }}
          >
            <Box flex={1}></Box>

            <Box sx={{ display: "flex" }}>
             
              <Box sx={{ mb: 2 }}>
                <BDMCustomeDateFilter handleDateChange={handleDateChange} />
              </Box>
            </Box>
          </Box>
          {loading?(
            <>
            <ShimmerTable row={5} col={5} />
            </>
          ):(
            <>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <Widget
                title="Total Companies"
                value={AllCompaniesList.length}
                icon={<BusinessIcon />}
                color="primary"
              />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <Widget
                title="Total Job Open"
                value={
                  AllCompaniesList
                    ? AllCompaniesList.reduce((count, company) => {
                        return (company.jobDetails &&
                          company.jobDetails.length > 0 &&
                          company.jobDetails[0].status === "Active") ||
                          company.jobDetails[0].status === "Open"
                          ? count + 1
                          : count;
                      }, 0)
                    : 0
                }
                icon={<BusinessIcon />}
                color="primary"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Widget
                title="Total Job Close"
                value={
                  AllCompaniesList
                    ? AllCompaniesList.reduce((count, company) => {
                        return company.jobDetails &&
                          company.jobDetails.length > 0 &&
                          company.jobDetails[0].status === "Close"
                          ? count + 1
                          : count;
                      }, 0)
                    : 0
                }
                icon={<BusinessIcon />}
                color="success"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Widget
                title="Total Job Hold"
                value={
                  AllCompaniesList
                    ? AllCompaniesList.reduce((count, company) => {
                        return company.jobDetails &&
                          company.jobDetails.length > 0 &&
                          company.jobDetails[0].status === "Hold"
                          ? count + 1
                          : count;
                      }, 0)
                    : 0
                }
                icon={<BusinessIcon />}
                color="success"
              />
            </Grid>
          </Grid>

          <Box sx={{ width: "100%", overflowX: "auto" }}>
            <DataGrid
              rows={companiesListWithIds}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 100 },
                },
              }}
              // checkboxSelection
              //disableColumnMenu // Add this line to hide the column menu
              //onCellClick={handleCellClick} // Handle cell click event
              autoHeight
              columnBuffer={companiesListWithIds.length} // Set columnBuffer to the number of columns
              disableRowSelectionOnClick
              onRowSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = companiesListWithIds.filter((row) =>
                  selectedIDs.has(row.id)
                );
                setSelectedRows(selectedRows);
              }}
            />
          </Box>
          </>)
             }
          

        </LayoutSidebarMenu>
      </SnackbarProvider>
      <Modal
        open={downloadModalOpen}
        //onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: {
              xs: 1,
              md: 2,
            },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: 1,
              rowGap: 0.5,
              flexWrap: "wrap",

              mb: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={"400"}>
              Select your preferred download format
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              variant="contained"
              color={selectDownloadType === ".xlsx" ? "error" : "inherit"}
              sx={{ textTransform: "inherit" }}
              onClick={() => handleSelectDownloadType(".xlsx")}
            >
              .xlsx
            </Button>
            <Button
              variant="contained"
              color={selectDownloadType === ".csv" ? "error" : "inherit"}
              sx={{ textTransform: "inherit" }}
              onClick={() => handleSelectDownloadType(".csv")}
            >
              .csv
            </Button>
          </Box>
          <Divider />
          <Box sx={{ display: "flex", gap: 2, justifyContent: "end", mt: 2 }}>
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleCancelDownload()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleDownload()}
            >
              Download
            </Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default BDMJobReports;
