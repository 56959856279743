import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
const CustomModal = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Box>
        <Button variant="outlined" onClick={handleOpen}>
          Modal
        </Button>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "800px",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Company Name
          </Typography>
          <Box sx={{ display: "flex", gap: 1, flexDirection: "column", mt: 3 }}>
            <Typography sx={{ fontWeight: 600 }}>Job Details</Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              <TextField
                variant="outlined"
                label="Job Title"
                size="small"
                fullWidth
              />
              <TextField
                variant="outlined"
                label="Job Location"
                size="small"
                fullWidth
              />
              <TextField
                variant="outlined"
                label="Job Description"
                size="small"
                fullWidth
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: 1, flexDirection: "column", mt: 3 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>Contacts</Typography>
              <Button variant="text" size="small" startIcon={<AddIcon />}>
                Add More Contacts
              </Button>
            </Box>

            <Box sx={{ display: "flex", gap: 2 }}>
              <TextField
                variant="outlined"
                label="First Name"
                size="small"
                fullWidth
              />
              <TextField
                variant="outlined"
                label="Last Name"
                size="small"
                fullWidth
              />
              <TextField
                variant="outlined"
                label="Designation"
                size="small"
                fullWidth
              />
              <TextField
                variant="outlined"
                label="Email Id"
                size="small"
                fullWidth
              />
            </Box>
          </Box>
          <Button variant="contained" sx={{ mt: 2 }}>
            Add Details
          </Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default CustomModal;
