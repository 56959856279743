import React, { useState, useEffect } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import {
  Box,
  Button,
  Divider,
  Modal,
  TextField,
  Typography,
} from "@mui/material";

import theme from "../../Theme";
// import data from "../../data/ats.companydatas.json";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

import { useSelector, useDispatch } from "react-redux";
import { SnackbarProvider, enqueueSnackbar, useSnackbar } from "notistack";
import { SnackbarComponent } from "../../Components";
import moment from "moment-timezone";
import { getAllUsers } from "../../store/auth/login/actions";
import {
  changeStatusViaApi,
  checkCompanyName,
  searchDataViaApi,
  statusChangeViaApi,
} from "../../store/search/action";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  companyBlockedViaApi,
  companyDNCViaApi,
  employeeBlockedViaApi,
  employeeDNCViaApi,
  noFallowUpsViaApi,
} from "../../store/companiesList/action";
const UpdateCompanyStatus = () => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [dataShow, setDataShow] = useState("");
  const [queryName, setQuryName] = useState("");
  const searchResultsData =
    useSelector((state) => state.Search.searchData) || [];
  const checkCompany = useSelector((state) => state.Search.checkCompany);
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const storeData = useSelector((state) => state.Login.storeData);
  const client=storeData.clientDb
  const handleSearch = () => {
    if (query.length > 0) {
      // Remove extra spaces between words
      dispatch(checkCompanyName(false));
      const cleanedQuery = query.replace(/\s+/g, " ").trim();
      setQuryName(cleanedQuery);
      dispatch(searchDataViaApi(cleanedQuery));
      // dispatch(statusChangeViaApi(cleanedQuery));

      setDataShow(cleanedQuery);
    }
  };

  useEffect(() => {
    const action={
      client:client
    }
    dispatch(getAllUsers(action));
  }, []);
  const latestResult = searchResultsData.sort(
    (a, b) => new Date(b.addedOn) - new Date(a.addedOn)
  )[0];

  const handleKeyPress = (e) => {
    // e.preventDefault()
    if (e.key === "Enter") {
      if (query.length === 0) {
        // If reduxPlaceOrder is not empty, return or show a message
        return;
      }
      handleSearch();
    }
  };

  const calculateDays = (providedDate) => {
    const timeZone = "Asia/Kolkata";

    // Convert provided date to IST
    const providedDateObj = moment.tz(providedDate, timeZone).startOf("day");

    // Get today's date in IST
    const todayDateObj = moment.tz(timeZone).startOf("day");

    // Calculate the difference in milliseconds between the provided date and today's date
    const differenceInMilliseconds =
      todayDateObj.valueOf() - providedDateObj.valueOf();

    // Convert milliseconds to days
    const daysDifference = Math.round(
      differenceInMilliseconds / (1000 * 60 * 60 * 24)
    );

    return daysDifference;
  };

  const sanitizeString = (str) => {
    if (str) {
      return str.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
    }
    return ""; // Return an empty string if str is undefined or null
  };

  const [changeStatus, setChangeStatus] = useState("");
  const [statusModal, setStatusModal] = useState(false);
  const handleClose = () => {
    setStatusModal(!statusModal);
  };
  const handleChangeStatusModal = (status) => {
    setChangeStatus(status);
    setStatusModal(!statusModal);
  };
  function detectTextType(inputText) {
    // Regular expressions for email and domain detection
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const domainRegex = /[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;

    // Check if input matches email regex
    if (emailRegex.test(inputText)) {
      return "Email";
    }

    // Check if input matches domain regex
    if (domainRegex.test(inputText)) {
      return "Domain";
    }

    // Assuming anything else could be a company name in this basic example
    return "Company Name";
  }
  const extractDomain = (email) => {
    const domainMatch = email.match(/@(.+)$/);
    return domainMatch
      ? domainMatch[1].toLowerCase()
      : email.toLowerCase().replace(/^[^@]*@?/, "");
  };
  const checkQueryType = detectTextType(queryName);
  const handleChangeStatus = () => {
    const dateTime = new Date();
    let today = new Date();
    let expiryDate = new Date(today);
    expiryDate.setMonth(expiryDate.getMonth() + 6);
    let isoExpiryDate = expiryDate.toISOString();

    const textType = detectTextType(queryName);
    let takeDomainName = "";

    if (Array.isArray(latestResult.contactDetails)) {
      latestResult.contactDetails.forEach((contact) => {
        if (contact.emailId) {
          takeDomainName = extractDomain(contact.emailId.trim().toLowerCase());
        }
      });
    }

    if (textType === "Email") {
      const action = {
        companyName: latestResult.companyName,
        domain: takeDomainName ? takeDomainName : "",
        addedBy: storeData._id,
        addedOn: dateTime,
        status: changeStatus ? changeStatus.trim() : "",
        emailId: query ? query.trim().toLowerCase() : "",
        expiryDate: isoExpiryDate,
      };

      if (changeStatus === "DNC") {
        dispatch(employeeDNCViaApi(action));
      } else if (changeStatus === "Blocked") {
        dispatch(employeeBlockedViaApi(action));
      } else if (changeStatus === "No FallowUp") {
        today = new Date();
        expiryDate = new Date(today);
        expiryDate.setDate(expiryDate.getDate() + 18);
        isoExpiryDate = expiryDate.toISOString();
        action.expiryDate = isoExpiryDate;
        dispatch(noFallowUpsViaApi(action));
      }
    } else {
      if (Array.isArray(latestResult.contactDetails)) {
        latestResult.contactDetails.forEach((contact) => {
          if (contact.emailId) {
            const action = {
              companyName: latestResult.companyName,
              domain: extractDomain(contact.emailId.trim().toLowerCase()),
              addedBy: storeData._id,
              addedOn: dateTime,
              status: changeStatus ? changeStatus.trim() : "",
              emailId: contact.emailId.trim().toLowerCase(),
            };
            takeDomainName = contact.emailId.trim().toLowerCase();
            if (changeStatus === "DNC") {
              action.expiryDate = isoExpiryDate;
              dispatch(employeeDNCViaApi(action));
            } else if (changeStatus === "Blocked") {
              dispatch(employeeBlockedViaApi(action));
            }
          } else {
            console.log("contact.emailId is null or empty");
          }
        });
      } else {
        console.log("latestResult.contactDetails is not an array");
      }

      const action = {
        companyName: latestResult.companyName,
        domain: takeDomainName ? extractDomain(takeDomainName) : "",
        addedBy: storeData._id,
        addedOn: dateTime,
        status: changeStatus ? changeStatus.trim() : "",
      };

      if (changeStatus === "DNC") {
        action.expiryDate = isoExpiryDate;
        dispatch(companyDNCViaApi(action));
      } else if (changeStatus === "Blocked") {
        dispatch(companyBlockedViaApi(action));
      } else if (changeStatus === "No FallowUp") {
        today = new Date();
        expiryDate = new Date(today);
        expiryDate.setDate(expiryDate.getDate() + 18);
        isoExpiryDate = expiryDate.toISOString();
        action.expiryDate = isoExpiryDate;
        dispatch(noFallowUpsViaApi(action));
      }
    }

    // Reset state and toggle modal
    setQuery("");
    setDataShow("");
    setStatusModal(!statusModal);
  };

  const handleCheckEmailID = (latestResult, query) => {
    if (latestResult && latestResult.contactDetails) {
      // Loop through each contact detail
      for (let contact of latestResult.contactDetails) {
        // Check if the sanitized email ID matches the sanitized query
        if (sanitizeString(contact.emailId) === sanitizeString(query)) {
          // If match found, return true
          return true;
        }
      }
    }
    // If no match found, return false
    return false;
  };
  let checkEMailStatus=''
  let checkEMailName=''
  const handleCheckEmailIDStatus = (latestResult, query) => {
    if (latestResult && latestResult.contactDetails) {
      // Loop through each contact detail
      for (let contact of latestResult.contactDetails) {
        // Check if the sanitized email ID matches the sanitized query
        if (sanitizeString(contact.emailId) === sanitizeString(query)) {
          const statusCheck = contact.status ? contact.status : "Active";
          if ((statusCheck === "DNC" || statusCheck === "Blocked")&&latestResult.status==='Active') {
            // If match found, return true
            checkEMailStatus=statusCheck;
            checkEMailName=contact.emailId;
            return true;
          }
        }
      }
    }
    // If no match found, return false
    return false;
  };

  // Function to check if any email ID domain matches the query domain
  const handleCheckEmailDomain = (latestResult, query) => {
    if (latestResult && latestResult.contactDetails && query) {
      for (let contact of latestResult.contactDetails) {
        const emailDomain = extractDomain(contact.emailId);
        // Check if the domain of the email matches the query domain
        if (emailDomain.toLowerCase() === query.trim().toLowerCase()) {
          return true;
        }
      }
    }

    // If no match found, return false
    return false;
  };
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Company Status"}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              gap: "15px",
              height: "100%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: "600px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <TextField
                label="Search by Company Name / Domain / Email-ID"
                fullWidth
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={handleKeyPress}
              />
              <Button variant="contained" size="large" onClick={handleSearch}>
                Submit
              </Button>
            </Box>

            {dataShow && sanitizeString(dataShow) === sanitizeString(query) && (
              <Box>
                {searchResultsData.length === 0 && checkCompany ? (
                  <Box>
                    {sanitizeString(query) === sanitizeString(queryName) && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <CheckCircleOutlineIcon
                          sx={{ color: theme.palette.success.dark }}
                        />
                        <Typography
                          sx={{
                            fontWeight: 600,
                            color: theme.palette.success.dark,
                          }}
                        >
                          {query}
                        </Typography>
                        <Typography> does not exist. </Typography>
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {latestResult &&
                      (sanitizeString(latestResult.companyName) ===
                        sanitizeString(query) ||
                        sanitizeString(latestResult.domain) ===
                          sanitizeString(query) ||
                        sanitizeString(latestResult.domain) ===
                          sanitizeString(extractDomain(query)) ||
                        handleCheckEmailID(latestResult, query) ||
                        handleCheckEmailDomain(latestResult, query)) && (
                        <Box>
                          {latestResult.status === "Active" && (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 1,
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="h6"
                                sx={{
                                  fontWeight: 700,
                                  color: theme.palette.secondary.dark,

                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                <CheckCircleOutlinedIcon />

                                {latestResult.companyName}
                              </Typography>
                              <Typography> is Active.</Typography>
                            </Box>
                          )}
                          {latestResult.status === "Active"&&handleCheckEmailIDStatus(latestResult, query) && (
                            <>
                           
                             {checkEMailStatus==='DNC'&&(
                              <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 1,
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="h6"
                                sx={{
                                  fontWeight: 700,
                                  color: theme.palette.warning.main,
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                <WarningOutlinedIcon />
                                {checkEMailName}
                              </Typography>

                              <Typography> is under DNC.</Typography>
                            </Box>
                             )}
                             {checkEMailStatus === "Blocked" && (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 1,
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="h6"
                                sx={{
                                  fontWeight: 700,
                                  color: theme.palette.error.dark,
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                <RemoveCircleIcon />
                                {checkEMailName}
                              </Typography>

                              <Typography> is Blocked.</Typography>
                            </Box>
                          )}
                            </>
                          )}

                          {latestResult.status === "DNC" && (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 1,
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="h6"
                                sx={{
                                  fontWeight: 700,
                                  color: theme.palette.warning.main,
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                <WarningOutlinedIcon />
                                {latestResult.companyName}
                              </Typography>

                              <Typography> is under DNC.</Typography>
                            </Box>
                          )}

                          {latestResult.status === "Blocked" && (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 1,
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="h6"
                                sx={{
                                  fontWeight: 700,
                                  color: theme.palette.error.dark,
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                <RemoveCircleIcon />
                                {latestResult.companyName}
                              </Typography>

                              <Typography> is Blocked.</Typography>
                            </Box>
                          )}
                          {latestResult.status === "On Process" && (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 1,
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="h6"
                                sx={{
                                  fontWeight: 700,
                                  color: theme.palette.success.dark,
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                <TrendingUpIcon />
                                {latestResult.companyName}
                              </Typography>

                              <Typography> is On Process.</Typography>
                            </Box>
                          )}
                          {latestResult.status === "No FallowUp" && (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 1,
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="h6"
                                sx={{
                                  fontWeight: 700,
                                  color: theme.palette.warning.main,
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                <WarningOutlinedIcon />
                                {latestResult.companyName}
                              </Typography>

                              <Typography> is under No FallowUp.</Typography>
                            </Box>
                          )}
                          {latestResult.status === "Active"&&!handleCheckEmailIDStatus(latestResult, query) && (
                            <Box
                              sx={{
                                width: "100%",
                                maxWidth: "1200px",
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "center",
                                gap: 2,
                              }}
                            >
                              <Button
                                variant="contained"
                                color="warning"
                                startIcon={<WarningOutlinedIcon />}
                                size="large"
                                onClick={() => handleChangeStatusModal("DNC")}
                              >
                                DNC (Do not Contact)
                              </Button>
                              <Button
                                variant="contained"
                                color="error"
                                startIcon={<RemoveCircleIcon />}
                                size="large"
                                onClick={() =>
                                  handleChangeStatusModal("Blocked")
                                }
                              >
                                Block
                              </Button>
                              <Button
                                variant="contained"
                                color="warning"
                                startIcon={<WarningOutlinedIcon />}
                                size="large"
                                onClick={() =>
                                  handleChangeStatusModal("No FallowUp")
                                }
                              >
                                {/* No FallowUp */}
                                UnSubscribe
                              </Button>
                              {/* <Button
                              variant="contained"
                              color="secondary"
                              startIcon={<TrendingUpIcon />}
                              size="large"
                              onClick={()=>handleChangeStatusModal('On Process')}

                            >
                              On Process
                            </Button> */}
                            </Box>
                          )}
                        </Box>
                      )}
                  </Box>
                )}
              </Box>
            )}
          </Box>
          {/* delete Contact */}
          {/**mahender update becuase delete contact 24-05-2024 */}
          <Modal
            open={statusModal}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxWidth: "400px",
                width: "80%",
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: {
                  xs: 1,
                  md: 2,
                },
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  columnGap: 1,
                  rowGap: 0.5,
                  flexWrap: "wrap",

                  mb: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography fontWeight={"400"}>
                  
                  {/* Are you sure you want to change the status of the{" "}
                  {checkQueryType} to {changeStatus} */}
                  {changeStatus==='No FallowUp'?(
                    <>
                    Are you sure you want to remove this {checkQueryType} from the follow-up list?
                    </>
                  ):(
                    <>
                    Are you sure you want to change the status of the{" "}
                    {checkQueryType} to {changeStatus}
                    </>
                  )}
                  
                </Typography>
              </Box>
              {changeStatus === "DNC" && (
                <>
                  {checkQueryType === "Company Name" ? (
                    <Box sx={{ color: "error.main", pb: 2 }}>
                      Note : The entire company will be marked as Do Not Contact
                      for 6 months{" "}
                    </Box>
                  ) : checkQueryType === "Domain" ? (
                    <Box sx={{ color: "red" }}>
                      Note : The entire company associated with this domain will
                      be marked as Do Not Contact for 6 months.
                    </Box>
                  ) : checkQueryType === "Email" ? (
                    <Box sx={{ color: "red" }}>
                      Note :  Only this email address will be permanently blocked, while the entire company will remain active.{" "}
                    </Box>
                  ) : (
                    ""
                  )}
                </>
              )}
              {changeStatus === "Blocked" && (
                <>
                  {checkQueryType === "Company Name" ? (
                    <Box sx={{ color: "error.main", pb: 2 }}>
                      Note : The entire company will be permanently blocked.{" "}
                    </Box>
                  ) : checkQueryType === "Domain" ? (
                    <Box sx={{ color: "red" }}>
                      Note : The entire company associated with this domain will
                      be permanently blocked.
                    </Box>
                  ) : checkQueryType === "Email" ? (
                    <Box sx={{ color: "red" }}>
                      Note : Only this email address will be permanently
                      blocked, while the entire company will remain active.{" "}
                    </Box>
                  ) : (
                    ""
                  )}
                </>
              )}
              {changeStatus === "No FallowUp" && (
                <>
                  {checkQueryType === "Company Name" ? (
                    <Box sx={{ color: "error.main", pb: 2 }}>
                      Note : The entire company will not be followed-up for 18
                      days.{" "}
                    </Box>
                  ) : checkQueryType === "Domain" ? (
                    <Box sx={{ color: "red" }}>
                      Note : The entire company associated with this domain will
                      not be followed-up for 18 days.
                    </Box>
                  ) : checkQueryType === "Email" ? (
                    <Box sx={{ color: "red" }}>
                      Note :The entire company will not be followed-up for 18
                      days.{" "}
                    </Box>
                  ) : (
                    ""
                  )}
                </>
              )}

              <Divider />
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  justifyContent: "center",
                  mt: 2,
                }}
              >
                <Button
                  color="inherit"
                  sx={{ textTransform: "inherit" }}
                  onClick={() => handleClose()}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  sx={{ textTransform: "inherit" }}
                  onClick={() => handleChangeStatus()}
                >
                  Change
                </Button>
              </Box>
            </Box>
          </Modal>
          <SnackbarComponent />
        </LayoutSidebarMenu>
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default UpdateCompanyStatus;
