import { Box, Typography } from "@mui/material";
import React from "react";

import slide01 from "../../Assets/Images/loginSlider/bulding-01.jpg";
import slide02 from "../../Assets/Images/loginSlider/bulding-02.jpg";

const LoginSlider = () => {
  const Slider = [
    {
      text: "Some Text",
      image: slide01,
    },
    {
      text: "Some Text 2",
      image: slide02,
    },
  ];

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          color: "white",
          backgroundImage: `url(${slide01})`, // Fix: use url() to specify the image
          backgroundSize: "cover", // Set background size as needed
          backgroundPosition: "center", // Set background position as needed
        }}
      ></Box>
    </React.Fragment>
  );
};

export default LoginSlider;
