import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import theme from "../../../Theme";
import LinearScaleOutlinedIcon from "@mui/icons-material/LinearScaleOutlined";
import Backgrounds from "../../Backgrounds/Backgrounds";

const DefaultLayout = ({
  title,
  value,
  icon,
  color,
  variant,
  shadow,
  bottomDetails,
  invert,
}) => {
  return (
    <React.Fragment>
      <Backgrounds shadow={shadow} variant={variant} color={color}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1.5,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color:
                variant === "contained"
                  ? theme.palette[color].contrastText
                  : theme.palette.grey[400],
            }}
          >
            {title}
          </Typography>
          <IconButton size="small">
            <LinearScaleOutlinedIcon
              sx={{
                fontSize: "10px",
                color:
                  variant === "contained"
                    ? theme.palette[color].contrastText
                    : theme.palette.grey[400],
              }}
            />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                color:
                  variant === "contained"
                    ? theme.palette[color].contrastText
                    : theme.palette.text[900],
                fontSize: 40,
                fontWeight: "bold",
                lineHeight: 1,
              }}
            >
              {value}
            </Typography>
          </Box>
          <Box>
            <Box
              sx={{
                bgcolor:
                  variant === "contained"
                    ? theme.palette[color].contrastText
                    : theme.palette.grey[200],
                width: 60,
                height: 60,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 60,
              }}
            >
              {React.cloneElement(icon, {
                sx: { fontSize: 35, color: theme.palette[color].main },
              })}
              {/* <SignalWifi4BarLockOutlinedIcon fontSize="large" /> */}
            </Box>
          </Box>
        </Box>
      </Backgrounds>
    </React.Fragment>
  );
};

export default DefaultLayout;
