import React from "react";
import { Box } from "@mui/material";
import theme from "../../Theme";

const ScrollView = ({ children, height = 100 }) => {
  return (
    <Box
      sx={{
        height: "100%",
        minHeight: "100%",
        maxHeight: height,
        display: "flex",
        flexDirection: "column",
        flex: "1",
        overflowY: "auto",
        overflowX: "hidden",

        "&::-webkit-scrollbar": {
          width: "8px", // width of the scrollbar
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#f1f1f1", // color of the track
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#888", // color of the thumb
          borderRadius: "4px", // roundness of the thumb
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#555", // color of the thumb on hover
        },
      }}
    >
      <Box sx={{ height: "100%" }}>{children}</Box>
    </Box>
  );
};

export default ScrollView;
