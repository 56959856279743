import { SnackbarProvider } from "notistack";
import React, { useState, useEffect } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import { PageHeader, SnackbarComponent, Widget } from "../../Components";
import { Box, Button, Grid } from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  dateSearchUsersCompaniesViaApi,
  getShuffledDataViaApi,
} from "../../store/search/action";
import { getAllUsers } from "../../store/auth/login/actions";
import { getSentEmailsViaApi } from "../../store/companiesList/action";
dayjs.extend(require("dayjs/plugin/timezone"));
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.tz.setDefault("Asia/Kolkata");
const BulkEmail = () => {
  const dispatch = useDispatch();

  const AllCompaniesList =
    useSelector((state) => state.Search.AllDateSearchComapniesList) || [];
  const AllSentEmails =
    useSelector((state) => state.CompaniesList.sentEmails) || [];
  const storeData = useSelector((state) => state.Login.storeData) || {};
  const client=storeData.clientDb
  const shuffledDataRedux = useSelector((state) => state.Search.shuffled) || [];
  const [shuffledData, setShuffledData] = useState([]);
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];

useEffect(() => {
  if (shuffledDataRedux.length > 0 && allUsers.length > 0) {
    const latestShuffledData = shuffledDataRedux[0]?.shuffled;
    if (latestShuffledData && latestShuffledData.length > 0) {
      const latestData = latestShuffledData[latestShuffledData.length - 1];
      setShuffledData([latestData]);
    }
  }
}, [shuffledDataRedux, allUsers]);
  const takenAssignedData = shuffledData.flatMap((shufflesData) => {
    return (shufflesData.shuffledData || [])
      .filter((data) => data.BdmId === storeData._id)
      .flatMap((filteredData) => filteredData.assignUsers || []);
  });

  let totalCompanies = 0;
  let totalEmails = 0;
  let sentEmailsCount = 0;

  takenAssignedData.forEach((assignUser) => {
    AllCompaniesList.forEach((company) => {
      if (company.addedBy === assignUser) {
        totalCompanies++;
        (company.contactDetails || []).forEach((contact) => {
          if (contact.emailId) {
            totalEmails++;
          }
        });
      }
    });
  });
  
  

  AllSentEmails.forEach((email) => {
    if (
      email.fromEmail === storeData.userEmailId ||
      email.fromEmail === storeData.userSudoEmailId
    ) {
      sentEmailsCount++;
    }
  });

  useEffect(() => {
    const action={
      client:client
    }
    dispatch(getAllUsers(action));
    
    dispatch(getShuffledDataViaApi(action));
  }, [dispatch]);

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      const formattedDates = selectedDates.map((date) => date.toISOString());

      const action = {
        searchFromDate: formattedDates[0],
        searchToDate: formattedDates[1],
        client:client,
      };

      dispatch(dateSearchUsersCompaniesViaApi(action));
      dispatch(getSentEmailsViaApi(action));
    }
  };

  useEffect(() => {
    const today = dayjs().tz("Asia/Kolkata").startOf("day");
    handleDateChange([today, today.endOf("day")]);
  }, []);

  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Bulk Email"}>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <Widget
                title="Emails assigned today"
                value={totalEmails}
                icon={<BusinessIcon />}
                color="primary"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Widget
                title="Emails sent today"
                value={sentEmailsCount}
                icon={<BusinessIcon />}
                color="primary"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Widget
                title="Total Companies"
                value={totalCompanies}
                icon={<BusinessIcon />}
                color="primary"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Widget
                title="Total emails delivered"
                value={0}
                icon={<BusinessIcon />}
                color="primary"
              />
            </Grid>
          </Grid>

          <Box>
            <Button
              variant="contained"
              LinkComponent={Link}
              to={"/manualEmail"}
            >
              Manual Email
            </Button>
          </Box>
          <SnackbarComponent/>
        </LayoutSidebarMenu>
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default BulkEmail;
