import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DateCalendar } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Import the utc plugin
import timezone from "dayjs/plugin/timezone"; // Import the timezone plugin

// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/New_York"); // Set default timezone to America/New_York

const BDMCustomDateFilter = ({ handleDateChange, onlyTitle,tillDate }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(tillDate?tillDate:"Today");
  const [displayDate, setDisplayDate] = useState(
    tillDate?`${dayjs("2024-01-01").endOf("day").tz("America/New_York").format("DD/MMMM, YYYY")} - ${dayjs().tz("America/New_York").format("DD/MMMM, YYYY")}`: dayjs().tz("America/New_York").format("MMMM DD, YYYY")
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const open = Boolean(anchorEl);

  // Function to close the menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function to handle click on the calendar button
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to get date range based on selected option
  const getDateBasedOnOption = (option) => {
    switch (option) {
      case "Today":
        handleDateChange([
          dayjs().tz("America/New_York").startOf("day"),
          dayjs().tz("America/New_York").endOf("day"),
        ]);
        return dayjs().tz("America/New_York").format("DD/MMMM, YYYY");
      case "Yesterday":
        handleDateChange([
          dayjs().tz("America/New_York").subtract(1, "day").startOf("day"),
          dayjs().tz("America/New_York").subtract(1, "day").endOf("day"),
        ]);
        return dayjs()
          .tz("America/New_York")
          .subtract(1, "day")
          .startOf("day")
          .format("DD/MMMM, YYYY");
      case "Last Week":
        const today = dayjs().tz("America/New_York");
        const lastMonday = today
          .subtract(1, "week")
          .startOf("day")
          .subtract(today.day() - 1, "days");
        const lastFriday = today
          .subtract(1, "week")
          .endOf("day")
          .subtract(today.day() - 5, "days");
        handleDateChange([lastMonday.startOf("day"), lastFriday.endOf("day")]);
        return `${lastMonday.format("DD/MMMM")} - ${lastFriday.format(
          "DD/MMMM, YYYY"
        )}`;

      case "This Month":
        const startOfMonth = dayjs()
          .tz("America/New_York")
          .startOf("month")
          .format("DD/MMMM");
        const todayDate = dayjs()
          .tz("America/New_York")
          .format("DD/MMMM, YYYY");
        handleDateChange([
          dayjs().tz("America/New_York").startOf("month").startOf("day"),
          dayjs().tz("America/New_York").endOf("day"),
        ]);
        return `${startOfMonth} - ${todayDate}`;
      case "Last Month":
        const startOfLastMonth = dayjs()
          .tz("America/New_York")
          .subtract(1, "month")
          .startOf("month")
          .format("DD/MMMM");
        const endOfLastMonth = dayjs()
          .tz("America/New_York")
          .subtract(1, "month")
          .endOf("month")
          .format("DD/MMMM, YYYY");
        handleDateChange([
          dayjs()
            .tz("America/New_York")
            .subtract(1, "month")
            .startOf("month")
            .startOf("day"),
          dayjs()
            .tz("America/New_York")
            .subtract(1, "month")
            .endOf("month")
            .endOf("day"),
        ]);
        return `${startOfLastMonth} - ${endOfLastMonth}`;
      case "Last 30 Days":
        const startDateLast30Days = dayjs()
          .tz("America/New_York")
          .subtract(30, "days")
          .format("DD/MMMM");
        const endDateLast30Days = dayjs()
          .tz("America/New_York")
          .format("DD/MMMM, YYYY");
        handleDateChange([
          dayjs().tz("America/New_York").subtract(30, "days").startOf("day"),
          dayjs().tz("America/New_York").endOf("day"),
        ]);
        return `${startDateLast30Days} - ${endDateLast30Days}`;
        case "Till Date":
          const appStartDate = dayjs("2024-01-01").tz("America/New_York");
          const endDateTillDate = dayjs().tz("America/New_York").format("DD/MMMM, YYYY");
          handleDateChange([
            appStartDate.startOf("day"),
            dayjs().tz("America/New_York").endOf("day"),
          ]);
          return `${appStartDate.format("DD/MMMM, YYYY")} - ${endDateTillDate}`;
      // Add cases for other options similarly
      default:
        return dayjs().tz("America/New_York").format("DD/MMMM, YYYY");
    }
  };
  // Function to handle menu item click
  const handleMenuItemClick = (option) => {
    setSelectedOption(option);
    if (option === "Custom Date") {
      openCustomDateModal();
    } else {
      setDisplayDate(getDateBasedOnOption(option));
      handleClose();
    }
  };
  // Function to open custom date modal
  const openCustomDateModal = () => {
    setIsModalOpen(true);
  };

  // Function to handle modal close
  const handleModalClose = () => {
    setIsModalOpen(false);
    // Reset to Today's date when closing the modal without saving
    setDisplayDate(dayjs().tz("America/New_York").format("DD/MMMM, YYYY"));
    setSelectedOption("Today");
  };

  // Function to handle saving custom date
  const handleSaveCustomDate = () => {
    const startDate = customStartDate
      ? dayjs(customStartDate).format("DD/MMMM, YYYY")
      : "";
    const endDate = customEndDate
      ? dayjs(customEndDate).format("DD/MMMM, YYYY")
      : "";
    setDisplayDate(`${startDate} - ${endDate}`);
    setIsModalOpen(false);

    handleDateChange([
      dayjs(customStartDate).startOf("day"),
      customEndDate ? dayjs(customEndDate).endOf("day") : dayjs().endOf("day"),
    ]);
  };

  // Effect to handle outside click to close menu
  // Close the menu if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (anchorEl && anchorEl.contains && !anchorEl.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [anchorEl]);

  // Menu options
  const menuOptions = [
    "Today",
    "Yesterday",
    "Last Week",
    "This Month",
    "Last Month",
    "Last 30 Days",
    "Till Date",
    "Custom Date",
  ];
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ flex: 1 }}></Box>
        <Box>
          {/* <Button onClick={handleClick} startIcon={<CalendarMonthIcon />}> */}

          {/* <Button onClick={handleClick} startIcon={!onlyTitle && <CalendarMonthIcon />}>
            <Typography sx={{ fontSize: "inherit", fontWeight: 600 }}>
              {selectedOption}
            </Typography>
            {!onlyTitle && - displayDate}
          </Button> */}
          <Button
            onClick={handleClick}
            startIcon={!onlyTitle && <CalendarMonthIcon />}
          >
            <Typography sx={{ fontSize: "inherit", fontWeight: 600 }}>
              {selectedOption}
            </Typography>
            {!onlyTitle && ` - ${displayDate}`}
          </Button>
          
        </Box>
      </Box>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose} // <-- Use handleClose to close the menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        hideBackdrop={true}
      >
        {menuOptions.map((option, index) => (
          <MenuItem
            key={index}
            selected={option === selectedOption}
            onClick={() => handleMenuItemClick(option)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>

      <Dialog
        open={isModalOpen}
        onClose={handleModalClose}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>Select Custom Date Range</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DateCalendar
                label="Start Date"
                value={customStartDate}
                onChange={(date) =>
                  setCustomStartDate(
                    dayjs(date).tz("America/New_York").startOf("day")
                  )
                }
                disableFuture
              />
            </Grid>
            <Grid item xs={6}>
              <DateCalendar
                label="End Date"
                value={customEndDate}
                onChange={(date) =>
                  setCustomEndDate(
                    dayjs(date).tz("America/New_York").endOf("day")
                  )
                }
                disabled={!customStartDate}
                minDate={customStartDate}
                maxDate={dayjs()}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose}>Cancel</Button>
          <Button onClick={handleSaveCustomDate}>Save</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default BDMCustomDateFilter;
