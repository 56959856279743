import {
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import theme from "../../Theme";
import CloseIcon from "@mui/icons-material/Close";
import { ContactDetailForm } from "..";

const FormRow = ({ children }) => {
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>{children}</Box>
    </React.Fragment>
  );
};
const FormCell = ({ children, width = 172, flex = 1 }) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          flex: flex,
          flexBasis: width,
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
};

const JobDetailForm = () => {
  return (
    <React.Fragment>
      <Box sx={{ my: 1 }}>
        <Typography sx={{ fontWeight: "bold" }}>Job Details</Typography>
      </Box>
      <FormRow>
        <FormCell>
          <TextField
            variant="standard"
            label="Job Title"
            placeholder="Application Engineer"
            size="small"
            fullWidth
          />
        </FormCell>

        <FormCell>
          <TextField
            variant="standard"
            label="Job Location"
            placeholder="Location, State"
            size="small"
            fullWidth
          />
        </FormCell>
        <FormCell>
          <TextField
            variant="standard"
            label="Job Description"
            placeholder="URL"
            size="small"
            fullWidth
          />
        </FormCell>
        <FormCell>
          <TextField
            variant="standard"
            label="Website"
            placeholder="https://tektreeinc.com"
            size="small"
            fullWidth
          />
        </FormCell>
      </FormRow>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          gap: 2,
          pt: 4,
        }}
      >
        <Button>Add later</Button>
        <Button variant="contained">Save Job Details </Button>
      </Box>
    </React.Fragment>
  );
};

export default JobDetailForm;
