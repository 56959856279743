import React, { useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import {
  Box,
  Chip,
  Stack,
  Paper,
  Avatar,
  Typography,
  Divider,
} from "@mui/material";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import theme from "../../Theme";

// Define item types for react-dnd
const ItemType = {
  CHIP: "chip",
};

// Draggable Chip component
const DraggableChip = ({
  item,
  index,
  rowId,
  column,
  moveItem,
  color = "primary",
}) => {
  const [, ref] = useDrag({
    type: ItemType.CHIP,
    item: { item, index, rowId, column },
  });

  return (
    <Chip
      ref={ref}
      label={`${item.name} -- ${item.value}`}
      size="small"
      avatar={
        <Avatar
          sx={{
            bgcolor: theme.palette[color].dark,
            color: `${theme.palette[color].contrastText} !important`,
          }}
        >
          {item.name.charAt(0).toUpperCase()}
        </Avatar>
      }
      variant="outlined"
      color={color}
    />
  );
};

// Droppable container for chips
const DroppableContainer = ({ rowId, items, column, moveItem, color }) => {
  const [, ref] = useDrop({
    accept: ItemType.CHIP,
    drop: (draggedItem) => {
      if (draggedItem.column === column) {
        moveItem(draggedItem.rowId, draggedItem.index, rowId, column);
      }
    },
  });

  return (
    <Box
      ref={ref}
      sx={{
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
        gap: 1,
        backgroundColor: items.length ? "inherit" : "#f0f0f0",
        border: items.length ? "none" : "1px dashed #ccc",
      }}
      divider={<Divider orientation="vertical" flexItem />}
    >
      {items.length ? (
        items.map((item, index) => (
          <DraggableChip
            key={index}
            item={item}
            index={index}
            rowId={rowId}
            column={column}
            moveItem={moveItem}
            color={color}
          />
        ))
      ) : (
        <Typography variant="body2" color="textSecondary">
          Drop items here
        </Typography>
      )}
    </Box>
  );
};

const Dashboard = () => {
  const initialRows = [
    {
      id: 1,
      col1: "Hello",
      col2: "World is a best ",
      items: [
        { name: "one", value: 30 },
        { name: "two", value: 25 },
        { name: "three", value: 28 },
      ],
      items2: [
        { name: "2-one", value: 22 },
        { name: "2-two", value: 18 },
        { name: "2-three", value: 45 },
      ],
    },
    {
      id: 2,
      col1: "DataGridPro",
      col2: "is Awesome",
      items: [
        { name: "four", value: 18 },
        { name: "five", value: 12 },
        { name: "six", value: 45 },
      ],
      items2: [
        { name: "2-four", value: 66 },
        { name: "2-five", value: 7 },
        { name: "2-six", value: 13 },
      ],
    },
    {
      id: 3,
      col1: "MUI",
      col2: "is Amazing",
      items: [
        { name: "seven", value: 18 },
        { name: "eight", value: 12 },
        { name: "nine", value: 45 },
      ],
      items2: [
        { name: "2-seven", value: 66 },
        { name: "2-eight", value: 7 },
        { name: "2-nine", value: 13 },
      ],
    },
  ];

  const [rows, setRows] = useState(initialRows);

  const moveItem = (fromRowId, fromIndex, toRowId, column) => {
    const fromRow = rows.find((row) => row.id === fromRowId);
    const toRow = rows.find((row) => row.id === toRowId);

    if (fromRow && toRow) {
      const item = fromRow[column][fromIndex];
      fromRow[column].splice(fromIndex, 1);
      toRow[column].push(item);

      setRows([...rows]);
    }
  };

  // Calculate total value for each row
  const calculateTotalValue = (row) => {
    const total = row.items.reduce((acc, item) => acc + item.value, 0);
    const total2 = row.items2.reduce((acc, item) => acc + item.value, 0);
    return total + total2;
  };

  const columns = [
    { field: "col1", headerName: "Column 1", minWidth: 150, flex: 1 },
    { field: "col2", headerName: "Column 2", minWidth: 150, flex: 1 },
    {
      field: "items",
      headerName: "Items",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <DroppableContainer
          rowId={params.id}
          items={params.value}
          column="items"
          moveItem={moveItem}
          color={"info"}
        />
      ),
    },
    {
      field: "items2",
      headerName: "Items 2",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <DroppableContainer
          rowId={params.id}
          items={params.value}
          column="items2"
          moveItem={moveItem}
        />
      ),
    },
    {
      field: "totalValue",
      headerName: "Total Value",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Typography>{calculateTotalValue(params.row)}</Typography>
      ),
    },
  ];

  return (
    <React.Fragment>
      <LayoutSidebarMenu>
        <Box sx={{ p: 5, width: "100%" }}>
          <DndProvider backend={HTML5Backend}>
            <DataGrid
              rows={rows}
              columns={columns}
              autoHeight
              getRowHeight={() => "auto"}
              sx={{
                [`& .${gridClasses.cell}`]: {
                  py: 1.5,
                },
              }}
            />
          </DndProvider>
        </Box>
      </LayoutSidebarMenu>
    </React.Fragment>
  );
};

export default Dashboard;