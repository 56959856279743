import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import theme from "../../../Theme";
import LinearScaleOutlinedIcon from "@mui/icons-material/LinearScaleOutlined";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import TrendingDownOutlinedIcon from "@mui/icons-material/TrendingDownOutlined";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import Backgrounds from "../../Backgrounds/Backgrounds";
import useSetupLogic from "./useSetupLogic";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  // [`& .${linearProgressClasses.bar}`]: {
  //   borderRadius: 5,
  //   backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  // },
}));

const Type2Layout = ({
  title,
  value,
  compairValue,
  //icon,
  //color,
  variant,
  result,
  shadow,
  invert,
  messages,
}) => {
  const { getPercentage, remainingPercentage, color, compairText, icon } =
    useSetupLogic(value, compairValue, messages, invert);

  return (
    <React.Fragment>
      <Backgrounds shadow={shadow} variant={variant} color={color}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          style={{ gap: 10 }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: "1",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%", // Adjust maxWidth as needed
                fontWeight: "600",
                color:
                  variant === "contained"
                    ? theme.palette[color].contrastText
                    : theme.palette.text[900],
              }}
            >
              {title}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 0.5,
              }}
              style={{ gap: "5px" }}
            >
              {/* <Typography variant="caption">
              {remainingPercentage !== 0 &&
                `${Math.abs(remainingPercentage)}% `}
              {compairText}
            </Typography> */}
              {icon}
              <Typography
                sx={{
                  color:
                    variant === "contained"
                      ? theme.palette[color].contrastText
                      : theme.palette[color].main,
                  fontSize: 12,
                }}
              >
                {remainingPercentage < 0
                  ? `${Math.abs(remainingPercentage.toFixed(1))}% `
                  : remainingPercentage > 0
                  ? `+ ${remainingPercentage.toFixed(1)}% `
                  : ""}
                {compairText}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <Typography
              sx={{
                color:
                  variant === "contained"
                    ? theme.palette[color].contrastText
                    : theme.palette.text[900],
                fontSize: 36,
                fontWeight: "bold",
                lineHeight: 1,
              }}
            >
              {value}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color:
                  variant === "contained"
                    ? theme.palette[color].contrastText
                    : theme.palette.grey[500],
              }}
            >
              / {compairValue}
            </Typography>
          </Box>
        </Box>
        <Box>
          <BorderLinearProgress
            variant="determinate"
            value={getPercentage}
            color={color}
          />
        </Box>
      </Backgrounds>
    </React.Fragment>
  );
};

export default Type2Layout;
