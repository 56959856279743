import { Box, Typography } from "@mui/material";
import React from "react";
import theme from "../../../Theme";
import { grey } from "@mui/material/colors";

const LineLayout = ({ title, value, cValue }) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          bgcolor: "rgba(255,255,255,.5)",
          px: 1.5,
          py: 1,
          borderRadius: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          gap: 0.2,
          boxShadow:"rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;",
        }}
      >
        <Typography noWrap sx={{ opacity: 0.9 }} variant="subtitle2">
          {title}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.2 }}>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 700,
              color: theme.palette.text.primary,
              lineHeight: "20px",
            }}
          >
            {value}
          </Typography>
          {typeof cValue === "number" && (
            <>
              <Typography
                sx={{
                  fontWeight: 500,
                  color: theme.palette.text.primary,
                  lineHeight: 1,
                }}
              >
                /
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  color: theme.palette.text.primary,
                }}
              >
                {cValue}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default LineLayout;
