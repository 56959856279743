import {
  Box,
  TextField,
  Typography,
  Button,
  Modal,
  Chip,
  Avatar,
  IconButton,
  Divider,
  Autocomplete,
  TableRow,
  TableHead,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Link,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import theme from "../../Theme";
import { useSelector, useDispatch } from "react-redux";
import {
  addCompanyViaApi,
  addJobDetailsViaApi,
  checkCompanyName,
  industriesNamesViaApi,
  removeNewCompany,
  searchDataViaApi,
} from "../../store/search/action";
import { getAllUsers } from "../../store/auth/login/actions";
import AddIcon from "@mui/icons-material/Add";

import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import {
  SnackbarProvider,
  enqueueSnackbar,
  closeSnackbar,
  useSnackbar,
} from "notistack";
import {
  Backgrounds,
  KeyData,
  SnackbarComponent,
  ScrollView,
  SectionTitle,
  MainBackground,
} from "../../Components";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import moment from "moment-timezone";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import { constant, debounce } from "lodash";
import { DataGrid } from "@mui/x-data-grid";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const FormRow = ({ children }) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          alignItems: "baseline",
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
};
const FormCell = ({ children, width = 172, flex = 1 }) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          flex: flex,
          flexBasis: width,
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
};

const ContactView = ({
  data,
  key,
  color = "primary", //primary, secondary, info, success, warning, error
  isSelected,
  onClick,
}) => {
  return (
    <React.Fragment>
      <Box
        key={key}
        sx={{
          borderLeft:
            ((color === "primary" || color === "success") &&
              data.status === "Blocked") ||
            data.status === "DNC"
              ? `3px solid ${theme.palette.error.main}`
              : `3px solid ${theme.palette[color].main}`,
          p: 1,
          display: "flex",
          flexDirection: "column",
          gap: 0.4,
          overflow: "hidden",
          cursor:
            data.status === "Blocked" || data.status === "DNC"
              ? "not-allowed"
              : "pointer",
          backgroundColor: isSelected
            ? theme.palette.action.selected
            : "inherit",
          opacity: data.status === "Blocked" || data.status === "DNC" ? 0.8 : 1,
        }}
        onClick={() =>
          data.status !== "Blocked" && data.status !== "DNC" && onClick(data)
        }
      >
        <Box>
          <Typography
            noWrap
            sx={{ fontSize: 14, fontWeight: 600, lineHeight: 1.2 }}
          >
            {data.firstName} {data.lastName}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: theme.palette.grey[500],
              flex: 1,
            }}
          >
            {data.designation}
          </Typography>
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: theme.palette.grey[500],
            }}
          >
            {/* Used: 35 */}
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: theme.palette.grey[500],
              flex: 1,
            }}
          >
            {data.emailId}
          </Typography>
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: theme.palette.grey[500],
            }}
          >
            {data.phoneNumber}
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};
const SearchCompany = () => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [dataShow, setDataShow] = useState("");
  const [queryName, setQuryName] = useState("");
  const searchResultsData =
    useSelector((state) => state.Search.searchData) || [];
  const checkCompany = useSelector((state) => state.Search.checkCompany);
  const currentCompany = useSelector((state) => state.Search.newCompany);

  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const storeData = useSelector((state) => state.Login.storeData);
  const [selectedContactIds, setSelectedContactIds] = useState([]);

  const handleSearch = () => {
    if (query.length > 0) {
      // Remove extra spaces between words
      dispatch(checkCompanyName(false));
      const cleanedQuery = query.replace(/\s+/g, " ").trim();
      setQuryName(cleanedQuery);
      dispatch(searchDataViaApi(cleanedQuery));
      setDataShow(cleanedQuery);
      setSelectedContactIds([]);
    }
  };

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);
  const latestResult = searchResultsData.sort(
    (a, b) => new Date(b.addedOn) - new Date(a.addedOn)
  )[0];
  const [addedDateTime, setAddedDateTime] = useState(new Date());

  const formatDate = (dateString) => {
    const date = moment.tz(dateString, "UTC"); // Parse date in UTC
    return date.tz("Asia/Kolkata").format("MMMM DD, YYYY"); // Convert to Kolkata time and format
  };
  const handleKeyPress = (e) => {
    // e.preventDefault()
    if (e.key === "Enter") {
      if (query.length === 0) {
        // If reduxPlaceOrder is not empty, return or show a message
        return;
      }
      handleSearch();
    }
  };

  const calculateDays = (providedDate) => {
    const timeZone = "Asia/Kolkata";

    // Convert provided date to IST
    const providedDateObj = moment.tz(providedDate, timeZone).startOf("day");

    // Get today's date in IST
    const todayDateObj = moment.tz(timeZone).startOf("day");

    // Calculate the difference in milliseconds between the provided date and today's date
    const differenceInMilliseconds =
      todayDateObj.valueOf() - providedDateObj.valueOf();

    // Convert milliseconds to days
    const daysDifference = Math.round(
      differenceInMilliseconds / (1000 * 60 * 60 * 24)
    );

    return daysDifference;
  };

  const filteredResults = searchResultsData.filter(
    (entry) =>
      entry.status === "Blocked" ||
      entry.status === "On Process" ||
      entry.status === "DNC" ||
      entry.status === "No FallowUp"
  );

  const sortedFilteredResults =
    filteredResults.length > 0
      ? filteredResults.sort(
          (a, b) => new Date(b.addedOn) - new Date(a.addedOn)
        )
      : [];

  const filteredStatusResults =
    sortedFilteredResults.length > 0 ? [sortedFilteredResults[0]] : [];
  const sanitizeString = (str) => {
    if (str) {
      return str.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
    }
    return ""; // Return an empty string if str is undefined or null
  };

  const industries = useSelector((state) => state.Search.industries || []);
  const industryNames = industries.map((item) => item.industryName) || [];
  const [jobTitleError, setJobTitleError] = useState("");
  const [jobLocationError, setJobLocationError] = useState("");
  const [jobDescriptionError, setJobDescriptionError] = useState("");
  const [jobWebsiteError, setJobWebsiteError] = useState("");
  const [jobDetails, setJobDetails] = useState([
    {
      addedBy: storeData._id,
      jobTitle: "",
      jobLocation: "",
      jobDescription: "",
      jobAddedOn: addedDateTime,
      status: "Active",
    },
  ]);
  //excel paste
  const [excelData, setExcelData] = useState([
    {
      addedBy: storeData._id,
      firstName: "",
      lastName: "",
      designation: "",
      emailId: "",
      contactAddedOn: addedDateTime,
    },
  ]);
  useEffect(() => {
    if (currentCompany) {
      if (
        currentCompany.contactDetails &&
        currentCompany.contactDetails.length > 0
      ) {
        setExcelData(currentCompany.contactDetails);
      }
      if (currentCompany.jobDetails && currentCompany.jobDetails.length > 0) {
        setJobDetails(currentCompany.jobDetails);
      }
    }
  }, [currentCompany]);
  const [jobDeatilsModalOpen, setJobDeatilsModalOpen] = useState(
    currentCompany && currentCompany._id
  );
  const handleCloseModal = () => {
    dispatch(removeNewCompany());
    setJobDeatilsModalOpen(!jobDeatilsModalOpen);
  };
  const handleSaveJobDetials = () => {
    setJobTitleError("");
    setJobLocationError("");
    setJobDescriptionError("");
    // Check for invalid job details
    const invalidJobDetails = jobDetails.some((contact, index) => {
      if (!contact.jobTitle.trim()) {
        setJobTitleError(`Job title  is required.`);
        return true;
      }
      if (!contact.jobLocation.trim()) {
        setJobLocationError(`Job location  is required.`);
        return true;
      }
      if (!contact.jobDescription.trim()) {
        setJobDescriptionError(`Job description  is required.`);
        return true;
      }
      return false;
    });
    if (invalidJobDetails) {
      return;
    }
    // Trim extra spaces from job details
    const trimmedJobDetails = jobDetails.map((contact) => ({
      ...contact,
      jobTitle: contact.jobTitle.trim(),
      jobLocation: contact.jobLocation.trim(),
      jobDescription: contact.jobDescription.trim(),
      jobWebsite: contact.jobWebsite ? contact.jobWebsite.trim() : "",
      jobIndustrieName: contact.jobIndustrieName
        ? contact.jobIndustrieName.trim()
        : "",
    }));
    // Trim extra spaces from contact details
    const trimmedContactDetails = excelData.map((contact) => ({
      ...contact,
      firstName: contact.firstName.trim(),
      lastName: contact.lastName ? contact.lastName.trim() : "",
      designation: contact.designation.trim(),
      emailId: contact.emailId.trim(),
      phoneNumber: contact.phoneNumber ? contact.phoneNumber.trim() : "",
    }));
    // All validations passed, proceed with submission
    const filteredContactDetails = trimmedContactDetails.filter(
      (contact) => contact.firstName !== "" || contact.designation !== ""
    );
    const action = {
      id: currentCompany._id,
      addedBy: currentCompany.addedBy,
      jobDetails: trimmedJobDetails,
      contactDetails: filteredContactDetails,
    };
    dispatch(addJobDetailsViaApi(action));

    dispatch(industriesNamesViaApi(action));
    dispatch(removeNewCompany());
    setSelectedContactIds([]);
  };
  const handleJobChange = (index, field, value) => {
    const updatedContacts = [...jobDetails];
    updatedContacts[index][field] = value;
    setJobDetails(updatedContacts);
  };

  const handleChange = (index, field, value) => {
    const updatedContacts = [...excelData];
    updatedContacts[index][field] = value;
    setExcelData(updatedContacts);
  };

  const handleContactClick = (data) => {
    setSelectedContactIds((prevSelectedContactIds) => {
      if (prevSelectedContactIds.includes(data)) {
        return prevSelectedContactIds.filter(
          (selectedData) => selectedData !== data
        );
      } else if (prevSelectedContactIds.length < 5) {
        return [...prevSelectedContactIds, data];
      } else {
        enqueueSnackbar(`Only 5 selections allowed.`, {
          variant: "error",
          action: (key) => (
            <IconButton onClick={() => closeSnackbar()} color="inherit">
              <CloseIcon />
            </IconButton>
          ),
        });
        console.log("Cannot add more than 5 contacts");
        return prevSelectedContactIds;
      }
    });
  };
  const saveCompanyName = debounce((newCompanyName) => {
    const dateTime = new Date();
    const action = {
      companyName: newCompanyName.trim(),
      addedBy: storeData._id,
      status: "Active",
      addedOn: dateTime,
    };
    if (selectedContactIds.length > 0) {
      const updatedContacts = selectedContactIds.map((contact) => ({
        ...contact,
        addedBy: storeData._id,
        contactAddedOn: new Date().toISOString(), // Ensuring ISO format
      }));

      action.contactDetails = updatedContacts;
    }
    if (latestResult && latestResult.jobDetails) {
      const updatedJobDetails = latestResult.jobDetails.map((jobDetail) => ({
        ...jobDetail,
        jobTitle: "",
        jobLocation: "",
        jobDescription: "",
        addedBy: storeData._id,
        jobAddedOn: new Date().toISOString(), // Ensuring ISO format
        status: "Active",
        comment: "",
      }));
      action.jobDetails = updatedJobDetails;
    }

    setJobDetails([
      {
        addedBy: storeData._id,
        jobTitle: "",
        jobLocation: "",
        jobDescription: "",
        jobAddedOn: addedDateTime,
        status: "Active",
      },
    ]);
    setExcelData([
      {
        addedBy: storeData._id,
        firstName: "",
        lastName: "",
        designation: "",
        emailId: "",
        contactAddedOn: addedDateTime,
      },
    ]);

    dispatch(removeNewCompany());
    dispatch(addCompanyViaApi(action));
    setQuery("");
    setDataShow("");
    setSelectedContactIds([]);
  }, 1000);

  const handleCheckEmailID = (latestResult, query) => {
    if (latestResult && latestResult.contactDetails) {
      // Loop through each contact detail
      for (let contact of latestResult.contactDetails) {
        // Check if the sanitized email ID matches the sanitized query
        if (sanitizeString(contact.emailId) === sanitizeString(query)) {
          // If match found, return true
          return true;
        }
      }
    }
    // If no match found, return false
    return false;
  };
  const extractDomain = (email) => {
    const domainMatch = email.match(/@(.+)$/);
    return domainMatch
      ? domainMatch[1].toLowerCase()
      : email.toLowerCase().replace(/^[^@]*@?/, "");
  };

  // Function to check if any email ID domain matches the query domain
  const handleCheckEmailDomain = (latestResult, query) => {
    if (latestResult && latestResult.contactDetails && query) {
      for (let contact of latestResult.contactDetails) {
        const emailDomain = extractDomain(contact.emailId);
        // Check if the domain of the email matches the query domain
        if (emailDomain.toLowerCase() === query.trim().toLowerCase()) {
          return true;
        }
      }
    }

    // If no match found, return false
    return false;
  };
  function mergeCompanyData(data) {
    if (!data || data.length === 0) {
      return [];
    }

    // Sort data based on addedOn date in descending order
    data.sort((a, b) => new Date(b.addedOn) - new Date(a.addedOn));

    // Initialize mergedData with the latest record
    let latestRecord = data[0];
    let mergedData = {
      ...latestRecord,
      contactDetails: latestRecord.contactDetails
        ? [...latestRecord.contactDetails]
        : [],
    };
    function cleanEmailId(emailId) {
      if (typeof emailId === "string") {
        return emailId.replace(/\r/g, "").trim();
      }
      return "";
    }
    // Function to check if a contact detail already exists
    function contactExists(existingContacts, newContact) {
      return existingContacts.some(
        (contact) =>
          contact.firstName === newContact.firstName &&
          contact.lastName === newContact.lastName &&
          contact.status === newContact.status &&
          cleanEmailId(contact.emailId) === cleanEmailId(newContact.emailId)
      );
    }

    // Merge contact details from the remaining records into the latest record
    for (let i = 1; i < data.length; i++) {
      if (data[i].contactDetails && data[i].contactDetails.length > 0) {
        data[i].contactDetails.forEach((contact) => {
          if (!contactExists(mergedData.contactDetails, contact)) {
            mergedData.contactDetails.push(contact);
          }
        });
      }
    }

    return [mergedData];
  }

  const mergeContactDetails = mergeCompanyData(searchResultsData || []);

  const beforeData = searchResultsData.map((item, index) => {
    const { addedBy, addedOn, jobDetails = [] } = item;

    const {
      jobTitle = "",
      jobLocation = "",
      jobDescription = "",
    } = jobDetails[0] || {};

    return {
      id: index + 1,
      addedBy,
      addedOn,
      jobTitle,
      jobLocation,
      jobDescription,
    };
  });

  const [colorStatus, setColorStatus] = useState("primary");
  useEffect(() => {
    if (latestResult && latestResult.status) {
      let newColorStatus;
      switch (latestResult.status) {
        case "Blocked":
          newColorStatus = "error";
          break;
        case "On Process":
          newColorStatus = "success";
          break;
        case "DNC":
          newColorStatus = "warning";
          break;
        case "No FallowUp":
          newColorStatus = "warning";
          break;
        default:
          newColorStatus = "primary";
      }
      setColorStatus(newColorStatus);
    } else {
      setColorStatus("primary");
    }
  }, [latestResult]);

  //Dummy Data
  const columnsData = [
    {
      field: "addedBy",
      headerName: "RA Name",
      width: 120,
      minWidth: 120,
      flex: 1,
      valueGetter: (value, row) => {
        if (row.addedBy) {
          return allUsers.find((user) => user._id === row.addedBy)
            ?.userFirstName;
        } else {
          return "--";
        }
      },
    },
    {
      field: "addedOn",
      headerName: "Added On",
      width: 150,
      valueGetter: (value, row) => {
        if (row.addedOn) {
          return formatDate(row.addedOn);
        } else {
          return "--";
        }
      },
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      width: 140,
      valueGetter: (value, row) => {
        if (row.jobTitle) {
          return row.jobTitle;
        } else {
          return "--";
        }
      },
    },
    {
      field: "jobLocation",
      headerName: "Job Location",
      width: 140,
      valueGetter: (value, row) => {
        if (row.jobLocation) {
          return row.jobLocation;
        } else {
          return "--";
        }
      },
    },
    {
      field: "jobDescription",
      headerName: "Job Description",
      width: 140, // Adjust width as needed
      align: "center",
      renderCell: (params) => (
        <Link
          href={params.value} // Assuming params.value contains the URL
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: "error.main" }} // Optional: Apply custom styles
        >
          Link
          {/* You can also display a text like "View" instead of the URL */}
        </Link>
      ),
    },
  ];

  const handleAddContact = () => {
    if (excelData.length < 5) {
      setExcelData([
        ...excelData,
        {
          addedBy: storeData._id,
          firstName: "",
          lastName: "",
          designation: "",
          emailId: "",
          contactAddedOn: addedDateTime,
          phoneNumber: "",
        },
      ]);
    } else {
      enqueueSnackbar(`Not add contacts more than 5`, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
  };

  
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Search Company"}>
          <Box>
            <MainBackground>
              <Box my={6}>
                <Box
                  sx={{
                    display: "flex",
                    maxWidth: 720,
                    margin: "0px auto",
                    width: "100%",
                    mb: 5,
                  }}
                >
                  <TextField
                    label="Search by Company Name / Domain / Email-ID"
                    fullWidth
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyDown={handleKeyPress}
                    InputProps={{
                      sx: {
                        borderBottomRightRadius: 0,
                        borderTopRightRadius: 0,
                      },
                    }}
                  />
                  <Button
                    variant="contained"
                    size="large"
                    onClick={handleSearch}
                    sx={{
                      borderBottomLeftRadius: 0,

                      borderTopLeftRadius: 0,
                      width: 170,
                    }}
                  >
                    Submit
                  </Button>
                </Box>
                {dataShow &&
                  sanitizeString(dataShow) === sanitizeString(query) && (
                    <Box>
                      {searchResultsData.length === 0 && checkCompany ? (
                        <Box>
                          {sanitizeString(query) ===
                            sanitizeString(queryName) && (
                            <>
                              <Box>
                                <Backgrounds>
                                  <KeyData
                                    title={"Company Name"}
                                    value={query}
                                    size={"large"}
                                  />
                                </Backgrounds>
                              </Box>
                            </>
                          )}
                        </Box>
                      ) : (
                        <>
                          {mergeContactDetails &&
                            mergeContactDetails.map((result, index) =>
                              sanitizeString(result.companyName) ===
                                sanitizeString(query) ||
                              sanitizeString(result.domain) ===
                                sanitizeString(query) ||
                              sanitizeString(result.domain) ===
                                sanitizeString(extractDomain(query)) ||
                              handleCheckEmailID(latestResult, query) ||
                              handleCheckEmailDomain(latestResult, query) ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 2,
                                    maxWidth: 992,
                                    margin: "0px auto",
                                    width: "100%",
                                  }}
                                >
                                  <Divider />
                                  <KeyData
                                    title={"Company Name"}
                                    value={result.companyName}
                                    size={"large"}
                                    color={colorStatus}
                                  />
                                  <Divider />
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: 2,
                                      justifyContent: "space-around",
                                    }}
                                  >
                                    <KeyData
                                      title={"Contact Status"}
                                      value={result.status}
                                      color={colorStatus}
                                    />
                                    <Divider orientation="vertical" flexItem />
                                    <KeyData
                                      title={"Industry"}
                                      value={
                                        (result.jobDetails &&
                                          result.jobDetails[0] &&
                                          result.jobDetails[0]
                                            .jobIndustrieName) ||
                                        "--"
                                      }
                                      color={colorStatus}
                                    />
                                    <Divider orientation="vertical" flexItem />
                                    <KeyData
                                      title={"Website"}
                                      value={
                                        (result.jobDetails &&
                                          result.jobDetails[0] &&
                                          result.jobDetails[0].jobWebsite) ||
                                        (result.contactDetails &&
                                          result.contactDetails[0] &&
                                          result.contactDetails[0].emailId &&
                                          `https://www.${
                                            result.contactDetails[0].emailId.split(
                                              "@"
                                            )[1]
                                          }`) ||
                                        "--"
                                      }
                                      color={colorStatus}
                                      link
                                    />
                                    <Divider orientation="vertical" flexItem />
                                    <KeyData
                                      title={"Last Added By"}
                                      value={
                                        allUsers.find(
                                          (user) => user._id === result.addedBy
                                        )?.userFirstName
                                      }
                                      color={colorStatus}
                                    />
                                    <Divider orientation="vertical" flexItem />
                                    <KeyData
                                      title={"Last Added On"}
                                      value={formatDate(result.addedOn)}
                                      color={colorStatus}
                                    />
                                    <Divider orientation="vertical" flexItem />
                                    <KeyData
                                      title={"Lock-In Period"}
                                      // value={calculateDays(result.addedOn)}
                                      value={calculateDays(result.addedOn)>17?'Company Unlock':18-calculateDays(result.addedOn)}
                                      color={colorStatus}
                                    />
                                  </Box>
                                </Box>
                              ) : (
                                ""
                              )
                            )}
                        </>
                      )}
                    </Box>
                  )}
              </Box>
            </MainBackground>
          </Box>
          {dataShow &&
            sanitizeString(dataShow) === sanitizeString(query) &&
             (
              <>
                {searchResultsData.length === 0 && checkCompany ? (
                  <Box sx={{ display: "flex", justifyContent: "center", m: 3 }}>
                    <Button
                      variant="outlined"
                      onClick={() => saveCompanyName(query)}
                    >
                      Add Company
                    </Button>
                  </Box>
                ) : (
                  <>
                    {filteredStatusResults &&
                    filteredStatusResults.length > 0 ? (
                      "" // Render nothing
                    ) : (
                      <>
                        {latestResult&&latestResult.addedOn &&
                        calculateDays(latestResult.addedOn) > 17 ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              m: 3,
                            }}
                          >
                            <Button
                              variant="outlined"
                              onClick={() =>
                                saveCompanyName(latestResult.companyName)
                              }
                            >
                              Add Company
                            </Button>
                          </Box>
                        ) : (
                          "" // Render nothing
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}

          <Box>
            {dataShow && sanitizeString(dataShow) === sanitizeString(query) && (
              <>
                {mergeContactDetails &&
                  mergeContactDetails.map((result, index) =>
                    sanitizeString(result.companyName) ===
                      sanitizeString(query) ||
                    sanitizeString(result.domain) === sanitizeString(query) ||
                    sanitizeString(result.domain) ===
                      sanitizeString(extractDomain(query)) ||
                    handleCheckEmailID(latestResult, query) ||
                    handleCheckEmailDomain(latestResult, query) ? (
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <MainBackground height="full">
                            <SectionTitle title="Other Added Details" />
                            <DataGrid
                              rows={beforeData}
                              columns={columnsData}
                              disableColumnMenu
                              disableColumnSorting
                              disableColumnSelector
                              disableColumnFilter
                              disableColumnReorder
                              disableColumnResize
                              //disableRowSelectionOnClick
                              disableColumnSort
                              hideFooterSelectedRowCount
                              //hideFooter
                              initialState={{
                                pagination: {
                                  paginationModel: { page: 0, pageSize: 3 },
                                },
                              }}
                              pageSizeOptions={[3]}
                              getRowHeight={() => "auto"}
                              autoHeight
                              sx={{
                                "& .MuiDataGrid-cell": {
                                  display: "flex",
                                  alignItems: "center !important",
                                },
                                [`& .MuiDataGrid-checkboxInput`]: {
                                  color: theme.palette.primary.main,
                                  p: 0,

                                  "&.Mui-checked": {
                                    color: theme.palette.primary.dark,
                                  },
                                },
                                //Scroller
                                [`& .MuiDataGrid-scrollbar`]: {
                                  scrollbarWidth: "thin",
                                  scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                                },
                                [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]:
                                  {
                                    borderRadius: "10px", // Apply border radius
                                  },

                                [`& .MuiDataGrid-scrollbar:hover`]: {
                                  scrollbarColor: `${theme.palette.primary.dark}`,
                                },
                              }}
                            />
                          </MainBackground>
                        </Grid>
                        <Grid item xs={8}>
                          <MainBackground height="full">
                            <SectionTitle title="Contact Lists" />
                            <Box pb={1}>
                              <ScrollView height={252}>
                                <Grid container spacing={0.5}>
                                  {result.contactDetails.map(
                                    (contact, index) => (
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={6}
                                        xl={3}
                                      >
                                        <ContactView
                                          key={index}
                                          data={contact}
                                          color={colorStatus}
                                          // isSelected={selectedContactIds.includes(
                                          //   contact
                                          // )}
                                          isSelected={selectedContactIds.includes(
                                            contact
                                          )}
                                          onClick={handleContactClick}
                                        />
                                      </Grid>
                                    )
                                  )}
                                </Grid>
                              </ScrollView>
                            </Box>
                          </MainBackground>
                        </Grid>
                      </Grid>
                    ) : (
                      ""
                    )
                  )}
              </>
            )}
          </Box>

          {/* // */}
          <Modal
            open={Boolean(currentCompany && currentCompany._id)}
            //onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxWidth: "1000px",
                width: "80%",
                maxHeight: "80vh",
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                overflowY: "auto",
                p: {
                  xs: 1.5,
                  md: 3,
                },
                borderRadius: 2,
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  color: theme.palette.primary.dark,
                  fontWeight: "bold",
                  mb: 1,
                }}
              >
                {currentCompany ? `${currentCompany.companyName}` : ""}
              </Typography>
              <Divider />

              <Box>
                <Box sx={{ my: 1 }}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Job Details
                  </Typography>
                </Box>
                {jobDetails.map((contact, index) => (
                  <FormRow>
                    <FormCell>
                      <TextField
                        variant="standard"
                        label="Website"
                        placeholder="https://tektreeinc.com"
                        size="small"
                        fullWidth
                        value={contact.jobWebsite}
                        onChange={(e) =>
                          handleJobChange(index, "jobWebsite", e.target.value)
                        }
                        error={Boolean(jobWebsiteError)}
                        helperText={jobWebsiteError}
                      />
                    </FormCell>
                    <FormCell>
                      <TextField
                        variant="standard"
                        label="Job Description"
                        placeholder="URL"
                        size="small"
                        fullWidth
                        value={contact.jobDescription}
                        onChange={(e) =>
                          handleJobChange(
                            index,
                            "jobDescription",
                            e.target.value
                          )
                        }
                        error={Boolean(jobDescriptionError)}
                        helperText={jobDescriptionError}
                      />
                    </FormCell>
                    <FormCell>
                      <TextField
                        variant="standard"
                        label="Job Title"
                        placeholder="Application Engineer"
                        size="small"
                        fullWidth
                        value={contact.jobTitle}
                        onChange={(e) =>
                          handleJobChange(index, "jobTitle", e.target.value)
                        }
                        error={Boolean(jobTitleError)}
                        helperText={jobTitleError}
                      />
                    </FormCell>
                    <FormCell>
                      <TextField
                        variant="standard"
                        label="Job Location"
                        placeholder="Location, State"
                        size="small"
                        fullWidth
                        value={contact.jobLocation}
                        onChange={(e) =>
                          handleJobChange(index, "jobLocation", e.target.value)
                        }
                        error={Boolean(jobLocationError)}
                        helperText={jobLocationError}
                      />
                    </FormCell>

                    <FormCell>
                      <Autocomplete
                        disablePortal
                        freeSolo
                        id="combo-box-demo"
                        options={industryNames}
                        value={contact.jobIndustrieName}
                        onChange={(event, newValue) => {
                          handleJobChange(index, "jobIndustrieName", newValue);
                        }}
                        onInputChange={(event, newInputValue) => {
                          // If you need to handle input changes separately, you can use this
                          handleJobChange(
                            index,
                            "jobIndustrieName",
                            newInputValue
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Industry"
                            variant="standard"
                            size="small"
                            fullWidth
                          />
                        )}
                      />
                    </FormCell>
                  </FormRow>
                ))}

                <Box
                  sx={{
                    my: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    Job Poster Details:
                  </Typography>
                  <Button
                      variant="text"
                      size="small"
                      startIcon={<AddIcon />}
                      onClick={handleAddContact}
                    >
                      Add more Contacts
                    </Button>
                </Box>

                
                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                  {excelData.map((row, rowIndex) => (
                    <Box
                      key={rowIndex}
                      sx={{
                        display: "flex",
                        gap: 3,
                      }}
                    >
                      <TextField
                        variant="standard"
                        label="First Name"
                        placeholder="Josh"
                        size="small"
                        fullWidth
                        value={row.firstName}
                        onChange={(e) =>
                          handleChange(rowIndex, "firstName", e.target.value)
                        }
                      />
                      <TextField
                        variant="standard"
                        label="Last Name"
                        placeholder="Morgan"
                        size="small"
                        fullWidth
                        value={row.lastName}
                        onChange={(e) =>
                          handleChange(rowIndex, "lastName", e.target.value)
                        }
                      />
                      <TextField
                        variant="standard"
                        label="Designation"
                        placeholder="HR Manager"
                        size="small"
                        fullWidth
                        value={row.designation}
                        onChange={(e) =>
                          handleChange(rowIndex, "designation", e.target.value)
                        }
                      />
                      <TextField
                        variant="standard"
                        label="Email Id"
                        placeholder="josh.m@company.com"
                        size="small"
                        fullWidth
                        value={row.emailId}
                        onChange={(e) =>
                          handleChange(rowIndex, "emailId", e.target.value)
                        }
                      />
                      <TextField
                        variant="standard"
                        label="Phone"
                        placeholder="+1 (555) 555 555"
                        size="small"
                        fullWidth
                        value={row.phoneNumber}
                        onChange={(e) =>
                          handleChange(rowIndex, "phoneNumber", e.target.value)
                        }
                      />
                    </Box>
                  ))}
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  gap: 2,
                  pt: 4,
                }}
              >
                <Button variant="text" onClick={handleCloseModal}>
                  Cancel
                </Button>
                {/* <Button>Add later</Button> */}
                <Button variant="contained" onClick={handleSaveJobDetials}>
                  Save Job Details{" "}
                </Button>
              </Box>
            </Box>
          </Modal>
        </LayoutSidebarMenu>
      </SnackbarProvider>
      <SnackbarComponent />
    </React.Fragment>
  );
};

export default SearchCompany;
