import { Box, Typography } from "@mui/material";
import React from "react";
import theme from "../../../Theme";
import Backgrounds from "../../Backgrounds/Backgrounds";

const Type3Layout = ({
  title,
  value,
  compairValue,
  icon,
  color,
  variant,
  result,
  shadow,
  invert,
}) => {
  return (
    <React.Fragment>
      <Backgrounds shadow={shadow} variant={variant} color={color}>
        <Box sx={{ mb: 0.5 }}>
          <Typography
            sx={{
              color:
                variant === "contained"
                  ? theme.palette[color].contrastText
                  : theme.palette.text[900],
              fontSize: 36,
              fontWeight: "bold",
              lineHeight: 1,
              textAlign: "center",
            }}
          >
            {value}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
              fontSize: 13,
              textTransform: "uppercase",
              letterSpacing: 0.5,
              textAlign: "center",
              color:
                variant === "contained"
                  ? theme.palette[color].contrastText
                  : theme.palette.grey[400],
              fontWeight: "500",
            }}
          >
            {title}
          </Typography>
        </Box>
      </Backgrounds>
    </React.Fragment>
  );
};

export default Type3Layout;
