import React, { useEffect, useState } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import { DataGrid } from "@mui/x-data-grid";
import theme from "../../Theme";
import {
  AddApplicantForm,
  CustomFormField,
  FilterHeader,
  KeyData,
  MainBackground,
  ScrollView,
  SnackbarComponent,
} from "../../Components";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Modal,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { pink } from "@mui/material/colors";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
//
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useLocation } from "react-router-dom";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  addApplicantNoteViaApi,
  addApplicantViaApi,
  addDegreeViaApi,
  addResumeSourcesViaApi,
  getAllApplicantsViaApi,
  getAllDegreesViaApi,
  getAllResumeSourceViaApi,
  searchDateJobPostingDataViaApi,
  tagApplicantToJobViaApi,
  updateApplicantViaApi,
} from "../../store/recruiter/action";
import { stopLoadingRedux } from "../../store/reports/action";
import { ShimmerTable } from "react-shimmer-effects";
import dayjs from "dayjs";
import moment from "moment-timezone";
import { getAllUsers } from "../../store/auth/login/actions";
import CloseIcon from "@mui/icons-material/Close";

import {
  SnackbarProvider,
  enqueueSnackbar,
  closeSnackbar,
  useSnackbar,
} from "notistack";
const Applicants = () => {
  const defaultPersonalDeatils = {
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    emailAddress: "",
    mobileNumber: "",
    state: "",
    city: "",
    zipCode: "",
    // taxTerm: "",
  };
  const defaultProfessionalDeatils = {
    resume: "",
    experience: {
      years: "",
      months: "",
    },
    title: "",
    sourceOfResume: "",
    availability: "",
    skills: [],
    workAuthorization:"",
    relocate: false,
    payRateSalary: {
      currency: "USD",
      minAmount: "",
      maxAmount: "",
      paySchedule: "",
      jobType: "",
    },
  };
  const initialEducationDetails = {
    schoolName: "",
    degree:"",
    yearCompleted:"",
    major: "",
    minor:"",
    gpa: "",
    country: "",
    state: "",
    city: "",
  };

  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.Login.storeData);
  const apiUrl = process.env.REACT_APP_API_URL;
  const Applicants = useSelector((state) => state.Recruiters?.applicants);
  const location = useLocation();
  const jobPost = location.state?.jobPost || "";
  const JobPosting = useSelector((state) => state.Recruiters?.jobPostings);
  const JobPostingData = Array.isArray(JobPosting) ? JobPosting : [];
  const jobPostingOptions = Array.isArray(JobPosting)
    ? JobPosting.map((job) => ({
        value: job.jobDetails.jobCode,
        label: `${job.jobDetails.jobCode}-${job.jobDetails.jobTitle}-${job.jobDetails.client}`,
      }))
    : [];
const client=storeData.clientDb
  const filterJobPostCode = jobPost
    ? jobPostingOptions.find((option) => option.value === jobPost.jobCode) || ""
    : "";
  
  useEffect(() => {
    const action={
      client:client
    }
    dispatch(getAllUsers(action));
  }, []);
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const formatDateForDisplay = (date) => {
    const dateInNY = dayjs(date).tz("America/New_York");
    const isDST = moment.tz(date, "America/New_York").isDST();
    const timeZoneAbbr = isDST ? "EDT" : "EST";
    return dateInNY.format(`MMMM DD, YYYY h:mm A [${timeZoneAbbr}]`);
  };
  const [openNoteDrawer, setOpenNoteDrawer] = useState(false);
  const [selectNoteData,setSelectNoteData]=useState([])


  const handleNoteDtawer = (note) => {
    setSelectNoteData(note); // Set the selected job code
    setOpenNoteDrawer(!openNoteDrawer);
  };

  const ApplicantIdData = Array.isArray(Applicants)
    ? Applicants.map((applicant, index) => {
        const recruiterData = allUsers.find(
          (user) => user._id === applicant.addedBy
        );
        const recruiterName = recruiterData?.userSudoFirstName ?? "";
        return {
          ...applicant,
          addedBy: recruiterName,
          workAuthorization: applicant?.professionalDetails?.workAuthorization || '',
          addedOn: formatDateForDisplay(applicant.addedOn),
          id: index + 1,
        };
      })
    : [];

  const resumeSources = useSelector((state) => state.Recruiters?.resumeSources);
  const resumeSourcesNames = Array.isArray(resumeSources)
    ? resumeSources.map((item) => item.resumeSource)
    : [];
    const degrees = useSelector((state) => state.Recruiters?.degrees);
    
    const degreesNames = Array.isArray(degrees)
    ? degrees.map((item) => item.degreeName)
    : [];
  const [loading, setLoading] = useState(true);
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);

  const [personalDetails, setPersonalDetails] = useState(
    defaultPersonalDeatils
  );
  const [professionalDetails, setProfessionalDetails] = useState(
    defaultProfessionalDeatils
  );
  const [educationDetails, setEducationDetails] = useState(initialEducationDetails);
  const [applicantDocId, setApplicantDocId] = useState("");
  const [errors, setErrors] = useState({});

  const handleRowSelection = (newSelection) => {
    setSelectedRows(newSelection);
    const selectedData = ApplicantIdData.filter((row) =>
      newSelection.includes(row.id)
    );
    setSelectedRowData(selectedData);
    const action={
      client:client
    }
    dispatch(searchDateJobPostingDataViaApi(action));

  };

  ////////////
  ////////////
  const [isAddApplicantModalOpen, setIsAddApplicantModalOpen] = useState(false);
  const [editSelectApplicant, setEditSelectApplicant] = useState("");

  const handleAddApplicantModal = () => {
    setIsAddApplicantModalOpen(!isAddApplicantModalOpen);
    setErrors({});
    setPersonalDetails(defaultPersonalDeatils);
    setProfessionalDetails(defaultProfessionalDeatils);
    setEducationDetails(initialEducationDetails)
    setEditSelectApplicant("");
    setApplicantDocId("");
  };

  const handleEditApplicant = (row) => {
    setApplicantDocId(row);
    setEditSelectApplicant(row);
    setPersonalDetails(row.personalDetails);
    setProfessionalDetails(row.professionalDetails);
    setEducationDetails(row.educationDetails)
    setIsAddApplicantModalOpen(!isAddApplicantModalOpen);
  };
  const handleDeleteSelectData = (row) => {
    const filterSelectedRowData = selectedRowData.filter((data) => data._id !== row._id);
    const filterSelectedRows=selectedRows.filter((id)=>id!==row.id)
    setSelectedRows(filterSelectedRows);
    setSelectedRowData(filterSelectedRowData);
    const action={
      client:client
    }
    dispatch(searchDateJobPostingDataViaApi(action));
  };

  const validateForm = () => {
    const newErrors = {};

    // Check if firstName is present and not empty inside personalDetails
    if (!personalDetails?.firstName) {
      newErrors.firstName = "First Name is required in Personal Details";
    }

    // Check if lastName is empty
    if (!personalDetails?.lastName) {
      newErrors.lastName = "Last Name is required in Personal Details";
    }

    // Validate personalDetails fields
    if (!personalDetails?.gender) {
      newErrors.gender = "Gender is required in Personal Details";
    }
    if (!personalDetails?.emailAddress) {
      newErrors.emailAddress = "EmailAddress is required in Personal Details";
    }
    if (!personalDetails?.mobileNumber) {
      newErrors.mobileNumber = "MobileNumber is required in Personal Details";
    }
    if (!personalDetails?.state) {
      newErrors.state = "state is required in Personal Details";
    }
    if (!personalDetails?.city) {
      newErrors.city = "city is required in Personal Details";
    }
    // if (!personalDetails?.zipCode) {
    //   newErrors.zipCode = "zipCode is required in Personal Details";
    // }
    // if (!personalDetails?.taxTerm) {
    //   newErrors.taxTerm = "taxTerm is required in Personal Details";
    // }
    // Validate professionalDetails fields
    if (!professionalDetails?.resume) {
      newErrors.resumes = "resumes is required";
    }
    if (!professionalDetails?.payRateSalary?.minAmount) {
      newErrors.payRateMinAmount =
        "payRateMaxAmount is required in Professional Details";
    }
    if (!professionalDetails?.payRateSalary?.maxAmount) {
      newErrors.payRateMaxAmount =
        "payRateMaxAmount is required in Professional Details";
    }
    if (!professionalDetails?.payRateSalary?.paySchedule) {
      newErrors.payRatePaySchedule =
        "paySchedule is required in Professional Details";
    }
    if (!professionalDetails?.payRateSalary?.jobType) {
      newErrors.payRateJobType = "jobType is required in Professional Details";
    }
    if (!professionalDetails?.experience?.years) {
      newErrors.years = "years is required in Professional Details";
    }
    if (!professionalDetails?.experience?.months) {
      newErrors.months = "months is required in Professional Details";
    }
    if (!professionalDetails?.title) {
      newErrors.title = "title is required in Professional Details";
    }
    if (!professionalDetails?.sourceOfResume) {
      newErrors.sourceOfResume =
        "sourceOfResume is required in Professional Details";
    }
    if (!professionalDetails?.availability) {
      newErrors.availability =
        "availability is required in Professional Details";
    }
     if (!professionalDetails?.skills || professionalDetails.skills.length === 0) {
      newErrors.skills = "At least one  skill is required";
    }
     if (!professionalDetails?.workAuthorization) {
      newErrors.workAuthorization = "At least one  workAuthorization is required";
    }
    //  if (!professionalDetails?.relocate) {
    //   newErrors.relocate = "relocation is required";
    // }
     if (!educationDetails?.schoolName) {
      newErrors.schoolName = "schoolName is required";
    }
     if (!educationDetails?.degree) {
      newErrors.degree = "degree is required";
    }
    //  if (!educationDetails?.yearCompleted) {
    //   newErrors.yearCompleted = "yearCompleted is required";
    // }
     if (!educationDetails?.country) {
      newErrors.country = "country is required";
    }
     if (!educationDetails?.state) {
      newErrors.state = "country is required";
    }
     if (!educationDetails?.city) {
      newErrors.city = "country is required";
    }
    setErrors(newErrors);

    // Returns true if no errors exist
    return Object.keys(newErrors).length === 0;
  };
  const trimObjectValuesExceptResume = (obj) => {
    return Object.keys(obj).reduce((acc, key) => {
      const value = obj[key];
      if (key === "resume") {
        acc[key] = value; // Skip trimming for the 'resume' field
      }else if (Array.isArray(value)) {
        acc[key] = value; 
      }
       else if (typeof value === "string") {
        acc[key] = value.trim(); // Trim strings
      } else if (typeof value === "object" && value !== null) {
        acc[key] = trimObjectValuesExceptResume(value); // Recursively trim nested objects
      } else {
        acc[key] = value; // Handle other types as-is
      }
      return acc;
    }, {});
  };
  const handleSaveSubmitApplicant = debounce(async () => {
    if (validateForm()) {
      setLoading(true);

      // Trim the values of personalDetails and professionalDetails, except for the 'resume' field
      const trimmedPersonalDetails =
        trimObjectValuesExceptResume(personalDetails);
      const trimmedProfessionalDetails =
        trimObjectValuesExceptResume(professionalDetails);
      // Define action object for the second API call
      const trimEducationDeatils=trimObjectValuesExceptResume(educationDetails);
      const action = {
        addedBy: storeData._id,
        addedOn: new Date().toISOString(),
        personalDetails: trimmedPersonalDetails,
        professionalDetails: trimmedProfessionalDetails,
        educationDetails:trimEducationDeatils,
        id: applicantDocId?._id || "",
        resumeSource: trimmedProfessionalDetails.sourceOfResume?.trim() || "",
        degree:trimEducationDeatils.degree?.trim()||"",
        client:client
      };

      //  console.log('formData',formData);
      // // Dispatch the actions
      await dispatch(addApplicantViaApi(action));
      await dispatch(addResumeSourcesViaApi(action));
      await dispatch(addDegreeViaApi(action));
    dispatch(searchDateJobPostingDataViaApi(action));

      setTimeout(() => {
        const action={
          client:client
        }
        dispatch(getAllApplicantsViaApi(action));
        dispatch(getAllDegreesViaApi(client));
        dispatch(getAllResumeSourceViaApi(action));
        handleAddApplicantModal();
      }, 2000);
    }
  }, 2000);

  ////////////
  ////////////
  // const ApplicantsList = [
  //   {
  //     id: 1,
  //     applicantID: "458912",
  //     applicantName: {
  //       firstName: "John",
  //       middleName: "A.",
  //       lastName: "Doe",
  //     },
  //     gender: "Male",
  //     emailAddress: "john.doe@example.com",
  //     mobileNumber: "(555) 123-4567",
  //     source: "Career Builder",
  //     location: {
  //       city: "Vallejo",
  //       state: "California",
  //       zipCode: "94590",
  //     },
  //     applicantStatus: "New lead",
  //     jobTitle: "Project Manager",
  //     ownership: "Patrick Hawkins",
  //     workAuthorization: "H1-B",
  //     createdBy: "Admin",
  //     createdOn: "08/26/24 21:19:34",
  //     availability: "Availability",
  //     payRate: "USD/200,000.00/Yearly/Full Time",
  //     resume: "https://msnlabs.com/img/resume-sample.pdf",
  //     resumeTitle: "Use Document Title",
  //     resumeInfo:
  //       "Created By Lily Scarlette on 08/27/24 15:50:14 | Modified By Lily Scarlette",
  //   },
  //   {
  //     id: 2,
  //     applicantID: "459013",
  //     applicantName: {
  //       firstName: "Jane",
  //       middleName: "B.",
  //       lastName: "Smith",
  //     },
  //     gender: "Female",
  //     emailAddress: "jane.smith@example.com",
  //     mobileNumber: "(555) 234-5678",
  //     source: "LinkedIn",
  //     location: {
  //       city: "San Francisco",
  //       state: "California",
  //       zipCode: "94102",
  //     },
  //     applicantStatus: "Interview Scheduled",
  //     jobTitle: "Software Engineer",
  //     ownership: "Sarah Connor",
  //     workAuthorization: "US Citizen",
  //     createdBy: "Admin",
  //     createdOn: "08/25/24 14:12:05",
  //     availability: "Availability",
  //     payRate: "USD/200,000.00/Yearly/Full Time",
  //     resume: "https://msnlabs.com/img/resume-sample.pdf",
  //     resumeTitle: "Use Document Title",
  //     resumeInfo:
  //       "Created By Lily Scarlette on 08/27/24 15:50:14 | Modified By Lily Scarlette",
  //   },
  //   {
  //     id: 3,
  //     applicantID: "459114",
  //     applicantName: {
  //       firstName: "Michael",
  //       middleName: "C.",
  //       lastName: "Johnson",
  //     },
  //     gender: "Male",
  //     emailAddress: "michael.johnson@example.com",
  //     mobileNumber: "(555) 345-6789",
  //     source: "Indeed",
  //     location: {
  //       city: "Los Angeles",
  //       state: "California",
  //       zipCode: "90001",
  //     },
  //     applicantStatus: "Offer Extended",
  //     jobTitle: "Data Analyst",
  //     ownership: "John Davis",
  //     workAuthorization: "Green Card",
  //     createdBy: "Admin",
  //     createdOn: "08/24/24 09:45:12",
  //     availability: "Availability",
  //     payRate: "USD/200,000.00/Yearly/Full Time",
  //     resume: "https://msnlabs.com/img/resume-sample.pdf",
  //     resumeTitle: "Use Document Title",
  //     resumeInfo:
  //       "Created By Lily Scarlette on 08/27/24 15:50:14 | Modified By Lily Scarlette",
  //   },
  //   {
  //     id: 4,
  //     applicantID: "459215",
  //     applicantName: {
  //       firstName: "Emily",
  //       middleName: "D.",
  //       lastName: "Davis",
  //     },
  //     gender: "Female",
  //     emailAddress: "emily.davis@example.com",
  //     mobileNumber: "(555) 456-7890",
  //     source: "Glassdoor",
  //     location: {
  //       city: "San Diego",
  //       state: "California",
  //       zipCode: "92101",
  //     },
  //     applicantStatus: "Hired",
  //     jobTitle: "Marketing Manager",
  //     ownership: "Lisa Brown",
  //     workAuthorization: "EAD",
  //     createdBy: "Admin",
  //     createdOn: "08/23/24 11:30:45",
  //     availability: "Availability",
  //     payRate: "USD/200,000.00/Yearly/Full Time",
  //     resume: "https://msnlabs.com/img/resume-sample.pdf",
  //     resumeTitle: "Use Document Title",
  //     resumeInfo:
  //       "Created By Lily Scarlette on 08/27/24 15:50:14 | Modified By Lily Scarlette",
  //   },
  //   {
  //     id: 5,
  //     applicantID: "459316",
  //     applicantName: {
  //       firstName: "David",
  //       middleName: "E.",
  //       lastName: "Williams",
  //     },
  //     gender: "Male",
  //     emailAddress: "david.williams@example.com",
  //     mobileNumber: "(555) 567-8901",
  //     source: "Monster",
  //     location: {
  //       city: "Sacramento",
  //       state: "California",
  //       zipCode: "94203",
  //     },
  //     applicantStatus: "Rejected",
  //     jobTitle: "HR Specialist",
  //     ownership: "Paul Anderson",
  //     workAuthorization: "H1-B",
  //     createdBy: "Admin",
  //     createdOn: "08/22/24 15:25:30",
  //     availability: "Availability",
  //     payRate: "USD/200,000.00/Yearly/Full Time",
  //     resume: "https://msnlabs.com/img/resume-sample.pdf",
  //     resumeTitle: "Use Document Title",
  //     resumeInfo:
  //       "Created By Lily Scarlette on 08/27/24 15:50:14 | Modified By Lily Scarlette",
  //   },
  //   {
  //     id: 6,
  //     applicantID: "459417",
  //     applicantName: {
  //       firstName: "Laura",
  //       middleName: "F.",
  //       lastName: "Martinez",
  //     },
  //     gender: "Female",
  //     emailAddress: "laura.martinez@example.com",
  //     mobileNumber: "(555) 678-9012",
  //     source: "Employee Referral",
  //     location: {
  //       city: "Fresno",
  //       state: "California",
  //       zipCode: "93721",
  //     },
  //     applicantStatus: "New lead",
  //     jobTitle: "UI/UX Designer",
  //     ownership: "Derek Morgan",
  //     workAuthorization: "Green Card",
  //     createdBy: "Admin",
  //     createdOn: "08/21/24 17:50:15",
  //     availability: "Availability",
  //     payRate: "USD/200,000.00/Yearly/Full Time",
  //     resume: "https://msnlabs.com/img/resume-sample.pdf",
  //     resumeTitle: "Use Document Title",
  //     resumeInfo:
  //       "Created By Lily Scarlette on 08/27/24 15:50:14 | Modified By Lily Scarlette",
  //   },
  // ];

  const columns = [
    {
       field:'',
       headerName:'',
       width: 20,
       renderCell: (params) => {
        const { status, note, jobCode, assignedTo,jobPostingId } = params.row;
        return (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              alignItems: "center",
            }}
          >
            {note&&note.length>0 && (
              <IconButton
                size="small"
                //onClick={() => handleNoteDtawer(params.row.id)}
                onClick={() => handleNoteDtawer(note)}
                title="note"
              >
                <SpeakerNotesIcon sx={{ fontSize: 14 }} color="warning" />
              </IconButton>
            )}
          </Box>
        );
      },
    },
    {
      field: "applicateId",
      headerName: "Applicant ID",
      width: 170,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
      renderCell: (params) => {
        return (
          <Button
            component={Link}
            state={{ applicant: params.row }}
            to={`/applicantDetails/${params.value}`} // Dynamic route if needed
            variant="text"
            sx={{ p: 0, color: "text.main", fontSize: 14, fontWeight: "400" }}
          >
            {params.value}
          </Button>
        );
      },
    },
    {
      field: "personalDetails",
      headerName: "Applicant Name",
      width: 230,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
      renderCell: (params) => {
        const { firstName, middleName, lastName, gender } =
          params.row.personalDetails;
        const fullName = `${firstName} ${middleName} ${lastName}`.trim();
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Avatar
              sx={{
                width: 18,
                height: 18,
                fontSize: 12,
                color: "white",
                background:
                  gender === "Male" ? theme.palette.primary.main : pink[300],
              }}
            >
              {gender.charAt(0).toUpperCase()}
            </Avatar>

            {fullName}
          </Box>
        );
      },
    },
    {
      field: "emailAddress",
      headerName: "Email Address",
      width: 270,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
      renderCell: (params) => {
        const emailAddress = params.row?.personalDetails?.emailAddress || "";
        return emailAddress;
      },
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
      renderCell: (params) => {
        const mobileNumber = params.row?.personalDetails?.mobileNumber || "";
        return mobileNumber;
      },
    },
    {
      field: "source",
      headerName: "Source",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
      renderCell: (params) => {
        const sourceOfResume =
          params.row?.professionalDetails?.sourceOfResume || "";
        return sourceOfResume;
      },
    },
    {
      field: "city",
      headerName: "City",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
      renderCell: (params) => {
        const { city } = params.row.personalDetails;
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {city}
          </Box>
        );
      },
    },
    {
      field: "state",
      headerName: "State",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
      renderCell: (params) => {
        const { state } = params.row.personalDetails;
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {state}
          </Box>
        );
      },
    },
    {
      field: "applicantStatus",
      headerName: "Applicant Status",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
      renderCell: (params) => {
        const title = params.row?.professionalDetails?.title || "";
        return title;
      },
    },
    // {
    //   field: "ownership",
    //   headerName: "Ownership",
    //   width: 200,
    //   sortable: false,
    //   filterable: false,
    //   disableColumnMenu: true,
    //   renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    // },
    {
      field: "workAuthorization",
      headerName: "Work Authorization",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "addedBy",
      headerName: "Created By",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "addedOn",
      headerName: "Created On",
      width: 250,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
  ];

  const[openModalNotAutharized,setOpenModalNotAutharized]=useState(false)

  const [selectAction, setSelectAction] = useState(
    jobPost ? "Submit to Job" : ""
  );
  const [addNote, setAddNote] = useState("");
  // Assign the entire object to selectJobTag
  const [selectJobTag, setSelectJobTag] = useState(filterJobPostCode || "");

  const handleChangeAction = (value) => {
    setSelectAction(value);
    const action={client:client}
    dispatch(searchDateJobPostingDataViaApi(action));

  };
  const handleJobSelect = (value) => {
    setSelectJobTag(value);
    const action={client:client}
    dispatch(searchDateJobPostingDataViaApi(action));
  };

  const handleApplyAction = () => {
    
    // Check if any rows are selected
    if (selectedRowData.length === 0) {
      return enqueueSnackbar("Please select at least one row!", {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }


    // Check if an action is selected
    if (!selectAction) {
      return enqueueSnackbar("Please select an action!", {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
    
   
    // Handle 'Add Notes' action
    if (selectAction === "Add Notes") {
      if (addNote && addNote.trim()) {
        selectedRowData.forEach((selectedRow) => {
          const newNote = {
            addedBy: storeData._id,
            addedOn: new Date(),
            note: addNote,
          };
          const existingNotes = Array.isArray(selectedRow.note)
            ? selectedRow.note
            : [];
          const updatedNotes = [...existingNotes, newNote];
          const action = {
            id: selectedRow._id,
            note: updatedNotes,
            client:client
          };
          dispatch(addApplicantNoteViaApi(action));
        });

        // Refresh the applicant data
        setLoading(true)
        setTimeout(() => {
          dispatch(getAllDegreesViaApi(client));
          const action={
            client:client
          }
          dispatch(searchDateJobPostingDataViaApi(action));
          dispatch(getAllApplicantsViaApi(action));
        }, 2000);
      } else {
        return enqueueSnackbar("Please add a note!", {
          variant: "error",
          action: (key) => (
            <IconButton onClick={() => closeSnackbar(key)} color="inherit">
              <CloseIcon />
            </IconButton>
          ),
        });
      }
    }

    // Handle 'Submit to Job' action
    else if (selectAction === "Submit to Job") {
      if (selectJobTag) {
        const takeJobCode = filterJobPostCode
        ? filterJobPostCode.value
        : selectJobTag.value;
const findId = JobPostingData.find(
        (job) => job.jobDetails.jobCode === takeJobCode
      );
const submissionData=findId?.submissions||[]

// Check if any applicant is already tagged by another person
const isAlreadyTagged = selectedRowData.some((select) => 
  submissionData.some((job) => select._id === job.applicateId && job.addedBy !== storeData._id)
);
if (isAlreadyTagged) {
 
  setOpenModalNotAutharized(!openModalNotAutharized)
  return; 
}


        const submission = selectedRowData.map((select) => ({
          addedBy: storeData._id,
          addedOn: new Date(),
          applicateId: select._id,
          applicantStatus: "SubmitJob",
        }));

       

        const jobSubmissionAction = {
          id: findId ? findId._id : "",
          submissions: submission,
        };

        let tagAction = [];

        selectedRowData.forEach((selectedRow) => {
          const tagData = {
            addedBy: storeData._id,
            addedOn: new Date(),
            jobPostId: findId ? findId._id : "",
            applicantStatus: "SubmitJob",
          };
          const existingTagJobs = Array.isArray(selectedRow.tagJobs)
            ? selectedRow.tagJobs.filter(
                (tag) => tag.jobPostId !== tagData.jobPostId
              )
            : [];
          const updatedTagJobs = [...existingTagJobs, tagData];
          tagAction.push({
            id: selectedRow._id,
            tagJobs: updatedTagJobs,
          });
        });

        const action = {
          jobSubmissionAction,
          tagAction,
          addedBy:storeData._id,
          client:client,
        };
        setLoading(true)
        dispatch(tagApplicantToJobViaApi(action));
        setTimeout(() => {
          dispatch(getAllDegreesViaApi(client));
          dispatch(searchDateJobPostingDataViaApi(action));
          dispatch(getAllApplicantsViaApi(action));
        }, 2000);
       
      } else {
        return enqueueSnackbar("Please select a job!", {
          variant: "error",
          action: (key) => (
            <IconButton onClick={() => closeSnackbar(key)} color="inherit">
              <CloseIcon />
            </IconButton>
          ),
        });
      }
    }

    // // Handle 'Tag to Job' action
    else if (selectAction === "Tag to Job") {
      if (selectJobTag) {
        const takeJobCode = filterJobPostCode
        ? filterJobPostCode.value
        : selectJobTag.value;
const findId = JobPostingData.find(
        (job) => job.jobDetails.jobCode === takeJobCode
      );
const submissionData=findId?.submissions||[]

// Check if any applicant is already tagged by another person
const isAlreadyTagged = selectedRowData.some((select) => 
  submissionData.some((job) => select._id === job.applicateId && job.addedBy !== storeData._id)
);
if (isAlreadyTagged) {
  setOpenModalNotAutharized(!openModalNotAutharized)
  return; 
}

        const submission = selectedRowData.map((select) => ({
          addedBy: storeData._id,
          addedOn: new Date(),
          applicateId: select._id,
          applicantStatus: "TagJob",
        }));

        const jobSubmissionAction = {
          id: findId ? findId._id : "",
          submissions: submission,
        };

        let tagAction = [];

        selectedRowData.forEach((selectedRow) => {
          const tagData = {
            addedBy: storeData._id,
            addedOn: new Date(),
            jobPostId: findId ? findId._id : "",
            applicantStatus: "TagJob",
          };
          const existingTagJobs = Array.isArray(selectedRow.tagJobs)
            ? selectedRow.tagJobs.filter(
                (tag) => tag.jobPostId !== tagData.jobPostId
              )
            : [];
          const updatedTagJobs = [...existingTagJobs, tagData];
          tagAction.push({
            id: selectedRow._id,
            tagJobs: updatedTagJobs,
          });
        });

        const action = {
          jobSubmissionAction,
          tagAction,
          addedBy:storeData._id,
          client:client,
        };
        setLoading(true)
        dispatch(tagApplicantToJobViaApi(action));
        setTimeout(() => {
          dispatch(getAllDegreesViaApi(client));
          const action={client:client}
          dispatch(searchDateJobPostingDataViaApi(action));
          dispatch(getAllApplicantsViaApi(action));
        }, 2000);
      
      } else {
        return enqueueSnackbar("Please select a job!", {
          variant: "error",
          action: (key) => (
            <IconButton onClick={() => closeSnackbar(key)} color="inherit">
              <CloseIcon />
            </IconButton>
          ),
        });
      }
    }

  };

  useEffect(() => {
    const action={client:client}
    dispatch(getAllResumeSourceViaApi(action));
    dispatch(getAllApplicantsViaApi(action));
    dispatch(getAllDegreesViaApi(client));
    dispatch(searchDateJobPostingDataViaApi(action));
  }, []);


  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      setLoading(false);

      // Set a timer to stop loading after 5 seconds
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      // Cleanup timer on unmount
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);

  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Applicants"}>
          <Box>
            <Grid container spacing={2} height={"100%"}>
              <Grid item xs={(selectedRows.length > 0 || jobPost) ? 9 : 12}>
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleAddApplicantModal}
                  >
                    Add New
                  </Button>
                </Box>
                {loading ? (
                  <>
                    <ShimmerTable row={5} col={5} />
                  </>
                ) : (
                  <>
                    <MainBackground>
                      <DataGrid
                        rows={ApplicantIdData}
                        columns={columns}
                        checkboxSelection
                        disableRowSelectionOnClick
                        disableColumnMenu
                        disableColumnSorting
                        disableColumnSelector
                        disableColumnFilter
                        disableColumnReorder
                        disableColumnResize
                        disableColumnSort
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                          },
                        }}
                        pageSizeOptions={[10]}
                        getRowHeight={() => "auto"}
                        autoHeight
                        sx={{
                          "& .MuiDataGrid-cell": {
                            display: "flex",
                            alignItems: "center !important",
                          },
                          [`& .MuiDataGrid-checkboxInput`]: {
                            color: theme.palette.primary.main,
                            p: 0,

                            "&.Mui-checked": {
                              color: theme.palette.primary.dark,
                            },
                          },
                          //Scroller
                          [`& .MuiDataGrid-scrollbar`]: {
                            scrollbarWidth: "thin",
                            scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                          },
                          [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]:
                            {
                              borderRadius: "10px", // Apply border radius
                            },

                          [`& .MuiDataGrid-scrollbar:hover`]: {
                            scrollbarColor: `${theme.palette.primary.dark}`,
                          },
                        }}
                        onRowSelectionModelChange={handleRowSelection}
                        rowSelectionModel={selectedRows}
                      />
                    </MainBackground>
                  </>
                )}
              </Grid>
              {(selectedRows.length > 0 || jobPost) && (
                <Grid
                  item
                  xs={3}
                  sx={{
                    position: "sticky", // Sticks within the container
                    top: -15, // Sticky from the top
                    height: "85vh", // Full viewport height
                    zIndex: 1, // Ensure it stays on top of other content
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        width: "100%",
                        top: 0,
                        bottom: 0,
                        height: "100%",
                      }}
                    >
                      <MainBackground height="full">
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                            gap: 2,
                          }}
                        >
                          <Box>
                            <CustomFormField
                              title={"Select Action "}
                              components={[
                                {
                                  type: "select",
                                  props: {
                                    title: "Action",
                                    options: [
                                      "Add Notes",
                                      // "Add Tag",
                                      // "Add to Folder",
                                      // "Add to Bench",
                                      // "Mark as Favorite",
                                      // "Remove Favorite Tag",
                                      // "Send Email",
                                      "Submit to Job",
                                      // "Delete",
                                      // "Change Ownership",
                                      // "Add to Snip List",
                                      // "Add to Group",
                                      // "Add Blacklist",
                                      "Tag to Job",
                                      // "Macro",
                                      // "Share Profiles",
                                      // "Share a Job",
                                      // "Archive",
                                      // "Download Resumes",
                                    ],
                                    //multiple: true,
                                    defaultValue: selectAction,
                                    onChange: (value) =>
                                      handleChangeAction(value),
                                  },
                                },
                              ]}
                            />
                          </Box>
                          {selectAction === "Add Notes" && (
                            <CustomFormField
                              title={"Add Notes"}
                              components={[
                                {
                                  type: "textArea",
                                  props: {
                                    title: "Action",

                                    //multiple: true,
                                    defaultValue: addNote,
                                    onChange: (value) => setAddNote(value),
                                  },
                                },
                              ]}
                            />
                          )}
                          {(selectAction === "Submit to Job" ||
                            selectAction === "Tag to Job") && (
                            <CustomFormField
                              title={selectAction}
                              components={[
                                {
                                  type: "autocomplete",
                                  props: {
                                    placeholder: `select the job`,
                                    options: jobPostingOptions,
                                    defaultValue: selectJobTag,
                                    onChange: (value) => handleJobSelect(value),
                                    // error: !!errors.sourceOfResume,
                                    // errorMessage: errors.sourceOfResume,
                                  },
                                },
                              ]}
                            />
                          )}
                          <Box sx={{ flex: 1, mb: 4, pb: 2 }}>
                            <Box
                              sx={{
                                display: "flex",
                                gap: 0.5,
                                alignItems: "center",
                                mb: 1,
                                mt: 2,
                              }}
                            >
                              <Avatar
                                sx={{
                                  width: 18,
                                  height: 18,
                                  fontSize: 12,
                                  background: theme.palette.primary.main,
                                }}
                              >
                                {selectedRows.length}
                              </Avatar>
                              <Typography>
                                {selectedRows.length > 1
                                  ? `Applicants`
                                  : `Applicant`}
                              </Typography>
                            </Box>
                            <ScrollView>
                              {selectedRowData.length > 0 ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 1,
                                  }}
                                >
                                  {selectedRowData.map((row) => (
                                    <Box
                                      key={row.id}
                                      sx={{
                                        borderRadius: 2,
                                        p: 1,
                                        mx: 0.5,
                                        background: `linear-gradient(to right bottom, ${theme.palette.grey[100]}, ${theme.palette.primary.contrastText})`,
                                        border: `1px solid ${theme.palette.grey[200]}`,
                                      }}
                                    >
                                      <Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            columnGap: 1,
                                            alignItems: "center",
                                            flexWrap: "wrap",
                                          }}
                                        >
                                          <Typography
                                            sx={{ fontWeight: "bold" }}
                                            noWrap
                                          >
                                            {`${row.personalDetails.firstName} ${row.personalDetails.middleName} ${row.personalDetails.lastName}`}
                                            -{row.applicateId || ""}
                                          </Typography>
                                          <Typography variant="caption" noWrap>
                                            {row.professionalDetails.title ||
                                              ""}
                                          </Typography>
                                        </Box>
                                        <Divider />
                                        <Box
                                          display={"flex"}
                                          flexWrap={"wrap"}
                                          py={0.5}
                                        >
                                          <Typography variant="caption" noWrap>
                                            {row.personalDetails.emailAddress ||
                                              ""}
                                          </Typography>
                                          <Box flex={1} />
                                          <Typography variant="caption" noWrap>
                                            {row.personalDetails.mobileNumber ||
                                              ""}
                                          </Typography>
                                        </Box>
                                        <Divider />
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          gap: 1,
                                          my: 1,
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexWrap: "nowrap",
                                            justifyContent: "space-between",
                                            gap: 1,
                                          }}
                                        >
                                          <KeyData
                                            title={"Availability:"}
                                            value={
                                              row.professionalDetails
                                                .availability || ""
                                            }
                                          />
                                          <Divider
                                            orientation="vertical"
                                            flexItem
                                          />
                                          <KeyData
                                            title={"Pay Rate:"}
                                            value={
                                              `${row.professionalDetails.payRateSalary.currency}/${row.professionalDetails.payRateSalary.minAmount}/${row.professionalDetails.payRateSalary.maxAmount}/${row.professionalDetails.payRateSalary.paySchedule}/${row.professionalDetails.payRateSalary.jobType} ` ||
                                              ""
                                            }
                                          />
                                        </Box>
                                        <Divider />
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexWrap: "nowrap",
                                            justifyContent: "space-between",
                                            gap: 1,
                                          }}
                                        >
                                          <KeyData
                                            title="Resume"
                                            value={
                                              row?.professionalDetails
                                                ?.resume || ""
                                            }
                                            link
                                            linkTitle={
                                              row?.professionalDetails
                                                ?.resume || ""
                                            }
                                            bottomInfo={
                                              row?.professionalDetails
                                                ?.resume || ""
                                            }
                                          />
                                        </Box>
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexWrap: "wrap",
                                          gap: 1,
                                          justifyContent: "end",
                                        }}
                                      >
                                        <Tooltip title="Edit">
                                          <IconButton
                                            aria-label="Edit"
                                            size="xsmall"
                                            color="warning"
                                          >
                                            <EditIcon
                                              fontSize="inherit"
                                              onClick={() =>
                                                handleEditApplicant(row)
                                              }
                                            />
                                          </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Delete">
                                          <IconButton
                                            aria-label="Delete"
                                            size="xsmall"
                                            color="error"
                                          >
                                            <DeleteIcon fontSize="inherit"  onClick={()=>handleDeleteSelectData(row)}/>
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                    </Box>
                                  ))}
                                </Box>
                              ) : (
                                <Typography>No rows selected</Typography>
                              )}
                            </ScrollView>
                          </Box>

                          <Box>
                            <Button
                              variant="contained"
                              onClick={handleApplyAction}
                            >
                              {" "}
                              {selectAction ? selectAction : "Apply"}
                            </Button>
                          </Box>
                        </Box>
                      </MainBackground>
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </LayoutSidebarMenu>
        <SnackbarComponent />

        <Modal open={isAddApplicantModalOpen} onClose={handleAddApplicantModal}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              background:
                "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)",
              height: "100vh",
            }}
          >
            <Box>
              <Paper
                elevation={1}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 1,
                  px: 4,
                }}
              >
                <Typography variant="h5" fontWeight={600}>
                  Add Applicant
                </Typography>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Button color="error" onClick={handleAddApplicantModal}>
                    Close
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveSubmitApplicant}
                  >
                    Submit Applicant
                  </Button>
                </Box>
              </Paper>
            </Box>

            <Box flex={1} overflow={"auto"} p={1}>
              <AddApplicantForm
                personalDetails={personalDetails}
                professionalDetails={professionalDetails}
                setPersonalDetails={setPersonalDetails}
                setProfessionalDetails={setProfessionalDetails}
                errors={errors}
                resumeSourcesNames={resumeSourcesNames}
                educationDetails={educationDetails}
                setEducationDetails={setEducationDetails}
                degreesNames={degreesNames}
              />
            </Box>
          </Box>
        </Modal>
        {/* <Drawer
        open={openNoteDrawer}
        onClose={() => setOpenNoteDrawer(false)}
        anchor="right"
      >
        <Box sx={{ width: 300, p: 5 }}>
    <Typography variant="h6">Note</Typography>
    {selectNoteData.map((note, index) => (
      <Box key={index} sx={{ mt: 2 }}>
        <Typography variant="body1">
          <strong>Added By:</strong> {allUsers.find((user)=>user._id===note.addedBy).userSudoFirstName  }
        </Typography>
        <Typography variant="body1">
          <strong>Added On:</strong> {formatDateForDisplay(note.addedOn)}
        </Typography>
        <Typography variant="body1">
          <strong>Note:</strong> {note.note}
        </Typography>
      </Box>
    ))}
  </Box>
      </Drawer> */}
      <Drawer
  open={openNoteDrawer}
  onClose={() => setOpenNoteDrawer(false)}
  anchor="right"
>
  <Box sx={{ width: 300, p: 3, position: 'relative' }}>
    <IconButton
      onClick={() => setOpenNoteDrawer(false)}
      sx={{ position: 'absolute', top: 8, right: 8 }}
    >
      <CloseIcon />
    </IconButton>
    <Typography variant="h6" sx={{ mt: 4 }}>Note</Typography>
    {selectNoteData.map((note, index) => (
      <Box key={index} sx={{ mt: 2 }}>
        <Typography variant="body1">
        <strong>Added By:</strong> {allUsers.find((user)=>user._id===note.addedBy)?.userSudoFirstName  }
        </Typography>
        <Typography variant="body1">
        <strong>Added On:</strong> {formatDateForDisplay(note.addedOn)}
        </Typography>
        <Typography variant="body1">
          <strong>Note:</strong> {note.note}
        </Typography>
      </Box>
    ))}
  </Box>
</Drawer>
<Modal
  open={openModalNotAutharized}
  aria-labelledby="child-modal-title"
  aria-describedby="child-modal-description"
>
  <Box
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "400px",
      width: "80%",
      bgcolor: "background.paper",
      border: "2px solid #f44336", // Red border to indicate danger
      boxShadow: 24,
      p: { xs: 2, md: 3 },
      borderRadius: 2,
    }}
  >
    <Box
      sx={{
        display: "flex",
        columnGap: 1,
        rowGap: 0.5,
        flexWrap: "wrap",
        mb: 3,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ReportProblemOutlinedIcon 
        sx={{ color: "#f44336", fontSize: 40 }} // Increase icon size to 40px
      />
      <Typography 
        fontWeight={500} 
        variant="h6"
        sx={{ color: "#f44336", textAlign: "center", fontSize: "1.25rem" }} // Slightly larger text
      >
        Other recruiter has already tagged or submitted this applicant
      </Typography>
    </Box>

    <Divider />

    <Box
      sx={{ display: "flex", justifyContent: "center", mt: 2 }}
    >
      <Button
        variant="contained"
        color="error" // Error color for the button
        sx={{ textTransform: "none", fontWeight: 500, fontSize: "1rem" }} // Increase button text size
        onClick={() => setOpenModalNotAutharized(false)} // Close modal on click
      >
        Cancel
      </Button>
    </Box>
  </Box>
</Modal>


      </SnackbarProvider>
    </React.Fragment>
  );
};

export default Applicants;
