import React, { useEffect, useState } from "react";
import { Box, Grid, Select } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import theme from "../../Theme";

import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import {
  Backgrounds,
  BDMCustomeDateFilter,
  CustomDateFilter,
  CustomSelect,
  KeyData,
  MainBackground,
  SectionTitle,
} from "../../Components";
import { bdmReportDateSearchViaApi } from "../../store/reports/action";
import { SnackbarProvider, enqueueSnackbar, useSnackbar } from "notistack";
import dayjs from "dayjs";
import Chart from "react-google-charts";
import { useDispatch, useSelector } from "react-redux";
dayjs.extend(require("dayjs/plugin/timezone"));
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.tz.setDefault("Asia/Kolkata");

const DashboardRecruiter = () => {
  const dispatch = useDispatch();
  const { storeData } = useSelector((state) => ({
    storeData: state.Login.storeData,
  }));
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const [searchFromDate, setSearchFromDate] = useState("");
  const [startingDate, setStartingDate] = useState(
    dayjs().tz("Asia/Kolkata").startOf("day")
  );



 
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Dashboard"}>
          {/* <Box sx={{ mb: 2 }}>
            <CustomDateFilter handleDateChange={handleDateChange} />
          </Box> */}

         
          
         
         
        </LayoutSidebarMenu>
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default DashboardRecruiter;