




import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import SectionTitle from "../Helper/SectionTitle";
import HelpIcon from "@mui/icons-material/Help";
import theme from "../../Theme";
import { DatePicker } from "@mui/x-date-pickers";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import dayjs from "dayjs";
import { currencyCodes, 
  payScheduleTypes, 
  employmentTypes,
   remoteJobOptions,
    jobStatusOptions,
    jobTypeOptions,
    requiredDocumentsOptions,
    turnaroundTimeOptions,
    priorityOptionsOptions,workAuthorizationOptions,industryOptions,
    languagesOptions,taxTermsOptions
   } from '../../Data/JobPostingData';
import CustomFormField from "../Common/CustomFormField";

const DefineStyle = {
  fontSize: 14,
  bgColor: "white",
  //bgColor: `linear-gradient(0deg, rgba(${theme.palette.primary.default},.07) 0%, rgba(${theme.palette.primary.default},0.02) 100%)`,
  boderColor: `rgba(${theme.palette.primary.default},.2)`,
  textColor: theme.palette.text.primary,
  placeholderColor: theme.palette.grey[900],
};

const FormSectionTitle = ({ title, info, required }) => {
  return (
    <React.Fragment>
      <Box sx={{ display: "flex" }}>
        <Typography
          noWrap
          sx={{
            fontSize: 14,
            fontWeight: 300,
            lineHeight: 1.2,
            letterSpacing: 0.5,
            color: theme.palette.text.primary,
          }}
        >
          {title}
        </Typography>
        {required && (
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              lineHeight: 0.8,
              mx: 0.2,
              color: theme.palette.error.dark,
            }}
          >
            *
          </Typography>
        )}
        {info && (
          <Tooltip title={info} arrow placement="top">
            <HelpIcon
              sx={{ color: "warning.main", fontSize: "12px", mx: 0.5 }}
            />
          </Tooltip>
        )}
      </Box>
    </React.Fragment>
  );
};


const CustomAutocomplete =(
  {placeholder,
  defaultValue,
  readOnly = false,
  multiline = false,
  onChange, // Generic onChange handle
  disabled,
  error = false, // Error state,
  errorMessage = "", // Error message to display
  type="text" 

  }
)=>{
  const handleChange = (event, newValue) => {
    if (onChange) {
      onChange(newValue);
    }
  };
  return(
    <FormControl fullWidth error={error} size="small">
    <Box>
      <Autocomplete
      placeholder={placeholder}
      clearIcon={false}
      value={defaultValue}
          onChange={handleChange}
      options={[]}
      freeSolo
      multiple
      renderTags={(value, props) =>
        value.map((option, index) => (
          <Chip label={option} {...props({ index })} size="small" />
        ))
      }
      renderInput={(params) => <TextField placeholder={placeholder} {...params}  sx={{
        background: DefineStyle.bgColor,
        "& .MuiInputBase-input::placeholder": {
          color: DefineStyle.placeholderColor,
          fontSize: DefineStyle.fontSize,
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: DefineStyle.boderColor, // Border color for the fieldset
          },
        },
      }} />}
    />
    </Box>
     {error && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  )
}


const CustomText = ({
  placeholder,
  defaultValue,
  readOnly = false,
  multiline = false,
  onChange, // Generic onChange handle
  disabled,
  error = false, // Error state,
  errorMessage = "", // Error message to display
  type="text" 
}) => {
  const handleChangeText = (event) => {
      if (onChange) {
        onChange(event.target.value);
      }
    };
  
  return (
    <React.Fragment>
      <TextField
        placeholder={placeholder}
        multiline={multiline}
        rows={multiline && 3}
        size="small"
        fullWidth
        type={type}
        value={defaultValue}
        InputProps={{
          readOnly: readOnly,
          sx: {
            fontSize: DefineStyle.fontSize,
            color: DefineStyle.textColor,
            py: 0.5,
          },
        }}
        sx={{
          background: DefineStyle.bgColor,
          "& .MuiInputBase-input::placeholder": {
            color: DefineStyle.placeholderColor,
            fontSize: DefineStyle.fontSize,
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: DefineStyle.boderColor, // Border color for the fieldset
            },
          },
        }}
        disabled={disabled}
        onChange={handleChangeText}
        error={error} // Highlight field if error
        helperText={error ? errorMessage : ""} // Display error message
      />
    </React.Fragment>
  );
};

const CustomTextEditor = ({ placeholder, defaultValue,onChange }) => {
  const handleTextEditorChange=(event)=>{
    if(onChange){
      onChange(event)
    }
  }
  return (
    <React.Fragment>
      <ReactQuill
        //ref={quillRef}
        theme="snow"
        value={defaultValue}
        onChange={handleTextEditorChange}
        placeholder={placeholder}
        modules={{
          toolbar: {
            container: [
              ["bold", "italic", "underline"],
              [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
              ],
              ["link"],
              ["clean"],
              [{ color: [] }, { background: [] }],
            ],
          },
        }}
      />
    </React.Fragment>
  );
};

const CustomSelect = ({ title, defaultValue = [], multiple = false, options = [], onChange,error = false, errorMessage = '' }) => {
  // Ensure defaultValue is always an array for multiple select
  const value = multiple ? (Array.isArray(defaultValue) ? defaultValue : []) : (defaultValue || '');
  const handleChange = (event) => {
    const newValue = event.target.value;
    // For multi-select, newValue is an array
    // For single-select, newValue is a single value
    onChange(newValue);
  };

  return (
    <FormControl fullWidth error={error} size="small">
    <Select
      fullWidth
      size="small"
      value={value}
      multiple={multiple}
      displayEmpty
      
      onChange={handleChange}
      renderValue={(selected) => {
        if (multiple) {
          if (selected.length === 0) {
            return (
              <Typography
                noWrap
                sx={{
                  color: 'gray', // DefineStyle.placeholderColor or similar
                  opacity: 0.4,
                  fontSize: '0.875rem', // DefineStyle.fontSize or similar
                  py: 0.15,
                  maxWidth: "60%",
                }}
              >
                {title}
              </Typography>
            );
          }
          return (
            <div>
              {selected.map((value) => (
                <Typography key={value} noWrap>{value}</Typography>
              ))}
            </div>
          );
        } else {
          if (selected === '') {
            return (
              <Typography
                noWrap
                sx={{
                  color: 'gray', // DefineStyle.placeholderColor or similar
                  opacity: 0.4,
                  fontSize: '0.875rem', // DefineStyle.fontSize or similar
                  py: 0.15,
                  maxWidth: "60%",
                }}
              >
                {title}
              </Typography>
            );
          }
          return selected;
        }
      }}
      sx={{
        maxWidth: "100%",
        background: 'white', // DefineStyle.bgColor or similar
        "& .MuiSelect-select": {
          padding: "11px 8px 10px 8px",
          fontSize: '0.875rem', // DefineStyle.fontSize or similar
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: 'gray', // DefineStyle.borderColor or similar
        },
      }}
    >
      <MenuItem disabled value="" sx={{ fontSize: '0.875rem' }}> {/* DefineStyle.fontSize or similar */}
        <Typography noWrap>{title}</Typography>
      </MenuItem>
      {options.map((option, index) => (
        typeof option === 'object' ? (
          <MenuItem key={index} value={option.value}>
            
            <ListItemText primary={option.key} />
          </MenuItem>
        ) : (
          <MenuItem key={index} value={option} sx={{ fontSize: "12px", fontWeight: 600 }}>
            
            {option}
          </MenuItem>
        )
      ))}
    </Select>
    {error && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};
const CustomSelectUser = ({
  title,
  defaultValue = [],
  multiple = false,
  options = [], // options will be ["123", "456"]
  onChange,
  error = false,
  errorMessage = '',
  allUsers = [], // list of users with { _id, userSudoFirstName }
}) => {
  // Ensure defaultValue is always an array for multiple select
  const value = multiple
    ? Array.isArray(defaultValue)
      ? defaultValue
      : []
    : defaultValue || '';

  const handleChange = (event) => {
    const newValue = event.target.value;
    onChange(newValue); // Return the ids when selected
  };

  // Debugging: Check if options and allUsers match

  // console.log('allUsers:', allUsers.map(user => user._id));

  return (
    <FormControl fullWidth error={error} size="small">
      <Select
        fullWidth
        size="small"
        value={value}
        multiple={multiple}
        displayEmpty
        onChange={handleChange}
        renderValue={(selected) => {
          if (multiple) {
            if (selected.length === 0) {
              return (
                <Typography
                  noWrap
                  sx={{
                    color: 'gray',
                    opacity: 0.4,
                    fontSize: '0.875rem',
                    py: 0.15,
                    maxWidth: '60%',
                  }}
                >
                  {title}
                </Typography>
              );
            }
            return (
              <div>
                {selected.map((id) => {
                  const user = allUsers.find((user) => user._id === id);
                  return (
                    <Typography key={id} noWrap>
                      {user ? user.userSudoFirstName : 'Unknown'}
                    </Typography>
                  );
                })}
              </div>
            );
          } else {
            if (selected === '') {
              return (
                <Typography
                  noWrap
                  sx={{
                    color: 'gray',
                    opacity: 0.4,
                    fontSize: '0.875rem',
                    py: 0.15,
                    maxWidth: '60%',
                  }}
                >
                  {title}
                </Typography>
              );
            }
            const user = allUsers.find((user) => user._id === selected);
            return user ? user.userSudoFirstName : 'Unknown';
          }
        }}
        sx={{
          maxWidth: '100%',
          background: 'white',
          '& .MuiSelect-select': {
            padding: '11px 8px 10px 8px',
            fontSize: '0.875rem',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'gray',
          },
        }}
      >
        <MenuItem disabled value="" sx={{ fontSize: '0.875rem' }}>
          <Typography noWrap>{title}</Typography>
        </MenuItem>
        {options.map((id, index) => {
          const user = allUsers.find((user) => user._id === id);
          return (
            <MenuItem key={index} value={id}>
              <ListItemText primary={user ? user.userSudoFirstName : 'Unknown'} />
            </MenuItem>
          );
        })}
      </Select>
      {error && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};



const CustomRadio = ({ title, defaultValue, options = [],onChange,error = false, errorMessage = '' }) => {
 


  const handleRadioChange = (event) => {
    const newValue = event.target.value;
    if (onChange) {
     
      onChange(newValue); // Pass only the value to the handler
    }
  };
  return (
    // <RadioGroup
    //   row
    //   name={`${title}-radio-buttons-group`}
    //   defaultValue={defaultValue}
    //   onChange={CustomRadioChange}
    // >
    //   {options.map((option, index) => {
    //     if (typeof option === "object") {
    //       return (
    //         <FormControlLabel
    //           key={index}
    //           value={option.value}
    //           control={
    //             <Radio
    //               size="small"
    //               sx={{
    //                 color: DefineStyle.placeholderColor,
    //                 opacity: 0.5,
    //                 py: 0.7,
    //                 "&.Mui-checked": {
    //                   color: theme.palette.warning.main,
    //                 },
    //               }}
    //             />
    //           }
            
    //           label={option.label}
    //           disabled={option.disabled || false} // Handle disabled option
    //         />
    //       );
    //     } else {
    //       return (
    //         <FormControlLabel
    //           key={index}
    //           value={option}
    //           control={
    //             <Radio
    //               size="small"
    //               sx={{
    //                 color: DefineStyle.placeholderColor,
    //                 opacity: 0.5,
    //                 py: 0.7,
    //                 "&.Mui-checked": {
    //                   color: "primary.main",
    //                   opacity: 1,
    //                 },
    //               }}
    //             />
    //           }
    //           onChange={CustomRadioChange}

    //           label={option} // Assuming the option value and label are the same
    //           sx={{
    //             "& .MuiFormControlLabel-label": {
    //               fontSize: DefineStyle.fontSize,
    //               color: DefineStyle.placeholderColor,
    //               opacity: 0.5,
    //             },
    //           }}
    //         />
    //       );
    //     }
    //   })}
    // </RadioGroup>
    <FormControl fullWidth error={error} size="small">
    <RadioGroup
    row
    name={`${title}-radio-buttons-group`}
    value={defaultValue} // Use value for controlled component
    onChange={handleRadioChange} // Handle changes
  >
    {options.map((option, index) => {
      const value = typeof option === "object" ? option.value : option;
      const label = typeof option === "object" ? option.label : option;

      return (
        <FormControlLabel
          key={index}
          value={value}
          control={
            <Radio
              size="small"
              sx={{
                color: DefineStyle.placeholderColor,
                opacity: 0.5,
                py: 0.7,
                "&.Mui-checked": {
                  opacity: 1,
                  color: theme.palette.primary.main,
                },
              }}
            />
          }
          label={label}
          disabled={typeof option === "object" && option.disabled} // Handle disabled option
        />
      );
    })}
  </RadioGroup>
  {error && <FormHelperText>{errorMessage}</FormHelperText>}
  </FormControl>
  );
};

const CustomSwitch = ({ title, defaultValue, options = ["Yes", "No"],onChange, error = false, errorMessage = '' }) => {
  // Manage the state of the switch
  // const [checked, setChecked] = useState(defaultValue);

  // Handle switch toggle
  const handleChange = (event) => {
    // setChecked(event.target.checked);
    if(onChange){
      onChange(event.target.checked)
    }
  };

  return (
    <FormControl fullWidth error={error} size="small">
    <Box display="flex" alignItems="center">
      <Switch
        checked={defaultValue}
        onChange={handleChange}
        sx={{
          width: 50,
          height: 26,
          padding: 0,
          "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: "4px",
            transitionDuration: "300ms",
            color: DefineStyle.boderColor,
            "&.Mui-checked": {
              transform: "translateX(24px)",
              color: theme.palette.primary.main,
              "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.grey[200],
                opacity: 1,
                border: 0,
              },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
              color: "#33cf4d",
              border: "6px solid #fff",
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
              color:
                theme.palette.mode === "light"
                  ? "red"
                  : theme.palette.grey[600],
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              //opacity: theme.palette.mode === "light" ? 1 : 1,
            },
          },
          "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 18,
            height: 18,
          },
          "& .MuiSwitch-track": {
            borderRadius: 13,
            backgroundColor: theme.palette.grey[200],
            opacity: 1,
          },
        }}
      />

      <Typography
        sx={{
          marginLeft: 1,
          fontSize: DefineStyle.fontSize,
          color: defaultValue
            ? theme.palette.primary.main
            : DefineStyle.placeholderColor,
          opacity: defaultValue ? 1 : 0.5,
        }}
      >
        {defaultValue ? options[0] : options[1]}
      </Typography>
    </Box>
    {error && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

const CustomDatePicker = ({
  onChange,defaultValue }) => {
  const handleChangeDate = (event) => {
   
    if (onChange) {
   

      onChange(event);
    }
  };
  return (
    <React.Fragment>
      <Box>
        <DatePicker
          sx={{
            width: "100%",
            borderRadius: "0px",
            background: DefineStyle.bgColor,
            "& .MuiOutlinedInput-root": {
              fontSize: DefineStyle.fontSize,
              "& fieldset": {
                borderColor: theme.palette.grey[500],
                borderColor: DefineStyle.boderColor,
              },
            },
            "& .MuiInputBase-input": {
              padding: "12px 8px 12px 8px", // Adjust padding as needed
            },
          }}
          value={dayjs(defaultValue)} // Corrected syntax here
        onChange={handleChangeDate}
        />
      </Box>
    </React.Fragment>
  );
};



const FormSection = ({ title, info, sectionInfo, required, components }) => {
  
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Box>
          <FormSectionTitle title={title} info={info} required={required} />
        </Box>
        <Box
          sx={{
            display: "flex",
            rowGap: 1,
            columnGap: 1,
            flexWrap: "wrap",
          }}
        >
          {components.map((component, index) => {
            const ComponentType = getComponentType(component.type);
            return (
              <Box key={index} sx={{ flex: 1, flexBasis: "120px" }}>
                <ComponentType {...component.props} sectionTitle={title} />
              </Box>
            );
          })}
        </Box>
        {sectionInfo && (
          <Box sx={{ px: 0.5, mt: -0.5 }}>
            <Typography
              noWrap
              sx={{
                fontSize: 12,
                color: DefineStyle.placeholderColor,
                letterSpacing: 0.5,
              }}
            >
              {sectionInfo}
            </Typography>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};

// Helper function to get the correct component type
const getComponentType = (type) => {
  switch (type) {
    case "select":
      return CustomSelect;
    case "text":
      return CustomText;
    case "datepicker":
      return CustomDatePicker;
    case "radio":
      return CustomRadio;
    case "switch":
      return CustomSwitch;
    case "textEditor":
      return CustomTextEditor;
    case "autocomplete":
      return CustomAutocomplete;
    case "selectUsers":
      return CustomSelectUser;
    default:
      return null;
  }
};

// const jobDetails = [
//   {
//     id: 1,
//     companyName: "Cal Solar Inc.",
//     jobTitle: "Project Manager",
//     jobLocation: "Hayward, CA",
//   },
// ];

const JobPostingForm = ({selectDocument,jobDetails,setJobDetails,skills,
  setSkills,organizationalInformation,setOrganizationalInformation,
  errors,allUsers,setAssignedTo,assignedTo,industryNames}) => {
    const recruiterIds = allUsers
    .filter(
      (user) =>
        user.userRole === "DeliveryManager"
    )
    .map((user) => user._id);
 // Function to handle input changes
const handleInputChange = (field, value) => {
  setJobDetails(prevData => ({
    ...prevData,
    [field]: value
  }));
};
// Function to handle nested input changes
const handleNestedInputChangeClientBillRate = (field, value) => {
  setJobDetails(prevData => ({
    ...prevData,
    clientBillRateSalary: {
      ...prevData.clientBillRateSalary,
      [field]: value
    }
  }));
};
// Function to handle nested input changes
const handleNestedInputChangePaySalary = (field, value) => {
  setJobDetails(prevData => ({
    ...prevData,
    payRateSalary: {
      ...prevData.payRateSalary,
      [field]: value
    }
  }));
};
const handleNestedInputChangeTurnoutTime = (field, value) => {
  setJobDetails(prevData => ({
    ...prevData,
    turnoutTime: {
      ...prevData.turnoutTime,
      [field]: value
    }
  }));
};
 // Function to handle input changes
 const handleInputChangeSkills = (field, value) => {
  setSkills(prevData => ({
    ...prevData,
    [field]: value
  }));
};
 
const handleNestedInputChangeExperience = (field, value) => {

  setSkills(prevData => ({
    ...prevData,
    experience: {
      ...prevData.experience,
      [field]: value
    }
  }));
};
// Function to handle input changes
const handleInputChangeOrganizationalInformation = (field, value) => {
  setOrganizationalInformation(prevData => ({
    ...prevData,
    [field]: value
  }));
};
function getDisplayString(fullString) {
  // Split the string by the hyphen and remove the last part (timestamp)
  const parts = fullString.split('-');
  
  // Join the first two parts to get the display string
  const displayString = `${parts[0]}-${parts[1]}`;

  return displayString;
}

  return (
    <React.Fragment>
      <Box
        sx={{
          px: 4,
          py: 3,
          display: "flex",
          flexDirection: "column",
          gap: 6,
        }}
      >
        <Box display={"flex"} flexDirection={"column"} gap={3}>
          <Box>
            <SectionTitle title="Job Details" size="sm" color="error" />
          </Box>
          <Box>
            <Grid container rowSpacing={5} columnSpacing={3}>
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Job Code"}
                  required
                  components={[
                    {
                      type: "text",
                      props: {
                        required: true,
                      //   defaultValue: generateJobString(selectDocument),
                      defaultValue: getDisplayString(jobDetails.jobCode),
                        readOnly: true,
                        placeholder: "Add Job Code",
                        disabled:true ,
                        error:!!errors.jobCode,
                        errorMessage:errors.jobCode 
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Job Title"}
                  required
                  components={[
                    {
                      type: "text",
                      props: {
                        required: true,
                      //   defaultValue: jobTitle,
                      defaultValue: jobDetails.jobTitle,
                        placeholder: "Add Job Title",
                        // disabled:true ,
                        // onChange: handleInputChange
                        onChange: (value) => handleInputChange('jobTitle', value),
                        error:!!errors.jobTitle,
                        errorMessage:errors.jobTitle 
                      },
                    },
                  ]}
                  
                />
              </Grid>
              {/* <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Job Website"}
                  required
                  components={[
                    {
                      type: "text",
                      props: {
                        // required: true,
                      //   defaultValue: jobTitle,
                      defaultValue: jobDetails.jobWebsite,
                        placeholder: "Add Job Website",
                      
                        // onChange: handleInputChange
                        onChange: (value) => handleInputChange('jobWebsite', value),
                     
                      },
                    },
                  ]}
                  
                />
              </Grid> */}
              <Grid item xs={12} sm={4}>
                <Box>
                  <FormSection
                    title={"Client Bill Rate / Salary"}
                    info={
                      "Client Bill Rate / Salary Client Bill Rate / SalaryClient Bill Rate / Salary"
                    }
                    
                    components={[
                      {
                        type: "select",
                        props: {
                          title: "Currency",
                          defaultValue: jobDetails.clientBillRateSalary.currency,
                          options: currencyCodes,
                          onChange: (value) => handleNestedInputChangeClientBillRate('currency', value),
                          error:!!errors.clientBillRateCurrency,
                          errorMessage:errors.clientBillRateCurrency 
                        },
                      },
                      {
                        type: "text",
                        props: {
                          required: true,
                          type:"number",
                          //placeholder: "Add Job Title",
                          defaultValue: jobDetails.clientBillRateSalary.amount,
                          onChange: (value) => handleNestedInputChangeClientBillRate('amount',value),
                          error:!!errors.clientBillRateAmount,
                          errorMessage:errors.clientBillRateAmount 
                        },
                      },
                      {
                        type: "select",
                        props: {
                          title: "Select",
                          defaultValue:jobDetails.clientBillRateSalary.paySchedule,
                          options: payScheduleTypes,
                          onChange: (value) => handleNestedInputChangeClientBillRate('paySchedule', value),
                          error:!!errors.clientBillRatePaySchedule,
                          errorMessage:errors.clientBillRatePaySchedule 
                        },
                      },
                      {
                        type: "select",
                        props: {
                          title: "Select",
                          defaultValue:jobDetails.clientBillRateSalary.jobType,
                          options: employmentTypes,
                          onChange: (value) => handleNestedInputChangeClientBillRate('jobType', value),
                          error:!!errors.clientBillRateJobType,
                          errorMessage:errors.clientBillRateJobType 
                        },
                      },
                    ]}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box>
                  <FormSection
                    title={"Pay Rate / Salary"}
                    info={"Pay Rate / Salary Info"}
            
                    components={[
                      {
                        type: "select",
                        props: {
                          title: "Currency",
                          defaultValue: jobDetails.payRateSalary.currency,
                          options: currencyCodes,
                          onChange: (value) => handleNestedInputChangePaySalary('currency', value),
                          error:!!errors.payRateCurrency,
                          errorMessage:errors.payRateCurrency 
                        },
                      },
                      {
                        type: "text",
                        props: {
                          type:"number",
                          required: true,
                          placeholder: "min",
                          defaultValue: jobDetails.payRateSalary.minAmount,
                          onChange: (value) => handleNestedInputChangePaySalary('minAmount', value),
                          error:!!errors.payRateMinAmount,
                          errorMessage:errors.payRateMinAmount 
                        },
                      },
                      {
                        type: "text",
                        props: {
                          type:"number",
                          required: true,
                          placeholder: "max",
                          defaultValue: jobDetails.payRateSalary.maxAmount,
                          onChange: (value) => handleNestedInputChangePaySalary('maxAmount', value),
                          error:!!errors.payRateMaxAmount,
                          errorMessage:errors.payRateMaxAmount
                        },
                      },
                      {
                        type: "select",
                        props: {
                          title: "Select",
                          options: payScheduleTypes,
                          defaultValue: jobDetails.payRateSalary.paySchedule,
                          onChange: (value) => handleNestedInputChangePaySalary('paySchedule', value),
                          error:!!errors.payRatePaySchedule,
                          errorMessage:errors.payRatePaySchedule
                        },
                      },
                      {
                        type: "select",
                        props: {
                          title: "Select",
                          options: employmentTypes,
                          defaultValue: jobDetails.payRateSalary.jobType,
                          onChange: (value) => handleNestedInputChangePaySalary('jobType', value),
                          error:!!errors.payRateJobType,
                          errorMessage:errors.payRateJobType
                        },
                      },
                    ]}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Job Start Date"}
                  // required
                  components={[
                    {
                      type: "datepicker",
                      props: {
                        required: true,
                        placeholder: "Add Job Start Date",
                        defaultValue: jobDetails.jobStartDate,
                        onChange: (value) =>handleInputChange('jobStartDate',value)
                      },
                     
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Job End Date"}
                  // required
                  components={[
                    {
                      type: "datepicker",
                      props: {
                        required: true,
                        placeholder: "Add Job End Date",
                        defaultValue: jobDetails.jobEndDate,
                        onChange: (value) =>handleInputChange('jobEndDate',value)
                      },
                    },
                  ]}
                />
              </Grid>
              {/* <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Respond By"}
                  
                  components={[
                    {
                      type: "select",
                      props: {
                        title: "Select",
                        options: ["Opt-1", "Opt-2"],
                        defaultValue: jobDetails.respondBy,
                        onChange: (value) =>handleInputChange('respondBy',value)
                      },
                    },
                  ]}
                />
              </Grid> */}
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Remote Job"}
                  required
                  components={[
                    {
                      type: "radio",
                      props: {
                        title: "Remote Job",
                        options: remoteJobOptions,
                        defaultValue: jobDetails.remoteJob,
                        onChange: (value) =>handleInputChange('remoteJob',value),
                        error:!!errors.remoteJob,
                          errorMessage:errors.remoteJob
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Expenses Paid"}
                  // required
                  components={[
                    {
                      type: "switch",
                      props: {
                        title: "Remote Job",
                        defaultValue: true,
                        options: ["Added", "Removed"],
                        defaultValue: jobDetails.expensesPaid,
                        onChange: (value) =>handleInputChange('expensesPaid',value)
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={2} />
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Country"}
                  required
                  components={[
                    {
                      type: "select",
                      props: {
                        title: "Country",
                        defaultValue: "USA",
                        options: ["USA"],
                        disabled:true  ,
                        defaultValue: jobDetails.country,
                        onChange: (value) =>handleInputChange('country',value),
                        error:!!errors.country,
                        errorMessage:errors.country
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"States"}
                  required
                  components={[
                    {
                      type: "text",
                      props: {
                        title: "States",
                        defaultValue: jobDetails.states,
                        disabled:true  ,
                        onChange: (value) =>handleInputChange('states',value)
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Location"}
                  required
                  components={[
                    {
                      type: "text",
                      props: {
                        title: "Location",
                        defaultValue: jobDetails.location,
                        disabled:true  ,
                        onChange: (value) =>handleInputChange('location',value),
                        error:!!errors.location,
                        errorMessage:errors.location
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Job Status"}
                  required={true}
                  components={[
                    {
                      type: "select",
                      props: {
                        required: true,
                        title: "Job Status",
                        options: jobStatusOptions,
                        defaultValue: jobDetails.jobStatus,
                        onChange: (value) =>handleInputChange('jobStatus',value),
                        error:!!errors.jobStatus,
                        errorMessage:errors.jobStatus
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Job Type"}
                  // required={true}
                  components={[
                    {
                      type: "select",
                      props: {
                        required: true,
                        title: "Job Type",
                        options: jobTypeOptions,
                        defaultValue: jobDetails.jobType,
                        onChange: (value) =>handleInputChange('jobType',value)
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Required Hour/Week"}
                  // required={true}
                  components={[
                    {
                      type: "text",
                      props: {
                        type:"number",
                        title: "Required Hour/Week",
                        defaultValue: jobDetails.requiredHourWeek,
                        onChange: (value) =>handleInputChange('requiredHourWeek',value)
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Client"}
                  // required={true}
                  components={[
                    {
                      type: "text",
                      props: {
                        required: true,
                        title: "Client",
                        // options: ["Opt-1", "Opt-2"],
                        defaultValue: jobDetails.client,
                        disabled:true ,
                        onChange: (value) =>handleInputChange('client',value)
                      },
                    },
                  ]}
                />
              </Grid>
              {/* <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Client Manager"}
                  // required={true}
                  components={[
                    {
                      type: "select",
                      props: {
                        required: true,
                        title: "Client Manager",
                        options: ["Opt-1", "Opt-2"],
                        defaultValue: jobDetails.clientManager,
                        onChange: (value) =>handleInputChange('clientManager',value)
                      },
                    },
                  ]}
                />
              </Grid> */}
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"End Client"}
                  // required={true}
                  components={[
                    {
                      type: "text",
                      props: {
                        title: "End Client",
                        defaultValue: jobDetails.endClient,
                        onChange: (value) =>handleInputChange('endClient',value)
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Client Job ID"}
                  // required={true}
                  components={[
                    {
                      type: "text",
                      props: {
                        title: "Client Job ID",
                        defaultValue: jobDetails.clientJobID,
                        onChange: (value) =>handleInputChange('clientJobID',value)
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Required Documents"}
                  // required={true}
                  components={[
                    {
                      type: "select",
                      props: {
                        required: true,
                        title: "Required Documents",
                        options: requiredDocumentsOptions,
                        defaultValue: jobDetails.requiredDocuments,
                        multiple:true,
                        onChange: (value) =>handleInputChange('requiredDocuments',value)
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Turnout Time"}
                  // required={true}
                  components={[
                    {
                      type: "text",
                      props: {
                        required: true,
                        size: 0.5,
                        defaultValue: jobDetails.turnoutTime.time,
                        onChange:(value)=>handleNestedInputChangeTurnoutTime('time',value)
                      },
                    },
                    {
                      type: "select",
                      props: {
                        required: true,
                        title: "In Days",
                        options: turnaroundTimeOptions,
                        defaultValue: jobDetails.turnoutTime.dates,
                        onChange:(value)=>handleNestedInputChangeTurnoutTime('dates',value)
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Priority"}
                  // required={true}
                  components={[
                    {
                      type: "select",
                      props: {
                        required: true,
                        title: "Priority",
                        options: priorityOptionsOptions,
                        defaultValue: jobDetails.priority,
                        onChange: (value) =>handleInputChange('priority',value)
                      },
                    },
                  ]}
                />
              </Grid>
              {/* <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Client Category"}
                  // required={true}
                  components={[
                    {
                      type: "select",
                      props: {
                        required: true,
                        title: "Client Category",
                        options: ["Opt-1", "Opt-2"],
                        defaultValue: jobDetails.clientCategory,
                        onChange: (value) =>handleInputChange('clientCategory',value)
                      },
                    },
                  ]}
                />
              </Grid> */}
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Duration"}
                  required={jobDetails.jobType==="Full Time"?false: true}
                  components={[
                    {
                      type: "text",
                      props: {
                        type:"number",
                        placeholder: "Duration",
                        required: true,
                        size: 0.5,
                        defaultValue: jobDetails.duration,
                        onChange: (value) =>handleInputChange('duration',value),
                        error:!!errors.duration,
                        errorMessage:errors.duration
                      },
                    },
                  ]}
                />
              </Grid>
              {/* <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Additional Details"}
                  // required={true}
                  components={[
                    {
                      type: "select",
                      props: {
                        required: true,
                        title: "Additional Details",
                        options: ["Opt-1", "Opt-2"],
                        defaultValue: jobDetails.additionalDetails,
                        onChange: (value) =>handleInputChange('additionalDetails',value)
                      },
                    },
                  ]}
                />
              </Grid> */}
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Area Code"}
                  // required={true}
                  components={[
                    {
                      type: "text",
                      props: {
                        required: true,
                        placeholder: "Area Code",
                        defaultValue: jobDetails.areaCode,
                        onChange: (value) =>handleInputChange('areaCode',value)
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Work Authorization"}
                  // required={true}
                  components={[
                    {
                      type: "select",
                      props: {
                        placeholder: "Work Authorization",
                        required: true,
                        size: 0.5,
                        options: workAuthorizationOptions,
                        multiple:true,
                        defaultValue: jobDetails.workAuthorization,
                        onChange: (value) =>handleInputChange('workAuthorization',value)
                      },
                    },
                  ]}
                />
              </Grid>
              
              {/* <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Interview Mode"}
                  // required={true}
                  components={[
                    {
                      type: "select",
                      props: {
                        required: true,
                        title: "Select",
                        options: ["Opt-1", "Opt-2"],
                        defaultValue: jobDetails.interviewMode,
                        onChange: (value) =>handleInputChange('interviewMode',value)
                      },
                    },
                  ]}
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Application Form"}
                  // required={true}
                  components={[
                    {
                      type: "select",
                      props: {
                        required: true,
                        title: "Select",
                        options: ["Opt-1", "Opt-2"],
                        defaultValue: jobDetails.applicationForm,
                        onChange: (value) =>handleInputChange('applicationForm',value)
                      },
                    },
                  ]}
                />
              </Grid> */}
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Clearance"}
                  // required={true}
                  components={[
                    {
                      type: "radio",
                      props: {
                        required: true,
                        defaultValue: "No",
                        options: ["Yes", "No"],
                        defaultValue: jobDetails.clearance,
                        onChange: (value) =>handleInputChange('clearance',value)
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Address"}
                  // required={true}
                  components={[
                    {
                      type: "text",
                      props: {
                        required: true,
                        placeholder: "Address",
                        multiline: true,
                        defaultValue: jobDetails.address,
                        onChange: (value) =>handleInputChange('address',value)
                      },
                    },
                  ]}
                />
              </Grid>
              {/* <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Employment Test Template"}
                  // required={true}
                  components={[
                    {
                      type: "select",
                      props: {
                        required: true,
                        title: "Select",
                        options: ["Opt-1", "Opt-2"],
                        defaultValue: jobDetails.employmentTestTemplate,
                        onChange: (value) =>handleInputChange('employmentTestTemplate',value)
                      },
                    },
                  ]}
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Employment Level"}
                  // required={true}
                  components={[
                    {
                      type: "select",
                      props: {
                        required: true,
                        title: "Select",
                        options: ["Opt-1", "Opt-2"],
                        defaultValue: jobDetails.employmentLevel,
                        onChange: (value) =>handleInputChange('employmentLevel',value)
                      },
                    },
                  ]}
                />
              </Grid> */}
            </Grid>
          </Box>
        </Box>

        <Box display={"flex"} flexDirection={"column"} gap={3}>
          <Box>
            <SectionTitle title="Skills" size="sm" color="error" />
          </Box>
          <Box>
            <Grid container rowSpacing={5} columnSpacing={3}>

              <Grid item xs={12} sm={2}>
                {/* <FormSection
                  title={"Industry"}
                  components={[
                    {
                      type: "select",
                      props: {
                        title: "Select",
                        options: industryOptions,
                        defaultValue: skills.industry,
                        onChange:(value)=>handleInputChangeSkills('industry',value)
                      },
                    },
                  ]}
                /> */}
                <CustomFormField
                title={"Industry"}
                required
                components={[
                  {
                    type: "autocomplete",
                    props: {
                      placeholder: "Industry",
                      required: true,
                      options: industryNames,
                      defaultValue: skills.industry,
                      onChange: (value) =>
                        handleInputChangeSkills('industry',value),
                      error: !!errors.sourceOfResume,
                      errorMessage: errors.sourceOfResume,
                    },
                  },
                ]}
              />
              </Grid>
              {/* <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Degree"}
                  components={[
                    {
                      type: "select",
                      props: {
                        title: "Select",
                        options: ["Opt-1", "Opt-2"],
                        defaultValue: skills.degree,
                        onChange:(value)=>handleInputChangeSkills('degree',value)
                      },
                    },
                  ]}
                />
              </Grid> */}
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Experience"}
                  sectionInfo={"in Years"}
                  required={true}
                  components={[
                    {
                      type: "text",
                      props: {
                        title: "text",
                        type:"number",
                        placeholder: "min",
                        defaultValue: skills.experience.min,
                        onChange:(value)=>handleNestedInputChangeExperience('min',value),
                        error:!!errors.skillsExperienceMin,
                        errorMessage:errors.skillsExperienceMin
                      },
                      
                    },
                    {
                      type: "text",
                      props: {
                        title: "text",
                        type:"number",
                        placeholder: "max",
                        defaultValue: skills.experience.max,
                        onChange:(value)=>handleNestedInputChangeExperience('max',value),
                        error:!!errors.skillsExperienceMax,
                        errorMessage:errors.skillsExperienceMax
                      },
                    },
                  ]}
                />
              </Grid>
              {/* <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Evaluation Template"}
                  components={[
                    {
                      type: "select",
                      props: {
                        title: "Select",
                        options: ["Opt-1", "Opt-2"],
                        defaultValue: skills.evaluationTemplate,
                        onChange:(value)=>handleInputChangeSkills('evaluationTemplate',value)
                      },
                    },
                  ]}
                />
              </Grid> */}
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Primary Skills"}
                  info={"Primary Skills details"}
                  required
                  components={[
                    {
                      type: "autocomplete",
                      props: {
                        title: "Select",
                        defaultValue: skills.primarySkills,
                        onChange:(value)=>handleInputChangeSkills('primarySkills',value),
                        error:!!errors.primarySkills,
                        errorMessage:errors.primarySkills
                      },
                    },
                  ]}
                />
                 note: Please type and press Enter.
              </Grid>
              {/* <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Secondary Skills"}
                  info={"Secondary Skills details"}
                  // required
                  components={[
                    {
                      type: "select",
                      props: {
                        title: "Select",
                        options: ["Opt-1", "Opt-2"],
                        defaultValue: skills.secondarySkills,
                        onChange:(value)=>handleInputChangeSkills('secondarySkills',value)
                      },
                    },
                  ]}
                />
              </Grid> */}
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Languages "}
                  components={[
                    {
                      type: "select",
                      props: {
                        title: "Select",
                        options: languagesOptions,
                        multiple:true,
                        defaultValue: skills.languages,
                        onChange:(value)=>handleInputChangeSkills('languages',value)
                      },
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box display={"flex"} flexDirection={"column"} gap={3}>
          <Box>
            <SectionTitle
              title="Organizational Information"
              size="sm"
              color="error"
            />
          </Box>
          <Box>
            <Grid container rowSpacing={5} columnSpacing={3}>
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Number of Positions"}
                  required
                  components={[
                    {
                      type: "text",
                      props: {
                        title: "text",
                        type:"number",
                        // options: ["Opt-1", "Opt-2"],
                        defaultValue: organizationalInformation.numberofPositions,
                        onChange:(value)=>handleInputChangeOrganizationalInformation('numberofPositions',value),
                        error:!!errors.numberofPositions,
                        errorMessage:errors.numberofPositions
                      },
                    },
                  ]}
                />
              </Grid>
              {/* <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Maximum Allowed Submissions"}
                  // required
                  components={[
                    {
                      type: "select",
                      props: {
                        title: "Select",
                        options: ["Opt-1", "Opt-2"],
                        defaultValue: organizationalInformation.maximumAllowedSubmission,
                        onChange:(value)=>handleInputChangeOrganizationalInformation('maximumAllowedSubmission',value)
                      },
                    },
                  ]}
                />
              </Grid> */}
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Tax Terms"}
                  required
                  components={[
                    {
                      type: "select",
                      props: {
                        title: "Select",
                        multiple:true,
                        options: taxTermsOptions,
                        defaultValue: organizationalInformation.taxTerms,
                        onChange:(value)=>handleInputChangeOrganizationalInformation('taxTerms',value),
                        error:!!errors.taxTerms,
                        errorMessage:errors.taxTerms
                      },
                    },
                  ]}
                />
              </Grid>
              {/* <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Sales Manager"}
                  required
                  components={[
                    {
                      type: "select",
                      props: {
                        title: "Select",
                        options: ["Opt-1", "Opt-2"],
                        defaultValue: organizationalInformation.salesManager,
                        onChange:(value)=>handleInputChangeOrganizationalInformation('salesManager',value)
                      },
                    },
                  ]}
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Department"}
                  required
                  components={[
                    {
                      type: "select",
                      props: {
                        title: "Select",
                        options: ["Opt-1", "Opt-2"],
                        defaultValue: organizationalInformation.department,
                        onChange:(value)=>handleInputChangeOrganizationalInformation('department',value)
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Recruitment Manager"}
                  required
                  components={[
                    {
                      type: "select",
                      props: {
                        title: "Select",
                        options: ["Opt-1", "Opt-2"],
                        defaultValue: organizationalInformation.recruitmentManager,
                        onChange:(value)=>handleInputChangeOrganizationalInformation('recruitmentManager',value)
                      },
                    },
                  ]}
                />
              </Grid> */}
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Assigned To"}
                  // required
                  components={[
                    {
                      type: "selectUsers",
                      props: {
                        title: "Select",
                        options: recruiterIds,
                        defaultValue: assignedTo,
                        onChange:(value)=>setAssignedTo(value),
                        allUsers:allUsers
                      },
                    },
                  ]}
                />
              </Grid>
              {/* <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Primary Recruiter"}
                  required
                  components={[
                    {
                      type: "select",
                      props: {
                        title: "Select",
                        options: ["Opt-1", "Opt-2"],
                        defaultValue: organizationalInformation.primaryRecruiter,
                        onChange:(value)=>handleInputChangeOrganizationalInformation('primaryRecruiter',value)
                      },
                    },
                  ]}
                />
              </Grid> */}
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Comments"}
                  // required
                  components={[
                    {
                      type: "text",
                      props: {
                        multiline: true,
                        defaultValue: organizationalInformation.comments,
                        onChange:(value)=>handleInputChangeOrganizationalInformation('comments',value)
                      },
                    },
                  ]}
                />
              </Grid>
              {/* <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Additional Notifications"}
                  // required
                  components={[
                    {
                      type: "select",
                      props: {
                        title: "Select",
                        options: ["Opt-1", "Opt-2"],
                        defaultValue: organizationalInformation.additionalNotifications,
                        onChange:(value)=>handleInputChangeOrganizationalInformation('additionalNotifications',value)
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormSection
                  title={"Career Portal Published Date"}
                  // required
                  components={[
                    {
                      type: "datepicker",
                      props: {
                        required: true,
                        placeholder: "Add Career Portal Published Date",
                        defaultValue: organizationalInformation.careerPortalPublishedDate,
                        onChange:(value)=>handleInputChangeOrganizationalInformation('careerPortalPublishedDate',value)
                      },
                    },
                  ]}
                />
              </Grid> */}
              <Grid item xs={12}>
                <FormSection
                  title={"Job Description"}
                  // required
                  components={[
                    {
                      type: "textEditor",
                      props: {
                        required: true,
                        placeholder: "Add Job Description",
                        defaultValue: organizationalInformation.jobDescription,
                        onChange:(value)=>handleInputChangeOrganizationalInformation('jobDescription',value)
                      },
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default JobPostingForm;