import {
  SnackbarProvider,
  closeSnackbar,
  enqueueSnackbar,
  useSnackbar,
} from "notistack";
import React, { useEffect, useState } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import {
  Box,
  Grid,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import {
  CustomDateFilter,
  DataWidget,
  MainBackground,
  SectionTitle,
  FilterHeader,
} from "../../Components";
import BusinessIcon from "@mui/icons-material/Business";
import { useSelector, useDispatch } from "react-redux";
import { dateSearchUsersCompaniesViaApi } from "../../store/search/action";
import dayjs from "dayjs";
import "dayjs/plugin/timezone";
import "dayjs/plugin/utc";
import { getAllUsers } from "../../store/auth/login/actions";
import theme from "../../Theme";
import { stopLoadingRedux } from "../../store/reports/action";
import { ShimmerTable } from "react-shimmer-effects";
dayjs.extend(require("dayjs/plugin/timezone"));
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.tz.setDefault("Asia/Kolkata");
const TeamCount = () => {
  const dispatch = useDispatch();
  const { storeData } = useSelector((state) => ({
    storeData: state.Login.storeData,
  }));
  const client=storeData.clientDb
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const [loading, setLoading] = useState(true);
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
  const [searchTeamCountJobPosition,setSearchTeamCountJobPosition]=useState("");
  const [searchTeamCountRAName,setSearchTeamCountRAName]=useState([]);
  const [searchTeamCountEmailID,setSearchTeamCountEmailID]=useState([]);
  let retrictAllUsers =
  allUsers &&
  allUsers.filter(
    (user) =>
      user.userEmailId === "ratest@tektreeinc.com" ||
      user.userEmailId === "lilyra@tektreeinc.com" ||
      user.userEmailId === "oliviara@tektreeinc.com" ||
      user.userEmailId === "emmara@tektreeinc.com" ||
      user.userEmailId === "charlottera@tektreeinc.com" ||
      user.userEmailId === "danielra@tektreeinc.com" ||
      user.userEmailId === "miara@tektreeinc.com" ||
      user.userEmailId === "williamra@tektreellc.com" ||
      user.userType.includes("BDM") ||user.userType.includes('SuperAdmin') || 
      user.userType.includes('Admin') || user.userType.includes('Developer')
  );
  const isAuthorizedUser =(storeData.userType.includes('SuperAdmin') ||  storeData.userType.includes('Developer'))
  if(isAuthorizedUser){
     retrictAllUsers =
    allUsers &&
    allUsers.filter(
      (user) =>

      user.userType.includes("BDM") ||user.userType.includes('SuperAdmin') || user.userType.includes('Developer')
    );
  }
  const restrictUserIds = retrictAllUsers
    ? retrictAllUsers.map((user) => user._id)
    : [];
    const AllCompaniesListRedux= useSelector((state) => state.Search.AllDateSearchComapniesList) || [];
    let AllCompaniesList =Array.isArray(AllCompaniesListRedux)?AllCompaniesListRedux:[]
  AllCompaniesList = AllCompaniesList.filter(
    (company) => !restrictUserIds.includes(company.addedBy)
  );
  let AlluserComapnies = Array.isArray(AllCompaniesList)
    ? AllCompaniesList.reduce((acc, companies) => {
        const useraddedBy = companies.addedBy;
        const existingGroupIndex = acc.findIndex(
          (group) => group.useraddedBy === useraddedBy
        );
        if (existingGroupIndex !== -1) {
          acc[existingGroupIndex].companies.push(companies);
        } else {
          acc.push({ useraddedBy, companies: [companies] });
        }
        return acc;
      }, []).map((group) => ({
        raName: group.useraddedBy,
        companies: group.companies,
      }))
    : [];

  const mergedData = AlluserComapnies.map((group) => {
    const userData = allUsers.find((user) => user._id === group.raName);
    if (userData) {
      return {
        raName: userData.userFirstName,
        userSudoFirstName: userData.userSudoFirstName,
        emailid: userData.userEmailId,
        position: userData.userLevel,
        companies: group.companies.map((company) => {
          return {
            ...company,
            addedBy: userData.userFirstName, // Update addedBy to user's name
          };
        }),
      };
    } else {
      return group;
    }
  });
  const filterSearchData = Array.isArray(mergedData) 
  ? mergedData.filter((data) => {
      // Convert necessary fields to lower case for case-insensitive comparison
      const lowerCaseEmailid = data.emailid?.toLowerCase() || "";
      const lowerCaseJobPosition = data.position?.toLowerCase() || "";
      const lowerCaseLeadRaName = data.raName || "";
      // Search term inputs
      const lowerCaseSearchJobPosition = searchTeamCountJobPosition?.toLowerCase() || "";
      const SearchTeamCountRAName = searchTeamCountRAName || [];
      const SearchTeamCountEmailID = searchTeamCountEmailID || [];
      // Filter logic
      return (
        lowerCaseJobPosition.includes(lowerCaseSearchJobPosition) &&
        (SearchTeamCountRAName.length === 0 || SearchTeamCountRAName.includes(lowerCaseLeadRaName)) &&
        (SearchTeamCountEmailID.length === 0 || SearchTeamCountEmailID.includes(lowerCaseEmailid))
      );
    })
  : [];

  const companiesListWithIds = Array.isArray(filterSearchData)
    ? filterSearchData.map((company, index) => ({
        ...company,
        id: index + 1,
      }))
    : [];
  useEffect(() => {
    const action={
      client:client
    }
    dispatch(getAllUsers(action));
  }, []);
  
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "raName",
      headerName: "RA Name",
      minWidth: 150,
      flex: 1,
      renderHeader: (params) => (<FilterHeader params={params}  type="multiselect" setSearchTeamCountRAName={setSearchTeamCountRAName} selectedValue={searchTeamCountRAName} title={'teamCountRAName'}  options={mergedData.map((user) => user.raName)}/>),
    },
    // {
    //   field: "userSudoFirstName",
    //   headerName: "Pseudo Name",
    //   minWidth: 150,
    //   flex: 1,
    // },
    {
      field: "emailid",
      headerName: "Email Id",
      minWidth: 150,
      flex: 1,
      renderHeader: (params) => (<FilterHeader params={params}  type="multiselect" setSearchTeamCountEmailID={setSearchTeamCountEmailID} selectedValue={searchTeamCountEmailID} title={'teamCountEmailId'}  options={mergedData.map((user) => user.emailid)}/>),
    },
    {
      field: "position",
      headerName: "Position",
      width: 180,
      renderHeader: (params) => (<FilterHeader params={params}  setSearchTeamCountJobPosition={setSearchTeamCountJobPosition} selectedValue={searchTeamCountJobPosition} title={'teamCountJobPosition'}/>),
    },
    {
      field: "companies",
      headerName: "Total Companies",
      width: 180,

      valueGetter: (value, row) => {
        if (row.companies && row.companies.length > 0) {
          return row.companies.length;
        } else {
          return "--";
        }
      },
    },
    {
      field: "jobDetails",
      headerName: "Total Job Details",
      width: 180,

      valueGetter: (value, row) => {
        if (row.companies && row.companies.length > 0) {
          const companies = row.companies;
          return companies.reduce((count, company) => {
            // return company.jobDetails && company.jobDetails.length > 0
            //   ? count + 1
            //   : count;
            if (company.jobDetails && company.jobDetails.length > 0) {
              return (
                count +
                company.jobDetails.filter(
                  (job) => job.jobLocation && job.jobDescription && job.jobTitle
                ).length
              );
            }
            return count;
          }, 0);
        } else {
          return "--";
        }
      },
    },
    {
      field: "contactDetails",
      headerName: "Total Contact Details",
      width: 180,

      valueGetter: (value, row) => {
        if (row.companies && row.companies.length > 0) {
          const companies = row.companies;

          return companies.reduce((count, company) => {
            return (
              count +
              (company.contactDetails ? company.contactDetails.length : 0)
            );
          }, 0);
        } else {
          return "--";
        }
      },
    },
  ];
  const totalCompanies = AllCompaniesList.length;
  const totalUsers = AlluserComapnies.length;
  const AVG = (totalUsers !== 0 ? totalCompanies / totalUsers : 0).toFixed(2);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchFromDate, setSearchFromDate] = useState("");
  const [searchToDate, setSearchToDate] = useState("");
  const handleDateChange = (selectedDates) => {
    setLoading(true);
    setSearchFromDate("");
    setSearchToDate("");
    if (selectedDates.length === 2) {
      const formattedDates = selectedDates.map((date, index) => {
        return date.toISOString();
      });

      const [fromDate, toDate] = selectedDates;
      setSearchFromDate(formattedDates[0]);
      setSearchToDate(formattedDates[1]);

      const action = {
        searchFromDate: formattedDates[0],
        searchToDate: formattedDates[1],
        client:client,
      };
      dispatch(dateSearchUsersCompaniesViaApi(action));
    }
  };

  useEffect(() => {
    // const today = new Date();
    const today = dayjs().tz("Asia/Kolkata").startOf("day");

    handleDateChange([today, today.endOf("day")]);
  }, []);

  const [jobDetailsCount, setJobDetailsCount] = useState(0);

  useEffect(() => {
    let count = 0;
    if (AllCompaniesList) {
      AllCompaniesList.forEach((company) => {
        if (company.jobDetails) {
          company.jobDetails.forEach((job) => {
            if (job.jobLocation && job.jobDescription && job.jobTitle) {
              count++;
            }
          });
        }
      });
    }
    setJobDetailsCount(count);
  }, [AllCompaniesList]);
  
  const getTop3ByCompanyCount = (data) => {
    // Make a copy of the data to sort, to avoid affecting the original array
    const sortedData = [...data].sort(
      (a, b) => b.companies.length - a.companies.length
    );
  
    // Get the top 3 objects
    return sortedData.slice(0, 3);
  };
  const top3Companies = getTop3ByCompanyCount(companiesListWithIds);
  const calculateTotalContacts = (user) => {
    return user.companies.reduce((total, company) => {
      return (
        total + (company.contactDetails ? company.contactDetails.length : 0)
      );
    }, 0);
  };
  
  const getTop3ByContactCount = (data) => {
    // Make a copy of the data to sort, to avoid affecting the original array
    const dataWithContactCount = data.map((user) => ({
      ...user,
      totalContacts: calculateTotalContacts(user),
    }));
    const sortedData = [...dataWithContactCount].sort(
      (a, b) => b.totalContacts - a.totalContacts
    );
    return sortedData.slice(0, 3);
  };
  const top3Contacts = getTop3ByContactCount(companiesListWithIds);


  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      setLoading(false);

      // Set a timer to stop loading after 5 seconds
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      // Cleanup timer on unmount
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Team Count"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              gap: 3,
            }}
          >
            <Box flex={1}></Box>
            {/* <Box>
              <DateTimePickerValue handleDateChange={handleDateChange} />
            </Box> */}
            <Box sx={{ mb: 1 }}>
              <CustomDateFilter handleDateChange={handleDateChange} />
            </Box>
          </Box>
            {loading?(
              <>
              <ShimmerTable row={5} col={5} />
              </>
            ):<>
        <Grid container columnSpacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <DataWidget
                title="Total Companies"
                value={AllCompaniesList.length}
                icon={<BusinessIcon />}
                color="primary"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <DataWidget
                title="Avg Companies/RA"
                value={AVG}
                icon={<BusinessIcon />}
                color="success"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <DataWidget
                title="Total Job Details"
                value={jobDetailsCount}
                icon={<BusinessIcon />}
                color="primary"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <DataWidget
                title="Total Contact Details"
                value={
                  AllCompaniesList
                    ? AllCompaniesList.reduce((count, company) => {
                        return (
                          count +
                          (company.contactDetails
                            ? company.contactDetails.length
                            : 0)
                        );
                      }, 0)
                    : 0
                }
                icon={<BusinessIcon />}
                color="success"
              />
            </Grid>
          </Grid>

          <Box sx={{ width: "100%", overflowX: "auto", mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <DataGrid
                  rows={companiesListWithIds}
                  columns={columns}
                  columnBuffer={companiesListWithIds.length} // Set columnBuffer to the number of columns
                  onRowSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRows = companiesListWithIds.filter((row) =>
                      selectedIDs.has(row.id)
                    );
                    setSelectedRows(selectedRows);
                  }}
                  disableColumnMenu
                  disableColumnSelector
                  disableColumnFilter
                  disableColumnReorder
                  disableColumnResize
                  disableRowSelectionOnClick
                  disableColumnSort
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 100 },
                    },
                  }}
                  // hideFooter={companiesListWithIds.length < 10}
                  pageSizeOptions={[100]}
                  getRowHeight={() => "auto"}
                  autoHeight
                  sx={{
                    "& .MuiDataGrid-cell": {
                      display: "flex",
                      alignItems: "center !important",
                    },
                    [`& .MuiDataGrid-checkboxInput`]: {
                      color: theme.palette.primary.main,
                      p: 0,
                      "&.Mui-checked": {
                        color: theme.palette.primary.dark,
                      },
                    },
                    //Scroller
                    [`& .MuiDataGrid-scrollbar`]: {
                      scrollbarWidth: "thin",
                      scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                    },
                    [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]: {
                      borderRadius: "10px", // Apply border radius
                    },

                    [`& .MuiDataGrid-scrollbar:hover`]: {
                      scrollbarColor: `${theme.palette.primary.dark}`,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <MainBackground height="full">
                  {top3Companies && top3Companies.length > 0 ? (
                    <>
                      <SectionTitle title="Top 3 RA (Total Companies)" />
                      {top3Companies.map((user, index) => (
                        <DataWidget
                          key={index}
                          title={user.raName}
                          value={user.companies.length}
                          type="line"
                        />
                      ))}
                    </>
                  ) : (
                    <>No Data Found</>
                  )}
                  {top3Contacts && top3Contacts.length > 0 ? (
                    <>
                  <SectionTitle title="Top 3 RA (Total Contact Details)" />
                  {top3Contacts &&
                    top3Contacts.map((user, index) => (
                      <DataWidget
                        key={index}
                        title={user.raName}
                        value={user.totalContacts}
                        type="line"
                      />
                    ))}
                    </>
                     ) : (
                      <></>
                    )}
                  
                </MainBackground>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              mt: 5,
              display: "flex",
              justifyContent: "center",
              gap: 3,
            }}
          ></Box>
            </>}
          

        </LayoutSidebarMenu>
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default TeamCount;



