
export const SEARCH_RA_REPORTS_VIA_API='SEARCH_RA_REPORTS_VIA_API';
export const SUCCESS_RA_REPORTS='SUCCESS_RA_REPORTS';
export const DELETE_REPORTS='DELETE_REPORTS';

export const PRODUCTION_DATE_SEARCH_VIA_API='PRODUCTION_DATE_SEARCH_VIA_API';

export const SUCCESS_PRODUCTION_DATE_SEARCH='SUCCESS_PRODUCTION_DATE_SEARCH';

export const LOADING_VIA_REDUX='LOADING_VIA_REDUX';
export const STOP_LOADING_REDUX='STOP_LOADING_REDUX';

export const BDM_REPORT_DATE_SEARCH_VIA_API='BDM_REPORT_DATE_SEARCH_VIA_API';
export const SUCCESS_BDM_REPORT_DATE_SEARCH='SUCCESS_BDM_REPORT_DATE_SEARCH';

export const SEARCH_DATE_BDM_COMPANY_STATUS_VIA_API='SEARCH_DATE_BDM_COMPANY_STATUS_VIA_API';
export const SUCCESS_SEARCH_DATE_BDM_COMPANY_STATUS='SUCCESS_SEARCH_DATE_BDM_COMPANY_STATUS';

export const SEARCH_BLOCKED_DNC_NOFALLOWUP_COMPANIES_VIA_API='SEARCH_BLOCKED_DNC_NOFALLOWUP_COMPANIES_VIA_API';
export const SUCCESS_SEARCH_BLOCKED_DNC_NOFALLOWUP_COMPANIES='SUCCESS_SEARCH_BLOCKED_DNC_NOFALLOWUP_COMPANIES';


export const SEARCH_BLOCKED_DNC_EMAILS_VIA_API='SEARCH_BLOCKED_DNC_EMAILS_VIA_API';
export const  SUCCESS_SEARCH_BLOCKED_DNC_EMAILS='SUCCESS_SEARCH_BLOCKED_DNC_EMAILS';

export const GET_DESKTOP_CLIENTS_VIA_API='GET_DESKTOP_CLIENTS_VIA_API';
export const SUCCESS_GET_DESKTOP_CLIENTS='SUCCESS_GET_DESKTOP_CLIENTS';
export const EDIT_DESKTOP_CLIENTS_VIA_API='EDIT_DESKTOP_CLIENTS_VIA_API';

export const BDM_WEEK_REPORT_VIA_API='BDM_WEEK_REPORT_VIA_API';
export const SUCCESS_BDM_WEEK_REPORT='SUCCESS_BDM_WEEK_REPORT';


export const SUCCESS_RA_WEEK_REPORTS='SUCCESS_RA_WEEK_REPORTS';
export const SUCCESS_YESTERDAY_RA_REPORTS='SUCCESS_YESTERDAY_RA_REPORTS';


