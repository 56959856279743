// import { Box, Divider, Typography } from "@mui/material";
// import React from "react";

// const SectionTitle = ({ title }) => {
//   return (
//     <React.Fragment>
//       <Box
//         sx={{
//           pb: 1,
//           display: "flex",
//           flexDirection: "row",
//           gap: 2,
//           justifyContent: "space-between",
//           alignItems: "center",
//         }}
//       >
//         <Box>
//           <Typography
//             sx={{
//               letterSpacing: 0.5,
//               wordSpacing: 2,
//               lineHeight: 1,
//               fontSize: 15,
//               fontWeight: 600,
//               textTransform: "uppercase",
//             }}
//           >
//             {title}
//           </Typography>
//         </Box>
//         <Box></Box>
//       </Box>
//       <Divider />
//     </React.Fragment>
//   );
// };

// export default SectionTitle;

// import { Box, Divider, Typography } from "@mui/material";
// import React from "react";

// const SectionTitle = ({ title, small = false }) => {
//   return (
//     <React.Fragment>
//       <Box
//         sx={{
//           pb: 1,
//           display: "flex",
//           flexDirection: "row",
//           gap: 2,
//           justifyContent: "space-between",
//           alignItems: "center",
//         }}
//       >
//         <Box>
//           <Typography
//             sx={{
//               letterSpacing: small ? 0.2 : 0.5,
//               wordSpacing: small ? 1 : 2,
//               lineHeight: 1,
//               fontSize: small ? 12 : 15,
//               fontWeight: 600,
//               textTransform: "uppercase",
//             }}
//           >
//             {title}
//           </Typography>
//         </Box>
//         <Box></Box>
//       </Box>
//       <Divider />
//     </React.Fragment>
//   );
// };

// export default SectionTitle;

import { Box, Typography } from "@mui/material";
import React from "react";
import theme from "../../Theme";

const SectionTitle = ({
  title = "Section Title",
  small = false,
  size = "md",
  color = "primary", //primary, secondary, info, success,warning,error
}) => {
  return (
    <React.Fragment>
      <Box sx={{ borderLeft: `4px solid ${theme.palette[color].light}`,}}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 500,
            fontSize: size == "xl" ? 24 : size == "sm" ? 16 : 18,
            ml: 0.7,
            py: 0.5,

            letterSpacing: 0.1,
          }}
        >
          {title}
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default SectionTitle;
