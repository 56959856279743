// import { SnackbarProvider } from "notistack";
// import React, { useState } from "react";
// import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
// import { Box, Button, TextField } from "@mui/material";
// import data from "../../data/ats.companydatas.json";

// const SearchFilter = () => {
//   const [searchTerm, setSearchTerm] = useState("");
//   const [filteredData, setFilteredData] = useState([]);

//   const handleSearch = () => {
//     const filtered = data.filter(
//       (item) => item.companyName.toLowerCase() === searchTerm.toLowerCase()
//     );
//     setFilteredData(filtered);
//   };

//   const dataLength = filteredData.length;

//   return (
//     <React.Fragment>
//       <SnackbarProvider
//         maxSnack={4}
//         anchorOrigin={{ horizontal: "right", vertical: "top" }}
//       >
//         <LayoutSidebarMenu>
//           <Box
//             sx={{ maxWidth: 600, display: "flex", margin: "0px auto", mt: 5 }}
//           >
//             <TextField
//               fullWidth
//               value={searchTerm}
//               onChange={(e) => setSearchTerm(e.target.value)}
//               placeholder="Enter company name"
//             />
//             <Button variant="contained" onClick={handleSearch}>
//               Search
//             </Button>
//           </Box>
//           <Box sx={{ mt: 2, textAlign: "center" }}>
//             {searchTerm && (
//               <p>
//                 The company name "{searchTerm}" appears {dataLength} times.
//               </p>
//             )}
//           </Box>
//         </LayoutSidebarMenu>
//       </SnackbarProvider>
//     </React.Fragment>
//   );
// };

// export default SearchFilter;

import { SnackbarProvider } from "notistack";
import React, { useState } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { ContactDetailForm, JobDetailForm } from "../../Components";
import theme from "../../Theme";
// import data from "../../data/ats.companydatas.json";

const SearchFilter = () => {
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu></LayoutSidebarMenu>
      </SnackbarProvider>
      <Modal open={true}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "1000px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: {
              xs: 1.5,
              md: 3,
            },
            borderRadius: 2,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.primary.dark,
              fontWeight: "bold",
            }}
          >
            Company Name
          </Typography>
          <JobDetailForm />
          <Box
            sx={{
              mt: 3,
              mb: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>
              Contacts Details
            </Typography>
            <Button>Add more Contacts +</Button>
          </Box>
          <ContactDetailForm />
          <ContactDetailForm />
          <ContactDetailForm />

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
              pt: 4,
            }}
          >
            <Button variant="text">Cancel</Button>
            <Button variant="contained">Submit</Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default SearchFilter;
