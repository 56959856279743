import { Box, Typography } from "@mui/material";
import React from "react";

import DefaultLayout from "./DefaultLayout";
import Type1Layout from "./Type1Layout";
import Type2Layout from "./Type2Layout";
import Type3Layout from "./Type3Layout";
import Type4Layout from "./Type4Layout";
import Type5Layout from "./Type5Layout";
import DataSaverOffOutlinedIcon from "@mui/icons-material/DataSaverOffOutlined";

// Mapping object for layout components
const layoutComponents = {
  default: DefaultLayout,
  type1: Type1Layout,
  type2: Type2Layout,
  type3: Type3Layout,
  type4: Type4Layout,
  type5: Type5Layout,
};

const WidgetMain = ({
  type = "default", // default, type1, type2
  title = "Title is Empty",
  value = 0,
  compairValue = 0,
  icon = <DataSaverOffOutlinedIcon />,
  color = "primary",
  variant = "text", //contained, text
  result = "percentage", //number, percentage
  shadow = true,
  invert = false,
  messages = [],
}) => {
  // Select the appropriate layout component based on the type
  const LayoutComponent = layoutComponents[type] || DefaultLayout;

  return (
    <>
      <LayoutComponent
        title={title}
        value={value}
        compairValue={compairValue}
        icon={icon}
        color={color}
        variant={variant}
        result={result}
        shadow={shadow}
        invert={invert}
        messages={messages}
      />
    </>
  );
};

export default WidgetMain;
