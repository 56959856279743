
import React, { useRef, useState } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const sanitizeTitle = (title) => {
  return title.toLowerCase().replace(/[^a-z0-9]/g, "-");
};

const CustomSelectThree = ({ title, options, value, onChange }) => {
  const [open, setOpen] = useState(false);
  const selectRef = useRef(null);
  const handleChange = (event) => {
    
    onChange(title, event.target.value);
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const id = sanitizeTitle(title);
  return (
    <Box onClick={handleClose} sx={{ width: "100%" }}>
      <FormControl
        variant="standard"
        size="small"
        sx={{ width: "100%" }}
        ref={selectRef}
        onClick={handleClick}
      >
        <InputLabel id={`${id}-label`}>{title}</InputLabel>
        <Select
          labelId={`${id}-label`}
          id={id}
          value={value || "all"}
          onChange={handleChange}
          label={title}
          open={open}
          MenuProps={{
            hideBackdrop: true,
          }}
        >
          {/* {options.map((item, key) => (
            <MenuItem value={item} key={key}>
              {item.userFirstName}
            </MenuItem>
          ))} */}
          {options.map((item, key) => (
            <MenuItem value={item._id} key={key}>
              {item.userSudoFirstName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default CustomSelectThree;
