import { Box, Button, Card, IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import React, { useEffect, useState } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import { SnackbarProvider } from "notistack";
import { Link, useNavigate } from "react-router-dom";
// import { usersList } from "../../Data/Users";
import theme from "../../Theme";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../store/auth/login/actions";
const UserProfile = ({ userDetail, allUsers }) => {
  const [hovered, setHovered] = useState(false);
  const navigate = useNavigate();
  const handleMouseEnter = () => {
    setHovered(true);
  };
  const storeData = useSelector((state) => state.Login.storeData);
  const client=storeData.clientDb
  const handleMouseLeave = () => {
    setHovered(false);
  };
  const handleUpdateUsers = (userDetail) => {
    if (userDetail) {
      navigate("/updateUser", { state: { userDetail } });
    }
  };
  return (
    <>
      <Grid item xs={6} sm={4} md={3} lg={2}>
        {userDetail.userEmailId === "ratest@tektreeinc.com" ||
          userDetail.userEmailId === "emily.johnson@tektreecorp.com"}
        <Card
          sx={{
            position: "relative",
            opacity: userDetail.userStatus === "Inactive" ? 0.3 : 1,
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {hovered && (
            <Box sx={{ position: "absolute", right: 5, top: 5 }}>
              <IconButton
                size="small"
                onClick={() => handleUpdateUsers(userDetail)}
              >
                <ModeEditOutlineOutlinedIcon fontSize="12" />
              </IconButton>
            </Box>
          )}
          <Box
            sx={{
              width: "100%",
              aspectRatio: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              bgcolor:
                userDetail.login === "Active"
                  ? theme.palette.success.dark
                  : theme.palette.grey[400],
            }}
          >
            <Typography
              variant="h1"
              sx={{
                color: theme.palette.primary.contrastText,
                textTransform: "uppercase",
              }}
            >
              {userDetail.userFirstName.charAt(0)}
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography
              variant="body1"
              sx={{
                fontWeight: "600",
                color: theme.palette.grey[800],
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {userDetail.userFirstName} {userDetail.userLastName}
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 0.2,
                alignItems: "center",
                color: theme.palette.grey[300],
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.grey[400],
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {userDetail.userEmailId}
              </Typography>
              <IconButton size="small" color="inherit">
                <ContentCopyOutlinedIcon fontSize="8" />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.grey[400],
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {userDetail.userType}
              </Typography>

              {/* <Typography
                variant="body2"
                sx={{
                  color: theme.palette.grey[400],
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {userDetail.userLevel}
              </Typography> */}
            </Box>
            <Box>
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.grey[400],
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {/* {allUsers&&allUsers.find((user)=>user._id===(userDetail.userReportingTo).user.userFirstName)} */}
                {allUsers &&
                  (allUsers.find(
                    (user) => user._id === userDetail.userReportingTo
                  )
                    ? allUsers.find(
                        (user) => user._id === userDetail.userReportingTo
                      ).userFirstName
                    : "--")}
              </Typography>
            </Box>
          </Box>
        </Card>
      </Grid>
    </>
  );
};

const Users = () => {
  const dispatch = useDispatch();
  const allUsersData = useSelector((state) => state.Login.allUsers);
  let allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const storeData = useSelector((state) => state.Login.storeData);
  const client=storeData.clientDb
  const isAuthorizedUser =(storeData.userType.includes('SuperAdmin') ||  storeData.userType.includes('Developer'))
  if (!isAuthorizedUser) {
    allUsers = allUsers.filter(
      (user) =>
        user.userEmailId !== "ratest@tektreeinc.com" &&
      user.userEmailId !== "lilyra@tektreeinc.com" &&
      user.userEmailId !== "oliviara@tektreeinc.com" &&
      user.userEmailId !== "emmara@tektreeinc.com" &&
      user.userEmailId !== "charlottera@tektreeinc.com" &&
      user.userEmailId !== "danielra@tektreeinc.com" &&
      user.userEmailId !== "miara@tektreeinc.com" &&
      user.userEmailId !== "williamra@tektreellc.com" &&
        user.userEmailId !== "emily.johnson@tektreecorp.com"&&
        user.userEmailId !== "olivia.clark@tektreellc.com"&&
        user.userEmailId !== "emma.scott@tektreellc.com"&&
        user.userEmailId !== "william.turner@tektreellc.com"&&
        user.userEmailId !== "charlotte.mitchell@tektreellc.com"&&
        user.userEmailId !== "daniel.clark@tektreellc.com"
    );
  }
  useEffect(() => {
    const action={
      client:client
    }
    dispatch(getAllUsers(action));
  }, []);
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Users"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 2,
              alignItems: "center",
            }}
          >
            <Box flex={1}></Box>
            <Button variant="contained" LinkComponent={Link} to={"/addUser"}>
              Add New User
            </Button>
          </Box>

          <Grid container spacing={{ xs: 1, md: 2 }}>
            {allUsers.map((item, key) => {
              return (
                <UserProfile userDetail={item} key={key} allUsers={allUsers} />
              );
            })}
          </Grid>
        </LayoutSidebarMenu>
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default Users;
