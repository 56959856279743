import { Box, Typography } from "@mui/material";
import React from "react";
import theme from "../../../Theme";
import { grey } from "@mui/material/colors";
import DefaultLayout from "./DefaultLayout";
import LineLayout from "./LineLayout";
import DataSaverOffOutlinedIcon from "@mui/icons-material/DataSaverOffOutlined";

const DataWidget = ({
  title = "Title is Empty",
  value = 0,
  cValue = undefined,
  color='primary',
  type = "default", //default, line
  icon = <DataSaverOffOutlinedIcon color={color} />,
}) => {
  switch (type) {
    case "line":
      return <LineLayout title={title} value={value} cValue={cValue} />;
    case "default":
    default:
      return (
        <DefaultLayout
          title={title}
          value={value}
          cValue={cValue}
          icon={icon}
        />
      );
  }
};

export default DataWidget;
