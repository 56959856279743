import { Box, Typography } from "@mui/material";
import React from "react";
import theme from "../../../Theme";
import { grey } from "@mui/material/colors";

const DefaultLayout = ({ title, value, cValue, icon }) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          flex: 1,
          bgcolor: "rgba(255,255,255,.5)",
          px: 1.5,
          py: 1.5,
          borderRadius: 2,
          boxShadow:"rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;",
        }}
      >
        <Box>
          <Typography noWrap sx={{ opacity: 0.4 }} variant="subtitle2">
            {title}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: 40,
            mt: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              gap: 0.1,
            }}
          >
            <Typography
              sx={{
                fontSize: "40px",
                fontWeight: 700,
                color: theme.palette.text.primary,
                lineHeight: "33px",
              }}
            >
              {value}
            </Typography>
            {typeof cValue === "number" && (
              <>
                <Typography
                  sx={{
                    fontWeight: 500,
                    color: theme.palette.text.primary,
                    lineHeight: 1,
                  }}
                >
                  /
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 500,
                    color: theme.palette.text.primary,
                    lineHeight: 1,
                  }}
                >
                  {cValue}
                </Typography>
              </>
            )}
          </Box>
          {icon && (
            <Box>
              <Box
                sx={{
                  bgcolor: grey[100],
                  width: 40,
                  height: 40,
                  overflow: "hidden",
                  borderRadius: 40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {icon}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default DefaultLayout;
