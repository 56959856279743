export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const API_ERROR = "LOGIN_API_ERROR";
export const RESET_LOGIN_FLAG = "RESET_LOGIN_FLAG";

export const SOCIAL_LOGIN = "SOCIAL_LOGIN";
export const GET_PROFILE_DATA_VIA_API = "GET_PROFILE_DATA_VIA_API";
export const SUCCESS_GET_PROFILE_DATA = "SUCCESS_GET_PROFILE_DATA";

export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";

export const LOGOUT_USER = "LOGOUT_USER";

export const LOGIN_USER_ACTIVE_VIA_API="LOGIN_USER_ACTIVE_VIA_API";

export const UPDATE_USER_VIA_API='UPDATE_USER_VIA_API';
export const UPDATE_USERS_TYPES_VIA_API='UPDATE_USERS_TYPES_VIA_API';

