import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import theme from "../../Theme";
//
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FavoriteIcon from "@mui/icons-material/Favorite";
// actions
//redux
import { useSelector, useDispatch } from "react-redux";
import { loginUser, resetLoginFlag } from "../../store/auth/login/actions";
import { useNavigate } from "react-router-dom";
import { LoginSlider } from "../../Components";
// Define the reusable style object
const customTextFieldStyle = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[600], // Set the border color
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.grey[700], // Set the border color on focus
    },
  },
  "& .MuiInputLabel-root": {
    color: "#828282", // Set the label color
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: theme.palette.grey[600], // Set the label color when focused
  },
  "& .MuiInputBase-input": {
    color: "#fff", // Set the text color
    fontSize: "16px",
    fontWeight: 400,
    letterSpacing: "1px",
  },
};

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, errorMsg, loading, error } = useSelector((state) => ({
    errorMsg: state.Login.errorMsg,
    loading: state.Login.loading,
    error: state.Login.error,
  }));
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const handleClickShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleSubmit = () => {
    // Validate email
    if (!email.trim()) {
      setEmailError("Email is required");
      return;
    }
    // You can add more sophisticated email validation here if needed

    // Validate password
    if (!password.trim()) {
      setPasswordError("Password is required");
      return;
    }

    dispatch(
      loginUser({ email: email.trim().toLowerCase(), password: password.trim() }, navigate)
    );
  };
  const handleKeyPress = (e) => {
    // e.preventDefault()
    if (e.key === "Enter") {
      handleSubmit();
    }
  };
  return (
    <React.Fragment>
      <Box sx={{ height: "100dvh" }}>
        <Grid container sx={{ height: "100%" }}>
          <Grid
            item
            sm={12}
            md={6}
            lg={3}
            sx={{
              display: "flex",
              backgroundColor: "#000",
              width: "100%",
              height: "100vh",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                m: "30px",
                color: "whitesmoke",
                gap: "80px",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: 700, color: theme.palette.grey[50] }}
                >
                  ATS
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: 500, color: theme.palette.grey[600] }}
                >
                  Tektree - Applicant Tracking System
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "40px",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{ fontWeight: 700, color: theme.palette.grey[600] }}
                >
                  Sign In
                </Typography>
                {errorMsg && errorMsg ? (
                  <Alert color="error"> {errorMsg} </Alert>
                ) : null}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "15px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    title="User Email"
                    label="User Email"
                    fullWidth
                    size="medium"
                    color="warning"
                    sx={customTextFieldStyle}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!!emailError}
                    helperText={emailError}
                  />

                  <FormControl variant="outlined" sx={customTextFieldStyle}>
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            sx={{ color: theme.palette.grey[600] }}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                      error={!!passwordError}
                      helperText={passwordError}
                      onKeyDown={handleKeyPress}
                    />
                  </FormControl>
                  <Button
                    variant="contained"
                    size="large"
                    component={Link}
                    // to="/dashboard"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: 500, color: theme.palette.grey[600] }}
                >
                  2024 Tektree. Crafted with{" "}
                  <FavoriteIcon color="error" fontSize="12" /> by Tektree
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            sm={12}
            md={6}
            lg={9}
            sx={{
              display: "flex",
              backgroundColor: "#000",
            }}
          >
            <LoginSlider />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default Login;
