export const ALL_RATEAM_COMPANIES_COUNT_VIA_PI='ALL_RATEAM_COMPANIES_COUNT_VIA_PI';
export const SUCCESS_ALL_RATEAM_COMPANIES_COUNT='SUCCESS_ALL_RATEAM_COMPANIES_COUNT';

export const DELETE_COMPANIESLIST='DELETE_COMPANIESLIST';

export const CLIENT_STATUS_VIA_API='CLIENT_STATUS_VIA_API';

export const GET_BDM_COMPANIES_VIA_API='GET_BDM_COMPANIES_VIA_API';
export const SUCCESS_BDM_COMPANIES='SUCCESS_BDM_COMPANIES';
export const GET_SENT_EMAILS_VIA_API='GET_SENT_EMAILS_VIA_API';
export const SUCCESS_GET_SENT_EMAILS='SUCCESS_GET_SENT_EMAILS';

export const JOB_STATUS_CHANGE_VIA_API='JOB_STATUS_CHANGE_VIA_API';

export const SEARCH_NOT_ACTIVES_COMPANIES_VIA_API='SEARCH_NOT_ACTIVES_COMPANIES_VIA_API';
export const SUCCESS_SEARCH_NOT_ACTIVES_COMPANIES='SUCCESS_SEARCH_NOT_ACTIVES_COMPANIES';

export const ADMIN_APPROVED_COMPANIES_VIA_API='ADMIN_APPROVED_COMPANIES_VIA_API';

export const DATE_SEARCH_BDMS_COMPANIES_VIA_API='DATE_SEARCH_BDMS_COMPANIES_VIA_API';
export const SUCCESS_DATE_SEARCH_BDMS_COMPANIES='SUCCESS_DATE_SEARCH_BDMS_COMPANIES';
export const OTHER_EMAILS_SENT_VIA_API='OTHER_EMAILS_SENT_VIA_API';

export const GET_ALL_EMAILS_LOGS_VIA_API='GET_ALL_EMAILS_LOGS_VIA_API';
export const SUCCESS_GET_ALL_EMAILS_LOGS='SUCCESS_GET_ALL_EMAILS_LOGS';

{/**11-07-24 */}
export const COMPANY_DNC_VIA_API='COMPANY_DNC_VIA_API';
export const EMPLOYEE_DNC_VIA_API='EMPLOYEE_DNC_VIA_API';

export const COMPANY_BLOCKED_VIA_API='COMPANY_BLOCKED_VIA_API';
export const EMPLOYEE_BLOCKED_VIA_API='EMPLOYEE_BLOCKED_VIA_API';
export const NO_FALLOWUPS_VIA_API='NO_FALLOWUPS_VIA_API';

export const APPROVAL_TRANSFER_VIA_API='APPROVAL_TRANSFER_VIA_API';
export const TRANSFER_BDM_VIA_API='TRANSFER_BDM_VIA_API';

export const COMPANY_BLOCKED_AND_DNC_VIA_API='COMPANY_BLOCKED_AND_DNC_VIA_API'
export const EMPLOYEE_BLOCKED_AND_DNC_VIA_API='EMPLOYEE_BLOCKED_AND_DNC_VIA_API'



