import { Box, Chip, Grid, IconButton, Paper, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BDMCustomeDateFilter, SectionTitle } from "../../Components";
import { DataGrid } from "@mui/x-data-grid";
import { SnackbarProvider } from "notistack";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import theme from "../../Theme";

import VisibilityIcon from '@mui/icons-material/Visibility';
import { searchBlockedDncCompaniesViaApi, searchBlockedDncEmailsViaApi } from "../../store/reports/action";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import moment from "moment-timezone";
import { getAllUsers } from "../../store/auth/login/actions";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
const AdminChangeCompanyStatus=()=>{
  const dispatch=useDispatch();
  const DncBlockedNoFollowUpCompanies = useSelector((state) => state.Reports?.dncBlockedNoFollowUpCompanies);
  const DncBlockedEmails = useSelector((state) => state.Reports?.dncBlockedEmails);

  const { storeData } = useSelector((state) => ({
    storeData: state.Login.storeData,
  }));
  const client=storeData.clientDB
  const action={
    client:client
  }
  dispatch(getAllUsers(action));
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const formatDateForDisplay = (date) => {

    const dateInNY = dayjs(date).tz('America/New_York');
    const isDST = moment.tz(date, 'America/New_York').isDST();
    const timeZoneAbbr = isDST ? 'EDT' : 'EST';
    return dateInNY.format(`MMMM DD, YYYY h:mm A [${timeZoneAbbr}]`);
  };
  let DncBlockedNoFollowUpCompaniesId = Array.isArray(DncBlockedNoFollowUpCompanies)
    ? DncBlockedNoFollowUpCompanies.map((company, index) => {
        // Find the user data for `addedBy`
        const userData = allUsers.find((user) => user._id === company.addedBy);
        return {
            ...company,
            addedBy: userData?.userSudoFirstName ?? "",
            addedOn: company?.addedOn ? formatDateForDisplay(company.addedOn) : "",
            id: index + 1,
        };
    })
    : [];
  let DncBlockedEmailsId = Array.isArray(DncBlockedEmails)
    ? DncBlockedEmails.map((company, index) => {
        // Find the user data for `addedBy`
        const userData = allUsers.find((user) => user._id === company.addedBy);
        return {
            ...company,
            addedBy: userData?.userSudoFirstName ?? "",
            addedOn: company?.addedOn ? formatDateForDisplay(company.addedOn) : "",
            id: index + 1,
        };
    })
    : [];

    
    const companyStatusColumns = [
        { field: "id", headerName: "ID", width: 70 },
        {
          field: "addedOn",
          headerName: "Date",
          width: 220,
      
        },
        {
          field: "companyName",
          headerName: "Company Name",
          minWidth: 150,
          flex: 1,
        //   renderHeader: (params) => (<FilterHeader params={params}  setSearchDashboardCompanyName={setSearchDashboardCompanyName} selectedValue={searchDashboardCompanyName} title={'dashBoardCompanyName'}/>),
        },
        {
          field: "addedBy",
          headerName: "RespondBy",
          minWidth: 150,
          flex: 1,
        //   renderHeader: (params) => <FilterHeader params={params}  type="multiselect" setSearchDashboardBDMName={setSearchDashboardBDMName} selectedValue={searchDashboardBDMName} title={'dashboardBdmName'} 
        //       options={[...new Set(companiesListWithStatus
        //         .filter(user => user.bdmName)
        //         .map(user => user.bdmName))]}
          
        //         />,
        },
        {
          field: "status",
        headerName: "Status",
      width: 150,
        
        renderCell: (params) => {
          const { row } = params;
          if (row) {
            let status = row.status;
           
            if (row.jobDetails && row.jobDetails.length > 0&& status !== 'DNC' &&
              status !== 'Blocked' &&
              status !== 'No FollowUp') {
              const company = row.jobDetails[0];
               status = company.status === 'Active' ? 'Open' : company.status;
            }
            return (
              <Box>
                <Chip
                  label={status}
                  size="small"
                  sx={{ px: 0.5 }}
                  color={
                    status === "Open"
                      ? "success"
                      : ["Blocked", "Close"].includes(status)
                      ? "error"
                      : ["DNC", "No FallowUp"].includes(status)
                      ? "warning"
                      : ["Interview L1", "Interview L2", "Interview L3"].includes(status)
                      ? "info"
                      : status === "Priority"
                      ? "secondary"
                      : "default"
                  }
                />
              </Box>
            );
          }
          return '';
        },
        // renderHeader: (params) => <FilterHeader params={params}  type="multiselect" setSearchDashboardStatus={setSearchDashboardStatus} selectedValue={searchDashboardStatus} title={'dashboardStatus'} 
        // options={['Open',,'DNC','No FallowUp','Close','Blocked','Hold','Interview L1','Interview L2','Interview L3']}
          
        //         />,
        },
        {
          field: "action",
          headerName: "Action",
          minWidth: 70,
         
          renderCell: (params) => (
            <Box
              sx={{
                display: "flex",
                gap: 0.5,
                justifyContent: "space-around",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Tooltip title="Add/Edit">
                <IconButton
                  size="small"
                  color="success"
                  // onClick={() => handleOpen(params.row.id)}
                >
                  <EditIcon fontSize="12" />
                </IconButton>
              </Tooltip>
              
              <Tooltip title="Delete">
                <IconButton
                  size="small"
                  color="error"
                  // onClick={() => toggleDeleteOrderModal(params.row.id)}
                >
                  <DeleteIcon fontSize="12" />
                </IconButton>
              </Tooltip>
            </Box>
          ),
          
          // valueGetter: (params) => {
          //   if (params && params.length > 0) {
          //     return params[0].closeReason;
          //   } else {
          //     return "--";
          //   }
          // },
        },
       
        
      ];
    
      const employeeStatusColumns = [
        { field: "id", headerName: "ID", width: 70 },
        {
          field: "addedOn",
          headerName: "Date",
          width: 200,
        },
        {
          field: "emailId",
          headerName: "EMail ID",
          minWidth: 150,
          flex: 1,
        //   renderHeader: (params) => (<FilterHeader params={params}  setSearchDashboardCompanyName={setSearchDashboardCompanyName} selectedValue={searchDashboardCompanyName} title={'dashBoardCompanyName'}/>),
        },
        {
          field: "addedBy",
          headerName: "RespondBy",
          minWidth: 150,
          flex: 1,
        //   renderHeader: (params) => <FilterHeader params={params}  type="multiselect" setSearchDashboardBDMName={setSearchDashboardBDMName} selectedValue={searchDashboardBDMName} title={'dashboardBdmName'} 
        //       options={[...new Set(companiesListWithStatus
        //         .filter(user => user.bdmName)
        //         .map(user => user.bdmName))]}
          
        //         />,
        },
        {
          field: "status",
        headerName: "Status",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => {
          const { row } = params;
          if (row) {
            let status = row.status;
            
            if (row.jobDetails && row.jobDetails.length > 0&& status !== 'DNC' &&
              status !== 'Blocked' &&
              status !== 'No FollowUp') {
              const company = row.jobDetails[0];
               status = company.status === 'Active' ? 'Open' : company.status;
            }
            return (
              <Box>
                <Chip
                  label={status}
                  size="small"
                  sx={{ px: 0.5 }}
                  color={
                    status === "Open"
                      ? "success"
                      : ["Blocked", "Close"].includes(status)
                      ? "error"
                      : ["DNC", "No FallowUp"].includes(status)
                      ? "warning"
                      : ["Interview L1", "Interview L2", "Interview L3"].includes(status)
                      ? "info"
                      : status === "Priority"
                      ? "secondary"
                      : "default"
                  }
                />
              </Box>
            );
          }
          return '';
        },
        // renderHeader: (params) => <FilterHeader params={params}  type="multiselect" setSearchDashboardStatus={setSearchDashboardStatus} selectedValue={searchDashboardStatus} title={'dashboardStatus'} 
        // options={['Open',,'DNC','No FallowUp','Close','Blocked','Hold','Interview L1','Interview L2','Interview L3']}
          
        //         />,
        },
        {
          field: "action",
          headerName: "Action",
          minWidth: 150,
          flex: 1,
          renderCell: (params) => (
            <Box
              sx={{
                display: "flex",
                gap: 0.5,
                justifyContent: "space-around",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Tooltip title="Add/Edit">
                <IconButton
                  size="small"
                  color="success"
                  // onClick={() => handleOpen(params.row.id)}
                >
                  <EditIcon fontSize="12" />
                </IconButton>
              </Tooltip>
              
              <Tooltip title="Delete">
                <IconButton
                  size="small"
                  color="error"
                  // onClick={() => toggleDeleteOrderModal(params.row.id)}
                >
                  <DeleteIcon fontSize="12" />
                </IconButton>
              </Tooltip>
            </Box>
          ),
          
          // valueGetter: (params) => {
          //   if (params && params.length > 0) {
          //     return params[0].closeReason;
          //   } else {
          //     return "--";
          //   }
          // },
        },
      ];
      const [searchFromDateCompanyStatus,setSearchFromDateCompanyStatus]=useState('');
      const [searchToDateCompanyStatus,setSearchToDateCompanyStatus]=useState('');

      const [searchFromEmailStatus,setSearchFromEmailStatus]=useState('')
      const [searchToEmailStatus,setSearchToEmailStatus]=useState('')
      const [loading,setLoading]=useState(false)
      const handleDateSearchCompanyStatus = (selectedDates) => {
        setSearchFromDateCompanyStatus("");
        setSearchToDateCompanyStatus("");
        setLoading(true);
        if (selectedDates.length === 2) {
          const formattedDates = selectedDates.map((date, index) => {
            return date.toISOString();
          });
          const [fromDate, toDate] = selectedDates;
          setSearchFromDateCompanyStatus(formattedDates[0]);
          setSearchToDateCompanyStatus(formattedDates[1]);
    
          // const action = {
          //   searchFromDate: formattedDates[0],
          //   searchToDate: formattedDates[1],
          // };
          const action = {
            searchFromDate: formattedDates[0],
            searchToDate: formattedDates[1],
            client:storeData.clientDB
          };
          console.log("action", action);
          dispatch(searchBlockedDncCompaniesViaApi(action));
        }
      };

      const handleDateSearchEmailStatus = (selectedDates) => {
        setSearchFromEmailStatus("");
        setSearchToEmailStatus("");
        setLoading(true);
        if (selectedDates.length === 2) {
          const formattedDates = selectedDates.map((date, index) => {
            return date.toISOString();
          });
          const [fromDate, toDate] = selectedDates;
          setSearchFromEmailStatus(formattedDates[0]);
          setSearchToEmailStatus(formattedDates[1]);
    
          // const action = {
          //   searchFromDate: formattedDates[0],
          //   searchToDate: formattedDates[1],
          // };
          const action = {
            searchFromDate: formattedDates[0],
            searchToDate: formattedDates[1],
            client:storeData.clientDB
          };
          console.log("action", action);
          dispatch(searchBlockedDncEmailsViaApi(action));
        }
      };
      useEffect(() => {
        handleDateSearchCompanyStatus([
          dayjs("2024-01-01").tz("America/New_York").startOf("day"),
          dayjs().tz("America/New_York").endOf("day"),
        ]);
        handleDateSearchEmailStatus([
          dayjs("2024-01-01").tz("Asia/Kolkata").startOf("day"),
          dayjs().tz("Asia/Kolkata").endOf("day"),
        ]);
      }, []);
    return(
        <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Dashboard"}>
        <Box>
             <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} xl={6}>
                  <Paper sx={{ p: 1, height: "calc(100% - 16px)" }}>  
                    <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "top",
                    }}
                  >
                    <Box>
                    <SectionTitle title="Companies List" size="sm" color="warning" />
                    </Box>

                    <BDMCustomeDateFilter
                      handleDateChange={handleDateSearchCompanyStatus}
                      tillDate={"Till Date"}
                    />
                  </Box>
                  <DataGrid
                      rows={DncBlockedNoFollowUpCompaniesId}
                      columns={companyStatusColumns}
                      disableColumnMenu
                      disableColumnSorting
                      disableColumnSelector
                      disableColumnFilter
                      disableColumnReorder
                      disableColumnResize
                    //   hideFooter={filterDNCData.length < 5}
                      disableColumnSort
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 25 },
                        },
                      }}
                      pageSizeOptions={[25,50,100]}
                      getRowHeight={() => "auto"}
                      autoHeight
                      sx={{
                        "& .MuiDataGrid-cell": {
                          display: "flex",
                          alignItems: "center !important",
                        },
                        [`& .MuiDataGrid-checkboxInput`]: {
                          color: theme.palette.primary.main,
                          p: 0,

                          "&.Mui-checked": {
                            color: theme.palette.primary.dark,
                          },
                        },
                        //Scroller
                        [`& .MuiDataGrid-scrollbar`]: {
                          scrollbarWidth: "thin",
                          scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                        },
                        [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]: {
                          borderRadius: "10px", // Apply border radius
                        },

                        [`& .MuiDataGrid-scrollbar:hover`]: {
                          scrollbarColor: `${theme.palette.primary.dark}`,
                        },
                      }}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={6}>
                  <Paper sx={{ p: 1, height: "calc(100% - 16px)" }}>
                    <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "top",
                    }}
                  >
                    <Box>
                    <SectionTitle title="Employee List" size="sm" color="error" />
                    </Box>

                    <BDMCustomeDateFilter
                      handleDateChange={handleDateSearchEmailStatus}
                      tillDate={"Till Date"}
                    />
                  </Box>
                    <DataGrid
                      rows={DncBlockedEmailsId}
                      columns={employeeStatusColumns}
                      disableColumnMenu
                      disableColumnSorting
                      disableColumnSelector
                      disableColumnFilter
                      disableColumnReorder
                      disableColumnResize
                      //disableRowSelectionOnClick
                    //   hideFooter={filterBlockedData.length < 10}
                      disableColumnSort
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 25 },
                        },
                      }}
                      pageSizeOptions={[25,50,100]}
                      getRowHeight={() => "auto"}
                      autoHeight
                      sx={{
                        "& .MuiDataGrid-cell": {
                          display: "flex",
                          alignItems: "center !important",
                        },
                        [`& .MuiDataGrid-checkboxInput`]: {
                          color: theme.palette.primary.main,
                          p: 0,

                          "&.Mui-checked": {
                            color: theme.palette.primary.dark,
                          },
                        },
                        //Scroller
                        [`& .MuiDataGrid-scrollbar`]: {
                          scrollbarWidth: "thin",
                          scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                        },
                        [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]: {
                          borderRadius: "10px", // Apply border radius
                        },

                        [`& .MuiDataGrid-scrollbar:hover`]: {
                          scrollbarColor: `${theme.palette.primary.dark}`,
                        },
                      }}
                    />
                  </Paper>
                </Grid>
                
              </Grid>

        </Box>
        </LayoutSidebarMenu>
        </SnackbarProvider>
        
        </React.Fragment>
    )
}

export default AdminChangeCompanyStatus;