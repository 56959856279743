import {
  CHECK_COMPANY_NAME,
  CLEAR_SNACKBAR_MESSAGE,
  DELETE_COMPANY_REDUX,
  DELETE_SEARCH,
  GET_COMPANIES_SUCCESS,
  REMOVE_COUNT_MAILS,
  REMOVE_NEW_COMPANY,
  SET_SNACKBAR_MESSAGE,
  SUCCESS_ALL_USERS_COMPANIES_LIST_VIA_API,
  SUCCESS_COUNT_SENT_MAILS,
  SUCCESS_DATE_SEARCH_USERS_COMPANIES,
  SUCCESS_GET_ALL_INDUSTRIES,
  SUCCESS_GET_ALL_TEMPLATES,
  SUCCESS_NEW_COMPANY,
  SUCCESS_SEARCH_DATA,
  SUCCESS_SHUFFLED_DATA,
  SUCCES_GLOBAL_SEARCH,
} from "./actionType";

const INIT_STATE = {
  searchData: [],
  snackbarMessage: {
    message: "",
    severity: "",
  },
  companiesList: [],
  AllCompaniesList:[],
  checkCompany:false,
  globalSearchData:[],
  industries:[],
  templates:[],
  emailCount:{},
  AllDateSearchComapniesList:[],
  shuffled:[],
  newCompany:{},
};
const cartReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SUCCESS_SEARCH_DATA: {
      const searchData = action.payload;
      // Reset the restaurant-related data to its initial state.
      return {
        ...state,
        searchData: searchData,
      };
    }

    case DELETE_SEARCH: {
      // Reset the restaurant-related data to its initial state.
      return INIT_STATE;
    }
    case SET_SNACKBAR_MESSAGE: {
      const { message, variant } = action.payload;

      return {
        ...state,
        snackbarMessage: {
          message,
          severity: variant || "", // Set severity or default to "success"
        },
      };
    }
    case CLEAR_SNACKBAR_MESSAGE: {
      return {
        ...state,
        snackbarMessage: {
          message: "",
          severity: "", // Reset severity to default
        },
      };
    }
    case GET_COMPANIES_SUCCESS: {
      return {
        ...state,
        companiesList: action.payload,
      };
    }
    case SUCCESS_ALL_USERS_COMPANIES_LIST_VIA_API:{
      return{
        ...state,
        AllCompaniesList:action.payload
      }
    }
    case CHECK_COMPANY_NAME:{
      return{
        ...state,
        checkCompany:action.payload,
        searchData: [],
      }
    }
    case DELETE_COMPANY_REDUX: {
      const _id = action.payload;
      const companiesListData = state.companiesList.filter(company => company._id !== _id);
      return {
        ...state,
        companiesList: companiesListData
      };
    }
    case SUCCES_GLOBAL_SEARCH: {
      const globalSearchData = action.payload;
      // Reset the restaurant-related data to its initial state.
      return {
        ...state,
        globalSearchData: globalSearchData,
      };
    }
    case SUCCESS_GET_ALL_INDUSTRIES:{
      const allIndutries=action.payload;
      return{
        ...state,
        industries:allIndutries,
      }
    }
    case SUCCESS_GET_ALL_TEMPLATES:{
      const temaplatesData=action.payload;
      return{
        ...state,
        templates:temaplatesData,
      }
    }
    case SUCCESS_COUNT_SENT_MAILS:{
      const emailDataCount=action.payload
      
      return{
        ...state,
        emailCount:emailDataCount
      }
    }
    case REMOVE_COUNT_MAILS:{
      return{
        ...state,
        emailCount:{},
      }
    }
    case SUCCESS_DATE_SEARCH_USERS_COMPANIES:{
      const AllData=action.payload
      return{
        ...state,
        AllDateSearchComapniesList:AllData,
      }
    }
    case SUCCESS_SHUFFLED_DATA:{
      const Shuffled=action.payload
      return{
        ...state,
        shuffled:Shuffled,
      }
    }
    case SUCCESS_NEW_COMPANY:{
      const newCompanyData=action.payload
      return{
        ...state,
        newCompany:newCompanyData,
      }
    }
    case REMOVE_NEW_COMPANY:{
      return{
        ...state,
        newCompany:{},
      }
    }
    default:
      return state;
  }
};

export default cartReducer;
