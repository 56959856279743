import { useMemo } from "react";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined"; //100+
import GraphicEqOutlinedIcon from "@mui/icons-material/GraphicEqOutlined"; //99-70
import TrendingDownOutlinedIcon from "@mui/icons-material/TrendingDownOutlined"; //-69

const useSetupLogic = (value, compairValue, messages, invert) => {
  const Setup = useMemo(
    () => [
      {
        color: "success", // 100+
        text: messages && messages[0] ? messages[0] : "Outstanding",
        icon: <TrendingUpOutlinedIcon color="success" fontSize="8" />,
      },
      {
        color: "secondary", // 90+
        text: messages && messages[1] ? messages[1] : "Excellent",
        icon: <GraphicEqOutlinedIcon color="secondary" fontSize="8" />,
      },
      {
        color: "info", // 80+
        text: messages && messages[2] ? messages[2] : "Good",
        icon: <GraphicEqOutlinedIcon color="info" fontSize="8" />,
      },
      {
        color: "warning", // 70+
        text: messages && messages[3] ? messages[3] : "Below Average",
        icon: <GraphicEqOutlinedIcon color="warning" fontSize="8" />,
      },
      {
        color: "error", // 0-69
        text: messages && messages[4] ? messages[4] : "Very Poor",
        icon: <TrendingDownOutlinedIcon color="error" fontSize="8" />,
      },
    ],
    [messages]
  );

  const getPercentage = ((value / compairValue) * 100).toFixed(1);
  const remainingPercentage = getPercentage - 100;

  let color = "inherit";
  let compairText = "";
  let icon = "";

  if (invert) {
    if (getPercentage <= 0) {
      color = Setup[0].color;
      compairText = Setup[0].text;
      icon = Setup[0].icon;
    } else if (getPercentage <= 10) {
      color = Setup[1].color;
      compairText = Setup[1].text;
      icon = Setup[1].icon;
    } else if (getPercentage <= 20) {
      color = Setup[2].color;
      compairText = Setup[2].text;
      icon = Setup[2].icon;
    } else if (getPercentage <= 30) {
      color = Setup[3].color;
      compairText = Setup[3].text;
      icon = Setup[3].icon;
    } else {
      color = Setup[4].color; // For getPercentage <= 69
      compairText = Setup[4].text;
      icon = Setup[4].icon;
    }
  } else {
    if (getPercentage >= 100) {
      color = Setup[0].color;
      compairText = Setup[0].text;
      icon = Setup[0].icon;
    } else if (getPercentage >= 90) {
      color = Setup[1].color;
      compairText = Setup[1].text;
      icon = Setup[1].icon;
    } else if (getPercentage >= 80) {
      color = Setup[2].color;
      compairText = Setup[2].text;
      icon = Setup[2].icon;
    } else if (getPercentage >= 70) {
      color = Setup[3].color;
      compairText = Setup[3].text;
      icon = Setup[3].icon;
    } else {
      color = Setup[4].color; // For getPercentage <= 69
      compairText = Setup[4].text;
      icon = Setup[4].icon;
    }
  }

  return { getPercentage, remainingPercentage, color, compairText, icon };
};

export default useSetupLogic;
