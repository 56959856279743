import { DELETE_LAYOUT, SUCCESS_USER_TYPE_ACCESS_LIST, USER_TYPE_ACCESS_LIST_SAGA } from "./actionType"




export const userTypeAccessListViaSaga=(action)=>{
  console.log('action',action)
    return{
      type:USER_TYPE_ACCESS_LIST_SAGA,
      payload:action
    }
  }
  export const successUserTypeAccessList=(reponse)=>{
    return{
      type:SUCCESS_USER_TYPE_ACCESS_LIST,
      payload:reponse
    }
  }

  export const deleteLayout=()=>{
    return{
      type:DELETE_LAYOUT,
      payload:{}
    }
  }