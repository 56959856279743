import { Box, Typography } from "@mui/material";
import React from "react";
import theme from "../../../Theme";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import TrendingDownOutlinedIcon from "@mui/icons-material/TrendingDownOutlined";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import PercentageCircle from "./PercentageCircle";
import Backgrounds from "../../Backgrounds/Backgrounds";
import useSetupLogic from "./useSetupLogic";

const Type1Layout = ({
  title,
  value,
  compairValue,
  //icon,  //is not required
  // color, //is based on value
  variant,
  shadow,
  invert,
  messages,
}) => {
  const { getPercentage, remainingPercentage, color, compairText, icon } =
    useSetupLogic(value, compairValue, messages, invert);
  return (
    <React.Fragment>
      <Backgrounds shadow={shadow} variant={variant} color={color}>
        <Box sx={{ mb: 1 }}>
          <Typography
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
              fontSize: 13,
              textTransform: "uppercase",
              letterSpacing: 0.5,
              color:
                variant === "contained"
                  ? theme.palette[color].contrastText
                  : theme.palette.grey[600],
              fontWeight: "500",
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", flexWrap: "wrap", alignItems: "flex-end" }}
          style={{ gap: 10 }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              width: "calc(100% - 90px)",
              minWidth: 100,
            }}
            style={{ gap: 3 }}
          >
            <Box
              sx={{ display: "flex", alignItems: "flex-end" }}
              style={{ gap: 3 }}
            >
              <Typography
                sx={{
                  color:
                    variant === "contained"
                      ? theme.palette[color].contrastText
                      : theme.palette.text[900],
                  fontSize: 36,
                  fontWeight: "bold",
                  lineHeight: 1,
                }}
              >
                {value}
              </Typography>
              <Typography>/</Typography>
              <Typography>{compairValue}</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              <Box
                sx={{
                  bgcolor: theme.palette.grey[100],
                  aspectRatio: 1,
                  width: 22,
                  borderRadius: 20,
                  p: 0.2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {icon}
              </Box>
              <Typography
                sx={{
                  color:
                    variant === "contained"
                      ? theme.palette[color].contrastText
                      : theme.palette[color].main,
                  fontSize: 12,

                  lineHeight: 1,
                }}
              >
                {remainingPercentage < 0
                  ? `${Math.abs(remainingPercentage.toFixed(1))}% `
                  : remainingPercentage > 0
                  ? `+ ${remainingPercentage.toFixed(1)}% `
                  : ""}
                {compairText}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: 60,
              aspectRatio: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <PercentageCircle
              value={isNaN(getPercentage) ? 0 : getPercentage}
              size={60}
              color={color}
              variant={variant}
            />
          </Box>
        </Box>
      </Backgrounds>
    </React.Fragment>
  );
};

export default Type1Layout;
