import { Alert, Box, Button, Snackbar, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { SnackbarProvider, enqueueSnackbar, useSnackbar } from "notistack";
const CustomSnackbar = () => {
  const [open, setOpen] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [time, setTime] = useState(0);
  const intervalRef = useRef(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState("");
  ///////////////////////////////////////
  const [time2, setTime2] = useState(0);
  const [isRunning2, setIsRunning2] = useState(false);
  const intervalRef2 = useRef(null);

  const handleClick = () => {
    setSnackbarColor("");
    setSnackbarMessage(`Some default snackbar Message`);
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  ///////////

  const start = () => {
    setIsRunning(true);
    intervalRef.current = setInterval(() => {
      setTime((prevTime) => prevTime + 10);
    }, 10);
  };

  const stop = () => {
    setIsRunning(false);
    clearInterval(intervalRef.current);
    setSnackbarColor("error"); // Change color to 'info' when stopped
    setSnackbarMessage(`Stopped at: ${formatTime(time)}`);
    setOpen(true);
  };

  const reset = () => {
    setIsRunning(false);
    clearInterval(intervalRef.current);
    setTime(0);
    setSnackbarColor("info"); // Change color to 'info' when stopped
    setSnackbarMessage(`Time is reset from : ${formatTime(time)}`);
    setOpen(true);
  };

  const formatTime = (timeInMilliseconds) => {
    const minutes = Math.floor((timeInMilliseconds / 60000) % 60)
      .toString()
      .padStart(2, "0");
    const seconds = Math.floor((timeInMilliseconds / 1000) % 60)
      .toString()
      .padStart(2, "0");
    const milliseconds = Math.floor((timeInMilliseconds % 1000) / 10)
      .toString()
      .padStart(2, "0");
    return `${minutes}:${seconds}:${milliseconds}`;
  };

  ///////////////////
  const start2 = () => {
    setIsRunning2(true);
    intervalRef2.current = setInterval(() => {
      setTime2((prevTime) => prevTime + 10);
    }, 10);
  };

  const stop2 = () => {
    setIsRunning2(false);
    clearInterval(intervalRef2.current);
    enqueueSnackbar(`Stopped at: ${formatTime(time2)}`, { variant: "success" });
  };

  const reset2 = () => {
    setIsRunning(false);
    clearInterval(intervalRef2.current);
    enqueueSnackbar(`Time is reset from : ${formatTime(time2)}`, {
      variant: "error",
    });
    setTime2(0);
  };

  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1",
            flexDirection: "column",
            flexWrap: "wrap",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" fontWeight={"600"}>
              Default Toast/Snackbar onclick
            </Typography>
            <Button variant="contained" onClick={handleClick}>
              Default Toast/Snackbar
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" fontWeight={"600"}>
              Default Toast/Snackbar inside the function
            </Typography>
            <Box>{formatTime(time)}</Box>
            <Box>
              {!isRunning ? (
                <Button onClick={start}>Start</Button>
              ) : (
                <Button onClick={stop}>Stop</Button>
              )}
              <Button onClick={reset}>Reset</Button>
            </Box>
            {/* Snackbar */}
            <Snackbar
              open={open}
              onClose={handleClose}
              autoHideDuration={4000}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert color={snackbarColor} onClose={handleClose}>
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" fontWeight={"600"}>
              Third party Toast/Snackbar onclick
            </Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  enqueueSnackbar("That was easy! info", {
                    variant: "info",
                    anchorOrigin: { horizontal: "right", vertical: "top" },
                  })
                }
              >
                Third party Notistack
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() =>
                  enqueueSnackbar("That was easy! error", {
                    variant: "error",
                    anchorOrigin: { horizontal: "right", vertical: "top" },
                  })
                }
                onClo
              >
                Third party Notistack
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box>{formatTime(time2)}</Box>
            <Box>
              {!isRunning2 ? (
                <Button onClick={start2}>Start</Button>
              ) : (
                <Button onClick={stop2}>Stop</Button>
              )}
              <Button onClick={reset2}>Reset</Button>
            </Box>
          </Box>
        </Box>
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default CustomSnackbar;
