import React, { useState } from "react";
import {
  Box,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import theme from "../Theme";
//////////////
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";

const TableFilterHeader = ({
  params,
  type = "line", // select, multiselect
  options = [],
  selectedValue = type === "multiselect" ? [] : "",
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  const SectionHeader = ({ title }) => (
    <InputLabel
      id={`${title}-label`}
      sx={{ fontSize: "12px", fontWeight: 600 }}
    >
      {title}
    </InputLabel>
  );
  return (
    <React.Fragment>
      {!showFilter && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Box flex={1}>
            <Typography
              noWrap
              sx={{
                backgroundColor: "#FFFFFF",
                fontSize: "15px",
                color: theme.palette.primary.main,
              }}
            >
              {params.headerName}
            </Typography>
          </Box>
          <Box>
            <IconButton size="small" onClick={handleShowFilter} color="primary">
              <FilterListIcon sx={{ fontSize: 14 }} />
            </IconButton>
          </Box>
        </Box>
      )}
      {showFilter && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box flex={1}>
            <SectionHeader title={params.headerName} />
            {type !== "line" ? (
              <Select
                labelId={`${params.headerName}-label`}
                id={params.headerName}
                label={params.headerName}
                MenuProps={{}}
                variant="standard"
                fullWidth
                size="small"
                // multiple={type === "multiselect" ? true : false}
                multiple={type === "multiselect"}
                value={selectedValue || []}
                sx={{ fontSize: "12px", fontWeight: 600 }}
                //onChange={handleSelectMultiple}
              >
                {options &&
                  options.map((item, key) => (
                    <MenuItem
                      value={item}
                      key={key}
                      sx={{ fontSize: "12px", fontWeight: 600 }}
                    >
                      {item}
                    </MenuItem>
                  ))}
              </Select>
            ) : (
              <TextField
                variant="standard"
                //label={`${params.colDef.headerName} Filter`}
                placeholder={`${params.headerName} Filter`}
                fullWidth
                InputProps={{
                  style: { fontSize: "12px" }, // Set font size for the input element
                }}
                //onChange={handleSearch}
              />
            )}
          </Box>
          <Box>
            <IconButton size="small" onClick={handleShowFilter} color="error">
              <FilterListOffIcon sx={{ fontSize: 14 }} />
            </IconButton>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

export default TableFilterHeader;
