
import { Box, Typography, useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";

// Mapping of time zones to country names and short codes
const timeZoneCountries = {
  "Asia/Kolkata": { country: "India", shortCode: "IST" },
  "America/New_York": { country: "United States", shortCode: "EST" },
  "Europe/London": { country: "United Kingdom", shortCode: "GB" },
  "Asia/Tokyo": { country: "Japan", shortCode: "JP" },
  // Add more mappings as needed
};

const DateTime = ({ timeZone = "Asia/Kolkata" }) => {
  const [dateTime, setDateTime] = useState(new Date());
  const theme = useTheme();

  useEffect(() => {
    const timer = setInterval(() => {
      setDateTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const getFormattedDateTime = (date, timeZone) => {
    const options = {
      //weekday: "long",
      weekday: "short",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      month: "long",
      day: "2-digit",
      year: "numeric",
      timeZone,
    };

    const formatter = new Intl.DateTimeFormat("en-US", options);
    const parts = formatter.formatToParts(date);

    const weekday = parts.find((part) => part.type === "weekday").value;
    const hour = parts.find((part) => part.type === "hour").value;
    const minute = parts.find((part) => part.type === "minute").value;
    const dayPeriod = parts.find((part) => part.type === "dayPeriod").value;
    const month = parts.find((part) => part.type === "month").value;
    const day = parts.find((part) => part.type === "day").value;
    const year = parts.find((part) => part.type === "year").value;

    return {
      weekday,
      time: `${hour}:${minute} ${dayPeriod}`,
      date: `${month} ${day}, ${year}`,
    };
  };

  const { weekday, time, date } = getFormattedDateTime(dateTime, timeZone);
  const { country, shortCode } = timeZoneCountries[timeZone] || {
    country: "Unknown",
    shortCode: "???",
  };

  const days = ["S", "M", "T", "W", "T", "F", "S"];
  const currentDayIndex = dateTime.getDay();
  // Note: JavaScript Date's getDay() returns 0 for Sunday, 1 for Monday, etc.

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
        {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {days.map((day, index) => (
            <Typography
              key={index}
              variant="caption"
              sx={{ lineHeight: 1 }}
              color={
                index === currentDayIndex
                  ? theme.palette.primary.dark
                  : theme.palette.grey[300]
              }
            >
              {day}
            </Typography>
          ))}
        </Box> */}
        <Box>
          <Typography
            sx={{
              lineHeight: 1,
              fontWeight: 600,
              fontSize: 16,
              color: theme.palette.grey[700],
            }}
          >
            {time} / {shortCode}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{ lineHeight: 1, color: theme.palette.grey[500], fontSize: 12 }}
          >
            {weekday} - {date}
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default DateTime;