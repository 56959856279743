const degreesOptions = [
    "Bachelor of Science",
    "Industrial Design/Spatial Design",
    "Bachelor's Degree, Computer Science, 4.0",
    "Bachelor of Commerce, Business and Accounting(started)",
    "MA and Ph.D, Communication Theory",
    "+2, Commerce",
    "+2, Maths, Physics and Chemistry",
    "+2, Science",
    ".NET Development Certificate",
    "1 Year Certificate, CCNA Program",
    "12th Std, Computer Science",
    "12th Std, High School/Secondary Diplomas and Certificates",
    "2 year degree, Electronics and Computer Science",
    "2 yr AAS, Computer Programming",
    "2005 4GL, Database",
    "AA Level / AS Level / BTEC / NVQA Level, Information Technology",
    "A lot of mathematics and science and a lot of everything else, too.",
    "A+ Certification, Information Technology",
    "A+ Certification/Microsoft Certified Technology Specialist, Computer and Information Sciences and Support Services",
    "A+ TRAINING CERTIFICATE, Microcomputer Repair",
    "A.A, Journalism",
    "A.A.S, Networking Technology (NET)",
    "A.A.S., Information Technology",
    "A.A.S., Network Technology and Security",
    "A.Arts",
    "A.S, General Studies",
    "A.S. in Digital Media & Web Development",
    "A.S",
    "A.A.S., Business Administration",
    "AA",
    "AA Degree, Persian Farsi",
    "AA, Business Technology",
    "AA, Computer Technology",
    "AA, Data Processing",
    "AA, Electronics",
    "AA, Health, Science, and Math",
    "AA, Paralegal",
    "AAS, Computer & Information Science",
    "AAS, Computer and Electronic Engineering Technology",
    "AAS, Electronics Engineering Technology",
    "AAS, Industrial Electronics",
    "AB, Business/Computers",
    "Accountancy",
    "Accounting",
    "Accounting Technology/Technician and Bookkeeping",
    "Advanced Study Program, Electrical Engineering & Computer Science",
    "Advanced High School Diploma",
    "Advanced Web Design Certification, Web Design",
    "A+",
    "Aeronautic Engineering Technology",
    "Aeronautics/Aviation/Aerospace Science and Technology, General",
    "AGILE Certification, Agile Product Owner, Silver Certified",
    "Agile Project Management, Computer Science, Management",
    "Agricultural Economics",
    "Airecrew Life Support Specialist, Aircrew Life Support",
    "AMSP, Multimedia Specialization",
    "Architecture, Art History, Psychology, Structural Engineering",
    "Architecture/Mechanical Design",
    "Artificial Intelligence",
    "AS, Computer Animation/Multimedia",
    "AS, Multimedia",
    "ASEET, Electrical Engineering Technology",
    "Associate of Science in MIS",
    "Associate",
    "Associate’s Degree",
    "Associate Degree, Cisco Certified Network Associate",
    "Associate Degree, Computer Science & Business Administration",
    "Associate Degree in Audio/Video Production",
    "Associate Degree in Liberal Arts & Science",
    "Associate Degree, EEG (Electroencephalography)",
    "Associate Degree of Technology, Electricity/Electronics",
    "Associate Degree, General Studies",
    "Associate of Applied Science (ADN), Registered Nursing (RN)",
    "Associate of Applied Science, Computer Information Systems",
    "Associate of Arts",
    "Associate of Arts (A.A.), Architecture",
    "Associate of Arts (A.A.), Film & TV: Production, 3.82 GPA (Overall)",
    "Associate of Arts (A.A.), Liberal Arts and Sciences, General Studies and Humanities",
    "Associate of Arts - AA, Business, Management, Marketing, and Related Support Services",
    "Associate of Arts and Sciences (A.A.S.)",
    "Associate of Arts and Sciences (A.A.S.), Business Administration",
    "Associate of Arts and Sciences (A.A.S.), Web Development",
    "Associate of Arts and Sciences (AAS) - Business Administration",
    "Associate of Arts and Sciences (AAS), Computer Science",
    "Associate of Arts and Sciences (AAS), Computer Science - Cyber Security",
    "Associate of Arts and Sciences (AAS), Data Processing",
    "Associate of Arts and Sciences (AAS), Information Technology",
    "Associate of Arts, Accounting",
    "Associate of Arts, Banking and Finance",
    "Associate of Science (A.S.) Information Technology",
    "Associate of Science (A.S.), Biology, General",
    "Associate of Science (A.S.), Computer and Information Sciences",
    "Associate of Science (A.S.), Computer Engineering",
    "Associate of Science (A.S.), Computer Engineering Technology/Technician, 3.0",
    "Associate of Science (A.S.), Computer Information Systems, Management",
    "Associate of Science (A.S.), Digital Media",
    "Associate of Science - AS",
    "Associate of Science - AS, Animation and Motion Graphics",
    "Associate of Science, Business Administration",
    "Associate of Science, Design",
    "Associate’s Degree, Business Administration and Management",
    "Associate’s Degree, Diploma in Accounting & Finance",
    "Associate’s Degree, Accounting",
    "Associate’s Degree, Chemical Engineering",
    "Associate’s Degree, Civil Engineering Technology/Technician",
    "Associate’s Degree, Computer and Information Sciences and Support",
    "Associate’s Degree, Computer Information Systems, 3.9",
    "Associate’s Degree, Computer Systems Networking and Administration",
    "Associate’s Degree, Computer Technology/Computer Systems Technology",
    "Associate’s Degree, Computer/Information Technology Administration",
    "Associate’s Degree, Culinary Arts and Related Services",
    "Associate’s Degree, Data Processing",
    "Associate’s Degree, Database Management",
    "Associate’s Degree, Digital Communication and Media/Multimedia",
    "Associate’s Degree, General Studies, 4.8",
    "Associate’s Degree, Information Technology",
    "Associate’s Degree, Information Technology - Computer Network Systems, 3.5 GPA",
    "Associate’s Degree, Liberal Arts and Sciences/Liberal Studies",
    "Associate’s Degree, Mechanical Drafting and Pipe Drafting CAD/CADD, 2.9 GPA",
    "Associate’s Degree, Network Specialist, 3.7",
    "Associate’s Degree, Specialized Business, 4.0",
    "Associate’s in Applied Science, Digital and Microprocessor Technology",
    "Associate, Applied Business Computer Information Systems",
    "Associate, Business Administration & Economics",
    "Associate, Electronic Engineering",
    "Associate, Graphic Design",
    "Associates Degree",
    "Associates in Applied Science, Software Development Technology",
    "Associates in Arts Degree, Business Administration",
    "Associates Not Completed",
    "Associates of Applied Science"
];
const currencyCodes =["USD", "INR", "CAD"]
const payScheduleTypes =["Hourly","Daily","Weekly","Bi-Weekly","Monthly","Yearly"]
const employmentTypes =["1099","C2C","C2C","Full Time","In-cop","Intern","Other","Part Time","W-2"]
const remoteJobOptions=["Yes","No","Hybrid"]
const jobStatusOptions=["Active","Closed","Filled","Hold by Client","On Hold"]
const jobTypeOptions=["Full Time","Cantract","Part Time"]
const requiredDocumentsOptions=
[
"Driving License",
"EML File",
"Passport",
"Resume",
"SSN",
"Transcripts"
]
const turnaroundTimeOptions=["In Hours","In Days"]
const priorityOptionsOptions=["Critical","High","Low","Medium"]
const workAuthorizationOptions=
[
"B1",
"Bangladesh Authorized", "Brazil Authorized",
"Can work for any employer",
"Canada Authorized",
"Canadian Citizen",
"Canadian Citizenship",
"China Authorized",
"Citizen",
"Colombia Authorized",
"Current Employer Only",
"Employment Auth Document",
"Employment Auth. Document",
"Employment Authorization Document",
"EU Eligible",
"GC",
"GC-EAD",
"Germany Authorized",
"Green Card",
"Green Card Holder",
"H1-B",
"H4 EAD",
"Have H1",
"Have H1 Visa",
"India Authorized",
"Ireland Authorized",
"Italy Authorized",
"Kuwait Authorized",
"L1-A",
"L1-B",
"L2-EAD",
"Luxembourg Authorized",
"Macedonia Authorized",
"Mexico Authorized",
"Need H1",
"Need H1 Visa",
"Need H1 Visa Sponsor",
"Not specified",
"OPT-EAD",
"Pakistan Authorized",
"Poland Authorized",
"Russian Federation Authorized",
"South Africa Authorized",
"Sponsorship Required",
"TN Permit Holder",
"TN Visa",
"Turkey Authorized",
"Ukraine Authorized",
"United Arab Emirates Authorized",
"United Kingdom Authorized",
"US Authorized",
"US Citizen",
"US Citizenship"
]
const industryOptions=[
    "Accounting - Finance",
    "Advertising",
    "Agriculture",
    "Airline - Aviation",
    "Architecture - Building",
    "Art - Photography - Journalism",
    "Automotive - Motor Vehicles - Parts",
    "Banking - Financial Services",
    "Biotechnology",
    "Broadcasting - Radio - TV",
    "Building Materials",
    "Chemical",
    "Computer Hardware",
    "Computer Software",
    "Construction",
    "Consulting",
    "Consumer Products",
    "Credit - Loan - Collections",
    "Defense - Aerospace",
    "Education - Teaching - Administration",
    "Electronics",
    "Employment - Recruiting - Staffing",
    "Energy - Utilities - Gas - Electric",
    "Entertainment",
    "Environmental",
    "Exercise - Fitness",
    "Fashion - Apparel - Textile",
    "Food",
    "Funeral - Cemetery",
    "Government - Civil Service",
    "Healthcare - Health Services",
    "Homebuilding",
    "Hospitality",
    "Hotel - Resort",
    "HVAC",
    "Import - Export",
    "Industrial",
    "Insurance",
    "Internet - ECommerce",
    "Landscaping",
    "Law Enforcement",
    "Legal",
    "Library Science",
    "Managed Care",
    "Manufacturing",
    "Medical Equipment",
    "Merchandising",
    "Military",
    "Mortgage",
    "Newspaper",
    "Not for Profit - Charitable",
    "Office Supplies - Equipment",
    "Oil Refining - Petroleum - Drilling",
    "Other Great Industries",
    "Packaging",
    "Pharmaceutical",
    "Printing - Publishing",
    "Public Relations",
    "Real Estate - Property Mgt",
    "Recreation",
    "Restaurant",
    "Retail",
    "Sales - Marketing",
    "Securities",
    "Security",
    "Semiconductor",
    "Social Services",
    "Telecommunications",
    "Training",
    "Transportation",
    "Travel",
    "Wireless"
    ]
    const languagesOptions=
    [
        "Arabic",
        "Chinese",
        "English",
        "French",
        "German",
        "Hindi",
        "Italian",
        "Japanese",
        "Korean",
        "Mandarin",
        "Persian",
        "Portuguese",
        "Russain",
        "Spanish",
        "Swedish",
        "Trukish",
        "Vietnamese"
        ]
        const taxTermsOptions=[
            "1099",
            "C2C",
            "C2H",
            "Full Time",
            "In-cop",
            "Intern",
            "OtherPart",
            "Time",
            "W-2"
            ]
         const  states = [
                "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", 
                "Connecticut","District of Columbia", "Delaware", "Florida", "Georgia", "Hawaii","Iowa", "Idaho", 
                "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", 
                "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", 
                "Mississippi", "Missouri", "Montana","North Carolina","North Dakota", "Nebraska", "Nevada", "New Hampshire", 
                "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", 
                "Ohio", "Oklahoma", "Oregon","Puerto Rico", "Pennsylvania", "Rhode Island", 
                "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", 
                "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming","U.S. Virgin Islands",
              ];
              const countries = [
                "Afghanistan", "Aland Islands", "Albania", "Algeria", "American Samoa", 
                "Andorra", "Angola", "Anguilla", "Antarctica", "Antigua and Barbuda", 
                "Argentina", "Armenia", "Aruba", "Australia", "Austria", "Azerbaijan", 
                "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", 
                "Belize", "Benin", "Bermuda", "Bhutan", "Bolivia", "Bosnia and Herzegovina", 
                "Botswana", "Bouvet Island", "Brazil", "British Indian Ocean Territory","British Virgin Islands", 
                "Brunei Darussalam", "Bulgaria", "Burkina Faso", "Burundi","CA", "Cabo Verde", 
                "Cambodia", "Cameroon", "Canada", "Cayman Islands", "Central African Republic", 
                "Chad", "Chile", "China", "Christmas Island", "Cocos (Keeling) Islands", 
                "Colombia", "Comoros", "Congo", "Congo (Democratic Republic)", "Cook Islands", 
                "Costa Rica", "Croatia", "Cuba", "Curaçao", "Cyprus", "Czech Republic", 
                "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt", 
                "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", 
                "Ethiopia", "Falkland Islands", "Faroe Islands", "Fiji", "Finland", 
                "France", "French Guiana", "French Polynesia", "French Southern Territories", 
                "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Gibraltar", 
                "Greece", "Greenland", "Grenada", "Guadeloupe", "Guam", "Guatemala", 
                "Guernsey", "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Heard Island and McDonald Islands", 
                "Holy See", "Honduras", "Hong Kong", "Hungary", "Iceland", "India", 
                "Indonesia", "Iran", "Iraq", "Ireland", "Isle of Man", "Israel", 
                "Italy", "Ivory Coast", "Jamaica", "Japan", "Jersey", "Jordan", 
                "Kazakhstan", "Kenya", "Kiribati", "Kuwait", "Kyrgyzstan", "Laos", 
                "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", 
                "Lithuania", "Luxembourg", "Macao", "Madagascar", "Malawi", "Malaysia", 
                "Maldives", "Mali", "Malta", "Marshall Islands", "Martinique", "Mauritania", 
                "Mauritius", "Mayotte", "Mexico", "Micronesia", "Moldova", "Monaco", 
                "Mongolia", "Montenegro", "Montserrat", "Morocco", "Mozambique", 
                "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "New Caledonia", 
                "New Zealand", "Nicaragua", "Niger", "Nigeria", "Niue", "Norfolk Island", 
                "North Korea", "North Macedonia", "Northern Mariana Islands", "Norway", 
                "Oman", "Pakistan", "Palau", "Palestine", "Panama", "Papua New Guinea", 
                "Paraguay", "Peru", "Philippines", "Pitcairn Islands", "Poland", 
                "Portugal", "Puerto Rico", "Qatar", "Réunion", "Romania", "Russia", 
                "Rwanda", "Saint Barthélemy", "Saint Helena", "Saint Kitts and Nevis", 
                "Saint Lucia", "Saint Martin", "Saint Pierre and Miquelon", 
                "Saint Vincent and the Grenadines", "Samoa", "San Marino", 
                "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", 
                "Seychelles", "Sierra Leone", "Singapore", "Sint Maarten", "Slovakia", 
                "Slovenia", "Solomon Islands", "Somalia", "South Africa", "South Georgia", 
                "South Korea", "South Sudan", "Spain", "Sri Lanka", "Sudan", 
                "Suriname", "Svalbard and Jan Mayen", "Sweden", "Switzerland", 
                "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Timor-Leste", 
                "Togo", "Tokelau", "Tonga", "Trinidad and Tobago", "Tunisia", 
                "Turkey", "Turkmenistan", "Turks and Caicos Islands", "Tuvalu", 
                "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", 
                "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Venezuela", 
                "Vietnam", "Wallis and Futuna", "Western Sahara", "Yemen", "Zambia", "Zimbabwe"
              ];
              
export { degreesOptions,currencyCodes,payScheduleTypes,employmentTypes,remoteJobOptions,jobStatusOptions,jobTypeOptions,
    requiredDocumentsOptions,turnaroundTimeOptions,priorityOptionsOptions,workAuthorizationOptions,industryOptions,
    languagesOptions,taxTermsOptions,states,countries,
 };