import React from "react";
import { Box, Divider, Grid } from "@mui/material";
import MainBackground from "../Backgrounds/MainBackground";
import SectionTitle from "../Helper/SectionTitle";
import KeyData from "../Helper/KeyData";

const CustomDetailViewSection = ({
  title = "Section Title",
  data = [],
  headerData,
  bodyData,
}) => {
  return (
    <React.Fragment>
      <MainBackground>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {typeof headerData === "function" ? (
            <Box>{headerData()}</Box>
          ) : (
            <Box>
              <SectionTitle title={title} />
            </Box>
          )}

          <Divider />
          {typeof bodyData === "function" ? (
            <Box>{bodyData()}</Box>
          ) : data.length <= 0 ? (
            "No data available"
          ) : (
            <Grid container spacing={2}>
              {data.map((item, index) =>
                Object.entries(item).map(([key, value], subIndex) => (
                  <Grid key={`${index}-${subIndex}`} item xs={2}>
                    <KeyData title={key} value={value} />
                  </Grid>
                ))
              )}
            </Grid>
          )}
        </Box>
      </MainBackground>
    </React.Fragment>
  );
};

export default CustomDetailViewSection;
