import{
    SUCCESS_RA_REPORTS,
    DELETE_REPORTS,
    SUCCESS_PRODUCTION_DATE_SEARCH,
    LOADING_VIA_REDUX,
    STOP_LOADING_REDUX,
    SUCCESS_SEARCH_DATE_BDM_COMPANY_STATUS,
    SUCCESS_GET_DESKTOP_CLIENTS,
    SUCCESS_BDM_REPORT_DATE_SEARCH,
    SUCCESS_SEARCH_BLOCKED_DNC_NOFALLOWUP_COMPANIES,
    SUCCESS_SEARCH_BLOCKED_DNC_EMAILS,
    SUCCESS_BDM_WEEK_REPORT,
    SUCCESS_RA_WEEK_REPORTS,
    SUCCESS_YESTERDAY_RA_REPORTS
} from "./actionType"


const INIT_STATE = {
    raReports:[],
    productionData:[],
    loadingRedux:false,
    companyStatus:[],
    desktopClients:[],
    bdmReports:[],
    dncBlockedNoFollowUpCompanies:[],
    dncBlockedEmails:[],
    bdmWeekReports:[],
    raWeekReports:[],
    yesterDayRAReports:[],
  };
  const reports = (state = INIT_STATE, action) => {
    switch (action.type) {
      case SUCCESS_RA_REPORTS: {
        const raReportsData = action.payload;
        // Reset the restaurant-related data to its initial state.
        return {
          ...state,
          raReports: raReportsData,
        };
      }
      case SUCCESS_BDM_REPORT_DATE_SEARCH: {
        const bdmReportsData = action.payload;
        // Reset the restaurant-related data to its initial state.
        return {
          ...state,
          bdmReports: bdmReportsData,
        };
      }
      case SUCCESS_PRODUCTION_DATE_SEARCH:{

        const reposeData=action.payload;
        return{
          ...state,
          productionData:reposeData,
        }
      }
      case LOADING_VIA_REDUX:{

        const repose=action.payload;
        return{
          ...state,
          loadingRedux:repose,
        }
      }
      case STOP_LOADING_REDUX:{

        const repose=action.payload;
        return{
          ...state,
          loadingRedux:repose,
        }
      }
      case SUCCESS_SEARCH_DATE_BDM_COMPANY_STATUS:{

        const reposeData=action.payload;
        return{
          ...state,
          companyStatus:reposeData,
        }
      }
      case SUCCESS_GET_DESKTOP_CLIENTS:{

        const reposeData=action.payload;
        return{
          ...state,
          desktopClients:reposeData,
        }
      }
      case SUCCESS_SEARCH_BLOCKED_DNC_NOFALLOWUP_COMPANIES:{
        const reposeData=action.payload;
        return{
          ...state,
          dncBlockedNoFollowUpCompanies:reposeData,
        }
      }
      case SUCCESS_SEARCH_BLOCKED_DNC_EMAILS:{
        const reposeData=action.payload;
        return{
          ...state,
          dncBlockedEmails:reposeData,
        }
      }
      case SUCCESS_BDM_WEEK_REPORT:{
        const reposeData=action.payload;
        return{
          ...state,
          bdmWeekReports:reposeData,
        }
      }
      case SUCCESS_RA_WEEK_REPORTS:{
        const reposeData=action.payload;
        return{
          ...state,
          raWeekReports:reposeData,
        }
      }
      case SUCCESS_YESTERDAY_RA_REPORTS:{
        const reposeData=action.payload;
        return{
          ...state,
          yesterDayRAReports:reposeData,
        }
      }
    
      case DELETE_REPORTS: {
        
        return INIT_STATE;
      }
      default:
        return state;
    }
  };
  
  export default reports;
  